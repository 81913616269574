import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { AccountRooms } from '../../../redux/modules/Data/selectors';

import { IsFileCabinetShowing } from '../../../redux/modules/Profile/selectors';
import ModuleDict from '../../../utils/ModuleDict';
// import { SidebarNavLink, SidebarNavLinkCompany } from '../../Sidebar';
import { SidebarNavLink } from '../../Sidebar';

const DashboardNav = ({ account_id, isCollapsed }) => {
  const { pathname } = useLocation();
  const { moduleId } = useParams();
  const isCabinetShowing = useSelector(IsFileCabinetShowing);
  const rooms = useSelector(AccountRooms);

  const isRoomsViewShowing = rooms.length > 0 || pathname.includes('data-room');

  return (
    <div className="sidebar__nav">
      {/* <SidebarNavLinkCompany isCollapsed={isCollapsed} link={ModuleDict['-1'].path} /> */}
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['home'].icon}
        isActive={(match, location) =>
          pathname.includes('home') ||
          moduleId === 'home' ||
          moduleId === '0' ||
          moduleId === '-1'
        }
        isCollapsed={isCollapsed}
        link={ModuleDict['home'].path}
        text={ModuleDict['home'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['workflows'].icon}
        isActive={(match, location) =>
          pathname.includes('workflows') || moduleId === 'workflows'
        }
        isCollapsed={isCollapsed}
        link={ModuleDict['workflows'].path}
        text={ModuleDict['workflows'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['tasks'].icon}
        isActive={(match, location) => pathname.includes('tasks') || moduleId === 'tasks'}
        isCollapsed={isCollapsed}
        id={ModuleDict['tasks'].id}
        link={ModuleDict['tasks'].path}
        text={ModuleDict['tasks'].label}
      />
      {isRoomsViewShowing && (
        <SidebarNavLink
          account_id={account_id}
          icon={ModuleDict['rooms'].icon}
          isCollapsed={isCollapsed}
          id={ModuleDict['rooms'].id}
          link={ModuleDict['rooms'].path}
          text={ModuleDict['rooms'].label}
        />
      )}
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['documents'].icon}
        isCollapsed={isCollapsed}
        id={ModuleDict['documents'].id}
        link={ModuleDict['documents'].path}
        text={ModuleDict['documents'].label}
      />
      <SidebarNavLink
        account_id={account_id}
        icon={['fal', 'info-circle']}
        isCollapsed={isCollapsed}
        link={'/reports'}
        text="Reports"
      />
      <div className="sidebar__divider" />
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['10'].icon}
        isCollapsed={isCollapsed}
        link={ModuleDict['10'].path}
        text={ModuleDict['10'].label}
      />
      {isCabinetShowing && (
        <SidebarNavLink
          account_id={account_id}
          icon={['fal', 'file-alt']}
          isCollapsed={isCollapsed}
          link={'/cabinet'}
          text="File Cabinet"
        />
      )}
      <SidebarNavLink
        account_id={account_id}
        icon={ModuleDict['account'].icon}
        isCollapsed={isCollapsed}
        link={ModuleDict['account'].path}
        text={ModuleDict['account'].label}
      />
    </div>
  );
};

export default DashboardNav;
