import React from 'react';

import classnames from 'classnames';

import InputStyledCheckbox from '../../common/InputStyledCheckbox/InputStyledCheckbox';
import { InputFeedback, InputHelp, InputSublabel } from '..';

import './InputCheckboxGroup.scss';
import { isArray } from 'lodash-es';

const CheckboxGroup = ({
  value,
  name,
  error,
  onChange,
  children,
  className,
  isDisabled,
  label,
  options,
  questionHelp,
  sublabel,
  type,
  ...otherProps
}) => {
  const updatedOptions = isArray(options)
    ? options
    : options.split(',').map(e => ({ label: e, value: e }));
  let valuesArr = type === 'radio' ? [value] : (value || '').split('|');
  const handleChange = ({ target }) => {
    const optionIndex = updatedOptions.findIndex(
      e => e === target.value || e.label === target.value,
    );
    if (type === 'radio') {
      return onChange(updatedOptions[optionIndex].value);
    }
    let checked = valuesArr.indexOf(target.value) !== -1;
    if (!valuesArr[0]) {
      valuesArr.pop();
    }
    if (!checked) {
      valuesArr.push(target.value);
    } else {
      valuesArr.splice(valuesArr.indexOf(target.value), 1);
    }
    return onChange(valuesArr.join('|'));
  };

  const classes = classnames(
    'input-checkbox-group__container',
    {
      'input-checkbox-group__container--error': !!error,
      'input-checkbox-group__container--disabled': isDisabled,
    },
    className,
  );

  const labelClass = 'input-checkbox-group__label';
  return (
    <div className={classes}>
      <div className="input-checkbox-group">
        <label className={labelClass}>
          {label}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
        {children ||
          updatedOptions.map((option, index) => {
            let checked = option.value === value;
            if (type === 'checkbox') {
              checked = valuesArr.indexOf(option.value) !== -1;
            }
            return (
              <InputStyledCheckbox
                key={'onboarding-checkbox-' + index}
                checked={checked}
                isDisabled={isDisabled}
                label={option.label}
                id={`${name}__${option.label}`}
                name={`${name}__${option.label}`}
                onChange={handleChange}
                type={type}
                value={option.label}
              />
            );
          })}
      </div>
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
    </div>
  );
};

CheckboxGroup.defaultProps = {
  sublabel: null,
  error: null,
};

export default CheckboxGroup;

// Children should be an array of Formik Field Components passed down
