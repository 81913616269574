import * as Types from './types';

export function resetErrors() {
  return {
    type: Types.RESET_ERRORS,
  };
}

export function setErrors(errors) {
  return {
    type: Types.SET_ERRORS,
    payload: { errors },
  };
}

// export function mergeTaskValuesToQuestionnaire(key, values = {}) {
//   return {
//     type: Types[`MERGE_TASK_VALUES`],
//     payload: { key, values },
//   };
// }
// export function setValue(key, payload) {
//   const upperCaseKey = key.replace(/ /g, '_').toUpperCase();
//   return {
//     type: Types[`SET_${upperCaseKey}`],
//     payload,
//   };
// }

// export function removeValue(key, payload) {
//   const upperCaseKey = key.replace(/ /g, '_').toUpperCase();
//   return {
//     type: Types[`REMOVE_${upperCaseKey}`],
//     payload,
//   };
// }

// export function setValue(key, value) {
//   return {
//     type: Types[`SET_VALUE`],
//     payload: { key, value },
//   };
// }

// export function removeValue(key, value) {
//   return {
//     type: Types[`REMOVE_VALUE`],
//     payload: { key, value },
//   };
// }

/**
 * General action that handles updating a specific data model.
 * Can be used to get Module, Task, Subtask, or Deliverable
 *
 * @param {('module'|'task'|'subtask'|'deliverable')} modelName
 *  :: used to match a Type to the action. (single)
 * @return {object} Action object with 'type' property.
 */
export function setAccountProject(payload) {
  return {
    type: Types['SET_ACCOUNT_PROJECT'],
    payload,
  };
}

export function setAccountProjects(payload) {
  return {
    type: Types['SET_ACCOUNT_PROJECTS'],
    payload,
  };
}

export function setProjects(payload) {
  return {
    type: Types['SET_PROJECTS'],
    payload,
  };
}
