import * as Types from './types';

export const setLink = link => ({ type: Types['SET_LINK'], link });

export const setFeaturesForRoom = (payload, key) => ({
  type: Types['SET_FEATURES_FOR_ROOM'],
  payload,
  key,
});

export const setRoom = (payload, accessCode) => ({
  type: Types['SET_ROOM'],
  payload,
  accessCode,
});

export const setUserRooms = payload => ({
  type: Types['SET_USER_ROOMS'],
  payload,
});

export const setRoomCodes = (payload, roomId) => ({
  type: Types['SET_ROOM_CODES'],
  payload,
  roomId,
});
export const setRoomDocuments = (payload, roomId) => ({
  type: Types['SET_ROOM_DOCUMENTS'],
  payload,
  roomId,
});
export const setRoomLinks = (payload, roomId) => ({
  type: Types['SET_ROOM_LINKS'],
  payload,
  roomId,
});

export const setRoomAccessLog = (payload, roomId) => ({
  type: Types['SET_ROOM_ACCESS_LOG'],
  payload,
  roomId,
});

export const setRoomHistory = (payload, roomId) => ({
  type: Types['SET_ROOM_HISTORY'],
  payload,
  roomId,
});

export const setRoomParticipants = (payload, roomId) => ({
  type: Types['SET_ROOM_PARTICIPANTS'],
  payload,
  roomId,
});

export const setRoomToken = (payload, accessCode) => ({
  type: Types['SET_ROOM_TOKEN'],
  payload,
  accessCode,
});
