import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { InputFeedback, InputHelp, InputSublabel } from '../';

import './InputTextarea.scss';

const bem = elementName => `input-textarea${elementName ? '__' + elementName : ''}`;

const InputTextarea = ({
  autoComplete = 'on',
  customClass,
  defaultPlaceholder,
  value,
  label,
  name,
  isDisabled,
  isFixed,
  isRequired,
  sublabel,
  error,
  type,
  onChange,
  onBlur,
  placeholder,
  questionHelp,
}) => {
  if (placeholder === true) {
    placeholder = label;
  }
  return (
    <div
      className={classnames(bem('container'), {
        [bem('container--error')]: !!error,
      })}
    >
      {label && (
        <label className={bem('label')} htmlFor={name}>
          {label}
          {!!isRequired && <span className="workbench-form__required">*</span>}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
      )}
      <textarea
        autoComplete={autoComplete}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder || defaultPlaceholder}
        className={classnames(customClass, bem())}
        disabled={isFixed || isDisabled}
      />
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
    </div>
  );
};

InputTextarea.propTypes = {
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InputTextarea.defaultProps = {
  isDisabled: false,
  placeholder: '',
  sublabel: null,
  error: null,
  name: 'input',
  type: 'text',
  label: 'label',
  value: '',
  icon: false,
  isRequired: false,
  onBlur: val => null,
  onChange: val => null,
};

export default InputTextarea;
