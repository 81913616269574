import React from 'react';

import './InputHelp.scss';
import { TooltipAction } from '../../common';

const InputHelpButton = ({ text }) => {
  return <TooltipAction text={text} />;
};

export default InputHelpButton;
