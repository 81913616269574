import React from 'react';
import { useHistory } from 'react-router';

import classnames from 'classnames';

import Button from '../common/Button';

import './WorkbenchActions.scss';
import handlePath from '../../utils/handlePath';
import getPortalType from '../../utils/getPortalType';

const WorkbenchActions = props => {
  const history = useHistory();

  if (props.isSharingForm) {
    return <SharingFormButtons {...props} />;
  } else if (props.isForm) {
    return <FormButtons {...props} history={history} />;
  } else if (props.isFinalize) {
    return <FinalizeButtons {...props} history={history} />;
  } else {
    return <TrackingButtons {...props} history={history} />;
  }
};

const SharingFormButtons = ({
  buttonSize,
  onPrimaryClick,
  onSecondaryClick,
  isFetching,
}) => (
  <div className={classnames('workbench-actions')}>
    <Button size="md" buttonType="secondary" onClick={onSecondaryClick}>
      Cancel
    </Button>
    <Button size="md" onClick={onPrimaryClick} isFetching={isFetching}>
      Create Shared Form
    </Button>
  </div>
);

const FormButtons = ({
  buttonSize,
  history,
  isAssessment,
  isDisabled,
  isFetching,
  isInitQuestionnaire,
  isModal,
  params,
  projectTemplateId,
}) => {
  const portalType = getPortalType(projectTemplateId, true, false);
  const actionLabel =
    (isAssessment && 'Generate Tasks') ||
    (isModal && !!portalType && `Add ${portalType}`) ||
    (isModal && `Add Individual`) ||
    'Review';

  return (
    <div className={classnames('workbench-actions')}>
      {isInitQuestionnaire && (
        <Button
          size="md"
          buttonType="secondary"
          isDisabled={isDisabled}
          onClick={() => history.push(handlePath('/home', params.companyId))}
        >
          Save & Exit
        </Button>
      )}
      {!isInitQuestionnaire && (
        <Button
          size="md"
          buttonType="secondary"
          isDisabled={isDisabled}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      )}
      <Button size="md" type="submit" isDisabled={isDisabled} isFetching={isFetching}>
        {actionLabel}
      </Button>
    </div>
  );
};

const TrackingButtons = ({
  buttonSize,
  handleNextTaskAction,
  handleSecondaryAction,
  isFetching,
  isDisabled,
  isOverview,
  isSecondaryHiding,
  primaryActionText,
  secondaryActionText,
}) => (
  // <div className={classnames('workbench-actions workbench-actions--single')}>
  <div
    className={classnames('workbench-actions', {
      'workbench-actions--single': !isOverview || isSecondaryHiding,
    })}
  >
    {isOverview && !isSecondaryHiding && (
      <Button
        size="md"
        buttonType="secondary"
        onClick={handleSecondaryAction}
        isFetching={isFetching}
        isDisabled={isFetching}
      >
        {secondaryActionText}
      </Button>
    )}
    <Button
      size="md"
      type="submit"
      onClick={handleNextTaskAction}
      isFetching={isFetching}
      isDisabled={isFetching || isDisabled}
    >
      {primaryActionText}
    </Button>
  </div>
);

const FinalizeButtons = ({
  buttonSize,
  handleFinalize,
  history,
  isAssessment,
  isDev,
  isDisabled,
  isFetching,
  params: { companyId, moduleId, projectId, transactionId },
}) => (
  <div className={classnames('workbench-actions')}>
    <Button
      size="md"
      buttonType="secondary"
      onClick={() =>
        history.replace(
          handlePath(
            `/workbench/${moduleId}/${projectId}/${transactionId}/questionnaire${
              isDev ? '-dev' : ''
            }`,
            companyId,
          ),
        )
      }
    >
      Previous
    </Button>
    <Button
      size="md"
      onClick={handleFinalize}
      isFetching={isFetching}
      isDisabled={isFetching || isDisabled}
    >
      {isAssessment ? 'Generate Tasks' : 'Finalize'}
    </Button>
  </div>
);

WorkbenchActions.defaultProps = {
  isAuthenticated: false,
  next: 'loading',
};

export default WorkbenchActions;
