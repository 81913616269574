import isEmpty from 'lodash-es/isEmpty';

const checkValue = (str, max) => {
  // eslint-disable-next-line
  if (str && (str.charAt(0) !== '0' || str == '00')) {
    // eslint-disable-next-line
    let num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str = // eslint-disable-next-line
      num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
        ? '0' + num
        : num.toString();
  }
  return str;
};

const TransformDict = {
  currency: str => {
    let semiParsedNum = str.replace(/^[$|0-9][0-9|.]/g, '');
    // eslint-disable-next-line
    let parsedNum = str.replace(/[^0-9|\.]/g, '');
    let numWithCommas = parsedNum.split('.');
    if (numWithCommas.length > 1 && numWithCommas[1].length > 2) {
      parsedNum = +parsedNum;
      parsedNum = parsedNum.toFixed(2);
      parsedNum = '$' + parsedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parsedNum;
    }
    numWithCommas[0] = numWithCommas[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    numWithCommas = numWithCommas.join('.');
    if (isEmpty(numWithCommas) && semiParsedNum) {
      return '$';
    }
    if (isEmpty(numWithCommas)) {
      return '';
    }
    return '$' + numWithCommas;
  },
  date: (str, event, seperator = '/') => {
    // Strip all non numbers
    let displayVal = str.replace(/\D/g, '');
    let month = checkValue(displayVal.slice(0, 2), 12);
    let day = checkValue(displayVal.slice(2, 4), 31);
    let year = displayVal.slice(4, 8);

    displayVal = month + day + year;

    // Inject dashes
    if (event === 'deleteContentBackward') {
      if (displayVal.length >= 3) {
        displayVal = displayVal.slice(0, 2) + seperator + displayVal.slice(2);
      }

      if (displayVal.length >= 6) {
        displayVal = displayVal.slice(0, 5) + seperator + displayVal.slice(5);
      }
      return displayVal;
    } else {
      if (displayVal.length >= 2) {
        displayVal = displayVal.slice(0, 2) + seperator + displayVal.slice(2);
      }

      if (displayVal.length >= 5) {
        displayVal = displayVal.slice(0, 5) + seperator + displayVal.slice(5);
      }
      return displayVal;
    }
  },
  float: str => {
    // eslint-disable-next-line
    let parsedNum = str.replace(/[^0-9|\.]/g, '');
    let numWithCommas = parsedNum.split('.');
    numWithCommas[0] = numWithCommas[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    numWithCommas = numWithCommas.join('.');
    return numWithCommas;
  },
  float_currency: str => {
    let semiParsedNum = str.replace(/^[$|0-9][0-9|.]/g, '');
    // eslint-disable-next-line
    let parsedNum = str.replace(/[^0-9|\.]/g, '');
    let numWithCommas = parsedNum.split('.');
    numWithCommas[0] = numWithCommas[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    numWithCommas = numWithCommas.join('.');
    if (isEmpty(numWithCommas) && semiParsedNum) {
      return '$';
    }
    if (isEmpty(numWithCommas)) {
      return '';
    }
    return '$' + numWithCommas;
  },
  integer: str => {
    let parsedNum = str.replace(/[^0-9|]/g, '');
    return parsedNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  month: (str, event) => {
    // Strip all non numbers
    let displayVal = str.replace(/\D/g, '');

    const month = checkValue(str.slice(0, 2), 12);
    const year = displayVal.substring(2, 6);
    displayVal = month + year;
    displayVal = displayVal.substring(0, 6);

    // Inject dashes
    if (event === 'deleteContentBackward') {
      if (displayVal.length >= 3) {
        displayVal = displayVal.slice(0, 2) + '/' + displayVal.slice(2);
      }
      return displayVal;
    } else {
      if (displayVal.length >= 2) {
        displayVal = displayVal.slice(0, 2) + '/' + displayVal.slice(2);
      }
      return displayVal;
    }
  },
  numerical: str => {
    let parsedNum = str.replace(/[^0-9|]/g, '');
    return parsedNum.toString();
  },
  percent: str => {
    // eslint-disable-next-line
    // let parsedNum = str.replace(/^-?\d[.]?\d$/g, '');
    let parsedNum = str.replace(/[^-|0-9|.]/g, '');
    let numWithCommas = parsedNum.split('.');
    numWithCommas[0] = numWithCommas[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    numWithCommas = numWithCommas.join('.');
    return numWithCommas;
  },
  phone: (str, event) => {
    // Strip all non numbers
    let displayVal = str.replace(/\D/g, '');

    displayVal = displayVal.substring(0, 10);

    // Inject dashes
    if (event === 'deleteContentBackward') {
      if (displayVal.length >= 4) {
        displayVal = '(' + displayVal.slice(0, 3) + ') ' + displayVal.slice(3);
      }

      if (displayVal.length >= 10) {
        displayVal = displayVal.slice(0, 9) + '-' + displayVal.slice(9);
      }
      return displayVal;
    } else {
      if (displayVal.length >= 3) {
        displayVal = '(' + displayVal.slice(0, 3) + ') ' + displayVal.slice(3);
      }

      if (displayVal.length >= 9) {
        displayVal = displayVal.slice(0, 9) + '-' + displayVal.slice(9);
      }
      return displayVal;
    }
  },
  ssc: (str, event) => {
    // Strip all non numbers
    let displayVal = str.replace(/\D/g, '');
    displayVal = displayVal.substring(0, 9);
    // Inject dashes
    if (event === 'deleteContentBackward') {
      // On backspace
      if (displayVal.length >= 4) {
        displayVal = displayVal.slice(0, 3) + '-' + displayVal.slice(3);
      }

      if (displayVal.length >= 7) {
        displayVal = displayVal.slice(0, 6) + '-' + displayVal.slice(6);
      }
      return displayVal;
    } else {
      if (displayVal.length >= 3) {
        displayVal = displayVal.slice(0, 3) + '-' + displayVal.slice(3);
      }

      if (displayVal.length >= 6) {
        displayVal = displayVal.slice(0, 6) + '-' + displayVal.slice(6);
      }
      // TODO: Hide Social Security Number?
      // Replace all numbers with astericks
      displayVal = displayVal.replace(/[0-9]/g, '*');
      return displayVal;
    }
  },
  string: str => str,
  zip: (str, event) => str,
  // zip: (str, event) => {
  //   // Strip all non numbers
  //   let displayVal = str.replace(/\D/g, '');
  //   displayVal = displayVal.substring(0, 5);
  //   return displayVal;
  // },
};

export default TransformDict;
