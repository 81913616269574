import get from 'lodash-es/get';
import { defaultMemoize } from 'reselect';

export const BoxToken = defaultMemoize(state =>
  get(state, 'Cabinet.box.accessToken', ''),
);

export const TempBoxToken = defaultMemoize(state =>
  get(state, 'Cabinet.tempBox.token.accessToken', ''),
);

export const IsTempBoxTokenFetchInitialized = defaultMemoize(state =>
  get(state, 'Cabinet.tempBox.isInitialized', true),
);

export const IsFetching = defaultMemoize(state =>
  get(state, 'Cabinet.isFetching', false),
);

export const Errors = defaultMemoize(state => get(state, 'Cabinet.errors', {}));
