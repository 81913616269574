import React from 'react';
import './InputSubLabel.scss';

const InputSublabel = ({ text, children }) => {
  if (text) {
    return <small className="inputSubLabel">{text}</small>;
  }
  if (children) {
    return typeof children === 'string' ? (
      <small className="inputSubLabel">{children}</small>
    ) : (
      children
    );
  }
  return null;
};

export default InputSublabel;
