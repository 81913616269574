export const SubscriptionsFeaturesDict = [
  {
    header: 'Basic',
    slug: 'basic',
    subheader:
      "Platform Access, Free Assessments, Initial Consultation, Limited Team Onboarding, FAQ's.",
    options: [
      {
        label: 'Free',
        value: 0,
        slug: 'basic',
      },
      {
        label: 'Free',
        value: 0,
        slug: 'basic',
      },
    ],
  },
  {
    header: 'Starter',
    slug: 'starter',
    subheader:
      'Basic Slack Support, Entity Setup & Management, Unlimited Team Onboarding.',
    optionalAddonsColItems: ['add-on', 'add-on', 'add-on', 'add-on'],
    workflowColItems: ['check', 'X'],
    options: [
      {
        label: '$499/Yr',
        // label: '$25/Mo($499/Yr)',
        slug: 'starter_annual',
        subscriptionLabel: 'Starter Annual',
        value: 499,
      },
      {
        label: '$35/Mo',
        slug: 'starter_monthly',
        subscriptionLabel: 'Starter Monthly',
        value: 35,
      },
    ],
  },
  {
    header: 'Advanced',
    slug: 'advanced',
    subheader: 'Full Equity Setup and Management with Carta integration.',
    optionalAddonsColItems: ['add-on', 'add-on', 'add-on', 'add-on'],
    workflowColItems: ['check', 'check'],
    options: [
      {
        label: '$2000/Yr',
        slug: 'advanced_annual',
        subscriptionLabel: 'Advanced Annual',
        value: 2000,
      },
      {
        label: '$94/Mo',
        slug: 'advanced_monthly',
        subscriptionLabel: 'Advanced Monthly',
        value: 94,
      },
    ],
  },
  {
    header: 'Premium',
    slug: 'premium',
    subheader:
      'Prepare for Due Diligence, Generate & Execute Standard Financing Documents.',
    optionalAddonsColItems: ['add-on', 'add-on', 'add-on', 'add-on'],
    workflowColItems: ['check', 'check'],
    options: [
      {
        isQuote: true,
        label: 'Request a Quote',
        slug: 'premium_annual',
        subscriptionLabel: 'Premium Annual',
        value: null,
      },
      {
        label: '$420/Mo',
        slug: 'premium_monthly',
        subscriptionLabel: 'Premium Monthly',
        value: 420,
      },
    ],
  },
];

export const KnowledgeLibraryDict = {
  columns: [
    ['check', 'X', 'X'],
    ['check', 'X', 'X'],
    ['check', 'check', 'X'],
    ['check', 'check', 'check'],
  ],
  features: [
    {
      rowFeature: {
        description: 'Manage standardized legal documents and filings through Savvi.',
        label: 'Standard Products',
      },
      rowSubfeatures: [
        { label: 'Form a New Entity' },
        { label: 'Issue Equity' },
        { label: 'Issue SAFE Notes' },
        { label: "Onboard & Offboard Contractors, W2's, and Advisors" },
        { label: 'Add/Remove Board Members' },
        { label: "Send NDA's" },
        { label: 'View All', linkUrl: 'https://learn.savvi.legal/' },
      ],
    },
    {
      rowFeature: {
        description: 'Attorney-managed workflows for complex transactions and scenarios.',
        label: 'Private Products',
      },
      rowSubfeatures: [
        { label: 'LLC to C-Corp Conversions' },
        { label: 'Preferred Stock Financings' },
        { label: 'Mergers & Acquisitions' },
      ],
    },
    {
      rowFeature: {
        description:
          'Customize your library with your own templates, questionnaires, and workflow logic.',
        label: 'Custom Products',
        hover:
          'Customize your library with your own templates, questionnaires, and workflow logic.',
      },
    },
  ],
};

export const DocumentManagementDict = {
  columns: [
    ['Up to 5', 'check', 'X', 'X', 'X'],
    ['Up to 60/yr', 'check', 'X', 'X', 'X'],
    ['Up to 180/yr', 'check', 'check', 'X', 'X'],
    ['Custom', 'check', 'check', 'check', 'check'],
  ],
  features: [
    {
      rowFeature: {
        description:
          'Generate, send, sign, and track legal documents through a Savvi workflow.',
        label: 'Execute Savvi Docs',
      },
    },
    {
      rowFeature: {
        description:
          'A connected view that ties documents to their legal terms and history on Savvi, with a Box (^TM) integration for secure sharing.',
        label: 'Digital File Cabinet',
      },
    },
    {
      rowFeature: {
        description:
          "Send and sign your own custom documents, or custom versions of Savvi's templates.",
        label: 'Sign Custom Docs',
      },
    },
    {
      rowFeature: {
        description: "Implement custom document templates within Savvi's workflows.",
        label: 'Custom Templates',
      },
    },
    {
      rowFeature: {
        description:
          'Create secure, shareable data rooms for due diligence or reporting events.',
        label: 'Data Room',
      },
    },
  ],
};

export const TaskManagementDict = {
  columns: [
    ['1', 'check', 'X', 'X'],
    ['3', 'check', 'X', 'X'],
    ['6', 'check', 'check', 'X'],
    ['Custom', 'check', 'check', 'check'],
  ],
  features: [
    {
      rowFeature: {
        description:
          'Give in-house and outside admins a shared space to delegate and collaborate on any legal task.',
        label: 'Admin Seats',
      },
    },
    {
      rowFeature: {
        description:
          'One place to view and access action items for both individuals and accounts.',
        label: 'Action Center',
      },
    },
    {
      rowFeature: {
        description: 'Assign recurring roles to accounts to automate task assignments.',
        label: 'Workflow Roles',
      },
    },
    {
      rowFeature: {
        description:
          "Implement a new workflow, or customize one of Savvi's workflow templates.",
        label: 'Custom Workflows',
      },
    },
  ],
};

export const PortfolioManagementDict = {
  columns: [
    ['check', 'X', 'X', 'X'],
    ['check', 'X', 'X', 'X'],
    ['check', 'X', 'X', 'X'],
    ['check', 'check', 'check', 'check'],
  ],
  features: [
    {
      rowFeature: {
        description:
          'One view to track and access all of your outstanding documents across your portfolio.',
        label: 'Portfolio Dashboard',
      },
    },
    {
      rowFeature: {
        description:
          'Grant admin access for your team across affiliated portfolio companies.',
        label: 'Portfolio Admin Groups',
      },
    },
    {
      rowFeature: {
        description: 'Provide library access to a portfolio of companies under one plan.',
        label: 'Portfolio Library Access',
      },
    },
    {
      rowFeature: {
        description: 'Manage transactions on behalf of portfolio companies.',
        label: 'Transaction Management',
      },
    },
  ],
};

export const IntegratedServicesDict = {
  columns: [
    ['X', 'check', 'check', 'check', 'X', 'X', 'X', 'X', 'X', 'X'],
    [
      'Up to 20 docs',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'X',
      'X',
      'X',
    ],
    [
      'Up to 60 docs',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'X',
    ],
    [
      'Custom',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
      'check',
    ],
  ],
  features: [
    {
      rowFeature: {
        description:
          'Savvi will setup your Data Room based on the documents that you have added to your File Cabinet.',
        label: 'Data Room Setup',
      },
    },
    {
      rowFeature: {
        description:
          "Receive sales, billing, and technical support from Savvi's team of experts.",
        label: 'Account Support',
      },
    },
    {
      rowFeature: {
        description:
          'As required by states where your company is registered, Savvi provides a State Registered Agent for your company with a physical address in that state.',
        label: 'State Registered Agent (+$99/state)',
      },
    },
    {
      rowFeature: {
        description:
          "A filing agent will handle all of your annual filings with the state. You'll receive reminders when we need information from you.",
        label: 'State Filing Agent (+$99/state)',
      },
      rowSubfeatures: [
        { label: 'Annual State Reports and Filings' },
        { label: 'Does not include state filing fees' },
      ],
    },
    {
      rowFeature: {
        description:
          'Connect with a Savvi certified attorney for counsel or custom needs.',
        label: 'Find Counsel',
      },
    },
    {
      rowFeature: {
        description:
          "Access discounts and flat-fee pricing from Savvi's curated network of service providers.",
        label: 'Exclusive Offerings',
      },
    },
    {
      rowFeature: {
        description:
          'A Savvi partner will setup your Carta Account based on documentation in your File Cabinet.',
        label: 'Free Carta Onboarding*',
      },
      rowSubfeatures: [
        {
          label:
            'Additional charge is required for companies who do not qualify for Carta Launch.',
        },
      ],
    },
    {
      rowFeature: {
        description:
          "Savvi will analyze your Data Room and generate suggested actions and to-do's based on missing documents.",
        label: 'Data Room Audit',
      },
    },
    {
      rowFeature: {
        description:
          "Connect with Savvi's team of resident attorneys and paralegals to address your custom needs.",
        label: 'Custom Support',
      },
    },
    {
      rowFeature: {
        description:
          'For complex transactions (i.e., preferred stock financings, corporate restructuring, and M&A), a Savvi General Counsel partner will work with your legal team and outside counsel to help reduce turn-around times and fees.',
        label: 'Transaction Management',
      },
      rowSubfeatures: [
        { label: 'Reduces legal fees by 40% on average.' },
        {
          label:
            "Receive pre-negotiated reduced rates from Savvi's partner counsel network.",
        },
        { label: 'Unlock and track counsel-managed transactions on your Savvi account.' },
        {
          label: 'Operational support to make sure that your transaction goes smoothly.',
        },
      ],
    },
  ],
};

/** *** UPDATED 2021 SUBSCRIPTIONS END *** */

export const SolutionsFeaturesDict = {
  EntityFeaturesDict: {
    columns: [
      ['check', 'X'],
      ['check', 'X'],
      ['check', 'check'],
      ['check', 'check'],
    ],
    features: [
      {
        id: 1,
        rowFeature: {
          label: 'Form a New Entity*',
          description:
            'Get your LLC or C-Corporation started with peace of mind. Everything you need to start operating as an official business.',
        },
        rowSubfeatures: [
          [
            { label: 'Entity Filing Certificate' },
            {
              label: 'EIN Number',
              description: 'Employer Identification Number provided by filing state',
            },
            { label: 'Organizational Documents' },
            { label: 'Filing State Registered Agent' },
            { label: 'Annual Report and Filing Renewal' },
          ],
          [
            {
              label: 'Subscription: Basic (Free) + State Filing Agent add-on ($249/Yr)',
              description: 'See "Optional Add-ons" section.',
            },
            {
              label: 'Document Credits: 2-3',
              description: 'See "Legal Document Credits" section.',
            },
            {
              label: 'One-time fees: Vary by state (shown in task)',
            },
          ],
        ],
      },
      {
        id: 5,
        rowFeature: {
          label: 'LLC to C-Corp Conversion*',
          description:
            'Convert your LLC to a Delaware C-Corporation with the help of your Managing Attorney.',
        },
        rowSubfeatures: [
          [
            { label: 'Entity Filing Certificate' },
            {
              label: 'EIN Number',
              description: 'Employer Identification Number provided by filing state',
            },
            { label: 'Organizational Documents' },
          ],
          [
            {
              label: 'Subscription: Advanced + State Filing Agent ($249/Yr)',
              description: 'See "Optional Add-ons" section.',
            },
            {
              label: 'Document Credits: 5 or more',
              description: 'See "Legal Document Credits" section.',
            },
            {
              label: 'One-time fees: Vary by state',
            },
          ],
        ],
      },
    ],
  },
  EquityFeaturesDict: {
    columns: [
      ['check', 'X', 'X'],
      ['check', 'check', 'check'],
      ['check', 'check', 'check'],
      ['check', 'check', 'check'],
    ],
    features: [
      {
        id: 3,
        rowFeature: {
          label: 'Issue Equity',
          description:
            "Issue and manage your company's equity. Issue different types of stock, generate an equity incentive plan (ex. option pool), and keep your cap table up-to-date.",
        },
        rowSubfeatures: [
          [
            { label: 'Common Stock Grants' },
            { label: 'Stock Option Grants' },
            { label: 'Equity Board Consents' },
          ],
          [
            { label: 'Subscription: Basic' },
            {
              label: 'Document Credits: 2 per issuance',
              // description: 'See "Legal Document Credits" section.',
            },
          ],
        ],
      },
      { rowFeature: { label: 'Stock Repurchase' } },
      { rowFeature: { label: 'Stock Option Pool Setup' } },
    ],
  },
  FundraisingFeaturesDict: {
    columns: [
      ['check', 'check', 'X'],
      ['check', 'check', 'X'],
      ['check', 'check', 'check'],
      ['check', 'check', 'check'],
    ],
    features: [
      { rowFeature: { label: 'Generate & Execute Term Sheets' } },
      { rowFeature: { label: 'Generate & Execute a SAFE agreement' } },
      { rowFeature: { label: 'Complex Financing' } },
    ],
  },
  TeamFeaturesDict: {
    columns: [
      ['check', 'X'],
      ['check', 'X'],
      ['check', 'check'],
      ['check', 'check'],
    ],
    features: [
      { rowFeature: { label: 'Onboard Employees and Contractors' } },
      { rowFeature: { label: 'Terminate Employees' } },
    ],
  },
  IPFeaturesDict: {
    columns: [
      ['check', 'check', 'X'],
      ['check', 'check', 'X'],
      ['check', 'check', 'check'],
      ['check', 'check', 'check'],
    ],
    features: [
      { rowFeature: { label: 'Trademark Application' } },
      { rowFeature: { label: 'Send a Cease and Desist Notice' } },
      { rowFeature: { label: 'Patent Services' } },
    ],
  },
  CustomersPartnersFeaturesDict: {
    columns: [
      ['check', 'check'],
      ['check', 'check'],
      ['check', 'check'],
      ['check', 'check'],
    ],
    features: [
      { rowFeature: { label: 'SAAS Services Agreement Template' } },
      { rowFeature: { label: 'NDA Template' } },
    ],
  },
  CrisisManagement: {
    columns: [['X'], ['X'], ['check'], ['check']],
    features: [
      {
        // id: 24,
        rowFeature: {
          label: 'COVID-19 Response Assessment',
          description: 'Consult with an attorney and receive a Crisis Response Proposal.',
        },
        rowSubfeatures: [
          [
            { label: 'Free Consultation (30 Minutes)' },
            {
              label: 'Crisis Response Proposal',
            },
            {
              label: 'Quotes Crisis Task',
            },
          ],
          [{ label: 'Subscription: Advanced' }],
        ],
      },
    ],
  },
  ExpeditedSBALoan: {
    columns: [['check'], ['check'], ['check'], ['check']],
    features: [
      {
        id: 25,
        rowFeature: {
          label: 'Generate PPP SBA Loan Assistance',
          description:
            "Generate, sign, and share your PPP SBA Application with access to our hints and FAQ's. We will share your application with our partner lenders and notify you if accepted.",
        },
        rowSubfeatures: [
          [
            { label: 'Generate and Sign the SBA Application Form' },
            {
              label: 'In-App Support (Advanced only)',
            },
            {
              label: 'Share Application to a Lender (Currently Unavailable)',
            },
          ],
          [{ label: 'Subscription: Basic (Free)' }],
        ],
      },
      {
        id: 26,
        rowFeature: {
          label: 'Upload & Share PPP SBA Loan Assistance',
          description:
            "Upload and share your PPP SBA Application with access to our hints and FAQ's. We will share your application with our partner lenders and notify you if accepted.",
        },
        rowSubfeatures: [
          [
            {
              label: 'In-App Support',
            },
            {
              label: 'Share Application to a Lender (Currently Unavailable)',
            },
          ],
          [{ label: 'Subscription: Advanced($80/mo)' }],
        ],
      },
    ],
  },
};
