import { get } from 'lodash-es';
import { combineReducers } from 'redux';

import {
  apiErrorsReducer,
  createReducer,
  fetchingReducer,
  getValidationErrors,
} from '../../utils';
import {
  CLEAR_ERRORS,
  EMAIL_CONFIRM_FAILED,
  EMAIL_CONFIRM_REQUEST,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGOUT,
  REGISTER_FAILED,
  REGISTER_REQUEST,
  SEND_PW_RESET_FAILED,
  SET_CHECKPOINTS,
  SET_INIT_LOADING,
  SET_NOTIFICATIONS,
  SET_PARTNER_BY_CODE,
  SET_PARTNER_CODE,
  SET_USER,
  SET_ACCOUNT,
  TOKEN_FAILED,
  SET_USER_REPORTS,
} from './types';

import { InitUser } from './utils';

const isFetching = fetchingReducer('user');

const UserReducer = createReducer(InitUser)({
  [SET_USER]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [LOGOUT]: () => ({
    ...InitUser,
  }),
});

const AccountReducer = createReducer(InitUser)({
  [SET_ACCOUNT]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [LOGOUT]: () => ({
    ...InitUser,
  }),
  [SET_PARTNER_BY_CODE]: (state, { payload, code }) => ({
    ...state,
    byCode: {
      ...state.byCodes,
      [code]: { ...payload },
    },
  }),
  [SET_PARTNER_CODE]: (state, { code }) => ({
    ...state,
    code,
  }),
});

const partnersReducer = createReducer({})({
  [SET_PARTNER_BY_CODE]: (state, { payload, code }) => ({
    ...state,
    byCode: {
      ...state.byCodes,
      [code]: { ...payload },
    },
  }),
  [SET_PARTNER_CODE]: (state, { code }) => ({
    ...state,
    code,
  }),
});

const errorsReducer = createReducer({})({
  [CLEAR_ERRORS]: () => ({}),
  [EMAIL_CONFIRM_REQUEST]: () => ({}),
  [REGISTER_REQUEST]: () => ({}),
  [LOGIN_REQUEST]: () => ({}),
  [LOGIN_FAILED]: (state, { payload }) => {
    const { code, message, errors } = payload;
    if (code === 'INVALID_CREDENTIALS') {
      return {
        form: 'Password is incorrect',
      };
    } else if (!!errors) {
      return errors;
    } else if (!!message) {
      return {
        form: message,
      };
    } else {
      return { form: 'There was an error while logging you in.' };
    }
  },
  [EMAIL_CONFIRM_FAILED]: (state, { payload }) => {
    const { message, errors } = payload;
    if (!!errors) {
      return errors;
    } else if (message) {
      return {
        token: message,
      };
    } else {
      return { token: 'There was an error while verifying your code.' };
    }
  },
  [REGISTER_FAILED]: (state, { payload }) => {
    const { code, message, errors } = payload;
    if (code === 'EMAIL_ALREADY_EXISTS') {
      return { form: message };
    } else if (errors) {
      return getValidationErrors(errors);
    } else if (message) {
      return { form: message };
    } else {
      return { form: 'There was an error while creating your account.' };
    }
  },
  [TOKEN_FAILED]: (state, { payload }) => ({
    form: get(
      payload,
      'message',
      'Your Session has failed to validate or expired, please login to continue.',
    ),
  }),
  [SEND_PW_RESET_FAILED]: (state, { payload }) => ({ form: payload.message }),
});

const checkpointsReducer = createReducer([])({
  [SET_CHECKPOINTS]: (state, { payload }) => payload,
});

const isInitLoadingReducer = createReducer(true)({
  [SET_INIT_LOADING]: (state, { isLoading }) => isLoading,
});

const socketsReducer = createReducer([])({
  [SET_NOTIFICATIONS]: (state, { payload: notifications }) => ({
    ...state,
    notifications,
  }),
});

const userReportsReducer = createReducer([])({
  [SET_USER_REPORTS]: (state, { payload }) => [...payload],
});

export default combineReducers({
  checkpoints: checkpointsReducer,
  sockets: socketsReducer,
  isInitLoading: isInitLoadingReducer,
  isFetching,
  user: UserReducer,
  account: AccountReducer,
  partners: partnersReducer,
  errors: errorsReducer,
  apiErrors: apiErrorsReducer('user'),
  userReports: userReportsReducer,
});
