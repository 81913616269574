import React from 'react';

import './ResourceList.scss';
import DataTable from '../../DataTable/DataTable';
import { InputHelp } from '../../inputs';
import { useSelector } from 'react-redux';
import { AccountInfo } from '../../../redux/modules/Formation/selectors';

const ResourceList = props => {
  let {
    featureProps: { json, question_text, question_label, resourceListData },
    params,
    questionHelp,
  } = props;

  const { id } = useSelector(AccountInfo);

  const resourcesArr = Object.entries(resourceListData);
  if (
    json.resource_type_id === 2 &&
    json.resource_label !== 'Fund Performance' &&
    resourcesArr.length > 1
  ) {
    let companyIndex = resourcesArr.findIndex(
      ([key, val]) => (val[0]?.account_id || '') === id,
    );
    if (companyIndex === -1) {
      companyIndex = 0;
    } else {
      resourceListData = {
        [resourcesArr[companyIndex][0]]: resourcesArr[companyIndex][1],
      };
    }
  }

  return (
    <div className="resourceList__container">
      <h4 className="resourceList__question">
        {question_text || question_label}
        {questionHelp && <InputHelp text={questionHelp} />}
      </h4>
      <DataTable
        accessCode={params.access_code}
        json={json}
        passedParams={params}
        question_label={question_label}
        resourceListData={resourceListData}
        isOutsideForm
      />
    </div>
  );
};

export default ResourceList;
