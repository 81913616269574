import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';

import { ReactComponent as SavviLoadingSVG } from '../../../assets/images/savvi_loading.svg';
import ConsistentTopbar from '../../ConsistentTopbar';
import InputFeedback from '../InputFeedback';
// import { ReactComponent as SavviLoadingSVG } from '../../assets/images/savvi_loading.svg';

import './InputVerification.scss';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

const InputVerification = ({
  autoFocus,
  className,
  clearable,
  disabled,
  fieldHeight,
  fields,
  fieldWidth,
  error,
  onChange,
  passedId,
  loading,
  onBlur,
  placeholder,
  required,
  label,
  name,
  type,
  value,
}) => {
  const autoFocusIndex = useMemo(
    () => (value.length >= fields ? 0 : value.length),
    [value, fields],
  );
  const iRefs = useMemo(() => {
    let iRefs = [];
    for (let i = 0; i < fields; i++) {
      iRefs.push(React.createRef());
    }
    return iRefs;
  }, [fields]);

  const [vals, setVals] = useState(() => {
    let vals = [];
    if (value.length > 0) {
      for (let i = 0; i < fields; i++) {
        vals.push(value[i] || '');
      }
    } else {
      vals = Array(fields).fill('');
    }
    return vals;
  });

  const INPUT_STYLE = {
    width: fieldWidth,
    height: fieldHeight,
  };
  const ROOT_STYLE = {
    width: fields * fieldWidth + 20,
  };
  const LOADING_STYLE = {
    height: fieldHeight,
  };

  const clearvalues = () => {
    setVals(Array(fields).fill(''));
    iRefs[0].current.focus();
  };

  const triggerChange = passedVals => {
    let val = passedVals;
    if (!val) {
      val = vals.join('');
    }
    onChange && onChange(val);
    if (onBlur && val.length >= fields) {
      onBlur(val);
    }
  };

  const handleChange = e => {
    const index = parseInt(e.target.dataset.id);
    if (type === 'number') {
      e.target.value = e.target.value.replace(/[^\d]/gi, '');
    }
    if (e.target.value === '' || (type === 'number' && !e.target.validity.valid)) {
      return;
    }
    let next;
    const updatedValue = e.target.value;
    let updatedVals = [...vals];
    if (updatedValue.length > 1) {
      let nextIndex = updatedValue.length + index - 1;
      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }
      next = iRefs[nextIndex];
      const split = updatedValue.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < fields) {
          updatedVals[cursor] = item;
        }
      });
      setVals([...updatedVals]);
    } else {
      next = iRefs[index + 1];
      updatedVals[index] = updatedValue;
      setVals([...updatedVals]);
    }

    if (next) {
      next.current.focus();
      next.current.select();
    }

    triggerChange(updatedVals.join(''));
  };

  const onKeyDown = e => {
    const index = parseInt(e.target.dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = iRefs[prevIndex];
    const next = iRefs[nextIndex];
    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        let updatedVals = [...vals];
        if (vals[index]) {
          updatedVals[index] = '';
          setVals(updatedVals);
          triggerChange(updatedVals.join(''));
        } else if (prev) {
          updatedVals[prevIndex] = '';
          prev.current.focus();
          setVals(updatedVals);
          triggerChange(updatedVals.join(''));
        }
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        // this.handleKeys[index] = true;
        break;
    }
  };

  // onKeyUp = e => {
  //   const index = parseInt(e.target.dataset.id);
  //   if (this.handleKeys[index]) {
  //     this.handleKeys[index] = false;
  //     const next = this.iRefs[index + 1];
  //     if (next) {
  //       next.current.focus();
  //     }
  //   }
  // };

  const onFocus = e => {
    e.target.select(e);
  };

  return (
    <div className={`input-verification__container ${className}`} style={ROOT_STYLE}>
      <ConsistentTopbar />
      {label && <p className="input-verification__label">{label}</p>}
      <div className="input-verification__input">
        {vals.map((val, index) => (
          <input
            className={index + 1 === vals.length ? 'last' : ''}
            type={type === 'number' ? 'tel' : type}
            pattern={type === 'number' ? '[0-9]*' : null}
            autoFocus={autoFocus && index === autoFocusIndex}
            style={INPUT_STYLE}
            key={`verification-key-${index}`}
            data-id={index}
            value={val}
            id={passedId ? `${passedId}-${index}` : null}
            ref={iRefs[index]}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            // onKeyUp={onKeyUp}
            onFocus={onFocus}
            disabled={disabled}
            required={required}
            placeholder={placeholder[index]}
          />
        ))}
        {clearable && <FontAwesomeIcon onClick={clearvalues} icon={['fal', 'times']} />}
      </div>
      <InputFeedback error={error} />
      {loading && (
        <div className="input-verification__loading" style={LOADING_STYLE}>
          <div className="input-verification__loading-blur" />
          <SavviLoadingSVG />
        </div>
      )}
    </div>
  );
};

InputVerification.defaultProps = {
  autoFocus: true,
  className: '',
  clearable: true,
  disabled: false,
  fieldHeight: 54,
  fields: 8,
  fieldWidth: 58,
  onChange: () => {},
  passedId: '',
  loading: false,
  onBlur: () => {},
  placeholder: [],
  required: false,
  label: '',
  type: 'text',
  value: '',
};

export default InputVerification;
