import * as Types from './types';

export function setAccountTodos(payload) {
  return {
    type: Types['SET_ACCOUNT_TODOS'],
    payload,
  };
}

export function setAssigneeOptions(payload, accountId, projectTemplateId) {
  return {
    type: Types['SET_ASSIGNEE_OPTIONS'],
    payload,
    accountId,
    projectTemplateId,
  };
}

export function setTeamTodos(payload) {
  return {
    type: Types['SET_TEAM_TODOS'],
    payload,
  };
}
