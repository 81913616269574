import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getOutsideBoxToken } from '../../redux/modules/Cabinet/operations';
import {
  createFeature,
  createResourceName,
  finalizeOutsideForm,
  getOutsideForm,
  updateResourceName,
} from '../../redux/modules/Transaction/operations';
import {
  getFaqsFromTransaction,
  getFormikValues,
  getOrderedQuestions,
  getQuestionnaireByTransactionId,
  getQuestionnaireSnapshotByTransactionId,
  getReviewValues,
} from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import InfoSidebar from '../InfoSidebar';
import OutsideForm from './OutsideForm';
import { Button } from '../../components/common';
import BasicTopbar from '../../components/BasicTopbar';
import SavviLoading from '../../components/SavviLoading';

import './OutsideForm.scss';
import '../WorkbenchForm/WorkbenchForm.scss';
import '../Workbench/Workbench.scss';

class OutsideFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      formValidationError: '',
      formValidationErrors: [],
      isFinalized: false,
      isReviewModalOpen: false,
      isSidebarCollapsed: window.innerWidth < 1240,
      isFetchingInit: true,
      isSubmitWaiting: false,
    };
  }

  componentDidMount() {
    const { getOutsideBoxToken, getOutsideForm, match } = this.props;
    const { params: { access_code } = {} } = match || {};
    getOutsideForm(access_code).then(
      e => {
        this.setState({ isFetchingInit: false });
        getOutsideBoxToken(access_code);
      },
      error => this.setState({ isFetchingInit: false, error }),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      createFeature,
      isFetching,
      match: {
        params: { access_code },
      },
      questions,
    } = this.props;

    if (!isEqual(prevProps.questions, questions)) {
      questions.forEach(question =>
        question.resourceArr.forEach(resource => {
          resource.innerQuestions.forEach(innerQ => {
            if (innerQ.question_default && !innerQ.value) {
              createFeature({
                access_code,
                value: innerQ.question_default,
                feature_type_id: innerQ.feature_type.id,
                resource_id: resource.resource_id,
                resourceTypeId: resource.resource_type_id,
              });
            }
          });
        }),
      );
    }
    if (this.state.isSubmitWaiting && !isFetching) {
      this.setState({ isSubmitWaiting: false }, () => this.handleSubmit());
    }
  }

  handleDelete = (resourceId, resourceName) => {
    const { match, deleteIndividual, getOutsideForm } = this.props;
    const { params: { access_code } = {} } = match || {};
    const message = `Are you sure you want to remove ${resourceName} from our system?`;
    if (window.confirm(message)) {
      deleteIndividual(resourceId, resourceName).then(e => getOutsideForm(access_code));
    }
  };

  handleFinalize = () => {
    const { finalizeOutsideForm, reviewInfo } = this.props;
    return finalizeOutsideForm(reviewInfo).then(
      e => {
        this.setState({ isFinalized: true, isReviewModalOpen: false });
        return e;
      },
      error => {
        this.setState({ isReviewModalOpen: false });
        return error;
      },
    );
  };

  handleFormBlur = (value, feature_type_id, feature_id, resource_id, resourceTypeId) => {
    const { createFeature } = this.props;
    const {
      match: {
        params: { access_code },
      },
    } = this.props;

    createFeature({
      access_code,
      value,
      feature_id,
      feature_type_id,
      resource_id,
      resourceTypeId,
    });
  };

  handleSubmit = (values = {}, actions) => {
    const { REACT_APP_LOG_ENV } = process.env;
    const isDevEnv = REACT_APP_LOG_ENV !== 'production';
    const finalVals = Object.entries(values);
    let validationErrors = [];
    for (let i = 0; i < finalVals.length; i++) {
      const key = finalVals[i][0];
      const val = finalVals[i][1];
      const finalVal = val[0]?.resource || '';
      if (
        finalVal.includes('New Investor') ||
        finalVal.includes('New Coach') ||
        finalVal.includes('New Attorney') ||
        finalVal.includes('New Officer') ||
        finalVal.includes('New Partner')
      ) {
        validationErrors.push(
          `Please update ${key} input with full legal name. ${
            isDevEnv
              ? " (**Demo Only** Make sure you do not have 'Investor' in either names' input."
              : ''
          }`,
        );
      }
    }
    if (validationErrors.length > 0) {
      return this.setState({ formValidationErrors: validationErrors });
    } else {
      this.setState({ formValidationErrors: [] });
    }
    this.setState({ formValidationErrors: [] });

    const {
      getOutsideForm,
      match: {
        params: { access_code },
      },
    } = this.props;
    const { isReviewModalOpen } = this.state;

    if (this.props.isFetching) {
      return this.setState({ isSubmitWaiting: true });
    }

    return getOutsideForm(access_code).then(e =>
      this.setState({ isReviewModalOpen: !isReviewModalOpen }),
    );
  };

  handleResourceName = (body, resourceId, isDraft) => {
    const {
      match: {
        params: { access_code },
      },
      updateResourceName,
    } = this.props;
    body.access_code = access_code;
    return updateResourceName(body, resourceId);
  };

  render() {
    const {
      faqs,
      formikValues,
      history,
      isFetching,
      isFetchingRoom,
      match,
      questions,
      outsideFormSnapshot,
      reviewInfo,
      questionnaireMeta,
    } = this.props;
    const {
      error,
      formValidationErrors,
      isFinalized,
      isFetchingInit,
      isReviewModalOpen,
      isSidebarCollapsed,
      // isSafariMobile,
    } = this.state;
    const { params = {} } = match || {};

    const isSidebarEmpty =
      (faqs.length === 0 && isEmpty(outsideFormSnapshot)) ||
      questionnaireMeta?.metadata?.type === 'persistent_instance';

    return (
      <div
        className={`outside-form__container${
          isSidebarEmpty || isSidebarCollapsed ? ' info-sidebar-collapsed' : ''
        }`}
      >
        <BasicTopbar />
        <div className="outside-form__main">
          {isFetchingInit && (
            <div className="workbench__loader">
              <span className="workbench__loading">Loading...</span>
              <SavviLoading />
            </div>
          )}
          {!isFetchingInit && (
            <Formik
              // enableReinitialize
              initialValues={formikValues}
              onSubmit={this.handleSubmit}
              children={formikProps => {
                return (
                  <OutsideForm
                    {...formikProps}
                    error={error}
                    formValidationErrors={formValidationErrors}
                    handleDelete={this.handleDelete}
                    handleFinalize={this.handleFinalize}
                    handleFormBlur={this.handleFormBlur}
                    handleResourceName={this.handleResourceName}
                    history={history}
                    isFetching={isFetching || isFetchingRoom}
                    isFetchingInit={isFetchingInit}
                    isFinalized={isFinalized}
                    isReviewModalOpen={isReviewModalOpen}
                    params={params}
                    questionnaireMeta={questionnaireMeta}
                    questions={questions}
                    reviewInfo={reviewInfo}
                    toggleReviewModal={() =>
                      this.setState({ isReviewModalOpen: !isReviewModalOpen })
                    }
                  />
                );
              }}
            />
          )}
          {!isSidebarEmpty && (
            <>
              <InfoSidebar faqs={faqs} isOutsideForm reviewInfo={outsideFormSnapshot} />
              <Button
                buttonType="icon"
                className="workbench__toggle-info"
                onClick={e => this.setState({ isSidebarCollapsed: !isSidebarCollapsed })}
              >
                <FontAwesomeIcon
                  className="workbench__toggle-info-action"
                  icon={isSidebarCollapsed ? 'info' : ['fal', 'arrow-from-left']}
                />
              </Button>
              {!isSidebarCollapsed && (
                <div
                  onClick={e => this.setState({ isSidebarCollapsed: true })}
                  className="workbench__info-sidebar-overlay"
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

OutsideFormContainer.propTypes = {
  formValues: PropTypes.object.isRequired,
  formikValues: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  match: PropTypes.object,
};
OutsideFormContainer.defaultProps = {
  formValues: {},
  formikValues: {},
  isFetching: false,
  getFormInfo: () => {},
};

const mapStateToProps = ({ Data, Transaction }, ownProps) => {
  const { isFetching } = Transaction;
  const { params: { access_code } = {} } = ownProps.match || {};
  return {
    isFetching,
    isFetchingRoom: Data.isFetching,
    formikValues: getFormikValues(Transaction, access_code),
    outsideFormSnapshot: getQuestionnaireSnapshotByTransactionId(
      Transaction,
      access_code,
    ),
    questions: getOrderedQuestions(Transaction, access_code),
    reviewInfo: getReviewValues(Transaction, access_code),
    questionnaireMeta: getQuestionnaireByTransactionId(Transaction, access_code),
    faqs: getFaqsFromTransaction(Transaction, access_code),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { params: { access_code } = {} } = ownProps.match || {};

  return {
    createFeature: meta => dispatch(createFeature(meta, true)),
    createResourceName: body => dispatch(createResourceName(body)),
    finalizeOutsideForm: snapshot => dispatch(finalizeOutsideForm(access_code, snapshot)),
    getOutsideBoxToken: accessCode => dispatch(getOutsideBoxToken(accessCode)),
    getOutsideForm: () => dispatch(getOutsideForm(access_code)),
    setNotice: obj => dispatch(setNotice(obj)),
    updateResourceName: (body, resourceId) =>
      dispatch(updateResourceName(body, resourceId, true)),
  };
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(OutsideFormContainer);
