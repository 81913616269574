import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import SavviLoading from '../../SavviLoading';

import './Button.scss';

const Button = ({
  buttonType,
  children,
  className,
  componentClass,
  fetchingIconColor,
  href,
  isDisabled,
  isFlat,
  isFetching,
  isForm,
  isWarning,
  label,
  onClick,
  size,
  to,
  disabledTooltip,
  tooltip,
  tooltipAlign,
  tooltipPosition,
  type,
  jsxStyles,
  ...restOfProps
}) => {
  const ButtonComponent = (href && 'a') || (to && Link) || 'button';
  let passedProps = {
    className: classnames(
      className, // Use '&#{&}' to override default styles by doubling a className's specificity
      'myBtn',
      `myBtn--${buttonType}`,
      `myBtn--${size}`,
      {
        isForm,
        isWarning,
        'myBtn--flat': isFlat,
        isDisabled: (href || to) && isDisabled,
      },
    ),
    disabled: isDisabled,
    href,
    style: jsxStyles,
    to,
    type,
    ...restOfProps,
  };

  return (
    <ButtonComponent
      onClick={e => {
        if (isDisabled) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          onClick(e);
        }
      }}
      {...passedProps}
    >
      {isFetching && <SavviLoading size="sm" color={fetchingIconColor} />}
      {!isFetching && (children || label)}
      {!isDisabled && !!tooltip && (
        <span
          className={`myBtn__tooltip myBtn__tooltip--align-${tooltipAlign} myBtn__tooltip--position-${tooltipPosition}`}
        >
          {tooltip}
        </span>
      )}
      {isDisabled && !!disabledTooltip && (
        <span
          className={`myBtn__tooltip myBtn__tooltip--align-${tooltipAlign} myBtn__tooltip--position-${tooltipPosition}`}
        >
          {disabledTooltip}
        </span>
      )}
    </ButtonComponent>
  );
};

Button.propTypes = {
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'link', 'primaryLink', 'icon']),
  children: PropTypes.node,
  className: PropTypes.string,
  fetchingIconColor: PropTypes.oneOf(['black', 'white', 'mint']),
  isDisabled: PropTypes.bool,
  isFlat: PropTypes.bool,
  isFetching: PropTypes.bool,
  isWarning: PropTypes.bool,
  isForm: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'wide', 'lg']),
  tooltip: PropTypes.string,
  tooltipAlign: PropTypes.oneOf(['left', 'middle', 'right']),
  tooltipPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** *** Mainly for style overwriting in Storybook tools  *** */
  jsxStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
  }),
};
Button.defaultProps = {
  buttonType: 'primary',
  label: 'Next',
  fetchingIconColor: 'white',
  onClick: () => {},
  size: 'md',
  tooltipAlign: 'middle',
  tooltipPosition: 'top',
  type: 'button',
};

export default Button;
