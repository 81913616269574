import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash-es';

import paperOSLogo from '../../assets/images/paperOS_capital_at_scale_brown_md.png';
import {
  checkVerificationStatusByQuery,
  exchangeChallenge,
  requestVerificationEmail,
  fetchValidatePassword,
  deleteChallenge,
} from '../../redux/modules/User/operations';
import { clearErrors } from '../../redux/modules/User/actions';
import { getAuthUser } from '../../redux/modules/User/selectors';

import './VerifyByLink.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button } from '../../components/common';
import handlePath from '../../utils/handlePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import PageLoader from '../../components/PageLoader';
import {
  getEmailVerification,
  removeEmailVerification,
} from '../../redux/modules/User/utils';
import { InputPassword } from '../../components/inputs';
import { validatePassword } from '../../utils/FeatureTypes';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const templateDescriptions = {
  'verification-email': 'verification link',
  'email-change-code': 'email change request',
  'email-change-notify': 'email change request',
  'reset-password': 'password reset',
  'magic-link': 'login link',
  'account-invite': 'account invite link',
  'partner-invite': 'partner group invite link',
};

const VerifyByLink = ({ hideVerify }) => {
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const params = useParams();
  const {
    template_key = 'verification-email',
    verification_id,
    verification_code,
  } = params;
  const location = useLocation();
  const { search, state: { redirectLocation } = {} } = location;

  const user = useSelector(getAuthUser);
  const brandMeta = useSelector(BrandMeta);
  const { support_email, assets = {} } = brandMeta || {};

  const [initialized, setInitialized] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [redirectLoc, setRedirectLoc] = useState(redirectLocation);

  const [isSuccess, setIsSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [isPWShowing, setIsPWShowing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const query = new URLSearchParams(search);
  let email = query.get('email');
  let isCancelling = query.get('cancel');
  const storedVerification = getEmailVerification(template_key);

  useEffect(() => {
    const isExpired = dayjs().diff(storedVerification.sent_at, 'h') >= 24;

    if (isSuccess || initializing || initialized) {
      return;
    } else if (isExpired) {
      setInitialized(true);
      return setIsSuccess(false);
    } else if (isCancelling) {
      setInitializing(true);
      dispatch(
        deleteChallenge(verification_id || storedVerification.id, template_key),
      ).then(
        e => {
          setInitialized(true);
          hideVerify();
          setIsSuccess(true);
          setInitializing(false);
        },
        error => {
          if (error.code === 'E_CODE_REDEEMED') {
            setIsSuccess(true);
          }
          setInitialized(true);
          setInitializing(false);
        },
      );
    } else if (template_key === 'reset-password') {
      const queryStr = `?${
        storedVerification.receipt
          ? 'receipt=' + storedVerification.receipt
          : 'code=' + verification_code
      }`;
      dispatch(
        checkVerificationStatusByQuery(
          verification_id || storedVerification.id,
          queryStr,
          template_key,
        ),
      ).then(
        payload => {
          setInitialized(true);
          setInitializing(false);
          setRedirectLoc(payload.state?.redirectLocation || redirectLocation);
        },
        error => {
          if (error && error.code === 'E_CODE_REDEEMED') {
            setIsSuccess(true);
          }
          setInitialized(true);
          setInitializing(false);
        },
      );
    } else if (verification_id || !isEmpty(storedVerification)) {
      setInitializing(true);
      dispatch(
        exchangeChallenge(
          verification_id || storedVerification.id,
          {
            code: verification_code,
          },
          template_key,
          template_key === 'verification-email' || template_key === 'magic-link',
        ),
      ).then(
        e => {
          setInitialized(true);
          hideVerify();
          setIsSuccess(true);
          setInitializing(false);
          setRedirectLoc(e.state?.redirectLocation || redirectLocation);
        },
        error => {
          if (error.code === 'E_CODE_REDEEMED') {
            setIsSuccess(true);
          }
          setInitialized(true);
          setInitializing(false);
        },
      );
    }
  }, [
    dispatch,
    email,
    hideVerify,
    initialized,
    initializing,
    isCancelling,
    isSuccess,
    redirectLocation,
    setIsSuccess,
    storedVerification,
    template_key,
    verification_code,
    verification_id,
  ]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (!passwordError) {
      setSubmitting(true);
      try {
        await dispatch(fetchValidatePassword(password));
        await dispatch(
          exchangeChallenge(
            verification_id || storedVerification.id,
            {
              code: verification_code,
              password,
            },
            template_key,
            template_key !== 'reset-password',
          ),
        );
        await dispatch(clearErrors());
        await setPasswordError('');
        await hideVerify();
        await setIsSuccess(true);
        await setSubmitting(false);
      } catch (error) {
        if (error.code === 'E_CODE_REDEEMED') {
          setIsSuccess(true);
        }
        setSubmitting(false);
      }
    }
  };

  const emailLabel = user.email || email || 'your email';
  const statusLabel =
    template_key === 'email-change-notify' ? 'has been cancelled.' : 'has succeeded.';
  const wordmark = assets?.logo_with_paperos || paperOSLogo;
  const logoClasses = `
    verify-link__logo ${
      (wordmark.includes('savvi') && 'verify-link__logo--savvi') ||
      (wordmark.includes('fund_launch') && 'verify-link__logo--fundLaunch') ||
      ''
    }`;

  return (
    <div className="verify-link__container">
      <div
        className={logoClasses}
        onClick={() =>
          push(
            handlePath(
              { pathname: '/company-select', state: { isInitialLoad: true } },
              0,
            ),
          )
        }
      >
        <img src={wordmark} alt="savvi-logo" />
      </div>
      <div className="verify-link__heading">
        {!initialized && <PageLoader />}
        {initialized && template_key === 'reset-password' && !isSuccess && (
          <>
            <h3>
              Reset your password for <b>{emailLabel}</b>
            </h3>
            <InputPassword
              inputClassName="-js-reset-password__input-password"
              error={passwordError}
              label={'Enter New Password'}
              name={'reset-password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              onBlur={e => setPasswordError(validatePassword(e.target.value))}
              hasToggle
              onToggle={() => setIsPWShowing(!isPWShowing)}
              isPlainText={isPWShowing}
            />
            <div className="login__action">
              <Button
                className="-js-reset-password__btn-main"
                isFetching={submitting}
                isDisabled={submitting}
                onClick={handleSubmit}
              >
                Reset Password
              </Button>
            </div>
          </>
        )}
        {initialized && isSuccess && (
          <>
            <h3>
              {initialized && !isSuccess && <FontAwesomeIcon icon="exclamation-circle" />}
              {`Your ${templateDescriptions[template_key] || 'link'} for`}
            </h3>
            <h2>
              <b>{emailLabel}</b>
            </h2>
            <h3>{statusLabel}</h3>
            <Button
              size="sm"
              to={
                redirectLoc ||
                redirectLocation ||
                handlePath(
                  { pathname: '/company-select', state: { isInitialLoad: true } },
                  0,
                )
              }
              onClick={() => removeEmailVerification(template_key)}
            >
              Continue From Here
            </Button>
            <h3>or</h3>
            <Button buttonType="link" onClick={() => window.close()}>
              close this tab
            </Button>
          </>
        )}
        {initialized && !isSuccess && template_key !== 'reset-password' && (
          <>
            <h3>
              {initialized && !isSuccess && <FontAwesomeIcon icon="exclamation-circle" />}
              {`Your ${templateDescriptions[template_key]} link from`}
            </h3>
            <h2>
              <b>{user.email || email || 'your email'}</b>
            </h2>
            <h3>
              has failed to verify. Your verification link may have expired or you may
              have already verified.
            </h3>
            <Button
              size="sm"
              to={
                redirectLoc ||
                redirectLocation ||
                handlePath(
                  { pathname: '/company-select', state: { isInitialLoad: true } },
                  0,
                )
              }
              onClick={() => removeEmailVerification(template_key)}
            >
              Continue From Here
            </Button>
            <h3>or</h3>
            <Button
              buttonType="link"
              onClick={() => {
                dispatch(clearErrors());
                dispatch(
                  requestVerificationEmail({
                    identifier: {
                      type: 'verification_code',
                      value: `${verification_id}:${verification_code}`,
                    },
                    template: template_key,
                    state: { redirectLocation: redirectLoc || redirectLocation },
                  }),
                ).then(e => replace('/verify-code/verification-email'));
              }}
            >
              resend verification code
            </Button>
          </>
        )}
      </div>
      <div className="verify-link__text">
        <p>
          For help with any issues please contact{' '}
          <a href={`mailto:${support_email || 'support@savvi.legal'}`}>support</a>
        </p>
      </div>
    </div>
  );
};

export default VerifyByLink;
