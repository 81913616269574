import { useMemo, useEffect, useState } from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js/pure';

import InputStyledCheckbox from '../common/InputStyledCheckbox';
import { Button, MyModal, TooltipAction } from '../common';
import {
  AccountSubs,
  CurrentSubscriptionIndex,
} from '../../redux/modules/Formation/selectors';

import './PlansModal.scss';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  IsFetching,
  FilteredPaymentMethodsWithDefault,
  PlanPreviewTotal,
  // StripeDomainSlug,
  // PlaidToken,
} from '../../redux/modules/Billing/selectors';
import { BrandMeta } from '../../redux/modules/UI/selectors';
import {
  addPaymentMethod,
  getPaymentMethods,
  // getPlaidToken,
  getSubscriptionPreview,
  removeACHMethod,
  removePaymentMethod,
  setSubscription,
} from '../../redux/modules/Billing/operations';
import { InputSelect, InputStyledTextField } from '../inputs';
import { isEmpty } from 'lodash-es';
import { useHistory, useLocation, useParams } from 'react-router';
import handlePath from '../../utils/handlePath';
import { setNotice } from '../../redux/modules/UI/actions';
import { PaymentMethodCard } from '../SettingsBilling/SettingsBilling';
// import { PaymentMethodCard, PlaidAction } from '../SettingsBilling/SettingsBilling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwoCharCountryData } from '../../data/CountryData';
import SavviLoading from '../SavviLoading';

const bem = elementName => `plansModal${elementName ? '__' + elementName : ''}`;

export const paperOSPlansDict = [
  {
    label: 'Personal',
    description: 'A free plan for investors & individuals.',
    details_link: 'https://paperos.com/plans',
    priceLabel: 'Free',
    priceValue: 0,
    slug: 'basic',
  },
  {
    label: 'Professional',
    description: 'For early-stage operators.',
    details_link: 'https://paperos.com/plans',
    priceLabel: '$499',
    priceValue: 499,
    slug: 'starter',
    savvi_slug: 'starter_annual',
  },
  {
    label: 'Portfolio',
    description: 'For teams managing multiple entities and capital relationships.',
    details_link: 'https://paperos.com/plans',
    priceLabel: 'Talk to Sales',
    priceValue: 0,
    slug: 'advanced',
    savvi_slug: 'advanced_annual',
  },
  {
    label: 'Platform',
    description: 'Unlock scale across your unique private capital ecosystem.',
    details_link: 'https://paperos.com/plans',
    priceLabel: 'Talk to Sales',
    priceValue: 0,
    slug: 'premium',
    savvi_slug: 'premium',
  },
];

export const savviPlansDict = [
  {
    label: 'Basic',
    description: 'The free plan for new users who are still trying out Savvi.',
    details_link: 'https://savvi.legal/plans',
    priceLabel: 'Free',
    priceValue: 0,
    slug: 'basic',
  },
  {
    label: 'Starter',
    description:
      'A popular plan for small teams dealing with primarily back-office workflows.',
    details_link: 'https://savvi.legal/plans',
    priceLabel: '$499',
    priceValue: 499,
    slug: 'starter',
    savvi_slug: 'starter_annual',
  },
  {
    label: 'Advanced',
    description: 'The best plan for teams managing both front & back-office operations.',
    details_link: 'https://savvi.legal/plans',
    priceLabel: '$2,000',
    priceValue: 2000,
    slug: 'advanced',
    savvi_slug: 'advanced_annual',
  },
  {
    label: 'Premium',
    description:
      'For established teams with needs related to high volume workflows, custom logic, portfolio management, and integrations.',
    details_link: 'https://savvi.legal/plans',
    priceLabel: 'Talk to Sales',
    priceValue: 0,
    slug: 'premium',
    savvi_slug: 'premium',
  },
];

const partnerStripeDict = {
  paperos: process.env.REACT_APP_STRIPE_KEY,
  develop: process.env.REACT_APP_TEST_STRIPE_KEY,
  rams: process.env.REACT_APP_RAMS_TEST_STRIPE_KEY,
};

const PlansModalWrapper = props => {
  const selectedStripeDomainSlug = 'paperos';
  // const selectedStripeDomainSlug = useSelector(StripeDomainSlug);

  const stripePromise = useMemo(() => {
    return loadStripe(partnerStripeDict['paperos']);
  }, []);
  // const stripePromise = useMemo(() => {
  //   return loadStripe(partnerStripeDict[selectedStripeDomainSlug]);
  // }, [selectedStripeDomainSlug]);

  return (
    <Elements stripe={stripePromise} key={`stripe-elements-${selectedStripeDomainSlug}`}>
      <PlansModal {...props} />
    </Elements>
  );
};

const PlansModal = ({ isInitOpen = true, passedParams = {} }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { push } = history;
  const routeParams = useParams();
  const { pathname, state } = useLocation();
  const { background } = state || {};
  const { companyId, moduleId, projectId, transactionId } = passedParams;
  const brandMeta = useSelector(BrandMeta);
  const { base_domain, support_email } = brandMeta;

  const plansDict = (base_domain || 'paperos').includes('savvi')
    ? savviPlansDict
    : paperOSPlansDict;

  const currentPlanIndex = useSelector(CurrentSubscriptionIndex);
  const isFetching = useSelector(IsFetching);
  const methods = useSelector(FilteredPaymentMethodsWithDefault);
  const accountSubs = useSelector(AccountSubs);
  const previewTotal = useSelector(PlanPreviewTotal);

  // const plaidToken = useSelector(PlaidToken);
  let defaultMethodIndex = methods.findIndex(e => e.is_default);
  if (defaultMethodIndex === -1) {
    defaultMethodIndex = 0;
  }

  const [country, setCountry] = useState({
    value: 'US',
    label: 'United States of America (the)',
  });
  const [isAddNewMethod, setIsAddNewMethod] = useState(false);
  const [isDefault, setIsDefault] = useState(true);
  const [isMethodsShowing, setIsMethodsShowing] = useState(false);
  const [name, setName] = useState('');
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(currentPlanIndex);
  const [selectedMethodIndex, setSelectedMethodIndex] = useState(defaultMethodIndex);
  const [submitting, setSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState(false);

  let totalLabel = '$' + previewTotal;
  if (
    !previewTotal ||
    accountSubs.length > 0 ||
    selectedPlanIndex === 0 ||
    selectedPlanIndex === 3 ||
    currentPlanIndex === 1
  ) {
    totalLabel = plansDict[selectedPlanIndex].priceLabel;
  }
  const isBelowCurrentPlan = currentPlanIndex > selectedPlanIndex;
  const isCurrentPlanSelected =
    currentPlanIndex !== 0 && currentPlanIndex === selectedPlanIndex;
  const isTotalShowing =
    selectedPlanIndex < 3 &&
    !isBelowCurrentPlan &&
    !isCurrentPlanSelected &&
    plansDict[selectedPlanIndex].priceValue !== 0;
  const isTotalProrated =
    !isBelowCurrentPlan &&
    currentPlanIndex !== 0 &&
    currentPlanIndex !== 3 &&
    previewTotal &&
    previewTotal !== plansDict[selectedPlanIndex].priceValue;
  const isPaymentReady =
    currentPlanIndex === 0 ||
    currentPlanIndex === 3 ||
    (methods.length > 0 && !isAddNewMethod) ||
    (!!name && !!country);

  const isSubmitDisabled =
    isBelowCurrentPlan ||
    isCurrentPlanSelected ||
    submitting ||
    isFetching ||
    (plansDict[selectedPlanIndex].priceValue !== 0 && !isPaymentReady);

  // useEffect(() => {
  //   dispatch(getPlaidToken());
  // }, [dispatch]);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (methods.length === 0) {
      dispatch(getPaymentMethods());
    }
  }, [dispatch, methods.length]);

  const onClose = () => {
    setSubmitting(false);
    // if (action !== 'POP') {
    //   goBack();
    // } else if (background) {
    if (background) {
      push(background);
    } else {
      push(
        handlePath(
          transactionId
            ? `/workbench/${moduleId}/${projectId}/${transactionId}/plans`
            : '/home',
          companyId || routeParams.companyId,
        ),
      );
    }
  };

  const onActionClick = (actionType, index) => {
    if (actionType === 'remove') {
      let { brand, last4, id } = methods[index];
      if (!brand) {
        brand = 'Bank Account';
      }
      const message = `Are you sure you want to delete the following card? \n\n${brand} •••• ${last4} \n`;

      if (window.confirm(message)) {
        if (!brand) {
          return dispatch(removeACHMethod(id)).then(
            e => selectedMethodIndex > methods.length - 1 && setSelectedMethodIndex(0),
          );
        }
        return dispatch(removePaymentMethod(id)).then(
          e => selectedMethodIndex > methods.length - 1 && setSelectedMethodIndex(0),
        );
      }
    }
  };

  // const handlePlaidSuccess = payload => {
  //   dispatch(getPaymentMethods()).then(
  //     e => {
  //       const updatedMethods = e.methods.filter(
  //         e => e.status !== 'new' && e.status !== 'verification_failed',
  //       );
  //       let selectedMethodIndex = updatedMethods.findIndex(
  //         e => payload.source_id === e.id,
  //       );
  //       if (selectedMethodIndex === -1) {
  //         selectedMethodIndex = defaultMethodIndex;
  //       }
  //       setIsAddNewMethod(false);
  //       setSelectedMethodIndex(selectedMethodIndex);
  //     },
  //     error => setIsMethodsShowing(true),
  //   );
  // };

  const handleSubmit = async () => {
    const optionSlug = plansDict[selectedPlanIndex].slug;
    const optionValue = plansDict[selectedPlanIndex].priceValue;
    const isFL360Domain = (base_domain || 'paperos').includes('fundlaunch360');

    setSubmitting(true);
    if (optionValue === 0) {
      let scheduleLink = 'https://meetings.hubspot.com/fischer-balfe';
      if (isFL360Domain) {
        scheduleLink = 'https://calendly.com/stockton-ashby/personalcall';
      }
      const win = window.open(scheduleLink, '_blank');
      win.focus();
      return onClose();
    } else if (optionSlug === 'basic') {
      return onClose();
    } else if (methods.length > 0 && !isAddNewMethod) {
      return dispatch(
        setSubscription(
          plansDict[selectedPlanIndex].savvi_slug,
          methods[selectedMethodIndex].id,
        ),
      )
        .then(e => {
          return onClose();
          // if (!e.error) {
          //   onClose();
          // }
        })
        .catch(e => setSubmitting(false));
    } else if (!name) {
      setSubmitting(false);
      return dispatch(
        setNotice({
          type: 'error',
          message: 'Please provide a name for your payment card',
        }),
      );
    } else if (!country) {
      setSubmitting(false);
      return dispatch(
        setNotice({
          type: 'error',
          message: 'Please provide a country for your payment card',
        }),
      );
    }

    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement, {
      name,
      country: country.value,
    });

    if (!error) {
      await dispatch(addPaymentMethod(token, isDefault)).then(e => {
        if (e && !e.error) {
          dispatch(setSubscription(plansDict[selectedPlanIndex].savvi_slug))
            .then(e => {
              return onClose();
              // if (!e.error) {
              //   onClose();
              // }
            })
            .catch(e => setSubmitting(false));
        }
      });
    } else {
      if (error.type === 'validation_error') {
        setStripeError(error.message);
      } else if (error.type === 'invalid_request_error') {
        setStripeError(error.message);
      } else {
        setStripeError(
          'Stripe is detecting an error in your information, please recheck your inputs.',
        );
        console.info('catch', error);
      }
      setSubmitting(false);
    }
  };

  const renderOption = (
    { label, description, details_link, priceLabel, priceValue, slug, savvi_slug },
    index,
  ) => (
    <div
      className={classNames(bem('option'), {
        [bem('option--selected')]: label === plansDict[selectedPlanIndex].label,
      })}
      key={`plan-option-${slug}`}
      onClick={e => {
        setSelectedPlanIndex(index);
        if (index !== 0 && index !== 3 && index !== selectedPlanIndex) {
          dispatch(getSubscriptionPreview(savvi_slug));
        }
      }}
    >
      <InputStyledCheckbox
        className={bem('optionCheckbox')}
        checked={label === plansDict[selectedPlanIndex].label}
        name={`plan-option-${label}`}
        type="radio"
        value={label}
      />
      <div className={bem('optionBody')}>
        <h2>
          {label}
          {currentPlanIndex !== 0 && index <= currentPlanIndex && (
            <span className={bem('asterik')}>*</span>
          )}
          {currentPlanIndex === index && <span>(current)</span>}
        </h2>
        <h4>{description}</h4>
        <Button
          buttonType="link"
          size="xs"
          href={details_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fal', 'external-link']} />
          View details
        </Button>
      </div>
      <div className={bem('optionPrice')}>
        {currentPlanIndex !== index && (
          <>
            <h2>{priceLabel}</h2>
            {priceValue !== 0 && <p>per year</p>}
          </>
        )}
      </div>
    </div>
  );

  const isDefaultLabel =
    methods.length === 0
      ? 'Card will be saved as default payment method'
      : 'Use as default payment method';

  const isPaymentMethodShowing =
    plansDict[selectedPlanIndex].priceValue !== 0 &&
    !isBelowCurrentPlan &&
    selectedPlanIndex !== currentPlanIndex;

  const primaryActionLabel =
    (selectedPlanIndex !== 0 &&
      plansDict[selectedPlanIndex].priceValue === 0 &&
      'Talk to us') ||
    'Subscribe';

  return (
    <MyModal
      isOpen={pathname.includes('/plans')}
      onRequestClose={onClose}
      className={bem()}
      overlayClassName={bem('overlay')}
    >
      <div className={bem('top')}>
        <h1>Choose Your PaperOS Plan</h1>
        <h4>
          Current Plan: <strong>{plansDict[currentPlanIndex].label}</strong>
        </h4>
      </div>
      <div className={bem('options')}>{plansDict.map(renderOption)}</div>
      {currentPlanIndex !== 0 && (
        <div className={bem('alert')}>
          <h4>
            {!isBelowCurrentPlan && <span className={bem('asterik')}>*</span>}
            {isBelowCurrentPlan && <FontAwesomeIcon icon="exclamation-circle" />}
            To downgrade or cancel your plan please
            <Button
              buttonType="link"
              size="xs"
              onClick={e => {
                e.preventDefault();
                if (window.HubSpotConversations) {
                  window.HubSpotConversations.widget.open();
                } else {
                  window.hsConversationsOnReady = [
                    () => {
                      window.HubSpotConversations.widget.open();
                    },
                  ];
                }
              }}
            >
              message support
            </Button>
            or contact us at{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${support_email || 'support@paperos.com'}`}
            >
              {support_email || 'support@paperos.com'}
            </a>
          </h4>
        </div>
      )}
      <div className={bem('bottom')}>
        {isPaymentMethodShowing && (
          <div className={bem('payment')}>
            <h3>
              Payment
              <TooltipAction
                text="We recommend setting up an ACH payment method to avoid any additional transaction and processing fees that may occur when using a credit card."
                toggleIcon="info-circle"
              />
              {methods.length > 1 && !isAddNewMethod && (
                <Button
                  buttonType="link"
                  isDisabled={submitting || isFetching}
                  size="sm"
                  onClick={() => setIsMethodsShowing(!isMethodsShowing)}
                >
                  {isMethodsShowing ? 'Hide other methods' : 'View saved methods'}
                </Button>
              )}
              {isAddNewMethod && (
                <Button
                  buttonType="link"
                  isDisabled={submitting || isFetching}
                  size="sm"
                  onClick={() => setIsAddNewMethod(!isAddNewMethod)}
                >
                  Use Saved Method
                </Button>
              )}
            </h3>
            {(methods.length === 0 || isAddNewMethod) && (
              <>
                <CardElement
                  className={bem('stripeContainer')}
                  options={{
                    hideIcon: true,
                    style: {
                      base: {
                        fontSize: '18px',
                        '::placeholder': {
                          color: '#afbdc5',
                          fontWeight: 400,
                          fontSize: '16px',
                          fontStyle: 'italic',
                        },
                      },
                    },
                  }}
                />
                <div className={bem('inputRow')}>
                  <InputStyledTextField
                    label="Name on Card"
                    name="name"
                    icon="user"
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <InputSelect
                    label="Country"
                    name="country"
                    options={TwoCharCountryData}
                    isClearable={false}
                    value={country}
                    onChange={val => {
                      setCountry(val);
                    }}
                  />
                </div>
                {!isEmpty(stripeError) && (
                  <div className={bem('stripeError')}>
                    <h4>
                      <strong>Error: </strong> {stripeError}
                    </h4>
                  </div>
                )}
                <InputStyledCheckbox
                  checked={isDefault}
                  value={isDefault}
                  isDisabled={methods.length === 0}
                  name="is_default"
                  label={isDefaultLabel}
                  onChange={e => setIsDefault(!isDefault)}
                />
              </>
            )}
            {methods.length > 0 &&
              !isAddNewMethod &&
              methods.map(
                (e, index) =>
                  (isMethodsShowing || selectedMethodIndex === index) && (
                    <PaymentMethodCard
                      key={`plans-method-${index}`}
                      method={e}
                      index={index}
                      isPlans
                      isDisabled={submitting || isFetching}
                      defaultMethodIndex={selectedMethodIndex}
                      onActionClick={onActionClick}
                      onSaveDefault={index => {
                        setSelectedMethodIndex(index);
                        setIsMethodsShowing(!isMethodsShowing);
                      }}
                    />
                  ),
              )}
            <div className={bem('methodActions')}>
              {!isAddNewMethod && methods.length > 0 && (
                <Button
                  buttonType="secondary"
                  size="sm"
                  onClick={() => {
                    setIsMethodsShowing(false);
                    setIsAddNewMethod(!isAddNewMethod);
                  }}
                >
                  Add New Payment
                </Button>
              )}
              <div className={bem('methodActionsCol')}>
                <Button
                  size="sm"
                  className={isAddNewMethod ? bem('methodAction--link') : ''}
                  buttonType={isAddNewMethod ? 'link' : 'primary'}
                  to={handlePath('/settings/billing', companyId || routeParams.companyId)}
                >
                  Add ACH Payment Method
                </Button>
                {/* {(isAddNewMethod || methods.length === 0) && !!plaidToken && (
                    <>
                      <h4>or</h4>
                      <PlaidAction
                        plaidToken={plaidToken}
                        onSuccess={handlePlaidSuccess}
                      />
                    </>
                  )} */}
              </div>
            </div>
          </div>
        )}
        <div className={bem('questions')}>
          <h3>Have Questions?</h3>
          <Button
            buttonType="secondary"
            onClick={e => {
              e.preventDefault();
              if (window.HubSpotConversations) {
                window.HubSpotConversations.widget.open();
              } else {
                window.hsConversationsOnReady = [
                  () => {
                    window.HubSpotConversations.widget.open();
                  },
                ];
              }
            }}
          >
            Contact Us
          </Button>
        </div>
      </div>
      <div className={bem('actions')}>
        <Button buttonType="secondary" onClick={onClose}>
          Close
        </Button>
        {isTotalShowing && (
          <h1>
            Total:{' '}
            {(!isFetching || !!submitting) &&
              `${totalLabel}${isTotalProrated ? '*' : ''}`}
            {isFetching && !submitting && <SavviLoading size="sm" />}
          </h1>
        )}
        <Button
          isFetching={submitting || isFetching}
          isDisabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          {primaryActionLabel}
        </Button>
      </div>
      {isTotalProrated && isTotalShowing && (
        <h4 className={bem('sub')}>
          {currentPlanIndex > 0 &&
            '*Any pro-rated subscriptions or billing credits will be applied upon payment.'}
          {currentPlanIndex === 0 &&
            '*Total amount reflects any pro-rated subscriptions or billing credits.'}
        </h4>
      )}
    </MyModal>
  );
};

export default PlansModalWrapper;
