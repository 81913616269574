import React from 'react';

import { Field, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../components/common/Button';
import { Password, Select, StyledCheckbox, TextField } from '../../components/formik';
import TermsOfService from '../../components/TermsOfServiceModal';

import './Register.scss';
import { businessTypeOptions } from '../NewCompany/NewCompany';
import {
  validateEmail,
  validatePassword,
  validateSelect,
  validateString,
} from '../../utils/FeatureTypes';

const RegisterForm = ({
  fl360sso,
  isFetching,
  isModalOpen,
  isPasswordPlainText,
  isRoomRedirect,
  isWhiteLabel,
  handleTogglePassword,
  handleOnLoginClick,
  logo_with_paperos,
  show_business_types,
  status,
  toggleModal,
  values,
  wordmark,
  logoClasses,
}) => (
  <div className="register">
    <div className="register__logoWrapper">
      <img className={logoClasses} src={wordmark} alt="savvi-logo" />
    </div>
    <Form className="card card-body register__form">
      <h2 className="form__heading">
        {(fl360sso && 'Please answer a few questions for us before continuing') ||
          (isWhiteLabel && 'Please answer a few questions to get started.') ||
          'Create an account to get started.'}
        {!!fl360sso && (
          <span>
            <FontAwesomeIcon icon="exclamation-circle" />
            Make sure to use the same email as your Fund Launch 360 account
          </span>
        )}
      </h2>
      <div className="grid--flex">
        <div className="grid__column">
          <Field
            component={TextField}
            inputClassName="-js-register__input-first-name"
            name="first_name"
            label="First Name"
            icon="user"
            validate={validateString}
          />
        </div>
        <div className="grid__column">
          <Field
            component={TextField}
            inputClassName="-js-register__input-last-name"
            name="last_name"
            label="Last Name"
            icon="user"
            validate={validateString}
          />
        </div>
      </div>
      <Field
        component={TextField}
        autoComplete="username"
        inputClassName="-js-register__input-email"
        name="email"
        label={'Email'}
        placeholder={'john@savvi.com'}
        icon="envelope"
        type="email"
        validate={validateEmail}
      />
      <Field
        component={Password}
        inputClassName="-js-register__input-password"
        name="password"
        label="Password"
        autoComplete="new-password"
        icon="lock"
        hasToggle={true}
        sublabel={
          fl360sso
            ? 'This will be a unique password for when you need to login to PaperOS separate from your CRM.'
            : ''
        }
        onToggle={handleTogglePassword}
        isPlainText={isPasswordPlainText}
        validate={validatePassword}
      />
      {!isRoomRedirect && (
        <>
          <Field
            component={StyledCheckbox}
            inputClassName="-js-register__input-create_workspace"
            label="Add Workspace"
            name="create_workspace"
            sublabel="Add a Workspace for your new or existing organization, company or entity. You can always add a Workspace later."
          />
          {!!values.create_workspace && values.create_workspace !== '0' && (
            <>
              <Field
                component={TextField}
                inputClassName="-js-register__input-company-name"
                label={'Company Name'}
                name="company_name"
                placeholder={'e.g. My Company Name, Inc.'}
                sublabel="If you currently aren't sure of your company name or suffix, go ahead and give us a placeholder name. You will be able to change it later."
                validate={validateString}
              />
              {(!isWhiteLabel || !!show_business_types) && (
                <Field
                  component={Select}
                  isValidatingOnBlur={false}
                  label="What is the purpose of this business entity?"
                  name="business_type"
                  options={businessTypeOptions}
                  validate={validateSelect}
                />
              )}
            </>
          )}
        </>
      )}
      <Field
        component={StyledCheckbox}
        inputClassName="-js-register__input-tos"
        name="tos"
        label={
          <label htmlFor="tos">
            I agree to {isWhiteLabel ? "PaperOS'" : "Savvi's"}{' '}
            <a
              href="http://docs.wixstatic.com/ugd/342422_fa8385a417404f138a687a08f8c5bc15.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            {' and'}
            <a
              href="http://docs.wixstatic.com/ugd/342422_2fcd1c73409a443d988a73a2fb263716.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="footer__link"
            >
              Privacy Policy
            </a>
          </label>
        }
        validate={val =>
          val !== '1' ? "You must agree to Savvi's Terms of Service" : ''
        }
      />
      {status.form && <div className="alert form__error">{status.form}</div>}
      <div className="register__actions">
        <Button
          className="-js-register__btn-back"
          buttonType="secondary"
          onClick={handleOnLoginClick}
        >
          Back
        </Button>
        <Button
          className="-js-register__btn-main"
          isFetching={isFetching}
          isDisabled={isFetching}
          type="submit"
        >
          {fl360sso ? 'Continue' : 'Create Account'}
        </Button>
      </div>
    </Form>
    <TermsOfService
      isOpen={isModalOpen}
      onClose={() => toggleModal(false)}
      label="Terms of Service"
    />
  </div>
);

RegisterForm.defaultProps = {
  values: {},
  status: {},
};

export default RegisterForm;
