import React, { Component } from 'react';

import get from 'lodash-es/get';
import pick from 'lodash-es/pick';

import { InputPassword } from '../../inputs';

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlainText: false,
    };
  }
  render() {
    const {
      field, //: { name, value, onChange, onBlur },
      form: { touched, errors, status },
      handleFormBlur,
      myType,
      ...props // everything that we should pass goes through here classnames etc
    } = this.props;
    const { isPlainText } = this.state;
    let { name, value } = field;
    if (value && myType === 'social_security') {
      value = value.replace(/[^0-9|\\*]/g, '');
      value = value.substring(0, 9);
    }
    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    const error = apiError || (isTouched && get(errors, name, null));
    return (
      <InputPassword
        error={error}
        hasToggle={true}
        isPlainText={isPlainText}
        onBlur={e => {
          handleFormBlur(e);
          field.onBlur(e);
        }}
        onToggle={() => {
          this.setState({ isPlainText: !isPlainText });
        }}
        {...pick(field, ['name', 'onChange'])}
        {...props}
        value={value}
      />
    );
  }
}

Password.defaultProps = {
  handleFormBlur: () => {},
};

export default Password;
