import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty, isEqual } from 'lodash-es';

import { setCompany } from '../../redux/modules/Company/operations';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { deleteTodo, getTodos, updateTodo } from '../../redux/modules/Todos/operations';
import { IsFetching } from '../../redux/modules/Todos/selectors';
import { Button, TooltipAction } from '../common';

import './TodosSection.scss';
import AssignTodo, { MultiAssignTodos, priorityOptions } from '../AssignTodo';
import InputStyledCheckbox from '../common/InputStyledCheckbox';
import handlePath from '../../utils/handlePath';
import dayjs from 'dayjs/esm';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const sortStrings = (a, b, dir) =>
  dir === 'desc' ? b.localeCompare(a) : a.localeCompare(b);

const sortPriorities = (a, b, dir) => {
  let isAGreater = a > b;
  let isBGreater = a < b;

  if (isAGreater) {
    return dir === 'desc' ? -1 : 1;
  }
  if (isBGreater) {
    return dir === 'desc' ? 1 : -1;
  }
  return dayjs(b.created_at).diff(dayjs(a.created_at));
};

const sortTodos = (todos, dir, filter) =>
  todos.sort((a, b) => {
    if (filter === 'label') {
      return sortStrings(a.label, b.label, dir);
    }
    if (filter === 'date') {
      return dir === 'desc'
        ? dayjs(b.created_at).diff(dayjs(a.created_at))
        : dayjs(a.created_at).diff(dayjs(b.created_at));
    }
    if (filter === 'type') {
      return sortStrings(a.type, b.type, dir);
    }
    if (filter === 'priority') {
      return sortPriorities(a.level, b.level, dir);
    }
    if (filter === 'company') {
      return sortStrings(a.account.name, b.account.name, dir);
    }
    if (filter === 'deadline') {
      if (a.deadline && b.deadline) {
        return dir === 'desc'
          ? dayjs(b.deadline).diff(dayjs(a.deadline))
          : dayjs(a.deadline).diff(dayjs(b.deadline));
      }
      if (!a.deadline && b.deadline) {
        return dir === 'desc' ? -1 : 1;
      }
      if (!b.deadline && a.deadline) {
        return dir === 'desc' ? 1 : -1;
      }
      return dir === 'desc'
        ? dayjs(a.created_at).diff(dayjs(b.created_at))
        : dayjs(b.created_at).diff(dayjs(a.created_at));
    }
    if (filter === 'assignee') {
      if (!isEmpty(a.partner)) {
        if (isEmpty(b.partner)) {
          return dir === 'desc' ? 1 : -1;
        }
        return sortStrings(a.partner.name, get(b, 'partner.name', ''), dir);
      } else if (!isEmpty(a.user)) {
        if (isEmpty(b.user)) {
          return dir === 'desc' ? 1 : -1;
        }
        return sortStrings(a.user.full_name, get(b, 'user.full_name', ''), dir);
      }
      return dir === 'desc' ? -1 : 1;
    }
    // Default to priority;
    return sortPriorities(a.level, b.level, dir);
  });

const TodosSection = ({ isInitOpen, isTeam, sectionLabel, todos }) => {
  const dispatch = useDispatch();

  const isFetching = useSelector(IsFetching);
  const currentAccountId = useSelector(getAccountId);

  const [dir, setDir] = useState('desc');
  const [filter, setFilterSlug] = useState('priority');
  const [isSectionOpen, setIsOpen] = useState(isInitOpen);
  const [isMultiEdit, setIsMultiEdit] = useState(false);
  const [isMultiEditModalOpen, setIsMultiEditModalOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isClearAll, setIsClearAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [myTodos, setTodos] = useState(todos);
  const [prevTodos, setPrevTodos] = useState([]);
  const [selectedTodos, setSelectedTodos] = useState([...Array(todos.length)]);
  const [selectedTodo, setSelectedTodo] = useState({});
  const [isEditTodoOpen, setIsEditTodoOpen] = useState(false);

  const closeMultiModal = () => {
    setIsMultiEdit(false);
    setIsMultiEditModalOpen(false);
    setSelectedTodos([...Array(myTodos.length)]);
  };

  const handleDeleteTodo = (id, isMulti, accountId) => {
    const message = isMulti
      ? 'Are you sure you want to delete selected To-dos?'
      : 'Are you sure you want to delete this To-do?';
    if (window.confirm(message)) {
      if (isMulti) {
        selectedTodos.forEach((e, index) => {
          if (index + 1 === selectedTodos.length) {
            if (e) {
              dispatch(deleteTodo(myTodos[index].id, isTeam, e.account_id, true)).then(
                closeMultiModal,
              );
            } else {
              dispatch(getTodos(isTeam));
              closeMultiModal();
            }
          } else if (e) {
            dispatch(deleteTodo(myTodos[index].id, isTeam, e.account_id));
          }
        });
      } else {
        return dispatch(deleteTodo(id, isTeam, accountId, true));
      }
    }
  };

  const handleUpdateTodos = formikProps => {
    let { deadline, selectedAssignee, selectedPriority } = formikProps;
    let updatedDeadline = deadline
      ? dayjs(deadline + 'T21:00:00.000Z', 'MM/DD/YYYY').format()
      : null;
    selectedTodos.forEach((e, index) => {
      const todo = myTodos[index];
      let todoDeadline = todo.deadline || null;
      if (todoDeadline) {
        todoDeadline = dayjs(todoDeadline + 'T21:00:00.000Z', 'MM/DD/YYYY').format();
      }
      let updatedBody = {
        deadline: updatedDeadline || todoDeadline,
        base_level: (selectedPriority && selectedPriority.value) || todo.base_level,
        label: todo.label,
        user_id: (selectedAssignee && selectedAssignee.user_id) || todo.user_id,
        partner_id: (selectedAssignee && selectedAssignee.partner_id) || todo.partner_id,
      };
      if (index + 1 === selectedTodos.length) {
        if (e) {
          dispatch(updateTodo(todo.id, updatedBody, isTeam, e.account_id, true)).then(
            closeMultiModal,
            closeMultiModal,
          );
        } else {
          dispatch(getTodos(isTeam));
          closeMultiModal();
        }
      } else if (e) {
        dispatch(updateTodo(todo.id, updatedBody, isTeam, e.account_id));
      }
    });
  };

  const setFilter = useCallback(
    (clickedFilter, newTodos) => {
      let updatedDir = dir;
      let updatedFilter = filter;
      if (clickedFilter === filter) {
        if (dir === 'desc') {
          updatedDir = 'asc';
        } else if (dir === 'asc') {
          updatedDir = 'desc';
          updatedFilter = '';
        }
      } else if (clickedFilter && clickedFilter !== filter) {
        updatedDir = 'desc';
        updatedFilter = clickedFilter;
      }
      let updatedTodos = newTodos || myTodos;

      updatedTodos = sortTodos(updatedTodos, updatedDir, updatedFilter);
      setDir(updatedDir);
      setFilterSlug(updatedFilter);
      setTodos(updatedTodos);
      setSelectedTodos([...Array(updatedTodos.length)]);
    },
    [dir, filter, myTodos, setDir, setFilterSlug, setTodos],
  );

  useEffect(() => {
    setTodos(
      todos.sort((a, b) => {
        if (a.level > b) {
          return -1;
        } else if (a.level < b) {
          return 1;
        }
        return dayjs(b.created_at).diff(dayjs(a.created_at));
      }),
    );
  }, [todos]);

  useEffect(() => {
    if (!isEqual(prevTodos, todos)) {
      setFilter(null, todos);
      setPrevTodos(todos);
    }
  }, [setFilter, prevTodos, todos]);

  useEffect(() => {
    if (isMultiEdit && selectedTodos.indexOf(true) === -1) {
      setIsMultiEdit(false);
      setIsIndeterminate(false);
      setIsAllSelected(false);
    }
  }, [selectedTodos, isMultiEdit]);

  useEffect(() => {
    if (isClearAll) {
      setTimeout(() => setIsClearAll(false), 100);
    }
  }, [isClearAll]);

  if (todos.length === 0) {
    return null;
  }

  return (
    <div className="todosSection">
      <h2 className="todosSection__header">
        <FontAwesomeIcon icon={['fal', 'check-square']} />
        {sectionLabel}
        <span>{` (${myTodos.length > 0 ? myTodos.length : 'None'})`}</span>
        {isTeam && (
          <TooltipAction text="You can edit or delete To-dos in the Home View of the To-do's parent Workspace." />
        )}
      </h2>
      <div className={classnames('todosSection__list', { isTeam })}>
        <h4 className="todosSection__list-checkbox first">
          <div className="todosSection__list-checkbox-highlight" />
          {!isTeam && (
            <InputStyledCheckbox
              checked={isIndeterminate ? false : isAllSelected}
              indeterminate={isIndeterminate}
              name="todo-select-all"
              onClick={e => {
                if (isAllSelected || isIndeterminate) {
                  setIsClearAll(true);
                  setIsAllSelected(false);
                  setIsIndeterminate(false);
                  setSelectedTodos([...Array(myTodos.length)]);
                  setIsMultiEdit(false);
                } else {
                  setIsAllSelected(true);
                  setIsIndeterminate(false);
                  setSelectedTodos([...Array(myTodos.length)].map(e => true));
                  setIsMultiEdit(true);
                }
              }}
              value={isIndeterminate ? false : isAllSelected}
            />
          )}
        </h4>
        <h4
          className="todosSection__list-header-label"
          onClick={() => setFilter('label')}
        >
          Label
          {filter === 'label' && (
            <FontAwesomeIcon
              className="todosSection__list-sorting-icon"
              icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
            />
          )}
        </h4>
        <h4 className="todosSection__list-header-label" onClick={() => setFilter('type')}>
          Type
          {filter === 'type' && (
            <FontAwesomeIcon
              className="todosSection__list-sorting-icon"
              icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
            />
          )}
        </h4>
        {isTeam && (
          <h4
            className="todosSection__list-header-label"
            onClick={() => setFilter('company')}
          >
            Parent Workspace
            {filter === 'company' && (
              <FontAwesomeIcon
                className="todosSection__sorting-icon"
                icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
              />
            )}
          </h4>
        )}
        <div className="todosSection__list-header">
          <h4
            className="todosSection__list-header-label"
            onClick={() => setFilter('date')}
          >
            Date Created
            {filter === 'date' && (
              <FontAwesomeIcon
                className="todosSection__sorting-icon"
                icon={dir === 'desc' ? 'caret-down' : 'caret-up'}
              />
            )}
          </h4>
          {!isTeam && (
            <div
              className={`todosSection__list-header-actions ${
                isMultiEdit ? 'show' : 'hide'
              }`}
            >
              <MultiAssignTodos
                account_id={currentAccountId}
                handleDeleteTodos={() => handleDeleteTodo(null, true)}
                isActionDisabled={isMultiEdit && selectedTodos.indexOf(true) === -1}
                isModalOpen={isMultiEditModalOpen}
                isMyDashboard={isTeam}
                handleUpdateTodos={handleUpdateTodos}
                toggleModal={() => setIsMultiEditModalOpen(!isMultiEditModalOpen)}
              />
              <Button
                buttonType="icon"
                isWarning
                onClick={() => handleDeleteTodo(null, true)}
                size="sm"
                tooltip="Delete Selected Todos"
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              </Button>
            </div>
          )}
        </div>
        {myTodos.map(
          (todoProps, index) =>
            (isSectionOpen || index < 4) && (
              <TodoRow
                key={`todo-${todoProps.id}-${index}`}
                todoIndex={index}
                currentAccountId={currentAccountId}
                dispatch={dispatch}
                handleDeleteTodo={handleDeleteTodo}
                isClearAll={isClearAll}
                isAllSelected={isAllSelected}
                selectedTodos={selectedTodos}
                setSelectedTodos={setSelectedTodos}
                setSelectedTodo={setSelectedTodo}
                setIsEditTodoOpen={setIsEditTodoOpen}
                setIsAllSelected={setIsAllSelected}
                setIsIndeterminate={setIsIndeterminate}
                isLastRow={(isSectionOpen && index === 3) || index === myTodos.length - 1}
                isMultiEdit={isMultiEdit}
                setIsMultiEdit={setIsMultiEdit}
                isFetching={isFetching}
                isTeam={isTeam}
                todoProps={todoProps}
              />
            ),
        )}
        {myTodos.length > 4 && (
          <Button
            buttonType="link"
            className="todosSection__listToggle"
            onClick={() => setIsOpen(!isSectionOpen)}
            size="sm"
          >
            {!isSectionOpen
              ? `Show ${myTodos.length - 4} more to-dos`
              : 'Show less to-dos'}
            <FontAwesomeIcon icon={isSectionOpen ? 'caret-up' : 'caret-down'} />
          </Button>
        )}
      </div>
      <AssignTodo
        handleDeleteTodo={handleDeleteTodo}
        isModalOpen={isEditTodoOpen}
        isInline={false}
        isMyDashboard={isTeam}
        toggleModal={e => {
          setIsEditTodoOpen(false);
          setSelectedTodo({});
        }}
        todoMeta={selectedTodo}
      />
    </div>
  );
};

const TodoRow = ({
  currentAccountId,
  dispatch,
  handleDeleteTodo,
  isMultiEdit,
  isLastRow,
  isTeam,
  selectedTodos,
  setSelectedTodos,
  setIsAllSelected,
  setIsIndeterminate,
  setIsMultiEdit,
  setSelectedTodo,
  setIsEditTodoOpen,
  todoIndex,
  todoProps,
}) => {
  const { push } = useHistory();
  const { companyId } = useParams();
  const { search } = useLocation();
  const {
    account,
    account_id,
    account_project_id,
    account_transaction_id,
    base_level,
    data,
    created_at,
    deadline,
    id,
    label,
    level,
    outside_code,
    partner,
    project_template_id, //if not started,
    state,
    type,
    user,
  } = todoProps;

  const isSignDocument = type === 'Sign Document';
  let projectId = account_project_id || project_template_id;
  const path = {
    pathname: `/workbench/-1/${projectId}/${account_transaction_id || -1}/${
      account_transaction_id ? 'initialize' : 'new'
    }`,
    search,
    state: { isClicked: true },
  };

  const priorityLevel = get(
    priorityOptions,
    `[${(level > 2 && 3) || priorityOptions.findIndex(e => e.value === level)}]`,
    priorityOptions[0],
  );
  const assigneeText =
    (!isEmpty(partner) && partner.name) ||
    (!isEmpty(user) && user.full_name) ||
    'Unassigned';
  const selectedAssignee = {
    partner_id: partner && partner.id,
    user_id: user && user.id,
    label: assigneeText,
    value:
      (partner && user && `${user.id} | ${partner.id}`) ||
      (partner && partner.id) ||
      (user && user.id) ||
      '',
  };
  let dueDateLabel = deadline ? 'due ' + dayjs(deadline).fromNow() : '';
  let isPastDue = false;
  if (deadline && dayjs(deadline).isSame(new Date(), 'day')) {
    dueDateLabel = 'Due Today';
    isPastDue = true;
  }
  if (deadline && dayjs(new Date()).isAfter(deadline)) {
    // dueDateLabel = 'due ' + dayjs(deadline).fromNow();
    isPastDue = true;
  }

  return (
    <div
      className={classnames('todosSection__list-row', {
        'todosSection__list-row--last': isLastRow,
      })}
      onClick={e => {
        if (isSignDocument) {
          const win = window.open(data.link, '_blank');
          win.focus();
        } else if (outside_code) {
          const win = window.open(`/outside-form/${outside_code}`, '_blank');
          win.focus();
        } else if (state !== 'Completed' && !isTeam) {
          push(
            handlePath(
              {
                pathname: `/workbench/-1/${projectId}/${account_transaction_id || -1}/${
                  account_transaction_id ? 'initialize' : 'new'
                }`,
                state: { isClicked: true },
              },
              companyId,
            ),
          );
        } else if (isTeam) {
          dispatch(setCompany(account.id, path, account.id === currentAccountId));
        }
      }}
    >
      <div
        className={`todosSection__list-checkbox ${selectedTodos[todoIndex] && 'checked'}`}
      >
        <div
          className="todosSection__list-checkbox-highlight"
          style={{
            backgroundColor:
              priorityLevel.value === 0 ? 'transparent' : priorityLevel.color,
          }}
        />
        {!isTeam && (
          <InputStyledCheckbox
            checked={selectedTodos[todoIndex]}
            onClick={e => {
              e.stopPropagation();
              let updatedSelectedTodos = [...selectedTodos];
              const prevValue = updatedSelectedTodos[todoIndex];
              updatedSelectedTodos[todoIndex] = !updatedSelectedTodos[todoIndex];
              setSelectedTodos(updatedSelectedTodos);
              setIsAllSelected(false);
              setIsIndeterminate(true);
              if (!prevValue) {
                setIsMultiEdit(true);
              }
            }}
            name={`todo-select-${id}`}
            value={selectedTodos[todoIndex]}
          />
        )}
      </div>
      <div className="todosSection__list-item first">
        <h4>{label || 'Complete this To-do'}</h4>
        <span>{assigneeText}</span>
      </div>
      <div className="todosSection__list-item">
        <h4>{type}</h4>
        <span className={priorityLevel.label}>{priorityLevel.label}</span>
      </div>
      {isTeam && (
        <div className="todosSection__list-item first">
          <h4>{account.name}</h4>
        </div>
      )}
      <div className="todosSection__list-item last">
        <h4>{dayjs(created_at).format('MM/DD/YYYY')}</h4>
        <span className={isPastDue ? priorityOptions[3].label : ''}>{dueDateLabel}</span>

        <div className={`todosSection__list-actions`}>
          {!isTeam && (
            <>
              <Button
                buttonType="icon"
                className="assignTodo__edit"
                isDisabled={isMultiEdit}
                onClick={e => {
                  e.stopPropagation();
                  setSelectedTodo({
                    account_id,
                    id,
                    initTodoVals: {
                      deadline: deadline ? dayjs(deadline).format('MM/DD/YYYY') : '',
                      label,
                      selectedAssignee,
                      selectedPriority: priorityOptions[base_level || 0],
                    },
                    project_template_id,
                  });
                  setIsEditTodoOpen(true);
                }}
                size="sm"
                tooltip="Edit Todo"
              >
                <FontAwesomeIcon icon={['fal', 'edit']} />
              </Button>
              <Button
                buttonType="icon"
                isDisabled={isMultiEdit}
                isWarning
                onClick={e => {
                  e.stopPropagation();
                  handleDeleteTodo(id, false, account_id);
                }}
                size="sm"
                tooltip="Delete Todo"
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              </Button>
            </>
          )}
          {isTeam && (
            <Button
              buttonType="icon"
              onClick={e => {
                e.stopPropagation();
                dispatch(setCompany(account.id, null, account.id === currentAccountId));
              }}
              size="sm"
              tooltip="Go to Workspace"
            >
              <FontAwesomeIcon icon={['fal', 'building-circle-arrow-right']} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

TodosSection.propTypes = {
  isInitOpen: PropTypes.bool,
  isTeam: PropTypes.bool,
  sectionLabel: PropTypes.string,
};

TodosSection.defaultProps = {
  isInitOpen: false,
  isTeam: false,
  sectionLabel: 'To-dos',
};

export default TodosSection;
