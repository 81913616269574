import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';

import { setDocumentReviewer } from '../../redux/modules/Transaction/operations';
import { isFetchingWithHooks as isTransactionFetchingWithHooks } from '../../redux/modules/Transaction/selectors';
import { validateEmail, validateString } from '../../utils/FeatureTypes';
import { Button, MyModal } from '../common';
import { TextField } from '../formik';

import './AddReviewerModal.scss';

// import { Link } from 'react-router-dom';

const customStyles = {
  overlay: {
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,.66)',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto',
    paddingTop: 20,
    WebkitOverflowScrolling: 'touch',
  },
  content: {
    position: 'static',
    maxWidth: '450px',
    minHeight: '450px',
    margin: 'auto',
    padding: 0,
    border: 'none',
    boxShadow: '3px 3px 3px 3px #33373859',
    display: 'flex',
    flexDirection: 'column',
  },
};

const AddReviewerModal = ({ isOpen, onClose, pandaDocId, transactionId }) => {
  const dispatch = useDispatch();
  const isFetchingTransaction = useSelector(isTransactionFetchingWithHooks);

  return (
    <MyModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div className="add-reviewer">
        <FontAwesomeIcon
          className="add-reviewer__exit"
          onClick={onClose}
          icon={['fal', 'times']}
        />
        <h2>Add Reviewer</h2>
        <h4 className="add-reviewer__h4">
          Allow others to view and edit the document through Panda Doc.
        </h4>
        <Formik
          initialValues={{
            newReviewer: { email: '', first_name: '', last_name: '' },
          }}
          enableReinitialize
          onSubmit={({ newReviewer }, actions) => {
            dispatch(setDocumentReviewer(pandaDocId, newReviewer, transactionId)).then(
              e => onClose(),
            );
          }}
        >
          {({
            handleBlur,
            handleChange,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            values: {
              newReviewer: { email, first_name, last_name },
            },
            ...formikProps
          }) => {
            return (
              <Form className="add-reviewer__form">
                <div className="add-reviewer__body">
                  <h4 className="add-reviewer__h4">New Reviewer Info:</h4>
                  <Field
                    component={TextField}
                    label="First Name"
                    name="newReviewer.first_name"
                    validate={validateString}
                  />
                  <Field
                    component={TextField}
                    label="Last Name"
                    name="newReviewer.last_name"
                    validate={validateString}
                  />
                  <Field
                    component={TextField}
                    label="Email"
                    name="newReviewer.email"
                    validate={validateEmail}
                  />
                </div>
                <div className="add-reviewer__buttons">
                  <Button buttonType="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isFetching={isFetchingTransaction}
                    isDisabled={isFetchingTransaction}
                  >
                    Add Reviewer
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </MyModal>
  );
};

export default AddReviewerModal;
