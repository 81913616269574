export default {
  LLC: {
    Alabama: 128,
    Alaska: 250,
    Arizona: 85,
    Arkansas: 45,
    California: 75,
    Colorado: 50,
    Connecticut: 120,
    Delaware: 90,
    'District of Columbia': 220,
    Florida: 125,
    Georgia: 100,
    Hawaii: 51,
    Idaho: 101,
    Illinois: 153.53,
    Indiana: 97.14,
    Iowa: 50,
    Kansas: 160,
    Kentucky: 40,
    Louisiana: 105,
    Maine: 185,
    Maryland: 156,
    Massachusetts: 520,
    Michigan: 50,
    Minnesota: 155,
    Mississippi: 53.14,
    Missouri: 51.25,
    Montana: 70,
    Nebraska: 108.15,
    Nevada: 425,
    'New Hampshire': 102,
    'New Jersey': 129,
    'New Mexico': 50,
    'New York': 205,
    'North Carolina': 127,
    'North Dakota': 135,
    Ohio: 99,
    Oklahoma: 104,
    Oregon: 100,
    Pennsylvania: 125,
    'Rhode Island': 156,
    'South Carolina': 132,
    'South Dakota': 150,
    Tennessee: 308.25,
    Texas: 301,
    Utah: 76,
    Vermont: 125,
    Virginia: 102.4,
    Washington: 200,
    'West Virginia': 125,
    Wisconsin: 130,
    Wyoming: 100,
  },
  'Foreign LLC': {
    Alabama: 150,
    Alaska: 350,
    Arizona: 150,
    Arkansas: 270,
    California: 75,
    Colorado: 100,
    Connecticut: 120,
    Delaware: 200,
    'District of Columbia': 220,
    Florida: 125,
    Georgia: 225,
    Hawaii: 51,
    Idaho: 101,
    Illinois: 150,
    Indiana: 105,
    Iowa: 100,
    Kansas: 165,
    Kentucky: 90,
    Louisiana: 150,
    Maine: 250,
    Maryland: 156,
    Massachusetts: 520,
    Michigan: 50,
    Minnesota: 205,
    Mississippi: 250,
    Missouri: 105,
    Montana: 70,
    Nebraska: 115,
    Nevada: 425,
    'New Hampshire': 100,
    'New Jersey': 125,
    'New Mexico': 100,
    'New York': 250,
    'North Carolina': 250,
    'North Dakota': 135,
    Ohio: 125,
    Oklahoma: 300,
    Oregon: 275,
    Pennsylvania: 250,
    'Rhode Island': 150,
    'South Carolina': 110,
    'South Dakota': 750,
    Tennessee: 300,
    Texas: 750,
    Utah: 76,
    Vermont: 125,
    Virginia: 102.4,
    Washington: 180,
    'West Virginia': 150,
    Wisconsin: 100,
    Wyoming: 100,
  },
  'Foreign Corp': {
    Alabama: 150,
    Alaska: 350,
    Arizona: 100,
    Arkansas: 270,
    California: 105,
    Colorado: 100,
    Connecticut: 385,
    Delaware: 245,
    'District of Columbia': 220,
    Florida: 70,
    Georgia: 225,
    Hawaii: 41,
    Idaho: 101,
    Illinois: 175,
    Indiana: 105,
    Iowa: 100,
    Kansas: 115,
    Kentucky: 90,
    Louisiana: 125,
    Maine: 250,
    Maryland: 156,
    Massachusetts: 390,
    Michigan: 60,
    Minnesota: 220,
    Mississippi: 100,
    Missouri: 155,
    Montana: 70,
    Nebraska: 140,
    Nevada: 725,
    'New Hampshire': 100,
    'New Jersey': 125,
    'New Mexico': 200,
    'New York': 230,
    'North Carolina': 250,
    'North Dakota': 145,
    Ohio: 125,
    Oklahoma: 300,
    Oregon: 275,
    Pennsylvania: 250,
    'Rhode Island': 310,
    'South Carolina': 157.5,
    'South Dakota': 750,
    Tennessee: 600,
    Texas: 750,
    Utah: 76,
    Vermont: 125,
    Virginia: 76.95,
    Washington: 180,
    'West Virginia': 100,
    Wisconsin: 100,
    Wyoming: 100,
  },
};
