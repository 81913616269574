import { combineReducers } from 'redux';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const companyReducer = createReducer({})({
  [Types['SET_COMPANIES']]: (state, { payload }) => ({
    ...state,
    byArr: [...payload],
    byIds: {
      ...state.byIds,
      ...payload.reduce((dict, e, ind) => {
        let account_group = e.account_group || dict[e.id]?.account_group;
        let account_order = dict[e.id]?.account_order || null;
        if (e.account_order > account_order) {
          account_order = e.account_order;
        }
        dict[e.id] = {
          ...e,
          account_group,
          account_order,
        };
        return dict;
      }, {}),
    },
  }),
});

const isCompanyTokenRefreshingReducer = createReducer(false)({
  [Types['SET_COMPANY_TOKEN_REFRESHING']]: (state, { isRefreshing }) => isRefreshing,
});

const companyDataTablesReducer = createReducer([])({
  [Types['SET_COMPANY_DATA_TABLES']]: (state, { payload }) => [...payload],
});

const sharedReportsReducer = createReducer([])({
  [Types['SET_SHARED_REPORTS']]: (state, { payload }) => [...payload],
});

const isFetching = fetchingReducer('company');

export default combineReducers({
  isFetching,
  apiErrors: apiErrorsReducer('company'),
  isCompanyTokenRefreshing: isCompanyTokenRefreshingReducer,
  companies: companyReducer,
  companyDataTables: companyDataTablesReducer,
  sharedReports: sharedReportsReducer,
});
