import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FA = ({ icon, ...props }) => {
  if (icon.indexOf(' ') >= 0) {
    icon = icon.split(' ');
  }
  return <FontAwesomeIcon icon={icon} {...props} />;
};

export default FA;

// Second Draft
// const FA = ({ fal, far, ...props }) => {
//   const type = (fal && 'fal') || far & 'far' || 'fas';
//   const name = Object.keys(props).filter(key => props[key] === true);
//   const icon = [type, name];

//   return <FontAwesomeIcon icon={icon} {...omit(props, name)} />;
// };

// First Draft
// const FA = ({...props}) =>
//     const icon = Object.keys(props).filter(key => props[key] === true);
//     return <FAIcon icon={icon} {...props}/>
//   }
// }
