import { combineReducers } from 'redux';

import { createReducer, fetchingReducer } from '../../utils';
import {
  GET_TEMPORARY_BOX_TOKEN_SUCCESS,
  BOX_TOKEN_FAILED,
  BOX_TOKEN_SUCCESS,
  SET_USER_DOCUMENT_BOX_TOKEN,
  SET_BOX_TOKEN,
  SET_TEMP_TOKEN_FETCH_INITIALIZED,
} from './types';

const isFetching = fetchingReducer('cabinet');

const boxTokenReducer = createReducer({})({
  [BOX_TOKEN_SUCCESS]: (state, { payload }) => payload.token,
  [SET_BOX_TOKEN]: (state, { payload }) => payload.token,
});

const userDocumentsBoxTokensReducer = createReducer({})({
  [SET_USER_DOCUMENT_BOX_TOKEN]: (state, { payload, documentId }) => ({
    ...state,
    [documentId]: payload.token,
  }),
});

const tempBoxTokenReducer = createReducer({
  token: {},
  isInitialized: false,
})({
  [GET_TEMPORARY_BOX_TOKEN_SUCCESS]: (state, { payload }) => ({
    ...state,
    token: payload.token,
  }),
  [SET_TEMP_TOKEN_FETCH_INITIALIZED]: (state, { isInitialized }) => ({
    ...state,
    isInitialized,
  }),
});

const errorsReducer = createReducer({})({
  [BOX_TOKEN_FAILED]: (state, { payload, error }) => {
    if (error) {
      if (payload.name === 'ApiError') {
        return {
          token: payload.statusText,
        };
      } else {
        return { token: 'There was a network error' };
      }
    }
    return null;
  },
});

// const meta = createReducer(initUser)({
//   [SET_USER]: (state, action) => payload.meta
// });

export default combineReducers({
  isFetching,
  box: boxTokenReducer,
  userDocumentsBoxTokens: userDocumentsBoxTokensReducer,
  tempBox: tempBoxTokenReducer,
  errors: errorsReducer,
});
