// Submit Form Actions
export const SUBMIT_FORM_REQUEST = 'savvi/transaction/SUBMIT_FORM_REQUEST';
export const SUBMIT_FORM_SUCCESS = 'savvi/transaction/SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILED = 'savvi/transaction/SUBMIT_FORM_FAILED';
export const CLEAR_ERRORS = 'savvi/transaction/CLEAR_ERRORS';
export const SET_ERRORS = 'savvi/transaction/SET_ERRORS';
export const SET_FORM_VALUES = 'savvi/transaction/SET_FORM_VALUES';
export const SET_TRANSACTION_PREVIEW = 'savvi/transaction/SET_TRANSACTION_PREVIEW';

// export const SET_VALUE = 'savvi/transaction/SET_VALUE';
// export const REMOVE_VALUE = 'savvi/transaction/REMOVE_VALUE';

export const SET_QUESTIONNAIRE = 'savvi/transaction/SET_QUESTIONNAIRE';

export const GET_TRANSACTION_QUESTIONNAIRE_REQUEST =
  'savvi/transaction/GET_TRANSACTION_QUESTIONNAIRE_REQUEST';
export const GET_TRANSACTION_QUESTIONNAIRE_SUCCESS =
  'savvi/transaction/GET_TRANSACTION_QUESTIONNAIRE_SUCCESS';
export const GET_TRANSACTION_QUESTIONNAIRE_FAILED =
  'savvi/transaction/GET_TRANSACTION_QUESTIONNAIRE_FAILED';
export const SET_TRANSACTION_QUESTIONNAIRE =
  'savvi/transaction/SET_TRANSACTION_QUESTIONNAIRE';

export const GET_OUTSIDE_FORM_REQUEST = 'savvi/transaction/GET_OUTSIDE_FORM_REQUEST';
export const GET_OUTSIDE_FORM_SUCCESS = 'savvi/transaction/GET_OUTSIDE_FORM_SUCCESS';
export const GET_OUTSIDE_FORM_FAILED = 'savvi/transaction/GET_OUTSIDE_FORM_FAILED';

export const CREATE_OUTSIDE_FORM_REQUEST =
  'savvi/transaction/CREATE_OUTSIDE_FORM_REQUEST';
export const CREATE_OUTSIDE_FORM_SUCCESS =
  'savvi/transaction/CREATE_OUTSIDE_FORM_SUCCESS';
export const CREATE_OUTSIDE_FORM_FAILED = 'savvi/transaction/CREATE_OUTSIDE_FORM_FAILED';
export const SET_OUTSIDE_FORM = 'savvi/transaction/SET_OUTSIDE_FORM';

export const GET_TRANSACTION_REQUEST = 'savvi/transaction/GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'savvi/transaction/GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_FAILED = 'savvi/transaction/GET_TRANSACTION_FAILED';

export const POST_TRANSACTION_REQUEST = 'savvi/transaction/POST_TRANSACTION_REQUEST';
export const POST_TRANSACTION_SUCCESS = 'savvi/transaction/POST_TRANSACTION_SUCCESS';
export const POST_TRANSACTION_FAILED = 'savvi/transaction/POST_TRANSACTION_FAILED';

export const GET_TRANSACTION_PREVIEW_REQUEST =
  'savvi/transaction/GET_TRANSACTION_PREVIEW_REQUEST';
export const GET_TRANSACTION_PREVIEW_SUCCESS =
  'savvi/transaction/GET_TRANSACTION_PREVIEW_SUCCESS';
export const GET_TRANSACTION_PREVIEW_FAILED =
  'savvi/transaction/GET_TRANSACTION_PREVIEW_FAILED';

export const GET_TRANSACTION_BY_TEMPLATE_ID_REQUEST =
  'savvi/transaction/GET_TRANSACTION_BY_TEMPLATE_ID_REQUEST';
export const GET_TRANSACTION_BY_TEMPLATE_ID_SUCCESS =
  'savvi/transaction/GET_TRANSACTION_BY_TEMPLATE_ID_SUCCESS';
export const GET_TRANSACTION_BY_TEMPLATE_ID_FAILED =
  'savvi/transaction/GET_TRANSACTION_BY_TEMPLATE_ID_FAILED';

export const UPDATE_TRANSACTION_REQUEST = 'savvi/transaction/UPDATE_TRANSACTION_REQUEST';
export const UPDATE_TRANSACTION_SUCCESS = 'savvi/transaction/UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILED = 'savvi/transaction/UPDATE_TRANSACTION_FAILED';
export const SET_TRANSACTION = 'savvi/transaction/SET_TRANSACTION';

export const GET_MY_TEMPLATES_REQUEST = 'savvi/transaction/GET_MY_TEMPLATES_REQUEST';
export const GET_MY_TEMPLATES_SUCCESS = 'savvi/transaction/GET_MY_TEMPLATES_SUCCESS';
export const GET_MY_TEMPLATES_FAILED = 'savvi/transaction/GET_MY_TEMPLATES_FAILED';
export const SET_MY_TEMPLATES = 'savvi/transaction/SET_MY_TEMPLATES';

export const GET_CUSTOM_DOCUMENT_REQUEST =
  'savvi/transaction/GET_CUSTOM_DOCUMENT_REQUEST';
export const GET_CUSTOM_DOCUMENT_SUCCESS =
  'savvi/transaction/GET_CUSTOM_DOCUMENT_SUCCESS';
export const GET_CUSTOM_DOCUMENT_FAILED = 'savvi/transaction/GET_CUSTOM_DOCUMENT_FAILED';
export const UPDATE_CUSTOM_DOCUMENT_REQUEST =
  'savvi/transaction/UPDATE_CUSTOM_DOCUMENT_REQUEST';
export const UPDATE_CUSTOM_DOCUMENT_SUCCESS =
  'savvi/transaction/UPDATE_CUSTOM_DOCUMENT_SUCCESS';
export const UPDATE_CUSTOM_DOCUMENT_FAILED =
  'savvi/transaction/UPDATE_CUSTOM_DOCUMENT_FAILED';
export const CREATE_CUSTOM_DOCUMENT_REQUEST =
  'savvi/transaction/CREATE_CUSTOM_DOCUMENT_REQUEST';
export const CREATE_CUSTOM_DOCUMENT_SUCCESS =
  'savvi/transaction/CREATE_CUSTOM_DOCUMENT_SUCCESS';
export const CREATE_CUSTOM_DOCUMENT_FAILED =
  'savvi/transaction/CREATE_CUSTOM_DOCUMENT_FAILED';
export const ADD_CUSTOM_DOCUMENT = 'savvi/transaction/ADD_CUSTOM_DOCUMENT';
export const SET_CUSTOM_DOCUMENT = 'savvi/transaction/SET_CUSTOM_DOCUMENT';

export const GET_ALL_TRANSACTIONS_REQUEST =
  'savvi/transaction/GET_ALL_TRANSACTIONS_REQUEST';
export const GET_ALL_TRANSACTIONS_SUCCESS =
  'savvi/transaction/GET_ALL_TRANSACTIONS_SUCCESS';
export const GET_ALL_TRANSACTIONS_FAILED =
  'savvi/transaction/GET_ALL_TRANSACTIONS_FAILED';
export const SET_MY_TRANSACTIONS = 'savvi/transaction/SET_MY_TRANSACTIONS';

export const SET_DOCUMENT_REVIEWER_REQUEST =
  'savvi/transaction/SET_DOCUMENT_REVIEWER_REQUEST';
export const SET_DOCUMENT_REVIEWER_SUCCESS =
  'savvi/transaction/SET_DOCUMENT_REVIEWER_SUCCESS';
export const SET_DOCUMENT_REVIEWER_FAILED =
  'savvi/transaction/SET_DOCUMENT_REVIEWER_FAILED';

export const GET_TRANSACTIONS_BY_MODULE_REQUEST =
  'savvi/transaction/GET_TRANSACTIONS_BY_MODULE_REQUEST';
export const GET_TRANSACTIONS_BY_MODULE_SUCCESS =
  'savvi/transaction/GET_TRANSACTIONS_BY_MODULE_SUCCESS';
export const GET_TRANSACTIONS_BY_MODULE_FAILED =
  'savvi/transaction/GET_TRANSACTIONS_BY_MODULE_FAILED';
export const SET_TRANSACTIONS_BY_MODULE = 'savvi/transaction/SET_TRANSACTIONS_BY_MODULE';

export const POST_REDLINING_VERSION_REQUEST =
  'savvi/transaction/POST_REDLINING_VERSION_REQUEST';
export const POST_REDLINING_VERSION_SUCCESS =
  'savvi/transaction/POST_REDLINING_VERSION_SUCCESS';
export const POST_REDLINING_VERSION_FAILED =
  'savvi/transaction/POST_REDLINING_VERSION_FAILED';

export const APPROVE_DOCUMENT_SUGGESTIONS_REQUEST =
  'savvi/transaction/APPROVE_DOCUMENT_SUGGESTIONS_REQUEST';
export const APPROVE_DOCUMENT_SUGGESTIONS_SUCCESS =
  'savvi/transaction/APPROVE_DOCUMENT_SUGGESTIONS_SUCCESS';
export const APPROVE_DOCUMENT_SUGGESTIONS_FAILED =
  'savvi/transaction/APPROVE_DOCUMENT_SUGGESTIONS_FAILED';

export const UPDATE_DOCUMENT_SUGGESTIONS_REQUEST =
  'savvi/transaction/UPDATE_DOCUMENT_SUGGESTIONS_REQUEST';
export const UPDATE_DOCUMENT_SUGGESTIONS_SUCCESS =
  'savvi/transaction/UPDATE_DOCUMENT_SUGGESTIONS_SUCCESS';
export const UPDATE_DOCUMENT_SUGGESTIONS_FAILED =
  'savvi/transaction/UPDATE_DOCUMENT_SUGGESTIONS_FAILED';

export const GET_DOCUMENTS_REQUEST = 'savvi/transaction/GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'savvi/transaction/GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILED = 'savvi/transaction/GET_DOCUMENTS_FAILED';
export const SET_DOCUMENTS = 'savvi/transaction/SET_DOCUMENTS';
export const SET_DOCUMENT_ERROR = 'savvi/transaction/SET_DOCUMENT_ERROR';
export const CLEAR_DOCUMENT_ERROR = 'savvi/transaction/CLEAR_DOCUMENT_ERROR';

export const DELETE_DOCUMENT_UPLOAD_REQUEST =
  'savvi/transaction/DELETE_DOCUMENT_UPLOAD_REQUEST';
export const DELETE_DOCUMENT_UPLOAD_SUCCESS =
  'savvi/transaction/DELETE_DOCUMENT_UPLOAD_SUCCESS';
export const DELETE_DOCUMENT_UPLOAD_FAILED =
  'savvi/transaction/DELETE_DOCUMENT_UPLOAD_FAILED';

export const GET_TRANSACTION_DOCUMENTS_REQUEST =
  'savvi/transaction/GET_TRANSACTION_DOCUMENTS_REQUEST';
export const GET_TRANSACTION_DOCUMENTS_SUCCESS =
  'savvi/transaction/GET_TRANSACTION_DOCUMENTS_SUCCESS';
export const GET_TRANSACTION_DOCUMENTS_FAILED =
  'savvi/transaction/GET_TRANSACTION_DOCUMENTS_FAILED';
export const SET_DOCUMENTS_BY_TRANSACTION =
  'savvi/transaction/SET_DOCUMENTS_BY_TRANSACTION';

export const GET_DOCUMENT_RECIPIENT_LINK_REQUEST =
  'savvi/transaction/GET_DOCUMENT_RECIPIENT_LINK_REQUEST';
export const GET_DOCUMENT_RECIPIENT_LINK_SUCCESS =
  'savvi/transaction/GET_DOCUMENT_RECIPIENT_LINK_SUCCESS';
export const GET_DOCUMENT_RECIPIENT_LINK_FAILED =
  'savvi/transaction/GET_DOCUMENT_RECIPIENT_LINK_FAILED';
export const SET_DOCUMENT_RECIPIENTS_LINKS =
  'savvi/transaction/SET_DOCUMENT_RECIPIENTS_LINKS';

export const RESEND_LINK_EMAIL_REQUEST = 'savvi/formation/RESEND_LINK_EMAIL_REQUEST';
export const RESEND_LINK_EMAIL_FAILED = 'savvi/formation/RESEND_LINK_EMAIL_FAILED';
export const RESEND_LINK_EMAIL_SUCCESS = 'savvi/formation/RESEND_LINK_EMAIL_SUCCESS';

export const GET_RESOURCE_REQUEST = 'savvi/transaction/GET_RESOURCE_REQUEST';
export const GET_RESOURCE_SUCCESS = 'savvi/transaction/GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILED = 'savvi/transaction/GET_RESOURCE_FAILED';

export const CREATE_FEATURE_REQUEST = 'savvi/transaction/CREATE_FEATURE_REQUEST';
export const CREATE_FEATURE_SUCCESS = 'savvi/transaction/CREATE_FEATURE_SUCCESS';
export const CREATE_FEATURE_FAILED = 'savvi/transaction/CREATE_FEATURE_FAILED';

export const PATCH_FEATURE_REQUEST = 'savvi/transaction/PATCH_FEATURE_REQUEST';
export const PATCH_FEATURE_SUCCESS = 'savvi/transaction/PATCH_FEATURE_SUCCESS';
export const PATCH_FEATURE_FAILED = 'savvi/transaction/PATCH_FEATURE_FAILED';

export const SET_RESOURCE_IDS_REQUEST = 'savvi/transaction/SET_RESOURCE_IDS_REQUEST';
export const SET_RESOURCE_IDS_SUCCESS = 'savvi/transaction/SET_RESOURCE_IDS_SUCCESS';
export const SET_RESOURCE_IDS_FAILED = 'savvi/transaction/SET_RESOURCE_IDS_FAILED';

export const CREATE_RESOURCE_NAME_REQUEST =
  'savvi/transaction/CREATE_RESOURCE_NAME_REQUEST';
export const CREATE_RESOURCE_NAME_SUCCESS =
  'savvi/transaction/CREATE_RESOURCE_NAME_SUCCESS';
export const CREATE_RESOURCE_NAME_FAILED =
  'savvi/transaction/CREATE_RESOURCE_NAME_FAILED';

export const UPDATE_RESOURCE_NAME_REQUEST =
  'savvi/transaction/UPDATE_RESOURCE_NAME_REQUEST';
export const UPDATE_RESOURCE_NAME_SUCCESS =
  'savvi/transaction/UPDATE_RESOURCE_NAME_SUCCESS';
export const UPDATE_RESOURCE_NAME_FAILED =
  'savvi/transaction/UPDATE_RESOURCE_NAME_FAILED';

export const FINALIZE_TRANSACTION_REQUEST =
  'savvi/transaction/FINALIZE_TRANSACTION_REQUEST';
export const FINALIZE_TRANSACTION_SUCCESS =
  'savvi/transaction/FINALIZE_TRANSACTION_SUCCESS';
export const FINALIZE_TRANSACTION_FAILED =
  'savvi/transaction/FINALIZE_TRANSACTION_FAILED';

export const CREATE_RESOURCE_FEATURE = 'savvi/transaction/CREATE_RESOURCE_FEATURE';

export const UPDATE_RESOURCE = 'savvi/transaction/UPDATE_RESOURCE';

export const POST_BATCH_TRANSACTIONS_REQUEST =
  'savvi/transaction/POST_BATCH_TRANSACTIONS_REQUEST';
export const POST_BATCH_TRANSACTIONS_SUCCESS =
  'savvi/transaction/POST_BATCH_TRANSACTIONS_SUCCESS';
export const POST_BATCH_TRANSACTIONS_FAILED =
  'savvi/transaction/POST_BATCH_TRANSACTIONS_FAILED';
