import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, DropdownAction } from '../common';

import './SortDropdown.scss';

const SortDropdown = ({
  isDisabled,
  onSelect,
  sortOption,
  isSortReversed,
  sortOptions,
}) => {
  const { slug, label, iconType = 'wide-short' } = sortOption;
  const iconRoot = isSortReversed ? 'arrow-up' : 'arrow-down';
  const sortIcon = (slug === 'none' && 'sort') || `${iconRoot}-${iconType}`;
  return (
    <div className="sort-dropdown">
      <DropdownAction
        Toggle={toggleProps => (
          <Button isDisabled={isDisabled} buttonType="link" size="sm" {...toggleProps}>
            Sort by:{'  '}
            <span>{label}</span>
            <FontAwesomeIcon icon={['fal', sortIcon]} />
          </Button>
        )}
      >
        {Object.values(sortOptions)
          .sort((a, b) => (a.slug === slug ? 1 : 0))
          .map(option => {
            const optionIconRoot =
              slug === option.slug && !isSortReversed ? 'arrow-up' : 'arrow-down';
            const optionSortIcon =
              (option.slug === 'none' && 'sort') ||
              `${optionIconRoot}-${option.iconType}`;
            const optionClass = `sort-dropdown__option ${
              option.slug === slug ? 'sort-dropdown__option--active' : ''
            }`;

            return (
              <Button
                key={`sort-option-${option.slug}`}
                buttonType="secondary"
                className={optionClass}
                isDisabled={option.slug === 'none' && slug === 'none'}
                onClick={e => onSelect(option)}
              >
                <FontAwesomeIcon icon={['fal', optionSortIcon]} />
                {option.label}
              </Button>
            );
          })}
      </DropdownAction>
    </div>
  );
};

SortDropdown.propTypes = {
  onSelect: PropTypes.func,
  sortSlug: PropTypes.string,
  sortIsDesc: PropTypes.bool,
  sortOptions: PropTypes.object,
};

export default SortDropdown;
