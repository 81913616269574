import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import startCase from 'lodash-es/startCase';

import PageLoader from '../../components/PageLoader';
import { SavviNavLinkBar } from '../../components/SavviNavLink';
import TodosSection from '../../components/TodosSection';
import DocumentsSection from '../../components/DocumentsSection';
import ModuleDict from '../../utils/ModuleDict';
import { Button, DropdownAction } from '../../components/common';
import TasksSection from '../../components/TasksSection';
import { hashContains, noHash } from '../../utils/generateMultiHash';

import './Home.scss';
import handlePath from '../../utils/handlePath';
import mercuryBankStatusDict from '../../utils/MercuryBankStatusDict';

const Home = ({
  companyId,
  hash,
  homeNavLinkItems,
  initialized,
  isAllEmpty,
  isMercuryBankShowing,
  isDataLoaded,
  moduleId,
  mercuryBank: { status, referral_link },
  handleCopyBankLink,
  myDocuments,
  filterInput,
  myTasks,
  myTodos,
  setFilterInput,
}) => (
  <div className="dashboard__content">
    <div className="dashboard__inner">
      <label className="dashboard__search">
        <FontAwesomeIcon className="dashboard__searchIcon" icon={['fal', 'search']} />
        <input
          autoComplete="off"
          placeholder="Search Dashboard"
          value={filterInput}
          name="workflowsFilter"
          onChange={e => setFilterInput(e.target.value)}
        />
        {(filterInput || '').length > 0 && (
          <FontAwesomeIcon
            className="dashboard__searchClear"
            icon={['fal', 'times']}
            onClick={() => setFilterInput('')}
          />
        )}
      </label>
      <SavviNavLinkBar
        navLinkItems={homeNavLinkItems}
        parentPathname={ModuleDict[moduleId].path}
        passedState={{ initialized, filterInput }}
      />
      {!isDataLoaded && <PageLoader />}
      {isDataLoaded && (
        <>
          {!!isMercuryBankShowing && (
            <div className="mercury-bank__wrapper">
              <p>
                Mercury Bank Status:{' '}
                <span
                  className={`mercury-bank__status ${
                    mercuryBankStatusDict[status] || ''
                  }`}
                >
                  {startCase(status)}
                </span>
              </p>
              {status !== 'pending' && status !== 'application_submitted' && (
                <>
                  <p>
                    <FontAwesomeIcon icon="exclamation-circle" />
                    Click below to finish registering to Mercury & connecting your bank.
                  </p>
                  <DropdownAction
                    className="mercury-bank__dropdown"
                    isFlat
                    left
                    Toggle={toggleProps => (
                      <Button
                        className="mercury-bank__link"
                        buttonType="primary"
                        size="xs"
                        // rel="noopener noreferrer"
                        {...toggleProps}
                      >
                        Finish Connecting Bank{' '}
                      </Button>
                    )}
                  >
                    <div className="mercury-bank__dropdownHeader">
                      <h4>Options</h4>
                      <div className="mercury-bank__divider" />
                    </div>
                    <Button
                      buttonType="secondary"
                      size="sm"
                      href={referral_link}
                      target="_blank"
                      onClick={e => e.stopPropagation()}
                      rel="noopener noreferrer"
                    >
                      Go to Mercury Registration{' '}
                      <FontAwesomeIcon icon={['fal', 'external-link']} />
                    </Button>
                    <Button buttonType="secondary" size="sm" onClick={handleCopyBankLink}>
                      Copy Registration Link <FontAwesomeIcon icon={['fal', 'copy']} />
                    </Button>
                  </DropdownAction>
                </>
              )}
            </div>
          )}
          {isAllEmpty && (
            <h3 className="dashboard__empty">
              No {!!isMercuryBankShowing && 'other '}action items found. Begin a new
              <Button
                buttonType="link"
                to={handlePath(ModuleDict['workflows'].path, companyId)}
              >
                Workflow
              </Button>
              or view your
              <Button
                buttonType="link"
                to={handlePath(ModuleDict['tasks'].path, companyId)}
              >
                Tasks
              </Button>
            </h3>
          )}
          {(noHash(hash) || hashContains(hash, '#actionable-documents')) &&
            myDocuments.length > 0 && (
              <DocumentsSection
                isAdmin
                isWarning
                moduleId={moduleId}
                sectionLabel="Documents Requiring Action"
                documents={myDocuments}
                isPdfHiding={myDocuments.length > 6}
              />
            )}
          {(noHash(hash) || hashContains(hash, '#to-dos')) && myTodos.length > 0 && (
            <TodosSection todos={myTodos} />
          )}
          {(noHash(hash) || hashContains(hash, '#open-tasks')) && myTasks.length > 0 && (
            <TasksSection
              isHome
              isSectionOpenInit={false}
              isHeightUnset
              moduleId={moduleId}
              sectionLabel="Open Tasks"
              transactions={myTasks}
            />
          )}
        </>
      )}
    </div>
  </div>
);

Home.propTypes = {
  actionableDocs: PropTypes.array,
  isDataLoaded: PropTypes.bool,
  isLoadingInitTimeout: PropTypes.bool,
  location: PropTypes.object,
  moduleId: PropTypes.string,
  homeNavLinkItems: PropTypes.array,
  todos: PropTypes.array,
};

export default Home;
