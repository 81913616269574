export const RESET_ERRORS = 'savvi/project/RESET_ERRORS';
export const SET_ERRORS = 'savvi/project/SET_ERRORS';

export const GET_PROJECTS_REQUEST = 'savvi/project/GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'savvi/project/GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILED = 'savvi/project/GET_PROJECTS_FAILED';

export const GET_PROJECT_REQUEST = 'savvi/project/GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'savvi/project/GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILED = 'savvi/project/GET_PROJECT_FAILED';

export const POST_PROJECT_REQUEST = 'savvi/project/POST_PROJECT_REQUEST';
export const POST_PROJECT_SUCCESS = 'savvi/project/POST_PROJECT_SUCCESS';
export const POST_PROJECT_FAILED = 'savvi/project/POST_PROJECT_FAILED';

export const SET_PROJECTS = 'savvi/project/SET_PROJECTS';
export const SET_PROJECT = 'savvi/project/SET_PROJECT';

export const GET_ACCOUNT_PROJECTS_REQUEST = 'savvi/project/GET_ACCOUNT_PROJECTS_REQUEST';
export const GET_ACCOUNT_PROJECTS_SUCCESS = 'savvi/project/GET_ACCOUNT_PROJECTS_SUCCESS';
export const GET_ACCOUNT_PROJECTS_FAILED = 'savvi/project/GET_ACCOUNT_PROJECTS_FAILED';

export const DELETE_ACCOUNT_PROJECT_REQUEST =
  'savvi/project/DELETE_ACCOUNT_PROJECT_REQUEST';
export const DELETE_ACCOUNT_PROJECT_SUCCESS =
  'savvi/project/DELETE_ACCOUNT_PROJECT_SUCCESS';
export const DELETE_ACCOUNT_PROJECT_FAILED =
  'savvi/project/DELETE_ACCOUNT_PROJECT_FAILED';

export const GET_ACCOUNT_PROJECT_REQUEST = 'savvi/project/GET_ACCOUNT_PROJECT_REQUEST';
export const GET_ACCOUNT_PROJECT_SUCCESS = 'savvi/project/GET_ACCOUNT_PROJECT_SUCCESS';
export const GET_ACCOUNT_PROJECT_FAILED = 'savvi/project/GET_ACCOUNT_PROJECT_FAILED';

export const UPDATE_PROJECT_REQUEST = 'savvi/project/UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'savvi/project/UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILED = 'savvi/project/UPDATE_PROJECT_FAILED';

export const SET_ACCOUNT_PROJECT = 'savvi/project/SET_ACCOUNT_PROJECT';
export const SET_ACCOUNT_PROJECTS = 'savvi/project/SET_ACCOUNT_PROJECTS';

export const GET_RESOURCE_REQUEST = 'savvi/project/GET_RESOURCE_REQUEST';
export const GET_RESOURCE_SUCCESS = 'savvi/project/GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILED = 'savvi/project/GET_RESOURCE_FAILED';

export const PERFORM_TASK_ACTION_REQUEST = 'savvi/project/PERFORM_TASK_ACTION_REQUEST';
export const PERFORM_TASK_ACTION_SUCCESS = 'savvi/project/PERFORM_TASK_ACTION_SUCCESS';
export const PERFORM_TASK_ACTION_FAILED = 'savvi/project/PERFORM_TASK_ACTION_FAILED';

export const UPDATE_TASK_VARIANT_REQUEST = 'savvi/project/UPDATE_TASK_VARIANT_REQUEST';
export const UPDATE_TASK_VARIANT_SUCCESS = 'savvi/project/UPDATE_TASK_VARIANT_SUCCESS';
export const UPDATE_TASK_VARIANT_FAILED = 'savvi/project/UPDATE_TASK_VARIANT_FAILED';

export const DELETE_PROJECT_COUNSEL_REQUEST =
  'savvi/project/DELETE_PROJECT_COUNSEL_REQUEST';
export const DELETE_PROJECT_COUNSEL_SUCCESS =
  'savvi/project/DELETE_PROJECT_COUNSEL_SUCCESS';
export const DELETE_PROJECT_COUNSEL_FAILED =
  'savvi/project/DELETE_PROJECT_COUNSEL_FAILED';

export const ADD_PROJECT_COUNSEL_REQUEST = 'savvi/project/ADD_PROJECT_COUNSEL_REQUEST';
export const ADD_PROJECT_COUNSEL_SUCCESS = 'savvi/project/ADD_PROJECT_COUNSEL_SUCCESS';
export const ADD_PROJECT_COUNSEL_FAILED = 'savvi/project/ADD_PROJECT_COUNSEL_FAILED';
