import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './BoxIsDownBanner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const SiteMaintenanceBanner = () => {
  const [isShowing, setIsShowing] = useState(true);

  return (
    <div className={`boxIsDown${!isShowing ? ' boxIsDown--hide' : ''}`}>
      <FontAwesomeIcon className="boxIsDown__icon" icon="exclamation-circle" />
      <h4>
        Box is currently down, until they are back up documents be viewable & won't
        generate correctly so please do not finalize any tasks. We are sorry for the
        inconvenience, please try again in 30 minutes!
      </h4>
      <Button
        buttonType="icon"
        size="xs"
        className="boxIsDown__hide"
        onClick={() => setIsShowing(false)}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    </div>
  );
};

SiteMaintenanceBanner.propTypes = {
  timeStartUtc: PropTypes.string,
  timeEndUtc: PropTypes.string,
};

export default SiteMaintenanceBanner;
