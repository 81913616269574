import * as Types from './types';

export function clearErrors() {
  return {
    type: Types.CLEAR_ERRORS,
  };
}

export function setErrors(errors) {
  return {
    type: Types.SET_ERRORS,
    payload: { errors },
  };
}

export function setTransactionsByModule(payload, moduleId) {
  return {
    type: Types['SET_TRANSACTIONS_BY_MODULE'],
    payload,
    moduleId,
  };
}

export function clearDocumentError(error) {
  return {
    type: Types['CLEAR_DOCUMENT_ERROR'],
    error,
  };
}

export function setDocumentError(error) {
  return {
    type: Types['SET_DOCUMENT_ERROR'],
    error,
  };
}

export function setDocumentRecipientsLinks(links, documentId) {
  return {
    type: Types['SET_DOCUMENT_RECIPIENTS_LINKS'],
    links,
    documentId,
  };
}

export function setDocuments(payload, taskId) {
  return {
    type: Types['SET_DOCUMENTS'],
    payload,
    taskId,
  };
}

export function setDocumentsByTransaction(payload, transactionId) {
  return {
    type: Types['SET_DOCUMENTS_BY_TRANSACTION'],
    payload,
    transactionId,
  };
}

export function setMyTransactions(payload) {
  return {
    type: Types['SET_MY_TRANSACTIONS'],
    payload,
  };
}

export function setTransaction(payload) {
  return {
    type: Types['SET_TRANSACTION'],
    payload,
  };
}

export function setTransactionPreview(transactionId, payload) {
  return {
    type: Types['SET_TRANSACTION_PREVIEW'],
    payload,
    transactionId,
  };
}

export function setTransactionQuestionnaire(payload, id) {
  return {
    type: Types['SET_TRANSACTION_QUESTIONNAIRE'],
    payload: { ...payload, id },
  };
}

export function setMyTemplates(payload) {
  return {
    type: Types['SET_MY_TEMPLATES'],
    payload,
  };
}

export function addCustomDocument(payload, transactionId) {
  return {
    type: Types['ADD_CUSTOM_DOCUMENT'],
    payload: { payload, transactionId },
  };
}

export const setCustomDocument = (payload, customDocIndex, transactionId) => ({
  type: Types['SET_CUSTOM_DOCUMENT'],
  payload,
  customDocIndex,
  transactionId,
});

export function createResourceFeature(payload) {
  return {
    type: Types['CREATE_RESOURCE_FEATURE'],
    payload,
  };
}

export function updateResource(payload) {
  return {
    type: Types['UPDATE_RESOURCE'],
    payload,
  };
}
