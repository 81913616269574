const mercuryBankStatusDict = {
  approved: 'low',
  pending: 'med',
  application_submitted: 'med',
  referred: 'med',
  rejected: 'high',
  information_requested: 'high',
};

export default mercuryBankStatusDict;
