export default (myModule = '') =>
  (state = false, { type }) => {
    if (!isModule(myModule, type)) {
      return state;
    }

    if (isRequest(type)) {
      return true;
    } else if (isSuccess(type) || isFailed(type)) {
      return false;
    } else {
      return state;
    }
  };

const isModule = (myModule, type) => !!myModule && type.indexOf(myModule) !== -1;
const isRequest = type => type.endsWith('REQUEST');
const isSuccess = type => type.endsWith('SUCCESS');
const isFailed = type => type.endsWith('FAILED');
