import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProjectHeader.scss';
import { Button } from '../common';

const ProjectHeader = ({
  icon,
  isEditingAllowed,
  isHighlighted,
  isLearnMoreShowing,
  handleUpdateProjectName,
  learnMoreUrl,
  projectLabel,
}) => {
  const inputEl = useRef(null);
  const [label, updateLabel] = useState(projectLabel || '');
  const [isEditable, setEditable] = useState(false);
  useEffect(() => {
    if (isEditable === true) {
      inputEl.current.focus();
    }
  }, [isEditable]);

  useEffect(() => {
    updateLabel(projectLabel || '');
  }, [projectLabel]);

  return (
    <div className={`project-header ${isHighlighted && 'highlight'}`}>
      <h1 className={`project-header__title${label.length > 16 ? ' long' : ''}`}>
        {isEditingAllowed && (
          <Button
            buttonType="icon"
            size="sm"
            className="project-header__edit-icon"
            onClick={() => setEditable(!isEditable)}
          >
            <FontAwesomeIcon icon={['fal', 'edit']} className="text-muted" />
          </Button>
        )}
        {isEditable && (
          <textarea
            ref={inputEl}
            onBlur={e => {
              handleUpdateProjectName(e.target.value).then(
                e => setEditable(false),
                error => {
                  updateLabel(projectLabel);
                  setEditable(false);
                },
              );
            }}
            onChange={e => {
              updateLabel(e.target.value);
            }}
            value={label}
          />
        )}
        {!isEditable && label}
      </h1>
      <div className="project-header__help">
        {isLearnMoreShowing && projectLabel && (
          <a href={learnMoreUrl} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={['fal', 'info-circle']} />
          </a>
        )}
      </div>
    </div>
  );
};

ProjectHeader.defaultProps = {
  isAuthenticated: false,
  icon: 'question',
};

export default ProjectHeader;
