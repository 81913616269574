import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DropdownMenu } from 'react-dd-menu';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import AlertModal from '../../components/AlertModal';
import FinalizeReviewModal from '../../components/FinalizeReviewModal';
import ProjectCounselModal from '../../components/ProjectCounselModal';
import ReviewersModal from '../../components/ReviewersModal';
import TaskCard from '../../components/TaskCard';
import WorkbenchActions from '../../components/WorkbenchActions';
import {
  getPaymentMethodsV2,
  getAccountMercuryBanks,
} from '../../redux/modules/Billing/operations';
import {
  AccountMercuryBanks,
  IsPaymentMethodAvailableV2,
  StripeDomainSlug,
} from '../../redux/modules/Billing/selectors';
import { AccountInfo, AccountSubs } from '../../redux/modules/Formation/selectors';
import { performTaskAction } from '../../redux/modules/Project/operations';
import {
  AccountProject,
  isFetchingWithHooks as isFetchingWithHooksProject,
  PartnerCoverage,
  ProjectCounsel,
} from '../../redux/modules/Project/selectors';
import {
  finalizeTransaction,
  getMyTemplates,
  getTransaction,
  getTransactionDocuments,
  getTransactionQuestionnaire,
  updateTransaction,
} from '../../redux/modules/Transaction/operations';
import {
  CustomDocumentsFormikValues,
  IsDocumentTransaction,
  isFetchingWithHooks,
  IsWorkflowCounselShowing,
  TransactionWithDocuments,
  WorkflowCounselForQuestionnaire,
  getFeesFromTransaction,
  getReviewValues,
  getFaqsFromTransaction,
  getConnectedOutsideForms,
} from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import ModuleDict from '../../utils/ModuleDict';
import InfoSidebar from '../InfoSidebar';
import WorkbenchForm from '../WorkbenchForm';

import './WorkbenchTaskOverview.scss';
import { get } from 'lodash-es';

import { Button, Dropdown, DropdownAction } from '../../components/common';
import handlePath from '../../utils/handlePath';
import useInterval from '../../utils/useInterval';
import usePageVisibility from '../../utils/usePageVisibility';
import usePageFocused from '../../utils/usePageFocused';
import { BrandMeta } from '../../redux/modules/UI/selectors';
import isObject from '../../utils/isObject';
import {
  addDocumentRecipient,
  getAllDocuments,
} from '../../redux/modules/Document/operations';
import mercuryBankStatusDict from '../../utils/MercuryBankStatusDict';

dayjs.extend(localizedFormat);

const partnerStripeDict = {
  paperos: process.env.REACT_APP_STRIPE_KEY,
  develop: process.env.REACT_APP_TEST_STRIPE_KEY,
  rams: process.env.REACT_APP_RAMS_TEST_STRIPE_KEY,
};

const WorkbenchTaskOverview = ({
  documentCredits,
  formikValues,
  isDocumentCreditsNeeded,
  isSubscriptionNeeded,
  isPortalView,
  params,
}) => {
  const { moduleId, projectId, transactionId } = params;
  const dispatch = useDispatch();
  const { push, replace, location } = useHistory();
  const { state: { isReopening } = {} } = useLocation();

  const accountInfo = useSelector(AccountInfo);
  const brandMeta = useSelector(BrandMeta);
  const project = useSelector(state => AccountProject(state, projectId));
  const stripeDomainSlug = useSelector(StripeDomainSlug);
  const projectStripeDomain =
    (project?.attached_partners || [])[0]?.stripe_domain || stripeDomainSlug;

  const reviewInfo = useSelector(state =>
    getReviewValues(state.Transaction, transactionId),
  );
  const connectedOutsideForms = useSelector(state =>
    getConnectedOutsideForms(state.Transaction, transactionId),
  );
  const customDocsReviewInfo = useSelector(state =>
    CustomDocumentsFormikValues(state.Transaction, transactionId),
  );
  const isWorkflowCounselShowing = useSelector(state =>
    IsWorkflowCounselShowing(state, transactionId),
  );
  const partnerCoverage = useSelector(state => PartnerCoverage(state, projectId));
  const { creditsCoveredBy, feesCoveredBy } = partnerCoverage;
  const projectCounsel = useSelector(state => ProjectCounsel(state, projectId));
  const projectCounselQuestions = useSelector(state =>
    WorkflowCounselForQuestionnaire(state, transactionId, projectId),
  );
  const transaction = useSelector(state =>
    TransactionWithDocuments(state.Transaction, transactionId),
  );
  const isPaymentMethodAvailable = useSelector(state =>
    IsPaymentMethodAvailableV2(state, projectStripeDomain),
  );

  const mercuryBanks = useSelector(AccountMercuryBanks);
  const mercuryBank = mercuryBanks[0] || {};
  const { referral_link, status } = mercuryBank;
  const {
    allow_reopen,
    disabled,
    group,
    is_started,
    isRedlining,
    label,
    open,
    order_block_info,
    reviewers = [],
    room_use,
    state,
    submission_date,
    tasks = [],
    template: { delete_level, id: templateId, is_assessment, redline_available } = {},
    updated_at,
    validated_questionnaire,
  } = transaction;

  const isMercuryBankProject = templateId === 258;
  const isMercuryBankShowing = mercuryBanks.length > 0 && templateId === 258 && !open;

  const { blocking } = order_block_info || {};

  const isVisible = usePageVisibility();
  const isFocused = usePageFocused();
  let [bankRefreshCount, setBankRefreshCount] = useState(0);
  let [docRefreshCount, setDocRefreshCount] = useState(0);
  let [taskRefreshCount, setTaskRefreshCount] = useState(0);
  const docRefreshTime =
    (isFocused && state === 'Processing' && 30000) ||
    (isVisible && state === 'Processing' && 90000) ||
    (!isVisible && state === 'Processing' && 120000) ||
    (isFocused && isRedlining && 60000) ||
    (isVisible && isRedlining && 120000) ||
    (!isVisible && isRedlining && 600000) ||
    (isFocused && !open && 300000) ||
    (isVisible && !open && 600000) ||
    null;
  const taskRefreshTime =
    (isFocused && state === 'Processing' && 30000) ||
    (isVisible && state === 'Processing' && 90000) ||
    (!isVisible && state === 'Processing' && 120000) ||
    (isFocused && !open && 60000) ||
    (isVisible && !open && 120000) ||
    (!isVisible && !open && 300000) ||
    320000;
  const bankStatusRefresh =
    (!open && isFocused && 60000) ||
    (!open && !isFocused && 300000) ||
    (!open && isVisible && 900000) ||
    (!open && !isVisible && 120000) ||
    null;

  const stripePromise = useMemo(() => {
    return loadStripe(partnerStripeDict[projectStripeDomain || stripeDomainSlug]);
  }, [projectStripeDomain, stripeDomainSlug]);

  useEffect(() => {
    if (isFocused) {
      setBankRefreshCount(1);
      setDocRefreshCount(1);
      setTaskRefreshCount(1);
    }
  }, [isFocused]);

  useInterval(e => {
    if (
      !!open ||
      templateId !== 258 ||
      status === 'approved' ||
      (!isFocused && bankRefreshCount > 2) ||
      (!isVisible && bankRefreshCount > 3) ||
      bankRefreshCount > 4
    ) {
      return;
    }
    setBankRefreshCount(bankRefreshCount + 1);
    dispatch(getAccountMercuryBanks());
  }, bankStatusRefresh);

  useInterval(e => {
    if (
      (state === 'Processing' && docRefreshCount > 15) ||
      (!isFocused && docRefreshCount > 4) ||
      (isFocused && docRefreshCount > 7)
    ) {
      return;
    }
    setDocRefreshCount(docRefreshCount + 1);
    dispatch(getTransactionDocuments(transactionId));
    dispatch(getTransaction(transactionId));
  }, docRefreshTime);

  useInterval(e => {
    if (
      (state === 'Processing' && docRefreshCount > 15) ||
      (!isFocused && taskRefreshCount > 8) ||
      (isFocused && taskRefreshCount > 12)
    ) {
      return;
    }
    setTaskRefreshCount(taskRefreshCount + 1);
    dispatch(getTransaction(transactionId));
  }, taskRefreshTime);

  let transactionDescription = get(
    transaction,
    'template.description',
    transaction.description,
  );

  const transactionFees = useSelector(state =>
    getFeesFromTransaction(state.Transaction, transactionId),
  );
  const { addons, amountTotal, fees, feesTotal } = transactionFees;

  const accountSubs = useSelector(AccountSubs);
  const isDocumentTransaction = useSelector(state =>
    IsDocumentTransaction(state, transactionId),
  );

  const faqs = useSelector(state =>
    getFaqsFromTransaction(state.Transaction, transactionId),
  );
  const isFetching = useSelector(isFetchingWithHooks);
  const isFetchingProject = useSelector(isFetchingWithHooksProject);

  const taskRooms = get(project, 'rooms', []).reduce((dict, e) => {
    if (e.group === group && (e.init_room || e.open)) {
      dict.push(e);
    }
    return dict;
  }, []);

  const isOutOfCredits =
    !!is_assessment &&
    !!accountInfo.credits_info &&
    accountInfo.credits_info.limit - accountInfo.credits_info.used > 0;

  const isFormShowing = !validated_questionnaire && !!open;

  const isTotalShowing =
    (fees.length > 0 || addons.length > 0) &&
    !!open &&
    !!validated_questionnaire &&
    !get(fees, '[0].is_annual', false);

  const isReopenAllowed =
    tasks.findIndex(e => e.mode !== 'Upload') === -1 || allow_reopen;

  const measuredRef = useCallback(
    node => {
      if (node !== null && !isPaymentMethodAvailable && isTotalShowing) {
        node.style.marginBottom = '372px';
      } else if (node !== null && (isPaymentMethodAvailable || !isTotalShowing)) {
        node.removeAttribute('style');
      }
    },
    [isPaymentMethodAvailable, isTotalShowing],
  );

  const currentTransactionIndex = project.transactions.findIndex(
    e => e.id + '' === params.transactionId,
  );

  const isNoAssessmentProject =
    project.transactions.length === 1 ||
    !get(project, 'transactions[0].template.is_assessment', true);

  let primaryActionText = 'Exit Workflow';
  let secondaryActionText =
    (isNoAssessmentProject && 'Exit Workflow') || (isPortalView && 'Back') || 'Overview';

  if (open && validated_questionnaire) {
    primaryActionText = 'Submit Task';
  } else if (open && !is_started) {
    primaryActionText = 'Start Form';
  } else if (open && !validated_questionnaire) {
    primaryActionText = 'Continue Form';
  } else if (currentTransactionIndex + 1 < project.transactions.length) {
    primaryActionText = 'Next Task';
  }

  let isDeletable = false;
  if (delete_level === 'completed') {
    isDeletable = true;
  } else if (delete_level === 'unsubmitted' && !!open) {
    isDeletable = true;
  } else if (
    delete_level === 'submitted' &&
    tasks.findIndex(e => e.state === 'completed') === -1
  ) {
    isDeletable = true;
  }

  const isAddonsHidden = accountSubs.some(r =>
    ['entity', 'equity', 'team', 'fundraising'].includes(r),
  );
  const isShareFormDisabled = !open || !!validated_questionnaire;

  const [isSharingForm, setIsSharingForm] = useState(false);
  const [isActionsShowing, setIsActionsShowing] = useState(false);
  const [isDeleteAlertOpen, setDeleteAlert] = useState(false);
  const [isDocLoadingFirstTime, setDocLoadingFirstTime] = useState(false);
  const [isFinalizeLoadingFirstTime, setFinalizeLoadingFirstTime] = useState(false);
  const [isProjectCounselModalOpen, setProjectCounselModal] = useState(false);
  const [isReopenAlertOpen, setReopenAlert] = useState(false);
  const [isReviewModalOpen, setReviewModal] = useState(false);
  const [isReviewersModalOpen, setReviewersModal] = useState(false);
  const [isPricingOpen, setPricingOpen] = useState(false);
  const [isReopeningTimeout, setReopeningTimeout] = useState(false);
  const [isDocumentsFetched, setIsDocumentsFetched] = useState(false);

  const handleAfterDocLoadingFirstTime = useCallback(() => {
    dispatch(getTransactionDocuments(transactionId));
    dispatch(getTransaction(transactionId));
    setDocLoadingFirstTime(false);
  }, [dispatch, transactionId]);

  const handleAfterFinalizeLoadingFirstTime = useCallback(() => {
    dispatch(getTransaction(transactionId));
    setFinalizeLoadingFirstTime(false);
  }, [dispatch, transactionId]);

  useEffect(() => {
    dispatch(getTransactionDocuments(transactionId)).then(p => {
      setIsDocumentsFetched(true);
    });
    dispatch(getTransactionQuestionnaire(transactionId)).then(e => {
      if (e.custom_documents.length > 0) {
        dispatch(getMyTemplates());
      }
    });
    dispatch(getPaymentMethodsV2());
  }, [dispatch, transactionId]);

  useEffect(() => {
    let timer;
    if (isDocLoadingFirstTime && !timer) {
      timer = setTimeout(() => handleAfterDocLoadingFirstTime(false), 16000);
    }
    return () => clearTimeout(timer);
  }, [handleAfterDocLoadingFirstTime, isDocLoadingFirstTime]);

  useEffect(() => {
    let timer;
    if (isFinalizeLoadingFirstTime && !timer) {
      timer = setTimeout(() => handleAfterFinalizeLoadingFirstTime(false), 5000);
    }
    return () => clearTimeout(timer);
  }, [handleAfterFinalizeLoadingFirstTime, isFinalizeLoadingFirstTime]);

  useEffect(() => {
    if (isReopening) {
      setReopeningTimeout(true);
      if (!!validated_questionnaire) {
        dispatch(updateTransaction(transactionId, { validated_questionnaire: 0 })).then(
          e => replace({ ...location, state: {} }),
        );
      }
    }
  }, [dispatch, isReopening, location, replace, transactionId, validated_questionnaire]);

  useEffect(() => {
    let reopeningTimer;
    if (isReopeningTimeout) {
      reopeningTimer = setTimeout(() => setReopeningTimeout(false), 12000);
    }
    return () => clearTimeout(reopeningTimer);
  }, [isReopeningTimeout]);

  const handleBack = () => {
    if (isNoAssessmentProject) {
      push(handlePath('/home', params.companyId));
    } else {
      push(
        handlePath(
          `/workbench/${moduleId}/${projectId}/${project.transactions[0].id}/overview`,
          params.companyId,
        ),
      );
    }
  };

  const handleFinalize = (isAutoSend, isDevPandadoc, isDocEditingEnabled) => {
    const body = {
      finalize_snapshot: {
        ...reviewInfo,
        finalized_at: dayjs().format('L'),
      },
      auto_send: isAutoSend ? 1 : 0,
      redline_enabled: isDocEditingEnabled ? 1 : 0,
    };
    return dispatch(
      finalizeTransaction(transactionId, projectId, body, isDevPandadoc),
    ).then(p => {
      if (isDocumentTransaction) {
        dispatch(
          setNotice({
            delay: 20000,
            message:
              'Your Document(s) will take 15-20 seconds to build the first time you submit your task.',
          }),
        );
      }
      if (isMercuryBankProject) {
        setTimeout(() => dispatch(getAccountMercuryBanks()), 3000);
      }
      const isUploadDoc =
        tasks.findIndex(
          task =>
            task.template.type === 'Document' && !task.bypassed && task.mode === 'Upload',
        ) !== -1;
      if (isUploadDoc) {
        dispatch(getAllDocuments()).then(docs => {
          for (const [key, value] of Object.entries(reviewInfo)) {
            if (key === 'Individual') {
              for (const resource of value) {
                let email = '';
                let name = resource.resource;
                let role = 'signer';
                let docIndexes = [];

                for (const [fieldKey, fieldVal] of Object.entries(resource)) {
                  if (fieldKey.includes('email') || fieldKey.includes('Email')) {
                    email = fieldVal;
                  } else if (isObject(fieldVal) && fieldVal?.fileId) {
                    const docIndex = docs.findIndex(
                      doc => doc.box_file_id === fieldVal.fileId,
                    );
                    if (docIndex !== -1) {
                      docIndexes.push(docIndex);
                    }
                  }
                }
                if (!!email && docIndexes.length > 0) {
                  for (const docIndex of docIndexes) {
                    const doc = docs[docIndex];
                    const isNotRecipient =
                      (doc?.recipients || []).findIndex(r => r.email === email) === -1;
                    const isNotEmailRecipient =
                      (doc?.email_recipients || []).findIndex(r => r.email === email) ===
                      -1;
                    if (isNotRecipient && isNotEmailRecipient) {
                      dispatch(
                        addDocumentRecipient({
                          email,
                          document_id: doc.id,
                          metadata: { name, role },
                        }),
                      );
                    }
                  }
                }
              }
            }
          }
        });
      }

      setFinalizeLoadingFirstTime(true);
      setDocLoadingFirstTime(true);
      return p;
    });
  };

  const handleNextTaskAction = () => {
    if (open) {
      if (!is_started || !validated_questionnaire) {
        document
          .querySelector('.workbench-form-inner__title')
          .scrollto({ top: 0, left: 0, behavior: 'smooth' });
      } else if (!!disabled) {
        let message = `Unable to submit task until previous project tasks are ${
          blocking === 2 ? 'completed' : 'submitted'
        }.`;
        dispatch(
          setNotice({
            type: 'error',
            message,
          }),
        );
      } else if (isDocumentCreditsNeeded) {
        dispatch(
          setNotice({
            type: 'error',
            message:
              'Account does not have enough document credits. Click Subscription link in banner or "Upgrade Plan" at top of page to get more credits.',
          }),
        );
      } else if (isSubscriptionNeeded) {
        dispatch(
          setNotice({
            type: 'error',
            message:
              'Account does not have the correct subscription. Click Subscription link in banner or "Upgrade Plan" at top of page to get correct subscription.',
          }),
        );
      } else {
        setReviewModal(true);
      }
    } else if (
      currentTransactionIndex !== -1 &&
      currentTransactionIndex + 1 < project.transactions.length
    ) {
      push(
        handlePath(
          `/workbench/${moduleId}/${projectId}/${
            project.transactions[currentTransactionIndex + 1].id
          }/initialize`,
          params.companyId,
        ),
      );
    } else {
      push(handlePath(ModuleDict[moduleId].path, params.companyId));
    }
  };

  const handleTaskAction = (action, taskId) => {
    if (action === 'reopen') {
      setReopenAlert(true);
    } else if (action === 'delete') {
      setDeleteAlert(true);
    } else {
      dispatch(performTaskAction(action, taskId)).then(e => {
        if (action === 'Custom') {
          dispatch(getMyTemplates());
        }
        dispatch(getTransactionQuestionnaire(transactionId));
        dispatch(updateTransaction(transactionId, { validated_questionnaire: 0 }));
        dispatch(
          setNotice(
            `Form ${
              action === 'Upload' ? 'Enabled' : 'Updated'
            }, please review before submitting.`,
          ),
        );
      });
    }
  };

  const handleTransactionDelete = isDeleting => {
    if (isDeleting) {
      dispatch(performTaskAction('delete', transactionId));
    }
    setDeleteAlert(false);
  };

  const handleTransactionReopen = isReopen => {
    if (isReopen) {
      dispatch(performTaskAction('reopen', transactionId)).then(
        e => {
          dispatch(getTransactionQuestionnaire(transactionId));
          dispatch(updateTransaction(transactionId, { validated_questionnaire: 0 }));
          setReopenAlert(false);
        },
        error => setReopenAlert(false),
      );
    } else {
      setReopenAlert(false);
    }
  };

  const handleFormCompletion = () => {
    if (!disabled && !isDocumentCreditsNeeded && !isSubscriptionNeeded) {
      setReviewModal(true);
    }
  };

  const isBankActionShowing =
    status !== 'approved' &&
    status !== 'pending' &&
    status !== 'rejected' &&
    status !== 'application_submitted';

  return (
    <>
      <div
        ref={measuredRef}
        className={`workbench__content${
          isFormShowing ? ' workbench__content--form' : ''
        }`}
      >
        <div className="workbenchTaskOverview__top">
          <div className="workbenchTaskOverview__title">
            <h2>{label}</h2>
          </div>
          <div className="workbenchTaskOverview__top-actions">
            <Button
              buttonType="icon"
              onClick={e => {
                e.stopPropagation();
                setIsActionsShowing(true);
              }}
            >
              <FontAwesomeIcon icon="ellipsis-v" />
            </Button>
            {isActionsShowing && (
              <Dropdown
                onClose={e => {
                  e.stopPropagation();
                  setIsActionsShowing(false);
                }}
              >
                {!!open && !!validated_questionnaire && (
                  <Button
                    buttonType="secondary"
                    onClick={() =>
                      dispatch(
                        updateTransaction(transactionId, {
                          validated_questionnaire: 0,
                        }),
                      )
                    }
                  >
                    Update Inputs
                  </Button>
                )}
                {!open && (
                  <Button
                    buttonType="secondary"
                    isDisabled={!isReopenAllowed}
                    onClick={() => setReopenAlert(true)}
                  >
                    Reopen Task
                  </Button>
                )}
                {isWorkflowCounselShowing && (
                  <Button
                    buttonType="secondary"
                    onClick={() => setProjectCounselModal(true)}
                  >
                    Assign Role
                  </Button>
                )}
                {isDocumentTransaction && !!open && !isNoAssessmentProject && (
                  <Button buttonType="secondary" onClick={() => setReviewersModal(true)}>
                    Assign Reviewers
                  </Button>
                )}
                {!is_assessment && (
                  <Button
                    buttonType="secondary"
                    isDisabled={isShareFormDisabled}
                    onClick={e => {
                      setIsSharingForm(!isSharingForm);
                    }}
                  >
                    {isSharingForm ? 'Cancel Sharing Form' : 'Share Form'}
                  </Button>
                )}
                <Button
                  buttonType="secondary"
                  isWarning
                  isDisabled={!isDeletable && !is_assessment}
                  onClick={e => {
                    handleTaskAction('delete');
                  }}
                >
                  Delete Task
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="workbenchTaskOverview__top-sections">
          <div className="workbenchTaskOverview__top-section first">
            <p>
              {(!!open &&
                !!validated_questionnaire &&
                `Form completed on ${dayjs(updated_at).format('dddd MMMM, D YYYY')}`) ||
                (!!open &&
                  (transactionDescription ||
                    'Please fill out the requested information.')) ||
                (state === 'Completed' &&
                  `Completed on ${dayjs(submission_date).format('dddd MMMM, D YYYY')}`) ||
                `Submitted on ${dayjs(submission_date).format('dddd MMMM, D YYYY')}`}
              {!!open && !!validated_questionnaire && (
                <>
                  {' ('}
                  <Button
                    className="workbenchTaskOverview__update-inputs"
                    size="xs"
                    buttonType="link"
                    onClick={() =>
                      dispatch(
                        updateTransaction(transactionId, {
                          validated_questionnaire: 0,
                        }),
                      )
                    }
                  >
                    Update Inputs
                  </Button>
                  )
                </>
              )}
            </p>
            {isMercuryBankShowing && (
              <>
                <p>
                  Mercury Bank Status:{' '}
                  <span
                    className={classnames(
                      'workbenchTaskOverview__status',
                      mercuryBankStatusDict[status],
                    )}
                  >
                    {status}
                  </span>
                </p>
                {isBankActionShowing && (
                  <>
                    <p>
                      <FontAwesomeIcon icon="exclamation-circle" />
                      Click below to finish registering to Mercury & connecting your bank.
                    </p>
                    <DropdownAction
                      className="workbenchTaskOverview__dropdown"
                      isFlat
                      left
                      Toggle={toggleProps => (
                        <Button
                          className="workbenchTaskOverview__bank-link"
                          buttonType="primary"
                          size="xs"
                          // rel="noopener noreferrer"
                          {...toggleProps}
                        >
                          Finish Connecting Bank{' '}
                        </Button>
                      )}
                    >
                      <div className="workbenchTaskOverview__dropdownHeader">
                        <h4>Options</h4>
                        <div className="workbenchTaskOverview__divider" />
                      </div>
                      <Button
                        buttonType="secondary"
                        size="sm"
                        href={referral_link}
                        target="_blank"
                        onClick={e => e.stopPropagation()}
                        rel="noopener noreferrer"
                      >
                        Go to Mercury Registration{' '}
                        <FontAwesomeIcon icon={['fal', 'external-link']} />
                      </Button>
                      <Button
                        buttonType="secondary"
                        size="sm"
                        onClick={ev => {
                          ev.preventDefault();
                          navigator.clipboard.writeText(referral_link);
                          dispatch(setNotice('Link copied'));
                        }}
                      >
                        Copy Registration Link <FontAwesomeIcon icon={['fal', 'copy']} />
                      </Button>
                    </DropdownAction>
                  </>
                )}
              </>
            )}
            {!!open && !validated_questionnaire && (
              <p className="workbench-form__asterik">
                (<span className="workbench-form__required">*</span>Required)
              </p>
            )}
          </div>
        </div>
        {tasks.length > 0 && (!!validated_questionnaire || !open) && (
          <div className="workbenchTaskOverview__content">
            {tasks.map((task, ind) => (
              <TaskCard
                key={`task-card-${ind}`}
                addons={addons}
                brandMeta={brandMeta}
                fees={fees}
                handleTaskAction={handleTaskAction}
                isAssessment={is_assessment}
                isDocLoadingFirstTime={isDocLoadingFirstTime}
                isDocumentsFetched={isDocumentsFetched}
                isFetching={isFetchingProject}
                isFirst={ind === 0}
                isLast={tasks.length === ind + 1}
                taskIndex={ind}
                mercuryBankStatus={status}
                open={open}
                params={params}
                projectLabel={project.label}
                task={task}
                transactionFees={transactionFees}
                validated_questionnaire={validated_questionnaire}
              />
            ))}
          </div>
        )}
        {isFormShowing && (
          <WorkbenchForm
            formikValues={formikValues}
            isPortalView={isPortalView}
            isReopeningTimeout={isReopeningTimeout}
            isSharingForm={isSharingForm}
            onFormCompletion={handleFormCompletion}
            params={params}
            push={push}
            project={project}
            setIsSharingForm={setIsSharingForm}
          />
        )}
        {(!open || !!validated_questionnaire) && (
          <div className="workbenchTaskOverview__bottom">
            {!!open && !validated_questionnaire && (
              <h4 className="workbenchTaskOverview__description">
                <FontAwesomeIcon icon="exclamation-circle" />
                Before you can submit this task please complete the form. Submitting will
                use {documentCredits || 'zero'} document credit(s){' '}
                {!!creditsCoveredBy &&
                  !!documentCredits &&
                  `(covered by ${creditsCoveredBy})`}
                {!!feesCoveredBy &&
                  isTotalShowing &&
                  ` and additional fees will be covered by ${feesCoveredBy} `}
                {!feesCoveredBy && isTotalShowing && (
                  <>
                    {' '}
                    {'and you will be charged'}{' '}
                    <span>${isAddonsHidden ? feesTotal : amountTotal}</span>
                  </>
                )}
                .
              </h4>
            )}
            {!!open && !!validated_questionnaire && (
              <h4 className="workbenchTaskOverview__description">
                Once you click 'Submit', we will use your information to begin processing
                your Task.
                {!!documentCredits && (
                  <>
                    {' Submitting will use '}
                    <span>{documentCredits}</span>
                    {' document credit(s)'}
                    {!!creditsCoveredBy && ` (covered by ${creditsCoveredBy})`}
                    {'.'}
                  </>
                )}
                {!!feesCoveredBy &&
                  isTotalShowing &&
                  ` Additional fees will be covered by ${feesCoveredBy}.`}
                {!feesCoveredBy && isTotalShowing && (
                  <>
                    {' You will be charged '}
                    <span>
                      ${isAddonsHidden ? feesTotal : amountTotal}
                      <DropdownMenu
                        align="left"
                        className="workbenchTaskOverview__help"
                        close={() => setPricingOpen(false)}
                        closeOnInsideClick={false}
                        isOpen={isPricingOpen}
                        toggle={
                          <sup>
                            <FontAwesomeIcon
                              onClick={() => setPricingOpen(!isPricingOpen)}
                              icon={['fal', 'info-circle']}
                            />
                          </sup>
                        }
                        upwards={true}
                      >
                        {fees.length > 0 && (
                          <>
                            <h4>Additional Fees:</h4>
                            {fees.map((fee, feeInd) => (
                              <li key={`product-fee-${feeInd}`}>
                                {`- ${fee.label} - ${
                                  (fee.is_annual && fee.amount) || '$' + fee.amount
                                }`}
                                {fee.comment && (
                                  <ul>
                                    <li>{fee.comment}</li>
                                  </ul>
                                )}
                              </li>
                            ))}
                          </>
                        )}
                        {addons.length > 0 && !isAddonsHidden && (
                          <>
                            <h4>Add-ons</h4>
                            <ul>
                              {addons.map((addon, addonInd) => (
                                <li key={`product-addon-${addonInd}`}>
                                  {`- ${addon.label} - $${addon.amount}/`}
                                  {(addon.period === 'year' && 'Yr') || 'Mo'}
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                        {(fees.length > 0 || addons.length > 0) &&
                          !get(fees, '[0].is_annual', false) && (
                            <h4>
                              {'Total - $' +
                                ((isAddonsHidden && feesTotal) || amountTotal)}
                            </h4>
                          )}
                      </DropdownMenu>
                    </span>
                  </>
                )}
              </h4>
            )}
            {!!disabled && (
              <h4 className="workbenchTaskOverview__description">
                <FontAwesomeIcon className="error" icon="exclamation-circle" />
                You will not be able to submit this task until all previous tasks have
                been {blocking === 2 ? 'completed' : 'submitted'}.
              </h4>
            )}
            {!!isReopeningTimeout && (
              <h4 className="workbenchTaskOverview__description">
                <FontAwesomeIcon className="error" icon="exclamation-circle" />
                Since you just reopened this task, please wait a few more seconds before
                submitting this task to allow everything to update correctly on our end.
              </h4>
            )}
            <WorkbenchActions
              handleNextTaskAction={handleNextTaskAction}
              handleSecondaryAction={handleBack}
              projectTemplateId={project?.template?.id}
              isDisabled={isReopeningTimeout || isOutOfCredits}
              isSecondaryHiding={isNoAssessmentProject && !open}
              isFetching={isFetching}
              isOverview
              params={params}
              primaryActionText={primaryActionText}
              secondaryActionText={secondaryActionText}
            />
          </div>
        )}
        {isProjectCounselModalOpen && (
          <ProjectCounselModal
            projectCounselQuestions={projectCounselQuestions || []}
            isOpen={isProjectCounselModalOpen}
            onClose={() => setProjectCounselModal(false)}
            params={params}
          />
        )}
        {isDeleteAlertOpen && (
          <AlertModal
            header={`Are you sure you want to delete the Task '${label}'?`}
            isOpen={isDeleteAlertOpen}
            isExclamationShowing
            isWarning
            onPrimaryClick={() => handleTransactionDelete(true)}
            onClose={() => handleTransactionDelete(false)}
            primaryActionText="Delete Task"
          />
        )}
        {isReopenAlertOpen && (
          <AlertModal
            header={`Are you sure you want to reopen the Task '${label}'?`}
            body="You will not be charged again for one time fees, but you will have to reuse any necessary document credits to re-finalize this task."
            isFetching={isFetchingProject}
            isOpen={isReopenAlertOpen}
            isSecondary
            isWarning
            isExclamationShowing
            onPrimaryClick={() => handleTransactionReopen(true)}
            onClose={() => handleTransactionReopen(false)}
            primaryActionText="Reopen Task"
          />
        )}
        <Elements stripe={stripePromise} key={`stripe-elements-${stripeDomainSlug}`}>
          <FinalizeReviewModal
            addons={addons}
            projectStripeDomain={projectStripeDomain}
            amountTotal={amountTotal}
            documentCredits={documentCredits}
            fees={fees}
            feesTotal={feesTotal}
            isAddonsHidden={isAddonsHidden}
            isAssessment={is_assessment}
            isOpen={isReviewModalOpen}
            isRedlineAvailable={redline_available}
            isPaymentMethodShowing={
              !!open &&
              !!validated_questionnaire &&
              !isPaymentMethodAvailable &&
              isTotalShowing &&
              !feesCoveredBy
            }
            isTotalShowing={isTotalShowing}
            onClose={() => setReviewModal(false)}
            handleFinalize={handleFinalize}
            params={params}
            partnerCoverage={partnerCoverage}
            reviewInfo={reviewInfo}
            room_use={room_use}
          />
        </Elements>
        {isReviewersModalOpen && (
          <ReviewersModal
            initReviewers={reviewers || []}
            isOpen={isReviewersModalOpen}
            onClose={() => setReviewersModal(false)}
            params={params}
          />
        )}
      </div>
      <InfoSidebar
        addons={addons}
        customDocsReviewInfo={customDocsReviewInfo}
        faqs={faqs}
        fees={fees}
        handleTaskAction={handleTaskAction}
        isAssessment={is_assessment}
        isProcessesShowing={tasks.length > 0 && !validated_questionnaire && !!open}
        isQuestionnaireSubmitted={!open || !!validated_questionnaire}
        isPortalView={isPortalView}
        isReviewersShowing={isDocumentTransaction && !!open && !isNoAssessmentProject}
        isTaskView
        isWorkflowCounselShowing={isWorkflowCounselShowing}
        open={open}
        outsideForms={connectedOutsideForms}
        params={params}
        rooms={taskRooms}
        partnerCoverage={partnerCoverage}
        projectCounsel={projectCounsel}
        reviewers={reviewers}
        reviewInfo={reviewInfo}
        tasks={tasks}
      />
    </>
  );
};

WorkbenchTaskOverview.defaultProps = {
  navTitle: 'Module Steps',
};
export default WorkbenchTaskOverview;
