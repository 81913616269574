import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import logo from '../../assets/images/paperOS_capital_at_scale_brown_md.png';
import {
  exchangeChallenge,
  checkVerificationStatusByAccessCode,
} from '../../redux/modules/User/operations';

import './RoomInvite.scss';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button } from '../../components/common';
import handlePath from '../../utils/handlePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLoader from '../../components/PageLoader';

const VerifyByLink = ({ hideVerify }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { access_code } = useParams();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const [initializing, setInitializing] = useState(false);
  const [error, setError] = useState('');
  const query = new URLSearchParams(location.search || {});
  let id = query.get('id');
  let code = query.get('code');

  useEffect(() => {
    if (!initializing && !initialized) {
      setInitializing(true);
      dispatch(checkVerificationStatusByAccessCode(access_code)).then(
        e => {
          dispatch(
            exchangeChallenge(
              id || e.access_claims?.challenge_id,
              {
                code: code || e.access_claims?.challenge_code,
              },
              'room',
            ),
          ).then(
            e => {
              push(`/data-room/${access_code}`);
            },
            error => {
              if (error.code === 'E_CODE_REDEEMED') {
                push(`/data-room/${access_code}`);
              } else {
                setError(error);
                setInitializing(false);
                setInitialized(true);
              }
            },
          );
        },
        error => {
          setError(error);
          setInitialized(true);
          setInitializing(false);
        },
      );
    }
  }, [push, dispatch, initializing, initialized, access_code, id, code]);

  const statusLabel =
    error || 'Your room access code has been validated. You will be redirected shortly.';

  return (
    <div className="roomInvite__container">
      <div
        className="roomInvite__logo"
        onClick={() => push(handlePath('/company-select', 0))}
      >
        <img src={logo} alt="savvi-logo" />
      </div>
      <div className="roomInvite__heading">
        {!initialized && <PageLoader />}
        {initialized && (
          <h3>
            {!!error && <FontAwesomeIcon icon="exclamation-circle" />}
            {statusLabel}
            {!!error && (
              <Button buttonType="link" to={handlePath('/company-select', 0)}>
                continue to Savvi from here
              </Button>
            )}
          </h3>
        )}
      </div>
      <div className="roomInvite__text">
        <p>
          For help with any issues please contact us at{' '}
          <a href="mailto:support@savvi.legal">support@savvi.legal</a>
        </p>
      </div>
    </div>
  );
};

export default VerifyByLink;
