import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useInterval from '../../../utils/useInterval';

import './ProgressBar.scss';

const ProgressBar = ({
  delay,
  isFetchingInit,
  isLoadingFirstTime = true,
  isStopped,
  percentage,
  setDelay,
  setError,
  setIsStopped,
  setPercentage,
}) => {
  useEffect(() => {
    if (isLoadingFirstTime) {
      switch (percentage) {
        case 8:
          return setDelay(200);
        case 15:
          return setDelay(125);
        case 30:
          return setDelay(125);
        case 45:
          return setDelay(125);
        case 65:
          return setDelay(200);
        case 75:
          return setDelay(175);
        case 80:
          return setDelay(250);
        case 85:
          return setDelay(550);
        case 89:
          return setDelay(725);
        case 95:
          return setDelay(175);
        case 100:
          !isFetchingInit &&
            setError(
              e =>
                e ||
                "An error has occurred loading your documents. If refreshing this page doesn't fix the issue, please contact us",
            );
          return setIsStopped(true);
        default:
          return;
      }
    } else if (!isFetchingInit || isStopped) {
      setDelay(5);
      if (percentage >= 100) {
        setIsStopped(true);
      }
    } else {
      switch (percentage) {
        case 15:
          return setDelay(25);
        case 65:
          return setDelay(100);
        case 75:
          return setDelay(50);
        case 80:
          return setDelay(50);
        case 87:
          return setDelay(1000);
        case 89:
          return setDelay(3000);
        case 90:
          setError(
            e =>
              e ||
              "An error has occurred loading your documents. If refreshing this page doesn't fix the issue, please contact us",
          );
          return setIsStopped(true);
        default:
          return;
      }
    }
  }, [
    isFetchingInit,
    isLoadingFirstTime,
    isStopped,
    percentage,
    setDelay,
    setError,
    setIsStopped,
  ]);

  useInterval(() => setPercentage(percentage + 1), isStopped ? null : delay);
  return (
    <div className="progressBar">
      <h4>Looking for attached Documents...</h4>
      <div className="tracker">
        <div className="progressInTracker" style={{ width: `${percentage}%` }} />
        <div className="progressInnerTrackerPercentage">
          <h4>{percentage}%</h4>
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  delay: PropTypes.number,
  isFetchingInit: PropTypes.bool,
  isLoadingFirstTime: PropTypes.bool,
  isStopped: PropTypes.bool,
  percentage: PropTypes.number,
  setDelay: PropTypes.func,
  setError: PropTypes.func,
  setIsStopped: PropTypes.func,
  setPercentage: PropTypes.func,
};

ProgressBar.defaultProps = {
  percentage: 0,
  min: 0,
  max: 100,
  isLoadingFirstTime: true,
  setDelay: () => {},
  setPercentage: () => {},
};

export default ProgressBar;
