import { RSAA } from 'redux-api-middleware';
import { push, replace } from 'connected-react-router';

import { requestHeaders } from '../../utils/api';
import { setNotice } from '../UI/actions';
import { setInitLoading, setAccountFromToken } from '../User/actions';
import * as actions from './actions';
import * as Types from './types';
import handlePath from '../../../utils/handlePath';
import {
  decodeToken,
  getDecodedToken,
  getStoredCompanyId,
  getStoredToken,
} from '../User/utils';
import { get, isObjectLike, isString } from 'lodash-es';
import { getAccountInfo } from '../Formation/operations';
import { fetchAccountRooms } from '../Data/operations';
import { setBoxToken } from '../Cabinet/actions';
import { setAccountProjects, setProjects } from '../Project/actions';
import { setTransactionsByModule } from '../Transaction/actions';
import { setAccountTodos } from '../Todos/actions';
import { setSavviPartners } from '../Profile/actions';
import { connectPartner } from '../User/operations';
import { setAccountMercuryBanks } from '../Billing/actions';
import { setAllDocuments } from '../Document/actions';

export const getCompanies = () => {
  const REQUEST = Types[`GET_COMPANIES_REQUEST`];
  const SUCCESS = Types[`GET_COMPANIES_SUCCESS`];
  const FAILED = Types[`GET_COMPANIES_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/accounts`,
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      // if (isLogin && actionResponse.payload.length > 0) {
      // if (isLogin) {
      //   await dispatch(actions.setCompaniesById(actionResponse.payload));
      //   return await push({ pathname: '/company-select', search });
      // }
      await dispatch(actions.setCompaniesById(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const updateCompanyPreferences = (body, accountId) => {
  const REQUEST = Types[`POST_COMPANY_PREFERENCE_REQUEST`];
  const SUCCESS = Types[`POST_COMPANY_PREFERENCE_SUCCESS`];
  const FAILED = Types[`POST_COMPANY_PREFERENCE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/accounts/${accountId}/preference`,
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        body: JSON.stringify(body, null, 2),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      // await dispatch(actions.setCompaniesById(actionResponse.payload));
      return await actionResponse;
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const removeCompanyPreferences = accountId => {
  const REQUEST = Types[`DELETE_COMPANY_PREFERENCE_REQUEST`];
  const SUCCESS = Types[`DELETE_COMPANY_PREFERENCE_SUCCESS`];
  const FAILED = Types[`DELETE_COMPANY_PREFERENCE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/accounts/${accountId}/preference`,
        method: 'DELETE',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      // await dispatch(actions.setCompaniesById(actionResponse.payload));
      return await actionResponse;
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const refreshCompanyToken = (account_id, user_id, isLocked, isRoom) => {
  const REQUEST = Types[`REFRESH_COMPANY_TOKEN_REQUEST`];
  const SUCCESS = Types[`REFRESH_COMPANY_TOKEN_SUCCESS`];
  const FAILED = Types[`REFRESH_COMPANY_TOKEN_FAILED`];
  return async (dispatch, getState) => {
    if (isLocked) {
      await dispatch(setInitLoading(true));
      await dispatch(actions.setCompanyTokenRefreshing(true));
    }
    const { impersonator_id, userId } = getDecodedToken(getStoredToken(account_id));
    let body = { account_id };
    if (user_id) {
      body.user_id = user_id;
    } else if (impersonator_id) {
      body.user_id = userId;
    }
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/access_token`, // alias of /authn/exchange
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        body: JSON.stringify(body, null, 2),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      let decodedToken = decodeToken(actionResponse.payload.access_token);
      await dispatch(setAccountFromToken(decodedToken));
      await dispatch(getAccountInfo(account_id));
      const partnerCode = localStorage.getItem('partner_code');
      if (partnerCode) {
        await dispatch(connectPartner(partnerCode));
        localStorage.removeItem('partner_code');
      }
      if (!isRoom && !isLocked) {
        await dispatch(fetchAccountRooms(account_id));
      }
      if (isLocked) {
        await dispatch(setInitLoading(false));
        await dispatch(actions.setCompanyTokenRefreshing(false));
      }
      return await actionResponse.payload;
    }
    const unauthedRoutes = [
      'forgot-password',
      'login',
      'register',
      'reset-password',
      'plans',
      'terms',
      'remote-login',
      'room',
      'outside-form',
    ];
    if (isLocked) {
      await dispatch(setInitLoading(false));
      await dispatch(actions.setCompanyTokenRefreshing(false));
    }
    if (
      actionResponse.payload.code === 'UNAUTHORIZED' &&
      unauthedRoutes.findIndex(e => JSON.stringify(window.location.href).includes(e)) ===
        -1
    ) {
      dispatch(
        setNotice({
          type: 'warning',
          message: 'To access that page you must be an admin of its parent Workspace.',
          // message: 'Unable to access view, redirected to company selection.',
        }),
      );
      await dispatch(replace('/c/0/company-select'));
      return await actionResponse.payload;
    }
    throw await actionResponse.payload;
  };
};

export const setCompany = (
  accountId,
  path = '/home',
  isCurrent,
  gotoType = 'push',
  isPathLocked,
) => {
  const REQUEST = Types[`SET_COMPANY_TOKEN_REQUEST`];
  const SUCCESS = Types[`SET_COMPANY_TOKEN_SUCCESS`];
  const FAILED = Types[`SET_COMPANY_TOKEN_FAILED`];
  const history = { push, replace };

  let updatedPath = isPathLocked ? path : handlePath(path || '/home', accountId);
  let storedCompanyId = getStoredCompanyId();
  if (!isPathLocked && storedCompanyId !== accountId && isString(updatedPath.search)) {
    if (updatedPath.search.includes(`%2Fc%2F${storedCompanyId}`)) {
      updatedPath.search = updatedPath.search.replace(
        `%2Fc%2F${storedCompanyId}`,
        `%2Fc%2F${accountId}`,
      );
    } else if (updatedPath.search.includes(`/c/${storedCompanyId}`)) {
      updatedPath.search = updatedPath.search.replace(
        `/c/${storedCompanyId}`,
        `/c/${accountId}`,
      );
    } else if (updatedPath.search.includes(`2Fc%2F0`)) {
      updatedPath.search = updatedPath.search.replace(`2Fc%2F0`, `2Fc%2F${accountId}`);
    } else if (updatedPath.search.includes(`/c/0`)) {
      updatedPath.search = updatedPath.search.replace(`/c/0`, `/c/${accountId}`);
    }
  } else if (!isPathLocked && isString(updatedPath.search)) {
    if (updatedPath.search.includes('%2Fc%2F0')) {
      updatedPath.search = updatedPath.search.replace('%2Fc%2F0', `%2Fc%2F${accountId}`);
    } else if (updatedPath.search.includes('/c/0')) {
      updatedPath.search = updatedPath.search.replace('/c/0', `/c/${accountId}`);
    }
  }
  return async (dispatch, getState) => {
    if (isCurrent) {
      return await dispatch(history[gotoType](updatedPath));
    }
    await dispatch(setInitLoading(true));
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/authn/access_token`, // alias of /authn/exchange
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        body: JSON.stringify({ account_id: accountId }, null, 2),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      let decodedToken = decodeToken(actionResponse.payload.access_token);
      await dispatch(setAccountFromToken(decodedToken));
      await dispatch(getAccountInfo(accountId));
      await dispatch(fetchAccountRooms(accountId));
      await dispatch(history[gotoType](updatedPath));
      /** clear store */
      await dispatch(setBoxToken({}));
      await dispatch(setProjects([]));
      await dispatch(setAccountProjects([]));
      await dispatch(setTransactionsByModule([], 'tasks'));
      await dispatch(setTransactionsByModule([], '-1'));
      await dispatch(setAccountTodos([]));
      await dispatch(setSavviPartners({ my_partners: [] }));
      await dispatch(actions.setCompanyDataTables([]));
      await dispatch(setAccountMercuryBanks([]));
      await dispatch(setAllDocuments([]));

      const partnerCode = localStorage.getItem('partner_code');
      if (partnerCode) {
        await dispatch(connectPartner(partnerCode));
        localStorage.removeItem('partner_code');
      }

      await dispatch(setInitLoading(false));
      // dispatch(window.location.reload());
    } else {
      const unauthedRoutes = [
        'forgot-password',
        'login',
        'register',
        'reset-password',
        'plans',
        'terms',
        'remote-login',
        'room',
        'outside-form',
      ];
      if (
        actionResponse.payload.code === 'UNAUTHORIZED' &&
        unauthedRoutes.findIndex(e => JSON.stringify(updatedPath).includes(e)) === -1
      ) {
        dispatch(
          setNotice({
            type: 'warning',
            message: 'To access that page you must be an admin of its parent Workspace.',
          }),
        );
        // await dispatch(push('/c/0/company-select'));
      } else {
        dispatch(
          setNotice({
            type: 'error',
            message: get(
              actionResponse,
              'payload.message',
              'An error has occurred. if error persists, please contact support',
            ),
          }),
        );
      }
      await dispatch(setInitLoading(false));
      throw await actionResponse.payload.message;
    }
  };
};

export const createNewCompany = (body, passedLoc, isFirstCompany) => {
  const REQUEST = Types[`CREATE_COMPANY_REQUEST`];
  const SUCCESS = Types[`CREATE_COMPANY_SUCCESS`];
  const FAILED = Types[`CREATE_COMPANY_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/accounts`,
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (actionResponse.error) {
      if (isObjectLike(actionResponse.payload)) {
        dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      } else {
        dispatch(setNotice({ type: 'error', message: actionResponse.payload }));
      }
      throw actionResponse.payload;
    }

    if (!actionResponse.error) {
      // Hubspot integration
      await dispatch(
        setNotice({
          message: 'Company Added! Please wait a few seconds as we set everything up.',
        }),
      );
      // const url = window.location.href;
      // if (url.includes('paperos') && !url.includes('onetaptalent')) {
      //   localStorage.setItem('partner_code', 'onetaptalent');
      // }
      return await setTimeout(async () => {
        const { account_id } = actionResponse.payload;
        // await dispatch(setAccountFromToken(decodedToken));
        let updatedPath = {
          pathname: `/c/${account_id}/home`,
          ...passedLoc,
          state: { isPlansShowing: !passedLoc?.search && isFirstCompany },
        };
        if (isString(updatedPath.search)) {
          if (updatedPath.search.includes('%2Fc%2F0')) {
            updatedPath.search = updatedPath.search.replace(
              '%2Fc%2F0',
              `%2Fc%2F${account_id}`,
            );
          } else if (updatedPath.search.includes('/c/0')) {
            updatedPath.search = updatedPath.search.replace('/c/0', `/c/${account_id}`);
          }
        }
        await dispatch(getCompanies());
        return dispatch(setCompany(actionResponse.payload.account_id, updatedPath));
      }, 2000);
    }
  };
};

export const fetchCompanyDataTables = account_id => {
  const REQUEST = Types[`GET_COMPANY_DATA_TABLES_REQUEST`];
  const SUCCESS = Types[`GET_COMPANY_DATA_TABLES_SUCCESS`];
  const FAILED = Types[`GET_COMPANY_DATA_TABLES_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/reports`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(actions.setCompanyDataTables(actionResponse.payload));
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      throw err;
    }
  };
};

export const fetchSharedReports = accountId => {
  const REQUEST = Types[`GET_SHARED_REPORTS_REQUEST`];
  const SUCCESS = Types[`GET_SHARED_REPORTS_SUCCESS`];
  const FAILED = Types[`GET_SHARED_REPORTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/shared_report`,
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(actions.setSharedReports(actionResponse.payload));
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      throw err;
    }
  };
};

export const createSharedReport = (accountId, body) => {
  const REQUEST = Types[`GET_SHARED_REPORTS_REQUEST`];
  const SUCCESS = Types[`GET_SHARED_REPORTS_SUCCESS`];
  const FAILED = Types[`GET_SHARED_REPORTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/shared_report`,
        method: 'POST',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(fetchSharedReports(accountId));
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      dispatch(setNotice(err));
      throw err;
    }
  };
};

export const updateSharedReport = (accountId, reportId, body) => {
  const REQUEST = Types[`PUT_SHARED_REPORTS_REQUEST`];
  const SUCCESS = Types[`PUT_SHARED_REPORTS_SUCCESS`];
  const FAILED = Types[`PUT_SHARED_REPORTS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/shared_report/${reportId}`,
        method: 'PUT',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(fetchSharedReports(accountId));
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      dispatch(setNotice(err));
      throw err;
    }
  };
};
