import React, { useEffect, useState } from 'react';
// import DropdownMenu from 'react-dd-menu';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
// import { Form, Formik } from 'formik';
// import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import { getCurrentUser } from '../../redux/modules/Profile/selectors';
import { setDocumentError } from '../../redux/modules/Transaction/actions';
import {
  approveDocumentSuggestions,
  resendLinkEmail,
  // setDocumentRedliningVersion,
  setDocumentReviewer,
  updateDocumentSuggestions,
} from '../../redux/modules/Transaction/operations';
import {
  DocumentError,
  IsDocumentTransaction,
  isFetchingWithHooks,
  TransactionWithDocuments,
} from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import PandaDocStatusDict from '../../utils/PandaDocStatusDict';
import AddReviewerModal from '../AddReviewerModal';
import AlertModal from '../AlertModal';
// import InputStyledCheckbox from '../common/InputStyledCheckbox';
import ProgressBar from '../common/ProgressBar';
// import DocumentPreview from '../DocumentPreview';

import './WorkbenchDocStatus.scss';
import { Button, Dropdown, Tooltip } from '../common';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BrandMeta } from '../../redux/modules/UI/selectors';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const WorkbenchDocStatus = ({
  document = {},
  isDocLoadingFirstTime,
  isDocumentsFetched,
  isTaskView,
  params,
  setSelectedRedlineIndex,
  taskMode,
  taskState,
}) => {
  const dispatch = useDispatch();
  const { transactionId } = params;
  const transaction = useSelector(state =>
    TransactionWithDocuments(state.Transaction, transactionId),
  );
  const {
    template: { redline_available },
  } = transaction;

  const {
    box_file_id,
    panda_data = {},
    panda_document_id,
    recipients = [],
    status,
  } = document;
  const {
    id: pandaDocId,
    suggestions = [],
    settings = {},
    redlining = {},
  } = panda_data || {};
  const { redlining_allowed } = settings;

  /** Can remove filter when duplicate recipients removed on backend. */
  let filteredRecipients = [];
  recipients.forEach(el => {
    const existingRecipientIndex = filteredRecipients.findIndex(
      e => e.email === el.email,
    );
    if (existingRecipientIndex !== -1) {
      if (el.signature_link) {
        filteredRecipients[existingRecipientIndex] = el;
      }
    } else {
      filteredRecipients.push(el);
    }
  });

  let suggestionsByUser = [];
  suggestions.forEach(suggestion => {
    const suggestionIndex = suggestionsByUser.findIndex(
      el => el.email === suggestion.user.email,
    );
    if (suggestionIndex !== -1) {
      let existingUser = suggestionsByUser[suggestionIndex];
      existingUser.suggestions.push(suggestion);
      if ((suggestion.replies || []).length > 0) {
        existingUser.replies = [...existingUser.replies, ...suggestion.replies];
      }
      if (
        new Date(existingUser.lastVisited).valueOf() <
        new Date(suggestion.date_created).valueOf()
      ) {
        existingUser.lastVisited = suggestion.date_created;
      }
    } else {
      suggestionsByUser.push({
        email: suggestion.user.email,
        name: (suggestion.user.first_name + ' ' + suggestion.user.last_name).trim(),
        lastVisited: suggestion.date_created,
        suggestions: [suggestion],
        replies: [...(suggestion.replies || [])],
      });
    }
  });

  const boxToken = useSelector(BoxToken);
  const currentUser = useSelector(getCurrentUser);
  const handleSetDocumentError = error => dispatch(setDocumentError(error));
  const isDocumentsShowing = useSelector(state =>
    IsDocumentTransaction(state, transactionId),
  );
  const documentError = useSelector(DocumentError);
  const isFetching = useSelector(isFetchingWithHooks);

  const brandMeta = useSelector(BrandMeta);

  const [isRedliningAllowed, setIsRedliningAllowed] = useState(!!redlining_allowed);
  const [isFetchingInit, setFetchingInit] = useState(true);
  const [isAddReviewerModalOpen, setAddReviewerModal] = useState(false);
  // const [isRedlineHelpOpen, setRedlineHelp] = useState(-1);
  const [isSetRedliningOpen, setIsSetRedliningOpen] = useState(false);
  // const [uuid, setUuid] = useState(
  //   get(redline_data, 'redlining_revisions[0].uuid', 'original'),
  // );
  const [loaderPercentage, setLoaderPercentage] = useState(0);
  const [loaderDelay, setLoaderDelay] = useState(100);
  const [isLoaderStopped, setIsLoaderStopped] = useState(false);

  const isDocOpen =
    status !== 'document.completed' &&
    status !== 'document.declined' &&
    status !== 'document.deleted';
  // const isRedlining =
  //   isDocOpen && suggestions.length === 0 && !!get(redlining, 'status', false);
  const isSuggestionsShowing = isDocOpen && suggestions.length > 0;
  const isRecipientsShowing = taskState !== 'completed' && status !== 'document.draft';
  const isDocStatusMessageShowing =
    isLoaderStopped && isEmpty(document) && !documentError;
  let docStatusMessage = 'Your document is being prepared and will be sent when ready.';
  if (taskState === 'completed') {
    docStatusMessage = `Your document has been${
      taskMode !== 'Uploaded' ? ' generated and' : ''
    } uploaded to your file cabinet.`;
  }

  const redliningAlertHeader =
    (isSuggestionsShowing && 'Accept All Document Suggestions') ||
    (!!isRedliningAllowed &&
      redlining?.status === 1 &&
      'Approve Document for Signature') ||
    (!isRedliningAllowed && 'Allow Document Redlining/Edit Suggestions') ||
    (!!isRedliningAllowed && 'Disable Document Redlining/Edit Suggestions') ||
    `Are you sure want to accept this version of the document and approve for signature?`;

  const redliningAlertBody =
    (isSuggestionsShowing &&
      'This will approve all document suggestions and approve it for signature. You can individually review suggestions on Pandadoc.') ||
    (!!isRedliningAllowed &&
      redlining?.status === 1 &&
      'This will allow the document recipients to sign the updated document with the applied suggestions.') ||
    (!isRedliningAllowed &&
      'If allowed, any document recipient can make a document edit suggestion. When a suggestion is made, document recipients and reviewers will be notified through email.') ||
    (!!isRedliningAllowed &&
      'Stop document recipients and yourself from being able to suggest document edits. You can re-enable at any time.') ||
    '';
  const redliningAlertAction =
    (isSuggestionsShowing && 'Approve Suggestions') ||
    (!!isRedliningAllowed && redlining?.status === 1 && 'Approve for Signature') ||
    (!isRedliningAllowed && 'Enable') ||
    (!!isRedliningAllowed && 'Disable') ||
    'Approve Version';

  const redliningStatusLabel = isRedliningAllowed
    ? 'Redlining Allowed'
    : 'Redlining Disabled';

  const redliningStatusAction =
    (!!isRedliningAllowed && redlining?.status === 1 && 'Approve for Signature') ||
    (!!isRedliningAllowed && 'Disable Redlining') ||
    'Allow Redlining';

  useEffect(() => {
    if (!isFetching && isFetchingInit && isDocumentsFetched) {
      setFetchingInit(false);
    }
  }, [isDocumentsFetched, isFetching, isFetchingInit]);

  useEffect(() => {
    if (isLoaderStopped) {
      setIsRedliningAllowed(!!redlining_allowed);
    }
  }, [isLoaderStopped, redlining_allowed]);

  const handleDocumentRecipientLink = link => {
    navigator.clipboard.writeText(link);
    dispatch(setNotice('Link copied'));
  };

  const handleDealRoomRecipientLink = roomCode => {
    navigator.clipboard.writeText(`${brandMeta.web_base_url}/data-room/${roomCode}`);
    dispatch(setNotice('Deal Room Link copied'));
  };

  const handleViewSuggestions = () => {
    const existingRecipient = filteredRecipients.find(
      recipient => recipient.email.toLowerCase() === currentUser.email.toLowerCase(),
    );
    if (existingRecipient) {
      const win = window.open(existingRecipient.signature_link, '_blank');
      win.focus();
    } else {
      dispatch(
        setDocumentReviewer(
          pandaDocId,
          {
            email: currentUser.email,
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
          },
          transactionId,
        ),
      ).then(payload => {
        const newRecipient = payload.recipients.find(
          recipient => recipient.email.toLowerCase() === currentUser.email.toLowerCase(),
        );
        const win = window.open(newRecipient.signature_link, '_blank');
        win.focus();
      });
    }
  };

  const handleApproveSuggestions = () => {
    const isDocInRedlining = redlining?.status === 1;
    dispatch(approveDocumentSuggestions(pandaDocId, transactionId)).then(
      e => {
        if (isDocInRedlining) {
          dispatch(setNotice('Document approved for signature.'));
        } else {
          dispatch(setNotice('Document suggestions approved.'));
        }
        setIsSetRedliningOpen(false);
      },
      error => setIsSetRedliningOpen(false),
    );
  };

  const handleSetDocumentRedliningAlert = () => {
    dispatch(
      updateDocumentSuggestions(
        pandaDocId,
        { redline_enabled: isRedliningAllowed ? 0 : 1 },
        transactionId,
      ),
    ).then(
      payload => {
        dispatch(
          setNotice(`Document Redlining ${isRedliningAllowed ? 'Disabled' : 'Allowed'}`),
        );
        setIsRedliningAllowed(!isRedliningAllowed);
        setIsSetRedliningOpen(false);
      },
      err => setIsSetRedliningOpen(false),
    );
    // if (isSubmitting) {
    //   dispatch(setDocumentRedliningVersion(id, uuid, transactionId)).then(
    //     e => {
    //       dispatch(setNotice('Document version approved.'));
    //       setIsSetRedliningOpen(false);
    //       setSelectedRedlineIndex(0);
    //     },
    //     error => setIsSetRedliningOpen(false),
    //   );
    // } else {
    //   setIsSetRedliningOpen(false);
    // }
  };

  // const handleSetDocumentRedliningVersion = uuid => {
  //   if (!uuid) {
  //     dispatch(
  //       setNotice({
  //         type: 'error',
  //         message:
  //           'Please pick which Redlining Version you want to set your document as.',
  //       }),
  //     );
  //   } else {
  //     setUuid(uuid);
  //     setIsSetRedliningOpen(true);
  //   }
  // };

  const handleResendLinkEmail = (docId, email) => {
    const message = `Are you sure you want to resend signature link to ${email}?`;
    if (window.confirm(message)) {
      dispatch(resendLinkEmail(docId, email));
    }
  };

  return (
    <div className="workbenchDocStatus">
      {!isDocumentsShowing && <div className="workbenchDocStatus__section" />}
      {isDocumentsShowing && (
        <div className="workbenchDocStatus__section">
          {!isLoaderStopped && (
            <ProgressBar
              delay={loaderDelay}
              error={documentError}
              isLoadingFirstTime={isDocLoadingFirstTime}
              isFetchingInit={isFetchingInit}
              isStopped={isLoaderStopped}
              percentage={loaderPercentage}
              setDelay={setLoaderDelay}
              setError={handleSetDocumentError}
              setIsStopped={setIsLoaderStopped}
              setPercentage={setLoaderPercentage}
            />
          )}
          {isLoaderStopped && isEmpty(document) && documentError && (
            <h4 className="workbenchDocStatus__empty">
              <FontAwesomeIcon
                className="workbenchDocStatus__warning-icon"
                icon="exclamation-circle"
              />
              {documentError}
            </h4>
          )}
          {isDocStatusMessageShowing && (
            <h4 className="workbenchDocStatus__empty">{docStatusMessage}</h4>
          )}
          {loaderPercentage >= 100 && (
            <div className="workbenchDocStatus__card">
              <div className="workbenchDocStatus__body">
                {!isEmpty(document) && !isSuggestionsShowing && (
                  <>
                    <div className="workbenchDocStatus__status">
                      {status !== 'document.completed' &&
                        `Status - ${PandaDocStatusDict(status)}`}
                      {isDocOpen && !!redline_available && (
                        <>
                          {', '}
                          {redliningStatusLabel}
                          {(isRedliningAllowed || status === 'document.draft') && (
                            <span>*</span>
                          )}
                          {' ('}
                          <Button
                            size="xs"
                            buttonType="link"
                            onClick={() => setIsSetRedliningOpen(true)}
                          >
                            {/* {`${isRedliningAllowed ? 'Disable' : 'Allow'} Redlining`} */}
                            {redliningStatusAction}
                            <div className="workbenchDocStatus__redlineAction">
                              {!!isRedliningAllowed && (
                                <FontAwesomeIcon
                                  className="workbenchDocStatus__redlineDisable"
                                  icon="slash"
                                />
                              )}
                              <FontAwesomeIcon icon={['fal', 'file-pen']} />
                            </div>
                          </Button>
                          {')'}
                        </>
                      )}
                    </div>
                    {isDocOpen && (status === 'document.draft' || isRedliningAllowed) && (
                      <div className="workbenchDocStatus__sub">
                        <span>*</span>
                        any documents changes or suggestions will be checked for and
                        updated here periodically
                      </div>
                    )}
                  </>
                )}
                {isSuggestionsShowing && (
                  <div className="workbenchDocStatus__suggestions">
                    <h4>
                      Status - <strong>Pending Suggestions</strong>
                      <span className="workbenchDocStatus__warning">*</span>
                    </h4>
                    <div className="workbenchDocStatus__sub">
                      <span>*</span>
                      any documents changes or suggestions will be checked for and updated
                      here periodically
                    </div>
                    <div className="workbenchDocStatus__suggestionsHeader">
                      <FontAwesomeIcon
                        className="workbenchDocStatus__suggestionsAlert"
                        icon="exclamation-circle"
                      />
                      Active Suggestions
                      <FontAwesomeIcon
                        className="workbenchDocStatus__suggestionsInfo"
                        icon={['fal', 'question-circle']}
                      />
                      <Tooltip text="View and Approve document suggestions that have been made. View actual suggestions in Pandadoc." />
                    </div>
                    {suggestionsByUser.map(
                      ({ email, name, lastVisited, suggestions, replies }, ind) => {
                        return (
                          <div
                            className="workbenchDocStatus__suggestion"
                            key={`document-suggestion-${email}-${ind}`}
                          >
                            <div className="workbenchDocStatus__suggestionCol workbenchDocStatus__suggestionCol--first">
                              <div className="workbenchDocStatus__suggestionRow">
                                <h3>{name}</h3>
                                <div className="workbenchDocStatus__suggestionDivider" />
                                <h4>{email}</h4>
                              </div>
                              <h4>
                                <strong>{suggestions.length}</strong>
                                {' active suggestions'}
                                {replies.length > 0 && (
                                  <span className="workbenchDocStatus__comments">
                                    {'with comments'}
                                    <sup>
                                      <FontAwesomeIcon icon={['fal', 'info-circle']} />
                                    </sup>
                                    <Tooltip upwards={true}>
                                      <>
                                        <div className="workbenchDocStatus__commentSubheader">
                                          <p>Suggestion Comments</p>
                                          <div className="workbenchDocStatus__commentSeparator" />
                                        </div>
                                        {replies.map((reply, replyInd) => (
                                          <div
                                            className="workbenchDocStatus__comment"
                                            key={`suggestion-comment-${reply.uuid}`}
                                          >
                                            <p>{reply.text}</p>
                                            <div className="workbenchDocStatus__commentCol">
                                              <h4>
                                                {dayjs(reply.date_created).format('LLL')}
                                              </h4>
                                              <h4>
                                                {`${reply.user.first_name} ${reply.user.last_name} | ${reply.user.email}`}
                                              </h4>
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    </Tooltip>
                                  </span>
                                )}
                                {`, last made ${dayjs(lastVisited).fromNow()}`}
                              </h4>
                            </div>
                          </div>
                        );
                      },
                    )}
                    <div className="workbenchDocStatus__actions">
                      <Button
                        size="xs"
                        buttonType="secondary"
                        isFetching={isFetching}
                        isDisabled={isFetching}
                        onClick={handleViewSuggestions}
                      >
                        Review Suggestions
                      </Button>
                      <Button size="xs" onClick={() => setIsSetRedliningOpen(true)}>
                        Approve All
                      </Button>
                    </div>
                  </div>
                )}
                {/* {isRedlining && (
                  <Formik
                    initialValues={{
                      selectedRedline: redline_data.redlining_revisions[0].uuid,
                    }}
                    onSubmit={({ selectedRedline }) => {
                      handleSetDocumentRedliningVersion(selectedRedline);
                    }}
                  >
                    {formikProps => (
                      <Form>
                        <h4>
                          Status - <strong>{PandaDocStatusDict(status)}</strong>
                        </h4>
                        <p>
                          Select which version of the document you want to approve for
                          signing:
                        </p>
                        {redline_data.redlining_revisions.map(
                          (
                            {
                              create_date,
                              docx_name,
                              metadata: { comment },
                              number,
                              uuid,
                              user: { first_name, last_name, email },
                              pages,
                            },
                            ind,
                          ) => (
                            <div
                              className="workbenchDocStatus__redline-ver"
                              key={`document-recipient-${ind}`}
                            >
                              <InputStyledCheckbox
                                checked={uuid === formikProps.values.selectedRedline}
                                label={
                                  <>
                                    <label
                                      className="styled-checkbox__label"
                                      htmlFor={`${docx_name}-${number}`}
                                    >
                                      {`Version ${
                                        number + 1
                                      }(edited by ${first_name} ${last_name})`}
                                    </label>
                                    <DropdownMenu
                                      align="left"
                                      className="workbenchDocStatus__help"
                                      close={() => setRedlineHelp(-1)}
                                      closeOnInsideClick={false}
                                      isOpen={isRedlineHelpOpen === ind}
                                      toggle={
                                        <sup>
                                          <FontAwesomeIcon
                                            onClick={() =>
                                              setRedlineHelp(
                                                isRedlineHelpOpen === ind ? -1 : ind,
                                              )
                                            }
                                            icon={['fal', 'info-circle']}
                                          />
                                        </sup>
                                      }
                                      upwards={true}
                                    >
                                      <p>{`Version File Name: ${docx_name}`}</p>
                                      <p>{`Date Created: ${dayjs(create_date).format(
                                        'LLL',
                                      )}`}</p>
                                      <p>{`Edited by: ${first_name} ${last_name}`}</p>
                                      {pages.length > 0 && (
                                        <DocumentPreview pages={pages} />
                                      )}
                                      {comment && (
                                        <p>{`Additional Comments: ${comment}`}</p>
                                      )}
                                    </DropdownMenu>
                                  </>
                                }
                                name={`${docx_name}-${number}`}
                                onChange={() => {
                                  formikProps.setFieldValue('selectedRedline', uuid);
                                  setSelectedRedlineIndex(ind);
                                }}
                                type="radio"
                                value={uuid}
                              />
                            </div>
                          ),
                        )}
                        <Button size="xs" type="submit">
                          Approve Version
                        </Button>
                      </Form>
                    )}
                  </Formik>
                )} */}
                {isRecipientsShowing && (
                  <div className="workbenchDocStatus__section">
                    <h4 className="workbenchDocStatus__subheader">Recipients</h4>
                    {filteredRecipients.length === 0 && <p>No recipients found.</p>}
                    {filteredRecipients.length > 0 &&
                      filteredRecipients.map(recipient => (
                        <RecipientRow
                          currentUser={currentUser}
                          key={`document-recipient-${recipient.id}`}
                          handleDealRoomRecipientLink={handleDealRoomRecipientLink}
                          handleDocumentRecipientLink={handleDocumentRecipientLink}
                          handleResendLinkEmail={handleResendLinkEmail}
                          panda_document_id={panda_document_id}
                          recipient={recipient}
                          status={status}
                        />
                      ))}
                    <div className="workbenchDocStatus__actions">
                      {status !== 'document.completed' && (
                        <Button
                          buttonType="secondary"
                          size="xs"
                          onClick={() => setAddReviewerModal(true)}
                        >
                          {<FontAwesomeIcon icon={['fal', 'user-plus']} />}
                          Add Reviewer
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!!boxToken && !!box_file_id && !isTaskView && (
                <div className="workbenchDocStatus__preview">
                  <ContentPreview fileId={box_file_id} token={boxToken} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!!redline_available && (
        <AlertModal
          body={redliningAlertBody}
          header={redliningAlertHeader}
          isOpen={isSetRedliningOpen}
          isFetching={isFetching}
          onPrimaryClick={() => {
            if (!!isRedliningAllowed && redlining?.status === 1) {
              handleApproveSuggestions();
            } else {
              handleSetDocumentRedliningAlert();
            }
          }}
          onClose={() => setIsSetRedliningOpen(false)}
          primaryActionText={redliningAlertAction}
        />
      )}
      {isAddReviewerModalOpen && (
        <AddReviewerModal
          isOpen={isAddReviewerModalOpen}
          onClose={() => setAddReviewerModal(false)}
          pandaDocId={pandaDocId}
          transactionId={transactionId}
        />
      )}
      {/* <AlertModal
        header="Are you sure want to enable this document for editing?"
        isOpen={isSetRedliningOnOpen}
        onPrimaryClick={() => handleSetDocumentRedliningOnAlert(true)}
        onClose={() => handleSetDocumentRedliningOnAlert(false)}
        primaryActionText="Enable Editing"
      /> */}
    </div>
  );
};

const RecipientRow = ({
  currentUser,
  handleDealRoomRecipientLink,
  handleDocumentRecipientLink,
  handleResendLinkEmail,
  panda_document_id,
  recipient,
  status,
}) => {
  const {
    email,
    email_sent,
    first_name,
    last_name,
    room_access_code,
    signature_link,
    signed,
    signer,
  } = recipient;

  const [isActionsShowing, setIsActionsShowing] = useState(false);

  const recipientEmail = email.toLowerCase();
  const userEmail = (currentUser?.email || '').toLowerCase();
  const isDeclined = status === 'document.declined' || status === 'document.deleted';
  const isSigned = !isDeclined && !!signed && !!signer;
  const isNotSigned = !isDeclined && (!signed || !signer);
  const recipientStatus =
    (isSigned && 'Signed') ||
    (!signer && 'Reviewer') ||
    (!email_sent && 'Email not sent') ||
    (status === 'document.draft' && 'Not yet sent') ||
    'Not signed';

  return (
    <div className="workbenchDocStatus__recipient">
      <p className="workbenchDocStatus__recipientLabel">
        {!first_name && !last_name && 'Name not found: '}
        {(first_name || last_name) &&
          `${first_name || '(First name not given)'} ${
            last_name || '(Last name not given)'
          }: `}
        <span>{email}</span>
      </p>
      {isSigned && (
        <p>
          <FontAwesomeIcon icon="check" />
          {recipientStatus}
        </p>
      )}
      {isNotSigned && (
        <div className="workbenchDocStatus__recipientStatus">
          <p onClick={e => setIsActionsShowing(!isActionsShowing)}>
            <FontAwesomeIcon
              className={signer ? 'workbenchDocStatus__recipientWarning' : ''}
              icon={['fal', signer ? 'times' : 'check']}
            />
            <span>{recipientStatus}</span>
            <FontAwesomeIcon
              className="workbenchDocStatus__listRecipientChevron"
              icon="chevron-down"
            />
          </p>
          {status !== 'document.draft' && isActionsShowing && (
            <Dropdown isOpen={isActionsShowing} onClose={e => setIsActionsShowing(false)}>
              <Button
                buttonType="secondary"
                onClick={e => handleResendLinkEmail(panda_document_id, email)}
                isDisabled={!!signed && !!signer}
                size="sm"
              >
                {email_sent === 0 ? 'Send Email' : 'Resend Email'}
              </Button>
              {!!room_access_code && (
                <>
                  <Button
                    buttonType="secondary"
                    onClick={e => handleDealRoomRecipientLink(room_access_code)}
                    size="sm"
                  >
                    {`Copy ${
                      (userEmail === recipientEmail && 'your ') ||
                      (!!signed && !signer && 'reviewer ') ||
                      ''
                    }Room Link`}
                  </Button>
                  {userEmail === recipientEmail && (
                    <Button
                      buttonType="secondary"
                      to={`/data-room/${room_access_code}`}
                      rel="noopener noreferrer"
                      size="sm"
                      target="_blank"
                    >
                      View Deal Room
                    </Button>
                  )}
                </>
              )}
              {!!signature_link && (
                <>
                  <Button
                    buttonType="secondary"
                    onClick={e => handleDocumentRecipientLink(signature_link)}
                    size="sm"
                  >
                    {`Copy ${
                      (userEmail === recipientEmail && 'Your') ||
                      (!!signed && !signer && 'Reviewer') ||
                      ''
                    } Document Link`}
                  </Button>
                  {userEmail === recipientEmail && (
                    <Button
                      buttonType="secondary"
                      href={signature_link}
                      rel="noopener noreferrer"
                      size="sm"
                      target="_blank"
                    >
                      View Document 
                    </Button>
                  )}
                </>
              )}
            </Dropdown>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkbenchDocStatus;
