const getValidationErrors = errors => {
  return errors.reduce(
    (memo, error, index) => ({
      ...memo,
      [error.path.pop()]: error.message,
    }),
    {},
  );
};

export { getValidationErrors };
