import { combineReducers } from 'redux';

import { createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const dataRoomLinkReducer = () =>
  createReducer({})({
    [Types['SET_LINK']]: (state, { link }) => link,
  });

const accountRoomsReducer = createReducer([])({
  [Types['FETCH_ACCOUNT_ROOMS_SUCCESS']]: (state, { payload }) => [...payload],
});

const roomFeatures = createReducer({})({
  [Types['SET_FEATURES_FOR_ROOM']]: (state, { payload, key }) => ({
    ...state,
    [key]: [...payload],
  }),
});

const roomReducer = createReducer({})({
  [Types['SET_ROOM']]: (state, { payload, accessCode }) => ({
    ...state,
    [accessCode]: {
      ...payload,
      user_email: state[accessCode]?.user_email || payload?.user_email,
      identifier: state[accessCode]?.identifier || payload?.identifier,
      identifier_type: state[accessCode]?.identifier_type || payload?.identifier_type,
    },
  }),
  [Types['SET_ROOM_TOKEN']]: (state, { payload, accessCode }) => ({
    ...state,
    [accessCode]: {
      ...state[accessCode],
      ...payload,
    },
  }),
});

const userRoomsReducer = createReducer([])({
  [Types['SET_USER_ROOMS']]: (state, { payload }) => payload,
});

const roomCodesReducer = createReducer({})({
  [Types['SET_ROOM_CODES']]: (state, { payload, roomId }) => ({
    ...state,
    [roomId]: [...payload],
  }),
});

const roomDocumentsReducer = createReducer({})({
  [Types['SET_ROOM_DOCUMENTS']]: (state, { payload, roomId }) => ({
    ...state,
    [roomId]: [...payload],
  }),
});

const roomLinksReducer = createReducer({})({
  [Types['SET_ROOM_LINKS']]: (state, { payload, roomId }) => ({
    ...state,
    [roomId]: [...payload],
  }),
});

const roomAccessLogReducer = createReducer({})({
  [Types['SET_ROOM_ACCESS_LOG']]: (state, { payload, roomId }) => ({
    ...state,
    [roomId]: [...payload],
  }),
});

const roomHistoryReducer = createReducer({})({
  [Types['SET_ROOM_HISTORY']]: (state, { payload, roomId }) => ({
    ...state,
    [roomId]: [...payload],
  }),
});
const roomParticipantsReducer = createReducer({})({
  [Types['SET_ROOM_PARTICIPANTS']]: (state, { payload, roomId }) => ({
    ...state,
    [roomId]: [...payload],
  }),
});

export default combineReducers({
  accountRooms: accountRoomsReducer,
  dataRoomLink: dataRoomLinkReducer(),
  room: roomReducer,
  roomCodes: roomCodesReducer,
  roomDocuments: roomDocumentsReducer,
  roomLinks: roomLinksReducer,
  roomAccessLog: roomAccessLogReducer,
  roomHistory: roomHistoryReducer,
  roomParticipants: roomParticipantsReducer,
  roomFeatures,
  userRooms: userRoomsReducer,
  isFetching: fetchingReducer('data'),
});
