import React, { Component } from 'react';

import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import startCase from 'lodash-es/startCase';
import toLower from 'lodash-es/toLower';

import FeatureTypes from '../../../utils/FeatureTypes';
import { InputSelect } from '../../inputs';

import './Select.scss';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      options: [],
    };
  }

  componentDidMount() {
    let { options } = this.props;
    if (!Array.isArray(options) && !options[0].value) {
      options = options.split(',').map(resource => ({
        value: resource,
        label: resource,
      }));
    }
    this.setState({ options });
  }

  componentDidUpdate(prevProps, prevState) {
    let { changeOnBlur, inputValue, onChangeHit } = this.state;
    let { options } = this.props;
    if (prevProps.options && prevProps.options !== options) {
      if (!Array.isArray(options) && !options[0].value) {
        options = options.split(',').map(resource => ({
          value: resource,
          label: resource,
        }));
      }
      this.setState({ options });
    }
    if (changeOnBlur && onChangeHit) {
      this.setState({ changeOnBlur: false, onChangeHit: false, inputValue: '' });
    } else if (changeOnBlur) {
      const {
        field: { name, value },
        form: { setFieldValue },
        handleFormBlur,
        isCreatable,
        isLabel,
        multi,
        multiEntry,
        setLabel,
      } = this.props;
      const { options } = this.state;
      const isMulti = multi || multiEntry;
      let updatedVal = value;
      const optionsIndex = options.findIndex(
        e => toLower(e.value + '') === toLower(inputValue + ''),
      );
      if (optionsIndex !== -1) {
        updatedVal = options[optionsIndex].value;
      } else {
        updatedVal = startCase(inputValue);
      }
      if (isMulti) {
        if (value) {
          updatedVal = value + (inputValue ? `|${inputValue}` : '');
        } else {
          updatedVal = inputValue;
        }
      } else if (inputValue) {
        updatedVal = inputValue;
      } else {
        updatedVal = '';
      }
      let formattedVal = {
        target: { value: updatedVal },
      };
      if (!isCreatable && optionsIndex === -1) {
        this.setState({
          changeOnBlur: false,
          inputValue: '',
        });
        return;
      }
      setFieldValue(name, updatedVal);
      handleFormBlur(formattedVal);
      if (isLabel) {
        setLabel(inputValue);
      }
      this.setState({
        changeOnBlur: false,
        inputValue: '',
      });
    }
  }

  createOption = label => ({
    label,
    value: label,
  });

  render() {
    const {
      feature_type,
      field: { name, value: fieldValue },
      form: { errors, setFieldTouched, setFieldValue, status, touched },
      handleFormBlur,
      handleResourceName,
      isCompany,
      isCounselOptions,
      isDetailedOptions,
      isLabel,
      isNewResource,
      isValidatingOnBlur,
      isResource,
      multi,
      multiEntry,
      resourceTypeName,
      setLabel,
      isFixed,
      ...props
    } = this.props; // everything that we should pass goes through here classnames etc
    const isMulti = !!multiEntry || multi;
    const { options } = this.state;

    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    let error = apiError || (isTouched && get(errors, name, null));
    let updatedVal = fieldValue;
    if (
      updatedVal &&
      (typeof updatedVal === 'string' || typeof updatedVal === 'number')
    ) {
      const optionsIndex = options.findIndex(e => e.value == fieldValue); // eslint-disable-line eqeqeq
      if (typeof updatedVal === 'string' && fieldValue.indexOf('|') !== -1) {
        updatedVal = updatedVal.split('|').map(e => {
          if (isDetailedOptions) {
            const currentOptionsIndex = options.findIndex(option => option.value === e);
            return options[currentOptionsIndex];
          }
          return this.createOption(e);
        });
      } else if (optionsIndex !== -1) {
        updatedVal = options[optionsIndex];
      } else {
        updatedVal = this.createOption(fieldValue);
      }
    }
    if (props.isRequired && error && !apiError) {
      error = FeatureTypes[feature_type].validate(updatedVal);
    }
    return (
      <InputSelect
        error={error}
        isFixed={isFixed}
        isMulti={isMulti}
        name={name}
        onBlur={(e, action) => {
          setFieldTouched(name, true, isValidatingOnBlur);
        }}
        onChange={(value, { action }) => {
          if (action === 'clear' && isEmpty(value) && isEmpty(updatedVal)) {
            return;
          }
          let newValue = value;
          if (value && isMulti) {
            newValue = value.map(val => val.value).join('|');
          } else if (value && !isCounselOptions) {
            newValue = value.value;
          }
          if (!value) {
            newValue = '';
          }
          if (isMulti) {
            setFieldValue(name, newValue);
          } else {
            setFieldValue(name, value);
          }
          this.setState({ inputValue: newValue, onChangeHit: true });
          // if (action !== 'pop-value' && action !== 'remove-value') {
          //   this.setState({ onChangeHit: true });
          // }
          let formattedVal = {
            target: { value: newValue },
          };
          handleFormBlur(formattedVal);
          if (isLabel && value) {
            setLabel(newValue);
          }
        }}
        onInputChange={(inputValue, { action }) => {
          if (action === 'input-blur' && this.state.inputValue) {
            this.setState({ changeOnBlur: true });
          } else if (action !== 'menu-close' && inputValue) {
            this.setState({ inputValue });
          }
        }}
        value={updatedVal}
        {...props}
        options={options} // placed after props to override 'options' prop
      />
    );
  }
}

Select.defaultProps = {
  handleFormBlur: () => {},
  options: [],
  isCreatable: false,
  isValidatingOnBlur: true,
};

export default Select;
