import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { clearNotice, clearNotices, setNotice } from '../../../redux/modules/UI/actions';
import { getNotices } from '../../../redux/modules/UI/selectors';

import './NoticeToast.scss';
import Button from '../Button';

class NoticeToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
    };
    this.timeoutNotice = null;
    this.timeoutClear = null;
  }

  componentDidMount() {
    const {
      notice: { delay, type, errorObject },
    } = this.props;
    let noticeDelay =
      delay ||
      (errorObject && 20000) ||
      ((type === 'error' || type === 'warning') && 15000) ||
      7000;
    this.timeoutNotice = setTimeout(() => this.setState({ hide: true }), noticeDelay);
  }

  componentDidUpdate() {
    if (this.state.hide) {
      this.timeoutClear = setTimeout(
        () => this.props.clearNotice(this.props.notice),
        5000,
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutNotice);
    clearTimeout(this.timeoutClear);
  }

  render() {
    const { clearNotice, notice, setNotice } = this.props;
    const { hide } = this.state;
    const isDevError = notice.errorObject?.code === 'DEVELOPER_ERROR';
    const message = isDevError
      ? "We're experiencing issues related to your session, please refresh this window."
      : notice.message;
    return (
      <div
        className={classnames('notice', {
          hide,
          error: notice.type === 'error',
          warning: notice.type === 'warning',
        })}
      >
        {message}
        {isDevError && (
          <Button
            size="sm"
            buttonType="link"
            onClick={ev => {
              navigator.clipboard
                .writeText(JSON.stringify(notice.errorObject))
                .then(() => {
                  setNotice('Error info copied!');
                });
            }}
          >
            <FontAwesomeIcon icon={['fal', 'clipboard']} />
            Copy Error
          </Button>
        )}
        <FontAwesomeIcon
          className="notice__close"
          icon={['fal', 'times']}
          onClick={() => clearNotice(notice)}
        />
      </div>
    );
  }
}

class NoticeToasts extends Component {
  componentDidUpdate(prevProps) {
    let duplicateIndex = -1;
    this.props.notices.forEach((notice, noticeIndex) => {
      const existingNoticeIndex = this.props.notices.findIndex(
        el =>
          el.message === notice.message &&
          el.errorObject?._id === notice.errorObject?._id,
      );
      if (existingNoticeIndex !== -1 && existingNoticeIndex !== noticeIndex) {
        duplicateIndex = noticeIndex;
      }
    });
    if (duplicateIndex !== -1) {
      this.props.clearNotice(this.props.notices[duplicateIndex]);
    }
    if (prevProps.isCompanyTokenRefreshing && !this.props.isCompanyTokenRefreshing) {
      this.props.clearNotices();
    }
  }
  render() {
    const { clearNotice, notices, isCompanyTokenRefreshing, setNotice } = this.props;
    return (
      <div className="notices">
        {isCompanyTokenRefreshing && (
          <NoticeToast notice={{ message: 'refreshing your session token...' }} />
        )}
        {!isCompanyTokenRefreshing &&
          notices.map(
            (notice, ind) =>
              !!(notice?.message || '').trim() && (
                <NoticeToast
                  key={`notice--${ind}`}
                  notice={notice}
                  clearNotice={clearNotice}
                  setNotice={setNotice}
                />
              ),
          )}
      </div>
    );
  }
}

NoticeToasts.propTypes = {
  notices: PropTypes.array.isRequired,
};

NoticeToasts.defaultProps = {
  notices: [],
};

const mapStateToProps = ({ UI, Company: { isCompanyTokenRefreshing } }) => ({
  notices: getNotices(UI),
  isCompanyTokenRefreshing,
});

const mapDispatchToProps = dispatch => ({
  setNotice: notice => dispatch(setNotice(notice)),
  clearNotice: notice => dispatch(clearNotice(notice)),
  clearNotices: notice => dispatch(clearNotices(notice)),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(NoticeToasts);
