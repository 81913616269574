export function generateBem(bemPrefix) {
  const bem = elementName => {
    const apply = e =>
      `${bemPrefix}${(!!e && e[0] === '-' && e) || (!!e && '__' + e) || ''}`;
    let className = [];
    if (Array.isArray(elementName)) {
      for (let i of elementName) {
        if (typeof i === 'string') {
          className.push(apply(i));
        }
      }
      return className.join(' ');
    }
    return apply(elementName);
  };
  return bem;
}
