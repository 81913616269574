import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentPreview from 'box-ui-elements/es/elements/content-preview';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { capitalize, get, isArray, isEmpty, isObject, startCase } from 'lodash';
import React, { Fragment, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getBoxFileInfo } from '../../redux/modules/Cabinet/operations';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import { isFetchingWithHooks } from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import { Button, MyModal } from '../common';
import { MultiToggle } from '../inputs';

import './WorkbenchInfoModal.scss';
import { BrandMeta } from '../../redux/modules/UI/selectors';
// Modal.setAppElement('#root');

const WorkbenchInfoModal = ({
  addons,
  customDocsReviewInfo,
  dataRoomLinks = [],
  faqs,
  fees,
  handleTaskAction,
  isAssessment,
  isDataRoom,
  isEditing,
  isOutsideForm,
  isOverview,
  isProcessesShowing,
  isQuestionnaireSubmitted,
  isPortalView,
  isReviewersShowing,
  isTaskView,
  isWorkflowCounselShowing,
  isOpen,
  onClose,
  open,
  sharedForms,
  params: { companyId } = {},
  projectCounsel,
  reviewers,
  reviewInfo,
  roomId,
  rooms = [],
  tasks = [],
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const boxToken = useSelector(BoxToken);

  const [isFAQCollapsed, setIsFAQCollapsed] = useState(window.innerHeight < 1400);

  if (
    (isDataRoom && faqs.length === 0 && dataRoomLinks.length === 0 && !isEditing) ||
    (isOutsideForm && isEmpty(reviewInfo) && faqs.length === 0)
  ) {
    return null;
  }
  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={'Review Info'}
      className="workbenchInfoModal"
      overlayClassName="workbenchInfoModal__overlay"
    >
      <div className="workbenchInfoModal__header">
        <h1>Review Information</h1>
        <FontAwesomeIcon
          className="workbenchInfoModal__exit"
          onClick={onClose}
          icon="times"
        />
      </div>
      {!isDataRoom && (
        <div className={`workbenchInfoModal__review ${faqs.length > 0 && 'maxHeight'}`}>
          <div className="workbenchInfoModal__reviewInner">
            {sharedForms.length > 0 && <SharedForms forms={sharedForms} />}
            {isProcessesShowing && (
              <div className="workbenchInfoModal__tasks">
                {tasks.map((task, taskIndex) => (
                  <ReviewTask
                    key={`task-${task.label}`}
                    addons={addons}
                    fees={fees}
                    handleTaskAction={handleTaskAction}
                    open={open}
                    isAssessment={isAssessment}
                    task={task}
                  />
                ))}
              </div>
            )}
            {isOverview && (
              <h4 className="workbenchInfoModal__reviewInnerSub">
                Review initial assessment input. Will not reflect changes that occur after
                assessment submission.
              </h4>
            )}
            {isOverview && isEmpty(reviewInfo) && (
              <h4 className="workbenchInfoModal__reviewInnerSub">
                Nothing to review. Please select a task
              </h4>
            )}
            {isTaskView && !isQuestionnaireSubmitted && tasks.length === 0 && (
              <h4 className="workbenchInfoModal__reviewInnerSub">
                Review inputs here upon task form completion.
              </h4>
            )}
            {isOutsideForm && !reviewInfo.length === 0 && (
              <h4 className="workbenchInfoModal__reviewInnerSub">No inputs to Review.</h4>
            )}
            {isWorkflowCounselShowing && (
              <div className="workbenchInfoModal__reviewHeader">
                <h4>Workflow Role Info</h4>
                {projectCounsel.length === 0 && (
                  <div className="workbenchInfoModal__reviewRow">
                    <p>No Role assigned.</p>
                  </div>
                )}
                {projectCounsel.map((counsel, parentIndex) => (
                  <div
                    className="workbenchInfoModal__reviewRow"
                    key={`project-counsel-${parentIndex}`}
                  >
                    <h4>
                      {startCase(counsel.counsel_type)}:{' '}
                      <p>
                        {counsel.user &&
                          counsel.partner &&
                          `${counsel.user.full_name} from ${counsel.partner.name}`}
                        {!!counsel.user && !counsel.partner && counsel.user.full_name}
                        {!!counsel.partner && !counsel.user && counsel.partner.name}
                      </p>
                    </h4>
                  </div>
                ))}
              </div>
            )}
            {isReviewersShowing && (
              <div className="workbenchInfoModal__reviewHeader">
                <h4>Reviewers Info</h4>
                <div className="workbenchInfoModal__reviewRow">
                  <h4>
                    {reviewers.length === 0 && 'No reviewers assigned.'}
                    {reviewers.map(
                      ({ first_name = '', last_name = '', value }, reviewerIndex) => (
                        <span key={`project-reviewers-${reviewerIndex}`}>
                          {`${first_name} ${last_name}`}
                          {reviewerIndex !== reviewers.length - 1 && `, `}
                        </span>
                      ),
                    )}
                  </h4>
                </div>
              </div>
            )}
            {customDocsReviewInfo && customDocsReviewInfo.length > 0 && (
              <>
                <h1>Custom Document Info</h1>
                {customDocsReviewInfo.map((values, parentIndex) => (
                  <Fragment key={`reviewInfo-custom-docs-${parentIndex}`}>
                    {!isEmpty(values.fields) && (
                      <div className="workbenchInfoModal__reviewHeader">
                        <h4>Input Fields</h4>
                        {Object.entries(values.fields).map(
                          ([fieldKey, fieldVal], index) => (
                            <div
                              key={`custom-doc-review-field-${parentIndex}-${index}`}
                              className="workbenchInfoModal__reviewRow"
                            >
                              <h4>
                                {startCase(fieldKey)}: <p>{fieldVal}</p>
                              </h4>
                            </div>
                          ),
                        )}
                      </div>
                    )}
                    {values.recipients.map((recipient, index) => (
                      <div
                        className="workbenchInfoModal__reviewHeader"
                        key={`custom-doc-review-${parentIndex}-${index}`}
                      >
                        <h4>Recipient #{index + 1}</h4>
                        <div className="workbenchInfoModal__reviewRow">
                          {Object.entries(recipient).map(([key, value], ind) => (
                            <h4 key={`custom-doc-review-${key}-${ind}`}>
                              {startCase(key)}: <p>{value}</p>
                            </h4>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </>
            )}
            {(isQuestionnaireSubmitted || isOverview || isOutsideForm) && (
              <ReviewInfo
                boxToken={boxToken}
                companyId={companyId}
                dispatch={dispatch}
                isOutsideForm={isOutsideForm}
                push={push}
                reviewInfo={reviewInfo}
              />
            )}
          </div>
        </div>
      )}
      {faqs.length > 0 && (
        <div
          className={classNames('workbenchInfoModal__faq', {
            collapsed: isFAQCollapsed,
          })}
        >
          <div className="workbenchInfoModal__faqHeader">
            <button type="button" onClick={() => setIsFAQCollapsed(!isFAQCollapsed)}>
              <h1>
                <span>FAQ</span>
                <FontAwesomeIcon icon={isFAQCollapsed ? 'chevron-up' : 'chevron-down'} />
              </h1>
            </button>
          </div>
          {/* <div className="workbenchInfoModal__faqContent"> */}
          <div className="workbenchInfoModal__faqInner">
            {faqs.map((faq, ind) => (
              <Fragment key={`sidebarfaq-${ind}`}>
                <h2>{faq.header}</h2>
                <ReactMarkdown linkTarget="_blank">{faq.body}</ReactMarkdown>
              </Fragment>
            ))}
          </div>
          {/* </div> */}
        </div>
      )}
    </MyModal>
  );
};

const ReviewInfo = ({ boxToken, companyId, dispatch, push, reviewInfo, isOutsideForm }) =>
  Object.entries(reviewInfo).map(([resourceKey, resourceArr], index) => (
    <div
      className="workbenchInfoModal__reviewHeader"
      key={`review-${resourceKey}-${index}`}
    >
      {Array.isArray(resourceArr) &&
        resourceArr.map((resource, resourceInd) => (
          <Fragment key={`resource-array-${resourceInd}`}>
            {resource.resource && (
              <h4>{`${resource.resource} (${resource['Custom Label']})`}</h4>
            )}
            {resource.resource && (
              <div className="workbenchInfoModal__reviewRow">
                {Object.entries(resource).map(([key, value], ind) => {
                  if (
                    key !== 'resource' &&
                    key !== 'Company Name' &&
                    key !== 'Name' &&
                    key !== 'Custom Label'
                  ) {
                    let updatedVal = value;

                    if (isObject(value)) {
                      if (value.isDocument) {
                        if (!isOutsideForm) {
                          return (
                            <Fragment key={`review-info-${key}-${ind}`}>
                              <h4>{key}:</h4>
                              <div className="workbenchInfoModal__reviewPreview">
                                {boxToken && (
                                  <ContentPreview
                                    token={boxToken}
                                    fileId={value.fileId}
                                  />
                                )}
                              </div>
                              <button
                                className="workbenchInfoModal__reviewGotoCabinet"
                                onClick={() =>
                                  dispatch(getBoxFileInfo(value.fileId, push, companyId))
                                }
                              >
                                {value.label}
                              </button>
                            </Fragment>
                          );
                        } else {
                          return (
                            <Fragment key={`review-info-${key}-${ind}`}>
                              <h4>
                                {key}: <p>{value.value}</p>
                              </h4>
                            </Fragment>
                          );
                        }
                      }
                      if (value.isLink) {
                        return (
                          <Fragment key={`review-info-${key}-${ind}`}>
                            <h4>
                              {key}: <a href={value.path}>{value.label}</a>
                            </h4>
                          </Fragment>
                        );
                      }
                      if (isArray(value)) {
                        return (
                          <Fragment key={`review-info-link-${key}-${ind}`}>
                            <h4>{key}:</h4>
                            <div className="workbenchInfoModal__reviewRow">
                              {value.map((e, linkIndex) => (
                                <Fragment
                                  key={`review-info-link-${e.label}}-${linkIndex}`}
                                >
                                  <h4>
                                    Link #{linkIndex + 1}:
                                    <a
                                      href={e.url.includes('http') ? e.url : '//' + e.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {e.label}
                                    </a>
                                  </h4>
                                </Fragment>
                              ))}
                            </div>
                          </Fragment>
                        );
                      }
                      if (!Object.values(value).join('')) {
                        updatedVal = 'None Given';
                      } else {
                        return (
                          <Fragment key={`review-info-${key}-${ind}`}>
                            <h4>{key}:</h4>
                            <p>{value.line_one}</p>
                            {value.line_two.length > 0 && <p>{value.line_two}</p>}
                            <p>{`${startCase(value.city)}, ${value.state} ${
                              value.zip
                            }`}</p>
                            {value.country && <p>{value.country}</p>}
                          </Fragment>
                        );
                      }
                    } else if (value.indexOf('|') !== -1) {
                      updatedVal = updatedVal.split('|').join(', ');
                    }
                    return (
                      <h4 key={`review-info-${key}-${ind}`}>
                        {key}: <p>{updatedVal || 'Not Given'}</p>
                      </h4>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </Fragment>
        ))}
    </div>
  ));

const ReviewTask = ({
  addons,
  fees,
  handleTaskAction,
  isAssessment,
  isInitCollapsed,
  open,
  task: {
    bypassed,
    deliverables,
    id,
    label,
    mode,
    state,
    status_label,
    template: { transaction_actions = {}, type, type_label } = {},
  } = {},
}) => {
  const { Bypass, Custom, Generate, Upload } = transaction_actions;
  const { high_days, low_days } = get(deliverables, '[0].timeline[0]', {});
  const taskAddons = addons.filter(e => e.taskId === id);
  const taskFees = fees.filter(e => e.taskId === id);

  const isFetchingTransaction = useSelector(isFetchingWithHooks);

  const [isTaskCollapsed, setIsTaskCollapsed] = useState(isInitCollapsed);

  let hideRadios = false;
  let isActionsShowing = true;
  let timelineLabel = '';

  if (type === 'System' || mode === 'Upload') {
    timelineLabel = 'instant';
  } else if (low_days) {
    timelineLabel = `${low_days} - ${high_days} days`;
  }

  if (
    (!Bypass && !Generate && !Upload && !Custom) ||
    isAssessment ||
    (Object.keys(transaction_actions).length < 2 && !Bypass)
  ) {
    isActionsShowing = false;
  }

  if (!Generate && !Upload && !Custom) {
    hideRadios = true;
  }

  const toggleActions = Object.entries(transaction_actions)
    .sort((a, b) => {
      if (a[0] === 'Generate') {
        return -1;
      }
      if (
        (a[0] === 'Upload' && b[0] !== 'Generate') ||
        (a[0] === 'Skip' && b[0] === 'Custom')
      ) {
        return -1;
      }
      return 1;
    })
    .reduce((dict, [key, value]) => {
      let updatedOption = {
        label: key,
        mode: key,
        value,
        disabled: false,
      };
      if (hideRadios && (key === 'Generate' || key === 'Upload' || key === 'Custom')) {
        return dict;
      }
      if (hideRadios && key !== 'Bypass') {
        updatedOption.value = !!bypassed;
      }
      if (key === 'Bypass') {
        updatedOption.disabled = !value;
        updatedOption.label = 'Skip';
      }
      dict.push(updatedOption);
      return dict;
    }, []);

  return (
    <div className="workbenchInfoModal__task">
      <p
        className={`workbenchInfoModal__taskToggle${isTaskCollapsed ? ' collapsed' : ''}`}
        onClick={() => setIsTaskCollapsed(!isTaskCollapsed)}
      >
        <FontAwesomeIcon
          icon={['fal', isTaskCollapsed ? 'chevron-right' : 'chevron-down']}
        />
        {label}
      </p>
      {!isTaskCollapsed && (
        <div className="workbenchInfoModal__taskBody">
          <div className="workbenchInfoModal__taskRow">
            <div className="workbenchInfoModal__taskItem">
              <h4>Status</h4>
              <p>
                {capitalize(
                  (mode === 'Upload' && state) ||
                    (state === 'completed' && 'Completed') ||
                    status_label ||
                    state ||
                    'Not Given',
                )}
              </p>
            </div>
            <div className="workbenchInfoModal__taskItem">
              <h4>Type</h4>
              <p>{type_label || type}</p>
            </div>
            {taskAddons.length > 0 && (
              <div className="workbenchInfoModal__taskItem wide">
                <h4>Add-ons</h4>
                {taskAddons.map((addon, addonInd) => (
                  <div key={`product-addon-${addonInd}`}>
                    <p>
                      <strong>
                        {'$' + addon.amount}/{addon.period === 'year' ? 'Yr ' : 'Mo '}
                      </strong>
                      {`${addon.label} Add-on `}
                      {!open && <span className="paid">Paid</span>}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {taskFees.length > 0 && (
              <div className="workbenchInfoModal__taskItem wide">
                <h4>Fees</h4>
                {taskFees.map((fee, feeInd) => (
                  <div key={`product-fee-${feeInd}`}>
                    <p>
                      <strong>
                        {fee.is_annual ? fee.amount : '$' + fee.amount + ' '}
                      </strong>
                      {`${fee.label} Fee `}
                      {!open && <span className="paid">Paid</span>}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {!!timelineLabel && (
              <div className="workbenchInfoModal__taskItem">
                <h4>Timeline</h4>
                <p>{timelineLabel}</p>
              </div>
            )}
          </div>
          {isActionsShowing && (
            <MultiToggle
              isDisabled={isFetchingTransaction}
              name="info-sidebar-task-actions"
              mode={mode}
              options={toggleActions}
              onClick={selectedOption => {
                const { mode: selectedMode, value } = selectedOption;
                if (hideRadios && mode === 'Bypass' && selectedMode === mode) {
                  handleTaskAction('Enable', id);
                } else if (selectedMode !== mode && value) {
                  handleTaskAction(selectedMode, id);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const SharedForms = ({ forms }) => {
  return (
    <div className="workbenchInfoModal__openForms">
      <h2>Shared Forms ({forms.length})</h2>
      <div className="workbenchInfoModal__openForms">{forms.map(SharedForm)}</div>
    </div>
  );
};

function SharedForm({
  access_code,
  assigned_email,
  created_at,
  id,
  metadata: { send_email, finalize, type = 'outside_form' } = {},
  name,
  state,
}) {
  const dispatch = useDispatch();
  const brandMeta = useSelector(BrandMeta);
  let emailStatusLabel = 'Email Sent';
  if (type === 'data_request' && !send_email) {
    emailStatusLabel = 'Email Not Sent';
  }
  return (
    <div className="workbenchInfoModal__room" key={`outside-form-${id}`}>
      <div className="workbenchInfoModal__room-label">
        <h3>{name}</h3>
        <Button
          buttonType="icon"
          size="xs"
          tooltip="Copy link"
          tooltipAlign="right"
          onClick={() => {
            navigator.clipboard
              .writeText(`${brandMeta.web_base_url}/outside-form/${access_code}`)
              .then(
                () => dispatch(setNotice('Link Copied!')),
                err => console.error('Could not copy text: ', err),
              );
          }}
        >
          <FontAwesomeIcon icon={['fal', 'copy']} />
        </Button>
        <Button
          buttonType="icon"
          size="xs"
          tooltip="Go to shared form"
          tooltipAlign="right"
          to={`/outside-form/${access_code}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fal', 'external-link']} />
        </Button>
      </div>
      <div className="workbenchInfoModal__room-row">
        <div className="workbenchInfoModal__room-item">
          <h4>Date Created</h4>
          <p>{dayjs(created_at).format('ddd, MMM YYYY hh:mm A')}</p>
        </div>
        <div className="workbenchInfoModal__room-item">
          <h4>Connected Email</h4>
          <p>{assigned_email || 'None connected'}</p>
        </div>
      </div>
      <div className="workbenchInfoModal__room-row">
        <div className="workbenchInfoModal__room-item">
          <h4>Status</h4>
          <div className="workbenchInfoModal__room-tags">
            <div
              className={`workbenchInfoModal__room-tag ${
                state === 'new' ? 'workbenchInfoModal__room-tag--low' : ''
              }`}
            >
              {state === 'new' ? 'Open' : 'Completed'}
            </div>
            <div
              className={`workbenchInfoModal__room-tag ${
                type === 'outside_form' || send_email
                  ? 'workbenchInfoModal__room-tag--low'
                  : ''
              }`}
            >
              {emailStatusLabel}
            </div>
            {finalize && (
              <div className="workbenchInfoModal__room-tag workbenchInfoModal__room-tag--low">
                Finalizes task on submission
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkbenchInfoModal;
