import React from 'react';

import './Calendly.scss';

// import PropTypes from 'prop-types';

class Calendly extends React.Component {
  constructor(props) {
    super(props);
    this.script = document.createElement('script');
  }

  componentDidMount() {
    const head = document.querySelector('head');
    this.script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js',
    );
    this.script.setAttribute('type', 'text/javascript');
    head.appendChild(this.script);
  }

  componentWillUnmount() {
    this.script.parentNode.removeChild(this.script);
  }

  render() {
    const { REACT_APP_LOG_ENV: hostEnv } = process.env;
    return (
      <div
        className="calendly-inline-widget"
        data-url={`${this.props.url}?utm_campaign=${this.props.taskId}&utm_source=${hostEnv}`}
      />
    );
  }
}

export default Calendly;
