import React from 'react';

import get from 'lodash-es/get';
import omit from 'lodash-es/omit';

import { InputTextarea } from '../../inputs';

const Textarea = ({
  field, //: { name, value, onChange, onBlur },
  form: { errors, status, touched, values },
  handleFormBlur = () => {},
  isLabel,
  setLabel,
  meta,
  ...props // everything that we should pass goes through here classnames etc
}) => {
  const { name } = field;
  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  const error = apiError || (isTouched && get(errors, name, null));
  return (
    <InputTextarea
      onBlur={e => {
        const {
          target: { value },
        } = e;
        if (isLabel) {
          setLabel(value);
        }
        field.onBlur(e);
        handleFormBlur(e);
      }}
      className="form__textarea"
      error={error}
      {...omit(field, 'onBlur')}
      {...props}
    />
  );
};

export default Textarea;
