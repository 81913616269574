import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setCompany } from '../../redux/modules/Company/operations';
import { Companies } from '../../redux/modules/Company/selectors';
import {
  CompanyNameWithHooks,
  getAccountId,
} from '../../redux/modules/Formation/selectors';

import './CompanyDropdown.scss';
import handlePath from '../../utils/handlePath';
import classNames from 'classnames';
import { Button, Dropdown, Tooltip } from '../common';
import { ReactComponent as BuildingsSVG } from '../../assets/images/buildings.svg';
import UseWindowSize from '../../utils/UseWindowSize';
import { getAuthAccount } from '../../redux/modules/User/selectors';

const CompanyDropdown = ({ isCollapsed, isTeam, isWorkbench }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { goBack, push, location: historyLocation } = history;
  const { companyId = '0' } = useParams();
  const { width } = UseWindowSize();

  const companies = useSelector(Companies);
  const companyName = useSelector(CompanyNameWithHooks);
  const currentAccountId = useSelector(getAccountId);
  const accountInfo = useSelector(getAuthAccount);
  const { impersonator_id } = accountInfo;
  const isTooltipShowing =
    (width < 876 && companyName.length > 16) ||
    (width < 1280 && companyName.length > 32) ||
    (width < 1400 && companyName.length > 37) ||
    companyName.length > 46;

  const [isOpen, setOpen] = useState(false);

  const userRoutes = [
    '/company-select',
    '/teams',
    '/dashboard',
    '/lp-portal',
    '/user-settings',
  ];
  const includedUserPathHistoryIndex = userRoutes.findIndex(e =>
    (historyLocation?.pathname || '').includes(e),
  );
  let accountId = Number(companyId);
  if (!accountId) {
    accountId = currentAccountId || accountInfo.id || companyId;
  }

  return (
    <div
      className={classNames('companyDropdown__wrapper', {
        workbench: isWorkbench,
      })}
      onClick={() => {
        if ((!companyName && isTeam) || !!impersonator_id) {
          return;
        } else if (includedUserPathHistoryIndex !== -1) {
          return push(handlePath('/home', accountId));
        } else if (isTeam) {
          return goBack();
        }
        setOpen(!isOpen);
      }}
    >
      <BuildingsSVG className="companyDropdown__icon" />
      <h4 className="companyDropdown__text">{companyName || 'none selected'}</h4>
      {!impersonator_id && !isTeam && (
        <FontAwesomeIcon
          className="companyDropdown__chevron"
          icon={isTeam ? 'chevron-right' : 'chevron-down'}
        />
      )}
      {!!impersonator_id && (
        <Tooltip
          text="You only have access to this workspace"
          position="bottom"
          align="center"
        />
      )}
      {isTooltipShowing && (
        <Tooltip text={companyName} position="bottom" align="center" />
      )}
      {!isTeam && isOpen && (
        <Dropdown
          className={`companyDropdown__menu ${isCollapsed && 'collapsed'}`}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
        >
          <Button
            className="companyDropdown__menuItem first"
            buttonType="link"
            to={handlePath(
              { pathname: '/company-select', state: { isToggled: true } },
              companyId,
            )}
          >
            View Your Workspaces
          </Button>
          <Button
            buttonType="secondary"
            onClick={e => {
              if (isTeam) {
                return push(
                  handlePath(
                    { pathname: '/home', state: { isToggled: true } },
                    companyId,
                  ),
                );
              }
              return setOpen(false);
            }}
            className="companyDropdown__menuItem second"
          >
            {companyName}(current)
          </Button>
          {companies.map(
            (company, index) =>
              company.id !== currentAccountId && (
                <Button
                  buttonType="secondary"
                  key={`company-${index}-${company.id}`}
                  className="companyDropdown__menuItem"
                  onClick={e => dispatch(setCompany(company.id, '/home'))}
                >
                  {company.name}
                </Button>
              ),
          )}
        </Dropdown>
      )}
    </div>
  );
};

export default CompanyDropdown;
