import React from 'react';
import { NavLink } from 'react-router-dom';
import useMultiHash from '../../utils/useMultiHash';
import PropTypes from 'prop-types';
import { Button } from '../common';
import './SavviNavLink.scss';

const SavviNavLink = ({ item, parentPathname, passedState, isRoom }) => {
  const { generatePath, hashContains, removeHash, addHash } = useMultiHash(
    parentPathname,
    passedState,
    isRoom,
  );
  return (
    <NavLink
      onClick={e => {
        if (hashContains(item.path.hash)) {
          removeHash(item);
          return;
        }
        addHash(item);
      }}
      className="navlink__tag"
      activeClassName="selected"
      to={generatePath(item)}
      isActive={() => hashContains(item.path.hash)}
    >
      {item.label}
      {item.isItemLengthShowing && (
        <>
          {' ('}
          <span className="navlink__tagLength">{item.itemLength}</span>
          {')'}
        </>
      )}
    </NavLink>
  );
};

SavviNavLink.propTypes = {
  item: PropTypes.shape({
    path: PropTypes.object,
    label: PropTypes.string,
    isItemLengthShowing: PropTypes.bool,
    itemLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  parentPathname: PropTypes.string.isRequired,
  passedState: PropTypes.object,
  isRoom: PropTypes.bool,
};
SavviNavLink.defaultProps = {
  item: {},
  parentPathname: '/home',
};

const SavviNavLinkBar = ({
  label,
  navLinkItems,
  parentPathname,
  passedState,
  isRoom,
}) => {
  const { clearHash, noHash } = useMultiHash(parentPathname, passedState, isRoom);
  return (
    <div className="navlink__tags">
      {navLinkItems.length > 0 && <p>{label}:</p>}
      {navLinkItems.map(
        item =>
          !item.isHiding && (
            <SavviNavLink
              key={`navlink-tag-${item.id}`}
              item={item}
              parentPathname={parentPathname}
              passedState={passedState}
              isRoom={isRoom}
            />
          ),
      )}
      {navLinkItems.length > 0 && !noHash() && (
        <Button
          buttonType="link"
          isWarning
          className="navlink__filterClear"
          onClick={e => clearHash()}
        >
          Clear
        </Button>
      )}
    </div>
  );
};

SavviNavLinkBar.propTypes = {
  label: PropTypes.string.isRequired,
  navLinkItems: PropTypes.array.isRequired,
  parentPathname: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  passedState: PropTypes.object,
};

SavviNavLinkBar.defaultProps = {
  label: 'filters',
  navLinkItems: [],
  parentPathname: '/home',
};

export { SavviNavLink, SavviNavLinkBar };
