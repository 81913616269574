import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { userIsNotAuthenticated } from '../../utils/Auth';
import ForgotPassword from '../../views/ForgotPassword';
import Login from '../../views/Login';
import ResetPassword from '../../views/ResetPassword';
import Registration from '../Registration';

import './Basic.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IsAuthenticated } from '../../redux/modules/User/selectors';
import SavviLoading from '../../components/SavviLoading';
import { refreshCompanyToken } from '../../redux/modules/Company/operations';
import { setNotice } from '../../redux/modules/UI/actions';
import { onLogout } from '../../redux/modules/User/operations';

const RemoteLogin = () => {
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(IsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const query = new URLSearchParams(search);
      let userId = query.get('user_id');
      let accountId = query.get('account_id');
      dispatch(refreshCompanyToken(accountId, userId, true)).then(
        e => {
          push(`/c/${accountId}/home`);
          window.location.reload();
        },
        error => {
          if (error.code !== 'UNAUTHORIZED') {
            if (error.code === 'NOT_SUDO') {
              dispatch(setNotice({ type: 'error', message: error.message }));
            }
            dispatch(
              onLogout({
                pathname: '/login',
                state: { redirectPathname: pathname + search },
              }),
            );
          }
        },
      );
    } else {
      replace({
        pathname: '/login',
        state: { redirectPathname: pathname + search },
      });
    }
  }, [dispatch, isAuthenticated, pathname, push, replace, search]);

  return (
    <div className="component__loader">
      <SavviLoading />
    </div>
  );
};

const Basic = props => {
  return (
    <div className="basic__container">
      <div className="basic__main">
        <Switch>
          <Route path="/login" component={userIsNotAuthenticated(Login)} />
          <Route path="/remote-login">
            <RemoteLogin />
          </Route>
          <Route
            path="/forgot-password"
            component={userIsNotAuthenticated(ForgotPassword)}
          />
          <Route
            exact
            path="/reset-password/:code"
            component={userIsNotAuthenticated(ResetPassword)}
          />
          <Route path="/register" component={Registration} />
          <Redirect from="/" to="/login" />
          {/* <Route render={() => <p>catch all</p>} /> */}
        </Switch>
      </div>
    </div>
  );
};

export default Basic;
