import PropTypes from 'prop-types';

// const
const initData = PropTypes.shape({
  optionOne: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }),
  optionTwo: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }),
  renderSublabel: PropTypes.func,
});

const deliverable = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  type: PropTypes.string,
  Status: PropTypes.string,
});

const moduleTask = PropTypes.shape({
  description: PropTypes.string,
  deliverables: PropTypes.arrayOf(deliverable),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  isLocked: PropTypes.bool,
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  name: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

// const subTask = PropTypes.shape({
//   id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// });

const myModule = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentComplete: PropTypes.string,
  currentTask: PropTypes.string,
  name: PropTypes.string,
  tasks: PropTypes.arrayOf(moduleTask),
});

const MyTypes = { deliverable, initData, myModule, moduleTask };

export { MyTypes as default };
