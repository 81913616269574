import React from 'react';

import ReactMarkdown from 'react-markdown';
import './Comment.scss';

const Comment = ({ label, markdown }) => {
  return (
    <div className="comment__container">
      {!!(label || '').trim() && <label className="comment__label">{label}</label>}
      <ReactMarkdown linkTarget="_blank">{markdown}</ReactMarkdown>
    </div>
  );
};

export default Comment;
