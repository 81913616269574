import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import SavviLoading from '../SavviLoading';
import './PageLoader.scss';

const PageLoader = ({ showLongLoadingText, customText }) => {
  return (
    <div className="pageLoader__wrapper">
      {/* <FontAwesomeIcon icon="spinner" spin /> */}
      <SavviLoading />
      {showLongLoadingText && (
        <h2>
          <FontAwesomeIcon icon="exclamation-circle" />
          This page may take 10-15 seconds to load.
        </h2>
      )}
      {customText && <h2>{customText}</h2>}
    </div>
  );
};

export default PageLoader;
