import React, { Component } from 'react';

// import { Field } from 'formik';
import isEqual from 'lodash-es/isEqual';

import './TemplateLink.scss';
import { InputHelp } from '../../inputs';

// import get from 'lodash-es/get';

// import { Calendly } from '../../common';
// import { Select } from '../';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class TemplateLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.field.value, prevProps.field.value)) {
      this.setState({ isUpdating: true });
      setTimeout(() => this.setState({ isUpdating: false }), 500);
    }
  }
  render() {
    const { filename, path, questionHelp } = this.props;
    return (
      <div className="workbench-form__group template-link">
        <label className="template-link__label">
          {`${filename}:`}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
        <a href={path}>Download Template</a>
      </div>
    );
  }
}

export default TemplateLink;
