import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const MyModal = props => {
  return <Modal {...props} />;
};

MyModal.propTypes = {
  onAfterClose: PropTypes.func.isRequired,
};

MyModal.defaultProps = {
  onAfterClose: ev => {
    document.body.classList.remove('ReactModal__Body--open');
  },
};

export default MyModal;
