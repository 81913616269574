import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getUser,
  IsAccountSettingsShowing,
  isFetchingProfile,
} from '../../redux/modules/Profile/selectors';
import {
  getAuthUser,
  getAuthAccount,
  IsAuthenticated,
  IsPartner,
} from '../../redux/modules/User/selectors';
import { onLogout } from '../../redux/modules/User/operations';
import UseWindowSize from '../../utils/UseWindowSize';
import SavviLoading from '../SavviLoading';

import './OrgButton.scss';
import { Button } from '../common';
import handlePath from '../../utils/handlePath';
import { useLocation, useParams } from 'react-router';
import { DropdownAction } from '../common/Dropdown/Dropdown';
import { Companies } from '../../redux/modules/Company/selectors';

const OrgButton = ({ accountInfo, darkText, isDataRoom, limitedUserEmail }) => {
  const { pathname } = useLocation();
  const { subscriptions = [], owner = {} } = accountInfo || {};
  const { company, name, impersonator_id } = useSelector(getAuthAccount);
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { width } = UseWindowSize();
  const isAuthenticated = useSelector(IsAuthenticated);
  const user = useSelector(getUser);
  const AuthUser = useSelector(getAuthUser);
  const isFetching = useSelector(isFetchingProfile);
  const isAccountSettingsShowing = useSelector(IsAccountSettingsShowing);
  const isPartner = useSelector(IsPartner);
  const companies = useSelector(Companies);
  let updatedSubscriptions = useMemo(() => {
    let updatedSubs = [];

    subscriptions.forEach(sub => {
      if (sub.length === 0) {
        return;
      }
      let splitSub = sub.split(' ');
      const companyIdInd = splitSub.findIndex(str => !isNaN(str));
      if (companyIdInd !== -1) {
        const companyInd = companies.findIndex(
          company => company.id === parseInt(splitSub[companyIdInd]),
        );
        if (companyInd === -1) {
          // since we can't find an account that matches the account id, we remove the id from the subscription name.
          splitSub.pop();
        } else {
          splitSub[companyIdInd] = `(${companies[companyInd].account_name})`;
        }
      }
      updatedSubs.push(splitSub.join(' '));
    });
    if (updatedSubs.length === 0) {
      updatedSubs = ['Free Basic'];
    }
    return updatedSubs.join(', ');
  }, [subscriptions, companies]);

  const [isInitFetchingTimeout, setIsInitFetchingTimeout] = useState(false);

  useEffect(() => {
    const initLoadingTimeout = setTimeout(() => setIsInitFetchingTimeout(true), 1000);
    return () => {
      clearTimeout(initLoadingTimeout);
    };
  }, []);

  const signedInEmail = user.email || AuthUser.email;
  const menuLabel = limitedUserEmail || signedInEmail || 'menu';
  const isNameShowing = !isFetching || isInitFetchingTimeout;
  const isExclamationShowing =
    !isAuthenticated ||
    (!!limitedUserEmail && signedInEmail !== limitedUserEmail) ||
    !!impersonator_id;
  let possessionLabel = name;
  if (owner?.email) {
    possessionLabel += ` (${owner.email})`;
  }

  return (
    <DropdownAction
      className="orgButton"
      Toggle={toggleProps => (
        <Button
          buttonType="link"
          size="sm"
          className="orgButton__toggle"
          {...toggleProps}
        >
          <FontAwesomeIcon
            className="orgButton__toggleIcon"
            icon={['fal', 'user-circle']}
          />
          {isExclamationShowing && (
            <FontAwesomeIcon
              className="orgButton__exclamation"
              icon="exclamation-circle"
            />
          )}
          {!isNameShowing && <SavviLoading customClass="orgButton__loading" size="sm" />}
          {isNameShowing && width > 678 && (
            <>
              {!!limitedUserEmail && <p>Viewing as</p>}
              <h4>{menuLabel}</h4>
            </>
          )}
          <FontAwesomeIcon
            className="orgButton__toggleChevron"
            icon={width <= 678 ? 'ellipsis-v' : 'chevron-down'}
          />
        </Button>
      )}
    >
      {!isAuthenticated && isDataRoom && (
        <>
          <div className="orgButton__section">
            <h4>
              <FontAwesomeIcon
                className="orgButton__exclamationCircle"
                icon="exclamation-circle"
              />
              Sign in to Savvi to more easily access & interact with your Documents &
              connected Deal Rooms.
            </h4>
          </div>
          <div className="orgButton__separator" />
          <Button
            buttonType="link"
            to={{
              pathname: '/login',
              state: { redirectPathname: pathname, passedEmail: limitedUserEmail },
            }}
            size="sm"
          >
            Sign In
          </Button>
        </>
      )}
      <div className="orgButton__section">
        {!!limitedUserEmail && signedInEmail && signedInEmail !== limitedUserEmail && (
          <h4>
            Signed in as <em>{signedInEmail}</em>
          </h4>
        )}
        {!!impersonator_id && (
          <h4>
            <FontAwesomeIcon
              className="orgButton__exclamationCircle"
              icon="exclamation-circle"
            />
            You are currently accessing this account as <em>{possessionLabel}</em>
          </h4>
        )}
        {user.first_name && user.last_name && (
          <h2>{`${user.first_name || ''} ${user.last_name || ''}`}</h2>
        )}
        {(user.email || AuthUser.email) && <h4>{user.email || AuthUser.email || ''}</h4>}
      </div>
      {isAuthenticated && companyId !== '0' && !isDataRoom && (
        <>
          <div className="orgButton__section">
            <h2>Subscriptions:</h2>
            <h4>{updatedSubscriptions}</h4>
          </div>
          {width < 576 && (
            <div className="orgButton__section">
              <h2>Current Workspace:</h2>
              <h4>{company}</h4>
              <Button
                buttonType="link"
                size="sm"
                to={handlePath(
                  { pathname: '/teams', state: { isToggled: true } },
                  companyId,
                )}
              >
                Select Workspace
              </Button>
            </div>
          )}
        </>
      )}
      {isAuthenticated && <div className="orgButton__separator" />}
      {isAuthenticated && companyId !== '0' && !isDataRoom && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath(
            { pathname: '/company-select', state: { isToggled: true } },
            companyId,
          )}
        >
          View Workspaces
        </Button>
      )}
      {isAuthenticated && isPartner && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath({ pathname: '/teams', state: { isToggled: true } }, companyId)}
        >
          View Teams Settings
        </Button>
      )}
      {isAuthenticated && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath(
            { pathname: '/dashboard', state: { isToggled: true } },
            companyId,
          )}
        >
          View User Dashboard
        </Button>
      )}
      {isAuthenticated && companyId !== '0' && !isDataRoom && (
        <Button
          className="dd-item-ignore"
          buttonType="link"
          size="sm"
          to={handlePath('/settings/subscriptions', companyId)}
        >
          View Subscriptions
        </Button>
      )}
      {isAuthenticated && isAccountSettingsShowing && !isDataRoom && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath('/settings/profile', companyId)}
        >
          View Workspace Settings
        </Button>
      )}
      {isAuthenticated && !isDataRoom && (
        <Button
          buttonType="link"
          size="sm"
          to={handlePath('/user-settings/profile', companyId)}
        >
          View User Profile
        </Button>
      )}
      {isAuthenticated && (
        <Button
          buttonType="link"
          size="sm"
          onClick={e => {
            e.preventDefault();
            dispatch(onLogout());
          }}
        >
          Logout
        </Button>
      )}
      {!isAuthenticated && !isDataRoom && (
        <Button
          buttonType="link"
          size="sm"
          to="/login"
          onClick={e => {
            e.preventDefault();
            dispatch(onLogout());
          }}
        >
          Login
        </Button>
      )}
    </DropdownAction>
  );
};

OrgButton.defaultProps = {
  darkText: true,
};

export default OrgButton;
