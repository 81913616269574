import dayjs from 'dayjs';
import { get, isEmpty, reduce } from 'lodash-es';
import moment from 'moment';
import { createSelector, defaultMemoize } from 'reselect';

/**
 * @return {object} a hash of modules indexed by their unique id.
 */
export const isFetching = defaultMemoize(state => get(state, 'isFetching', false));

export const isFetchingProfile = defaultMemoize(state =>
  get(state, 'Profile.isFetching', false),
);

export const getCurrentUser = defaultMemoize(state => get(state, 'Profile.user', {}));

export const getEmailsToVerify = defaultMemoize(state => {
  const emails = get(state, 'Profile.emails', []);
  return reduce(
    emails,
    (dict, e) => {
      if (e.email_change && !dayjs().isAfter(dayjs(e.expires_at))) {
        dict.push(e);
      }
      return dict;
    },
    [],
  );
});

export const IsFileCabinetShowing = defaultMemoize(state =>
  get(state, 'Profile.user.filecabinet_access', false),
);

export const IsAccountSettingsShowing = defaultMemoize(
  state => get(state, 'Profile.user.role_id', 1) < 100,
);

export const IsAccountAdmin = defaultMemoize(
  state => get(state, 'Profile.user.is_admin', 1) < 100,
);

export const getManagingAttorney = defaultMemoize(state =>
  get(state, 'counsels.managing_attorney', {}),
);

export const getAttorneys = defaultMemoize((state, moduleId) =>
  get(state, 'counsels.attorneys', []),
);

export const getMySavviPartners = defaultMemoize((state, moduleId) =>
  get(state, 'savviPartners.myPartners', []),
);

export const SavviPartnerGroups = defaultMemoize((state, moduleId) =>
  get(state, 'Profile.savviPartners.partnerGroups', []),
);

export const NewFeatureChanges = defaultMemoize(state =>
  get(state, 'Profile.user.feature_changes', []),
);

export const AllFeatureChanges = defaultMemoize(state =>
  get(state, 'Profile.featureChanges', []),
);

export const FeatureChanges = createSelector(
  NewFeatureChanges,
  AllFeatureChanges,
  (newFeatures, allFeatures) =>
    allFeatures
      .reduce((dict, e, index) => {
        if (newFeatures.findIndex(newFeature => newFeature.id === e.id) === -1) {
          e = {
            ...e,
            isOld: true,
          };
        }
        dict.push(e);
        return dict;
      }, [])
      .sort((a, b) => moment(b.change_date).diff(moment(a.change_date))),
);

export const PartnerTeams = defaultMemoize((state, moduleId) =>
  get(state, 'Profile.teams', []),
);

export const getAttorneysForReviewers = createSelector(getAttorneys, attorneys =>
  attorneys.map(e => ({
    ...e,
    label: `${e.first_name} ${e.last_name}`,
    value: `${e.first_name} ${e.last_name}`,
  })),
);

export const getLegalTeam = createSelector(
  getManagingAttorney,
  getAttorneys,
  (managingAttorney, attorneys) => {
    let updatedArr = isEmpty(managingAttorney) ? [] : [managingAttorney];
    attorneys.reduce((dict, e) => {
      if (e.id === managingAttorney.id) {
        dict[0] = {
          ...dict[0],
          specializations: [
            {
              is_primary: 1,
              title: 'Managing Attorney',
            },
            ...e.specializations,
          ],
        };
      } else {
        dict.push(e);
      }
      return dict;
    }, updatedArr);
    return updatedArr;
  },
);

export const getUser = defaultMemoize(state => get(state, 'Profile.user', {}));
