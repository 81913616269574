import { combineReducers } from 'redux';

import { get } from 'lodash-es';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const questionnaireReducer = createReducer({})({
  [Types['SET_TRANSACTION_QUESTIONNAIRE']]: (state, { payload }) => {
    return {
      ...state,
      byIds: {
        ...state.byIds,
        [payload.id]: payload,
      },
    };
  },
  [Types['CREATE_RESOURCE_FEATURE']]: (state, { payload }) => {
    const { feature, resourceTypeId, transactionId } = payload;
    let updatedResources = state.byIds[transactionId].resources;
    const resourceIndex = updatedResources[resourceTypeId].findIndex(
      e => e.id === feature.resource_id,
    );
    let resourceFeatures = get(
      updatedResources,
      `[${resourceTypeId}][${resourceIndex}].features`,
      [],
    );
    updatedResources[resourceTypeId][resourceIndex].features = [
      ...resourceFeatures,
      { ...feature },
    ];
    return {
      ...state,
      byIds: {
        ...state.byIds,
        [transactionId]: {
          ...state.byIds[transactionId],
          resources: {
            ...updatedResources,
          },
        },
      },
    };
  },
  [Types['UPDATE_RESOURCE']]: (state, { payload }) => {
    const { value, feature_type_id, resource_id, resourceTypeId, transactionId } =
      payload;
    const { byIds } = state;
    let resourceIndex = -1;
    let featureIndex = -1;
    let updatedResources = byIds[transactionId].resources;
    resourceIndex = updatedResources[resourceTypeId].findIndex(e => e.id === resource_id);
    if (resourceIndex !== -1) {
      featureIndex = updatedResources[resourceTypeId][resourceIndex].features.findIndex(
        e => e.feature_type_id === feature_type_id,
      );
    }
    if (featureIndex !== -1) {
      updatedResources[resourceTypeId][resourceIndex].features[featureIndex].value =
        value;
    }

    return {
      ...state,
      byIds: {
        ...state.byIds,
        [transactionId]: {
          ...state.byIds[transactionId],
          resources: {
            ...updatedResources,
          },
        },
      },
    };
  },
});

const documentReducer = createReducer({})({
  [Types['SET_DOCUMENTS_BY_TRANSACTION']]: (state, { payload, transactionId }) => ({
    ...state,
    byTransaction: {
      ...state.byModule,
      [transactionId]: [...payload],
    },
  }),
  [Types['SET_DOCUMENTS']]: (state, { payload, taskId }) => {
    if (taskId) {
      return {
        ...state,
        byTaskId: {
          ...state.byTaskId,
          [taskId]: [...payload],
        },
      };
    }
    return {
      ...state,
      byArr: [...payload],
    };
  },
  [Types['SET_DOCUMENT_ERROR']]: (state, { error }) => ({
    ...state,
    error,
  }),
  [Types['CLEAR_DOCUMENT_ERROR']]: state => ({
    ...state,
    error: '',
  }),
});

const documentRecipientsLinks = createReducer({})({
  [Types['SET_DOCUMENT_RECIPIENTS_LINKS']]: (state, { links, documentId }) => ({
    ...state,
    [documentId]: {
      ...state[documentId],
      ...links,
    },
  }),
});

const myTemplatesReducer = createReducer([])({
  [Types['SET_MY_TEMPLATES']]: (state, { payload }) => [...payload],
});

const customDocumentsReducer = createReducer({ byIds: {} })({
  [Types['SET_TRANSACTION_QUESTIONNAIRE']]: (state, { payload }) => ({
    ...state,
    byIds: {
      ...state.byIds,
      [payload.id]: [...payload.custom_documents],
    },
  }),
  [Types['ADD_CUSTOM_DOCUMENT']]: (state, { payload, transactionId }) => ({
    ...state,
    byIds: {
      ...state.byIds,
      [transactionId]: [...state.byIds[transactionId], { ...payload }],
    },
  }),
  [Types['SET_CUSTOM_DOCUMENT']]: (state, { payload, customDocIndex, transactionId }) => {
    let newCustomDocuments = get(state, `byIds[${transactionId}]`, [payload]).slice();
    newCustomDocuments[customDocIndex] = {
      ...newCustomDocuments[customDocIndex],
      custom_document: {
        ...payload,
      },
    };

    return {
      ...state,
      byIds: {
        ...state.byIds,
        [transactionId]: [...newCustomDocuments],
      },
    };
  },
});

const myTransactionsReducer = createReducer([])({
  [Types['SET_MY_TRANSACTIONS']]: (state, { payload }) => [...payload],
});

const transactionReducer = createReducer({})({
  [Types['SET_TRANSACTION']]: (state, { payload }) => {
    return {
      ...state,
      byIds: {
        ...state.byIds,
        [payload.id]: { ...payload },
      },
    };
  },
  [Types['SET_TRANSACTIONS_BY_MODULE']]: (state, { payload, moduleId }) => ({
    ...state,
    byModule: {
      ...state.byModule,
      [moduleId]: [...payload],
    },
  }),
  [Types['SET_TRANSACTION_PREVIEW']]: (state, { payload, transactionId }) => {
    return {
      ...state,
      byIds: {
        ...state.byIds,
        [transactionId]: {
          ...state.byIds[transactionId],
          preview: [...payload],
        },
      },
    };
  },
});

const isFetching = fetchingReducer('transaction');

export default combineReducers({
  isFetching,
  apiErrors: apiErrorsReducer('transaction'),
  customDocuments: customDocumentsReducer,
  documents: documentReducer,
  documentRecipientsLinks,
  myTemplates: myTemplatesReducer,
  myTransactions: myTransactionsReducer,
  transactions: transactionReducer,
  questionnaires: questionnaireReducer,
});

/*
 *
 *
 *
 ************ UTILITY FUNCTIONS *************
 *
 *
 *
 */

// const assignInitResources = (dict, e, ind) => {
//   const feature_type = e.feature_type.feature_type;
//   let updatedVal =
//     e.question_default ||
//     (e.multi_entry_feature && []) ||
//     FeatureTypes[feature_type].value;

//   dict.push({
//     ...e,
//     // feature: e.feature_type.name,
//     // is_fixed: e.feature_type.is_fixed,
//     // isCreatable: !!e.feature_type.is_creatable,
//     // label: e.question_label,
//     multi_entry_index: 0,
//     // name: e.feature_type_id + '', // incorrect
//     // options: e.feature_type.calendly_options || e.feature_type.options,
//     // placeholder: e.question_placeholder,
//     // pre_req_value: e.required_question_value,
//     // pre_req_question: e.required_question_id,
//     resource: null,
//     resource_id: null,
//     resource_is_unique: 0,
//     // resource_type: e.resource_variable_name,
//     // sublabel: e.question_comment,
//     value: updatedVal,
//     initValue: updatedVal,
//   });
//   return dict;
//   // unused?
//   // [] feature_type_id
//   // [] name?

//   // DIFF ASSIGNS
//   // [] isCreatable -> is_creatable
//   // [] label -> question_label
//   // [] name -> id
//   // [] feature -> name
//   // [] placeholder -> question_placeholder
//   // [] pre_req_value -> required_question_value
//   // [] pre_req_question -> required_question_id
//   // [] resource_type -> resource_variable_name
//   // [] sublabel -> question_comment
// };
