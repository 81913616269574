import { useLocation, useHistory, useParams } from 'react-router-dom';
import handlePath from './handlePath';

export default function useMultiHash(parentPathname = null, passedState = {}, isRoom) {
  const location = useLocation();
  const hash = v => {
    let h = location.hash;
    if (v) {
      h = v;
    }
    return h.replace(/(%20| |#)/g, '');
  };
  const explode = v => {
    let string = hash(v);
    return string
      .split(',')
      .filter(String)
      .map(v => hash(v));
  };
  const createHash = list => '#' + list.filter(v => v.length).join(',');
  const { push } = useHistory();
  const { companyId } = useParams();
  let updatedHandlePath = handlePath;
  if (isRoom) {
    updatedHandlePath = pathname => pathname;
  }

  const generatePath = item => {
    if (hash().includes(hash(item.path.hash))) {
      return updatedHandlePath(
        {
          pathname: parentPathname,
          hash: createHash(
            explode().filter(v => {
              return hash(v) !== hash(item.path.hash);
            }),
          ),
          state: passedState,
        },
        companyId,
      );
    }
    return updatedHandlePath(
      {
        pathname: parentPathname,
        hash: createHash([...explode(), hash(item.path.hash)]),
        state: passedState,
      },
      companyId,
    );
  };
  const removeHash = item => {
    const updatedHash = createHash(
      explode().filter(name => name !== hash(item.path.hash)),
    );
    push(
      updatedHandlePath(
        {
          pathname: parentPathname,
          hash: updatedHash,
          state: passedState,
        },
        companyId,
      ),
    );
  };
  const addHash = item => {
    push(
      updatedHandlePath(
        {
          pathname: parentPathname,
          hash: createHash([...explode(), hash(item.path.hash)]),
          state: passedState,
        },
        companyId,
      ),
    );
  };
  const clearHash = () => {
    push(
      updatedHandlePath(
        {
          pathname: parentPathname,
          hash: '',
          state: passedState,
        },
        companyId,
      ),
    );
  };

  const hashContains = h => {
    const parts = explode();
    return parts.indexOf(hash(h)) !== -1;
  };

  const noHash = () => hash().length === 0;

  return { noHash, generatePath, hashContains, removeHash, addHash, clearHash };
}
