export default {
  1: 'https://learn.savvi.legal/knowledge/entity-formation-1',
  2: 'https://learn.savvi.legal/knowledge/onboard-employees',
  3: 'https://learn.savvi.legal/knowledge/issue-equity',
  4: 'https://learn.savvi.legal/knowledge/stock-option-plan-1',
  5: 'https://learn.savvi.legal/knowledge/llc-to-delaware-c-corp-conversion',
  8: 'https://learn.savvi.legal/knowledge/document-review-by-attorney',
  10: 'https://learn.savvi.legal/knowledge/safe-fundraising',
  11: 'https://learn.savvi.legal/send-an-nda',
  12: 'https://learn.savvi.legal/knowledge/trademark-filing',
  13: 'https://learn.savvi.legal/knowledge/request-a-patent',
  14: 'https://learn.savvi.legal/knowledge/send-an-nda',
  15: 'https://learn.savvi.legal/knowledge/service-agreement-template',
  16: 'https://learn.savvi.legal/knowledge/privacy-policy-template',
  17: 'https://learn.savvi.legal/knowledge/employee-offboarding',
  18: 'https://learn.savvi.legal/knowledge/issue-equity-llc',
  19: 'https://learn.savvi.legal/knowledge/annual-report-1',
  20: 'https://learn.savvi.legal/request-legal-counsel',
  21: 'https://learn.savvi.legal/knowledge/request-template-access',
  23: 'https://learn.savvi.legal/knowledge/operating-state-registration',
  24: 'https://learn.savvi.legal/knowledge/distressed-company-consultation',
  25: 'https://learn.savvi.legal/knowledge/ppp-sba-loan-assistance',
  27: 'https://learn.savvi.legal/knowledge/onboard-an-independent-contractor',
  28: 'https://learn.savvi.legal/knowledge/onboard-an-advisor',
  32: 'https://learn.savvi.legal/knowledge/equity-repurchase-agreement',
  33: 'https://learn.savvi.legal/execute-safes',
  37: 'https://learn.savvi.legal/knowledge/switch-to-a-savvi-partnered-registered-agent',
  38: 'https://learn.savvi.legal/knowledge/issue-stock-options',
  40: 'https://learn.savvi.legal/knowledge/new-document-drafting-request',
  42: 'https://learn.savvi.legal/knowledge/tax-and-accounting-assistance',
  44: 'https://learn.savvi.legal/knowledge/company-name-change',
  46: 'https://learn.savvi.legal/knowledge/legal-audit',
  47: 'https://learn.savvi.legal/carta-account-setup-request',
  50: 'https://learn.savvi.legal/create',
  51: 'https://learn.savvi.legal/knowledge/custom-request',
  63: 'https://learn.savvi.legal/due-diligence-checklist',
  64: 'https://learn.savvi.legal/upload-registered',
  68: 'https://learn.savvi.legal/director-appointment',
  69: 'https://learn.savvi.legal/submit-a-contract-for-ai-review',
  70: 'https://learn.savvi.legal/annual-subscription',
  71: 'https://learn.savvi.legal/file-cabinet-and-reports-onboarding',
  77: 'https://learn.savvi.legal/prepare-preferred-stock-financing',
  80: 'https://learn.savvi.legal/officer-appointment',
  81: 'https://learn.savvi.legal/director-or-officer-resignation',
  84: 'https://learn.savvi.legal/equity-incentive-plan-amendment',
  86: 'https://learn.savvi.legal/statement-of-work',
  87: 'https://learn.savvi.legal/director/officer-indemnification',
  88: 'https://learn.savvi.legal/exercise-options-workflow',
  94: 'https://learn.savvi.legal/founder-onboarding',
  95: 'https://learn.savvi.legal/ip-assignment',
  99: 'https://learn.savvi.legal/due-diligence-checklist',
  96: 'https://learn.savvi.legal/prepare-preferred-stock-financing',
  97: 'https://learn.savvi.legal/letter-of-intent',
  102: 'https://learn.savvi.legal/simple-promissory-note',
};
