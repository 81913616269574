import { combineReducers } from 'redux';

import { createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const accountProjectReducer = createReducer({})({
  [Types['SET_ACCOUNT_PROJECT']]: (state, { payload }) => {
    return {
      ...state,
      byIds: {
        ...state.byIds,
        [payload.id]: {
          ...payload,
        },
      },
    };
  },
  [Types['SET_ACCOUNT_PROJECTS']]: (state, { payload }) => ({
    ...state,
    byOrder: payload,
  }),
});
const projectReducer = createReducer({})({
  [Types['SET_PROJECTS']]: (state, { payload }) => ({
    ...state,
    byOrder: payload,
  }),
  // [Types['SET_PROJECTS']]: (state, { payload }) => {
  //   const byId = payload.reduce((dict, e) => {
  //     dict[e.id] = e;
  //     return dict;
  //   }, {});
  //   return { ...byId };
  // },
});

const isFetching = fetchingReducer('project');

const errors = createReducer({})({
  [Types.RESET_ERRORS]: () => ({}),
  [Types.SET_ERRORS]: errors => errors,
});

export default combineReducers({
  isFetching,
  errors,
  projects: projectReducer,
  accountProjects: accountProjectReducer,
});
