import { combineReducers } from 'redux';

import Billing from '../modules/Billing';
import Cabinet from '../modules/Cabinet';
import Company from '../modules/Company';
import Document from '../modules/Document';
import Data from '../modules/Data';
import Formation from '../modules/Formation';
import Profile from '../modules/Profile';
import Project from '../modules/Project';
import Todos from '../modules/Todos';
import Transaction from '../modules/Transaction';
import UI from '../modules/UI';
import User from '../modules/User';
import { connectRouter } from 'connected-react-router';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    Auth: User,
    Billing,
    Cabinet,
    Company,
    Document,
    Data,
    Formation,
    Profile,
    Project,
    Todos,
    Transaction,
    UI,
  });

export default createRootReducer;
