import { RSAA } from 'redux-api-middleware';

import get from 'lodash-es/get';

import { requestHeaders } from '../../utils/api';
import { setNotice } from '../UI/actions';
import { setBoxToken } from './actions';
import * as types from './types';
import * as actions from './actions';
import { boxTokenIsValid, userDocumentBoxTokenIsValid } from './utils';
import handlePath from '../../../utils/handlePath';
import { generateErrorNotice } from '../../../utils/generateErrorNotice';

export const getOutsideBoxToken = accessCode => {
  const { BOX_TOKEN_REQUEST, BOX_TOKEN_SUCCESS, BOX_TOKEN_FAILED } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/unlisted/storage/token${
          accessCode ? `?access_code=${accessCode}` : ''
        }`,
        method: 'GET',
        headers: requestHeaders(),
        types: [BOX_TOKEN_REQUEST, BOX_TOKEN_SUCCESS, BOX_TOKEN_FAILED],
        bailout: boxTokenIsValid,
      },
    });
    if (boxTokenIsValid(getState())) {
      return await 'Using Previous Token';
    }
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An error has occurred. If this persists after refresh please contact support.',
        ),
      }),
    );
  };
};

const getBoxToken = accountId => {
  const { BOX_TOKEN_REQUEST, BOX_TOKEN_SUCCESS, BOX_TOKEN_FAILED } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/storage/token',
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [BOX_TOKEN_REQUEST, BOX_TOKEN_SUCCESS, BOX_TOKEN_FAILED],
        bailout: boxTokenIsValid,
      },
    });
    if (boxTokenIsValid(getState())) {
      return await 'Using Previous Token';
    }
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    dispatch(setNotice(generateErrorNotice(actionResponse.payload)));
  };
};

export const getUserDocumentBoxToken = documentId => {
  const {
    GET_USER_DOCUMENT_BOX_TOKEN_REQUEST: REQUEST,
    GET_USER_DOCUMENT_BOX_TOKEN_SUCCESS: SUCCESS,
    GET_USER_DOCUMENT_BOX_TOKEN_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const userDocumentToken = userDocumentBoxTokenIsValid(getState(), documentId);
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/document/${documentId}/token`,
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        bailout: () => !!userDocumentToken,
      },
    });
    if (userDocumentToken) {
      return await userDocumentToken;
    }
    if (!actionResponse.error) {
      dispatch(actions.setUserDocumentBoxToken(actionResponse.payload, documentId));
      return await actionResponse.payload.token;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An error has occurred. If this persists after refresh please contact support.',
        ),
      }),
    );
  };
};

export const fetchTemporaryUserReportsToken = (
  boxFileId,
  jwt,
  shouldPassFile = false,
) => {
  const {
    GET_TEMPORARY_USER_REPORTS_TOKEN_REQUEST: REQUEST,
    GET_TEMPORARY_USER_REPORTS_TOKEN_SUCCESS: SUCCESS,
    GET_TEMPORARY_USER_REPORTS_TOKEN_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/report/file/${boxFileId}/jwt/${jwt}`,
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (shouldPassFile) {
        return await actionResponse.payload;
      }
      return await actionResponse.payload.accessToken;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An error has occurred. If this persists after refresh please contact support.',
        ),
      }),
    );
  };
};

export const downloadSelectedBoxFiles = (name, items) => {
  const {
    DOWNLOAD_SELECTED_BOX_FILES_REQUEST,
    DOWNLOAD_SELECTED_BOX_FILES_SUCCESS,
    DOWNLOAD_SELECTED_BOX_FILES_FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/storage/download-zip',
        method: 'POST',
        headers: requestHeaders(true),
        types: [
          DOWNLOAD_SELECTED_BOX_FILES_REQUEST,
          DOWNLOAD_SELECTED_BOX_FILES_SUCCESS,
          DOWNLOAD_SELECTED_BOX_FILES_FAILED,
        ],
        body: JSON.stringify({ name, items }),
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An error has occurred. If this persists after refresh please contact support.',
        ),
      }),
    );
  };
};

export const getBoxFileForDataRoom = (boxFileId, accessCode, queryUrl = '') => {
  const {
    GET_BOX_FILE_INFO_REQUEST,
    GET_BOX_FILE_INFO_SUCCESS,
    GET_BOX_FILE_INFO_FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/guest/rooms/${accessCode}/file/${boxFileId}/token${queryUrl}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [
          GET_BOX_FILE_INFO_REQUEST,
          GET_BOX_FILE_INFO_SUCCESS,
          GET_BOX_FILE_INFO_FAILED,
        ],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      throw err;
    }
  };
};

const getBoxFileInfo = (fileId, push, companyId) => {
  const {
    GET_BOX_FILE_INFO_REQUEST,
    GET_BOX_FILE_INFO_SUCCESS,
    GET_BOX_FILE_INFO_FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/box/file/${fileId}/info`,
        method: 'GET',
        headers: requestHeaders(true, companyId),
        types: [
          GET_BOX_FILE_INFO_REQUEST,
          GET_BOX_FILE_INFO_SUCCESS,
          GET_BOX_FILE_INFO_FAILED,
        ],
      },
    });
    if (!actionResponse.error) {
      if (push) {
        push(
          handlePath(
            `/cabinet/${get(actionResponse, 'payload.parent.id', -1)}/${fileId}`,
            companyId,
          ),
        );
      }
      return await actionResponse.payload;
    } else {
      if (push) {
        await push(handlePath(`/cabinet/-1/${fileId}`, companyId));
      }
      return await actionResponse.error;
    }
  };
};

export const getBoxTokenFolderId = (path, isOutsideForm, access_code, accountId) => {
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${isOutsideForm ? 'unlisted/' : ''}account/storage/folder?path=${
          path || '/'
        }${access_code ? `&access_code=${access_code}` : ''}`,
        method: 'GET',
        headers: requestHeaders(!isOutsideForm, accountId),
        types: [
          types.GET_BOX_TOKEN_FOLDER_ID_REQUEST,
          types.GET_BOX_TOKEN_FOLDER_ID_SUCCESS,
          types.GET_BOX_TOKEN_FOLDER_ID_FAILED,
        ],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An Error has occured'),
        }),
      );
      return await actionResponse.payload;
    }
  };
};

export const getTempBoxToken = () => {
  return async (dispatch, getState) => {
    const {
      Cabinet: {
        tempBox: { isInitialized },
      },
    } = getState();
    if (isInitialized) {
      return await {};
    } else {
      dispatch(actions.setTempTokenFetchInitialized());
    }
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/storage/token/temp_upload',
        method: 'GET',
        headers: requestHeaders(true),
        types: [
          types.GET_TEMPORARY_BOX_TOKEN_REQUEST,
          types.GET_TEMPORARY_BOX_TOKEN_SUCCESS,
          types.GET_TEMPORARY_BOX_TOKEN_FAILED,
        ],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An Error has occurred'),
        }),
      );
      return await actionResponse.payload;
    }
  };
};

export const getBoxFile = (fileId, isOutsideForm, account_id) => {
  // const config = setupRequestConfig();
  const { UPLOAD_BOX_FILE_REQUEST, UPLOAD_BOX_FILE_SUCCESS, UPLOAD_BOX_FILE_FAILED } =
    types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${isOutsideForm ? 'unlisted/' : ''}account/box/file/${fileId}`,
        method: 'GET',
        headers: requestHeaders(true, account_id),
        types: [UPLOAD_BOX_FILE_REQUEST, UPLOAD_BOX_FILE_SUCCESS, UPLOAD_BOX_FILE_FAILED],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'An Error has occured'),
        }),
      );
      throw actionResponse.payload;
    }
  };
};

export const uploadBoxFile = (fileId, body, isOutsideForm, account_id) => {
  // const config = setupRequestConfig();
  const { UPLOAD_BOX_FILE_REQUEST, UPLOAD_BOX_FILE_SUCCESS, UPLOAD_BOX_FILE_FAILED } =
    types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${isOutsideForm ? 'unlisted/' : ''}account/box/file/${fileId}`,
        method: 'PUT',
        headers: requestHeaders(true, account_id),
        types: [UPLOAD_BOX_FILE_REQUEST, UPLOAD_BOX_FILE_SUCCESS, UPLOAD_BOX_FILE_FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      if (actionResponse.payload.status !== 500) {
        dispatch(
          setNotice({
            type: 'error',
            message: get(actionResponse, 'payload.message', 'An Error has occured'),
          }),
        );
      }
      throw actionResponse.payload;
    }
  };
};

export const getParsedFolderPath = (transactionId, token) => {
  // const config = setupRequestConfig();
  const {
    GET_PARSED_FOLDER_PATH_REQUEST,
    GET_PARSED_FOLDER_PATH_SUCCESS,
    GET_PARSED_FOLDER_PATH_FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/parse/${encodeURIComponent(
          encodeURIComponent(token),
        )}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [
          GET_PARSED_FOLDER_PATH_REQUEST,
          GET_PARSED_FOLDER_PATH_SUCCESS,
          GET_PARSED_FOLDER_PATH_FAILED,
        ],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    } else {
      // if (actionResponse.payload.status !== 500) {
      //   dispatch(
      //     setNotice({
      //       type: 'error',
      //       message: get(actionResponse, 'payload.message', 'An Error has occured'),
      //     }),
      //   );
      // }
      throw actionResponse.payload;
    }
  };
};

export { setBoxToken, getBoxFileInfo, getBoxToken };
