import React from 'react';

import isEmpty from 'lodash-es/isEmpty';
import './InputFeedback.scss';

// import classnames from 'classnames';

const InputFeedback = ({ error }) =>
  error ? (
    <div className="inputFeedback">{isEmpty(error) ? 'Field Required' : error}</div>
  ) : null;

export default InputFeedback;
