const boxTokenIsValid = ({ Cabinet }) => {
  const { accessToken = false, accessTokenTTLMS, acquiredAtMS } = Cabinet.box;

  if (!accessToken) {
    return false;
  }

  const expiresAt = new Date(accessTokenTTLMS + acquiredAtMS);
  const now = new Date();
  return expiresAt > now;
};

const userDocumentBoxTokenIsValid = ({ Cabinet }, documentId) => {
  const userDocumentToken = Cabinet.userDocumentsBoxTokens[documentId] || {};
  const { accessToken = false, accessTokenTTLMS, acquiredAtMS } = userDocumentToken;

  if (!accessToken) {
    return false;
  }

  const expiresAt = new Date(accessTokenTTLMS + acquiredAtMS);
  const now = new Date();
  if (expiresAt > now) {
    return userDocumentToken;
  }
  return false;
};

export { boxTokenIsValid, userDocumentBoxTokenIsValid };
