import { get, includes, reduce } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';
import {
  paperOSPlansDict,
  savviPlansDict,
} from '../../../components/PlansModal/PlansModal';

/**
 * @return {object} a hash of modules indexed by their unique id.
 */
export const isFetching = defaultMemoize(state => get(state, 'isFetching', false));

export const isFetchingWithHooks = defaultMemoize(state =>
  get(state, 'Formation.isFetching', false),
);

export const getAccountId = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.id', null),
);

export const AccountSubs = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.subscriptions', []).filter(sub => !!sub),
);

export const UserLimit = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.user_limit', 2),
);

export const AccountSubsJoined = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.subscriptions', []),
);

export const CurrentSubscriptionIndex = createSelector(AccountSubsJoined, subs => {
  const plansDict = window.location.href.includes('savvi')
    ? savviPlansDict
    : paperOSPlansDict;
  let reverseIndex = plansDict
    .slice()
    .reverse()
    .findIndex(e => includes(subs, e.slug));
  let subDictCount = plansDict.length - 1;
  if (reverseIndex === -1) {
    return 0;
  }
  return reverseIndex >= 0 ? subDictCount - reverseIndex : reverseIndex;
});

export const getAccountUsers = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.users', []),
);

export const getResourcesByTypeId = defaultMemoize((state, resourceTypeId) =>
  get(state, `Formation.resources.${resourceTypeId}`, []),
);

export const getResourcesByTypeIdForSelect = createSelector(
  getResourcesByTypeId,
  resources =>
    resources.reduce((dict, e) => {
      dict.push({
        ...e,
        label: e.name,
        value: e.name,
        resourceId: e.id,
      });
      return dict;
    }, []),
);

export const AccountInfo = defaultMemoize(state =>
  get(state, 'Formation.accountInfo', {}),
);

/**
 * @return {object} a hash of modules indexed by their unique id.
 */
export const getFetchError = defaultMemoize(state => get(state, 'fetchError', null));

export const getAccountSubscriptions = defaultMemoize(state =>
  get(state, 'accountInfo.subscriptions', []),
);

export const getSubtaskBySlug = defaultMemoize((state, taskId, subtaskSlug) => {
  const subtasks = get(state.tasks.byIds, `${taskId}.subtasks`, []);
  return subtasks.find(({ meta: { slug } }) => slug === subtaskSlug);
});

export const IsRedliningAllowed = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.subscriptions', []).includes('advanced'),
);

/**
 * @return {array} get a module for ModuleView.
 */
export const getTasksForHomeView = defaultMemoize(state =>
  reduce(
    get(state, 'homeTasks.byIds', []),
    (dict, e) => {
      dict.push(e);
      return dict;
    },
    [],
  ),
);

export const FlatFeatures = defaultMemoize((state, slug) =>
  get(state, `Formation.features.${slug}`, {}),
);

export const getFlatFeaturesForOptions = createSelector(FlatFeatures, individuals =>
  reduce(
    Object.entries(individuals),
    (dict, e) => {
      const updatedFeatures = reduce(
        e[1].features,
        (features, feature, key) => {
          features = {
            ...features,
            [key]: get(feature, '[0].value', ''),
          };
          return features;
        },
        {},
      );
      dict.push({
        ...updatedFeatures,
        label: e[0],
        resource_id: e[1].resource_id,
        value: e[0],
      });
      return dict;
    },
    [],
  ),
);

/**
 * @return {array} get a module for ModuleView.
 */
export const getTasksForModuleView = defaultMemoize((state, moduleId) =>
  get(state.modules, `${moduleId}.tasks`, []),
);

/**
 * @return {object} get a Features Object.
 */
const getFeaturesObject = defaultMemoize(state => get(state, 'features', {}));

export const getFeaturesForHomeView = createSelector(getFeaturesObject, homeObj =>
  reduce(
    homeObj,
    (dict, e, key) => {
      dict.push({
        ...e.features,
      });
      return dict;
    },
    [],
  ),
);

export const CompanyNameWithHooks = defaultMemoize(state =>
  get(state, 'Formation.accountInfo.name', ''),
);

export const isSubscribed = defaultMemoize(
  state => get(state.subscription, 'subscriptions.data', []).length > 0,
);
