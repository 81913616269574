import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import kebabCase from 'lodash-es/kebabCase';

import SavviLoading from '../SavviLoading';

import './TimelineSection.scss';
import { Link } from 'react-router-dom';
import handlePath from '../../utils/handlePath';
import dayjs from 'dayjs/esm';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const TimelineSection = ({
  deliverableLabel,
  isFetchingInit = false,
  params,
  timeline = [],
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggleCollapse = () => {
    const element = document.querySelector(
      `#timeline-inner-${kebabCase(deliverableLabel)}`,
    );
    if (!isCollapsed) {
      let sectionHeight = element.scrollHeight;
      // temporarily disable all css transitions
      let elementTransition = element.style.transition;
      element.style.transition = '';

      // on the next frame (as soon as the previous style change has taken effect),
      // explicitly set the element's height to its current pixel height, so we
      // aren't transitioning out of 'auto'
      requestAnimationFrame(function () {
        element.style.maxHeight = sectionHeight + 'px';
        element.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 176px then scroll to header
        requestAnimationFrame(function () {
          const elementHeader = document.querySelector(
            `#timeline-${kebabCase(deliverableLabel)}`,
          );
          document
            .querySelector('.workbench__main')
            .scrollTo({ top: elementHeader.offsetTop - 100, behavior: 'smooth' });
          setTimeout(() => {
            element.style.maxHeight = 176 + 'px';
          }, 500);
        });
      });

      // set section to collapsed
      setIsCollapsed(true);
    } else {
      // get the height of the element's inner content, regardless of its actual size
      let sectionHeight = element.scrollHeight;

      // have the element transition to the height of its inner content
      element.style.maxHeight = sectionHeight + 'px';

      // set section to not collapsed
      setIsCollapsed(false);
    }
  };
  if (isEmpty(timeline)) {
    return null;
  }
  return (
    <div className="TimelineSection">
      <div
        className="TimelineSection__section-header"
        id={`timeline-${kebabCase(deliverableLabel)}`}
      >
        {/* <h3>{deliverableLabel} Timeline</h3> */}
        <h4>Timeline</h4>
      </div>
      {isFetchingInit && <SavviLoading size="md" />}
      {!isFetchingInit && isEmpty(timeline) && (
        <div className="TimelineSection__fetching">
          <h4>No Timeline data found.</h4>
        </div>
      )}
      <div
        className={`TimelineSection__content--inner${isCollapsed ? ' hide' : ''}`}
        id={`timeline-inner-${kebabCase(deliverableLabel)}`}
      >
        {isEmpty(timeline) && (
          <h4 className="TimelineSection__timeline-empty">
            No timeline found for {deliverableLabel}.
          </h4>
        )}
        {isCollapsed && timeline.length > 2 && <div className="TimelineSection__fade" />}
        {timeline.map(({ created_at, description, icon, id, label, link }, ind) => (
          <div
            className="TimelineSection__card TimelineSection__timeline"
            key={`timeline-card-${id}-${ind}`}
          >
            <div className="TimelineSection__pre">
              <div className="TimelineSection__icon">
                <FontAwesomeIcon icon={['fal', icon || 'check-circle']} />
              </div>
              <div
                className={classnames('TimelineSection__line', {
                  'TimelineSection__line--isLast': ind === timeline.length - 1,
                })}
              />
            </div>
            <div className="TimelineSection__body">
              <h4>
                {label}
                {link && (
                  <>
                    {`  (`}
                    <Link to={handlePath(link, params.companyId)}>
                      View Document in File Cabinet
                    </Link>
                    )
                  </>
                )}
              </h4>
              {description && <p>{description}</p>}
              <p>
                <FontAwesomeIcon icon={['fal', 'clock']} />
                {dayjs(created_at).format('LLL')}
              </p>
            </div>
          </div>
        ))}
      </div>
      {timeline.length > 2 && (
        <div
          className="TimelineSection__toggle"
          onClick={handleToggleCollapse}
          // onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? 'Show Older' : 'Show Less'}
          <FontAwesomeIcon icon={['fal', isCollapsed ? 'caret-down' : 'caret-up']} />
        </div>
      )}
    </div>
  );
};

export default TimelineSection;
