const cleanHash = hash => hash.replace(/(%20| |#)/g, '');

const explode = cleanedHash => {
  return cleanedHash
    .split(',')
    .filter(String)
    .map(v => cleanHash(v));
};

export const noHash = hash => cleanHash(hash).length === 0;

export const hashContains = (hash, check) => {
  if (!hash || !check) {
    return false;
  }
  const parts = explode(cleanHash(hash));

  return parts.indexOf(cleanHash(check)) !== -1;
};
