import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Pages
import { userIsNotAuthenticated } from '../../utils/Auth';
import Register from '../../views/Register';
import RegisterConfirm from '../../views/RegisterConfirm';

const Registration = props => {
  return (
    <Switch>
      <Route path="/register/confirm-email" component={RegisterConfirm} />
      <Route exact path="/register" component={userIsNotAuthenticated(Register)} />
    </Switch>
  );
};

export default Registration;
