import React, { isValidElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { InputFeedback, InputHelp, InputSublabel } from '../../inputs';
import { FA, Tooltip } from '..';

import './InputStyledCheckbox.scss';

const InputStyledCheckbox = ({
  error,
  defaultPlaceholder,
  indeterminate,
  isDisabled,
  isInputHelpUpward,
  inputClassName,
  inputHelpAlign,
  isFetching,
  isFixed,
  isRequired,
  label,
  labelStyle,
  name,
  parentStyle,
  sublabel,
  tooltip,
  type,
  questionHelp,
  ...inputProps
}) => {
  const checkboxClassname = `
      styled-checkbox
      ${!!error && 'styled-checkbox--has-error'}
      ${parentStyle}
      ${(isDisabled || isFixed) && 'disabled'}
    `;

  const inputClassnames = `
      styled-checkbox__input
      styled-checkbox--${type}
      ${!!error && 'styled-checkbox--has-error__input'}
      ${inputClassName}
    `;

  const labelClassname = `
      ${labelStyle}
      styled-checkbox__label
      ${isDisabled && 'disabled'}
      `;
  // ${type === 'switch' && 'styled-checkbox--switch__label'}

  const checkboxRef = useRef(null);

  useEffect(() => {
    if (indeterminate && type === 'checkbox') {
      checkboxRef.current.indeterminate = true;
    } else if (!indeterminate && type === 'checkbox') {
      checkboxRef.current.indeterminate = false;
    }
  }, [indeterminate, type]);

  return (
    <div className={checkboxClassname}>
      <div className="styled-checkbox__row">
        <input
          type={type === 'radio' ? 'radio' : 'checkbox'}
          className={inputClassnames}
          ref={checkboxRef}
          // ref={el => (this.selector = el)}
          id={name}
          name={name}
          disabled={isFixed || isDisabled || isFetching}
          {...inputProps}
        />
        {isValidElement(label) && label}
        {!isValidElement(label) && label && (
          <label className={labelClassname} htmlFor={name}>
            {label}
            {!!isRequired && <span className="workbench-form__required">*</span>}
          </label>
        )}
        {questionHelp && (
          <InputHelp
            align={inputHelpAlign}
            upwards={isInputHelpUpward}
            text={questionHelp}
          />
        )}
        {isFetching && <FA icon="spinner" spin className="btn-loading" />}
      </div>
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
      {tooltip && <Tooltip text={tooltip} />}
    </div>
  );
};

InputStyledCheckbox.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFetching: PropTypes.bool,
  isDisabled: PropTypes.bool,
  inputHelpAlign: PropTypes.oneOf(['center', 'right', 'left']),
  isInputHelpUpward: PropTypes.bool,
  isRequired: PropTypes.bool,
  questionHelp: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  sublabel: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio', 'switch']),
};

InputStyledCheckbox.defaultProps = {
  checked: false,
  error: false,
  onChange: () => {},
  type: 'checkbox',
};

export default InputStyledCheckbox;

/** *********** Examples of usage ********** */
// ReactDOM.render(
//   <div className="content">
//     <h1>ReactJS <span>checkbox</span> component</h1>
//     <Checkbox id="unchecked" label="Unchecked" />
//     <Checkbox id="checked" label="Checked" checked />
//     <Checkbox id="indeterminate" label="indeterminate" indeterminate />
//     <Checkbox id="switch" label="Switch" type="switch" />
//     <Checkbox id="hasError" label="Has error" hasError />
//     <Checkbox id="disabled" label="Disabled" disabled />
//   </div>,
//   document.getElementById('container')
// );
