import {
  ADD_SAVVI_PARTNER_GROUP_MEMBER,
  REMOVE_SAVVI_PARTNER_GROUP_MEMBER,
  SET_COUNSELS,
  SET_EMAILS,
  SET_FEATURE_CHANGES,
  SET_SAVVI_PARTNERS,
  SET_SAVVI_PARTNER_GROUP,
  SET_TEAMS,
  UPDATE,
  UPDATE_SAVVI_PARTNER_GROUP_MEMBER,
} from './types';

export const updateSettings = settings => ({
  type: UPDATE,
  payload: settings,
});
export const setCounsels = payload => ({
  type: SET_COUNSELS,
  payload,
});
export const setSavviPartners = payload => ({
  type: SET_SAVVI_PARTNERS,
  payload,
});
export const setSavviPartnerGroup = payload => ({
  type: SET_SAVVI_PARTNER_GROUP,
  payload,
});
export const setTeams = payload => ({
  type: SET_TEAMS,
  payload,
});
export const addPartnerGroupMember = (payload, groupId) => ({
  type: ADD_SAVVI_PARTNER_GROUP_MEMBER,
  payload,
  groupId,
});
export const updatePartnerGroupMember = (payload, groupId) => ({
  type: UPDATE_SAVVI_PARTNER_GROUP_MEMBER,
  payload,
  groupId,
});
export const removePartnerGroupMember = (groupId, individualId) => ({
  type: REMOVE_SAVVI_PARTNER_GROUP_MEMBER,
  groupId,
  individualId,
});

export const setEmails = emails => {
  return {
    type: SET_EMAILS,
    payload: emails,
  };
};

export const setFeatureChanges = featureChanges => {
  return {
    type: SET_FEATURE_CHANGES,
    payload: featureChanges,
  };
};
