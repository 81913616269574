import React from 'react';

import get from 'lodash-es/get';

import { InputPhoneNumber } from '../../inputs';

import './PhoneNumber.scss';

const PhoneNumber = ({
  field: { name, onBlur, value },
  form: { errors, setFieldError, setFieldTouched, setFieldValue, status, touched },
  handleFormBlur = () => {},
  isLabel,
  myType,
  question,
  setLabel,
  isRequired,
  savedValue,
  ...props // everything that we should pass goes through here classnames etc
}) => {
  const apiError = get(status, name, false);
  const isTouched = get(touched, name, false);
  const error = apiError || (isTouched && get(errors, name, null));

  return (
    <InputPhoneNumber
      error={error}
      handleIsValid={(inputVal, country) => {
        if (isTouched) {
          if (!inputVal && !isRequired) {
            return true;
          } else if (!inputVal && isRequired) {
            return false;
          } else if (
            country.dialCode === '1' &&
            inputVal.length > 0 &&
            inputVal.length < 11
          ) {
            return false;
          } else if (inputVal.match(/12345/)) {
            return false;
          } else if (inputVal.match(/1234/)) {
            return false;
          }
          return true;
        }
        return true;
      }}
      isRequired={isRequired}
      name={name}
      onBlur={(event, country) => {
        setFieldTouched(name);
        const passedVal = event.target.value;
        if (!passedVal && !isRequired) {
        } else if (!passedVal && isRequired) {
          setFieldError(name, 'Phone number required');
        } else if (
          country.dialCode === '1' &&
          passedVal.length > 0 &&
          passedVal.length < 17
        ) {
          setFieldError(name, 'Full phone number required.');
        } else if (passedVal.match(/12345/)) {
          setFieldError(name, 'Invalid value: ' + value + ', ' + country.name);
        } else if (passedVal.match(/1234/)) {
          setFieldError(name, 'Invalid value');
        }
        handleFormBlur(event);
      }}
      onChange={(updatedValue, isBlur) => {
        setFieldValue(name, updatedValue);
        if (isBlur) {
          const updatedSavedVal = savedValue || '';
          setFieldTouched(name);
          if (!updatedValue && isRequired) {
            setFieldError(name, 'Phone number required.');
          }
          if (updatedValue !== updatedSavedVal) {
            handleFormBlur({ target: { value: updatedValue } });
          }
        }
      }}
      value={value}
      {...props}
    />
  );
};

export default PhoneNumber;
