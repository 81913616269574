import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';

import { InputFeedback, InputHelp, InputSublabel } from '..';

import 'react-phone-input-2/lib/style.css';
import './InputPhoneNumber.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const bem = elementName => `input-phone-number${elementName ? '__' + elementName : ''}`;

const InputPhoneNumber = forwardRef(
  (
    {
      autoComplete,
      customClass,
      disableDropdown,
      defaultPlaceholder,
      error,
      handleIsValid,
      isDataHidden,
      isDisabled,
      isFixed,
      isRequired,
      questionHelp,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      sublabel,
      value,
    },
    ref,
  ) => {
    const inputPlaceholder = placeholder || defaultPlaceholder;
    const wrapperClasses = classnames(customClass, bem('wrapper'), {
      [bem('wrapper--error')]: !!error,
      [bem('wrapper--hidden')]: isDataHidden,
      [bem('wrapper--copyable')]: isDataHidden,
      [bem('wrapper--disabled')]: isFixed || isDisabled,
      [bem('wrapper--padded-top')]: label.length > 100,
    });
    let updatedVal = value;
    if (updatedVal === null || updatedVal === undefined) {
      updatedVal = '';
    }

    const [numberInput, setNumberInput] = useState(updatedVal);
    const [isActive, setIsActive] = useState(!!value || (!value && inputPlaceholder));

    useEffect(() => {
      if (!isActive && (value || (!value && inputPlaceholder))) {
        setIsActive(true);
      }
    }, [inputPlaceholder, isActive, value]);

    useEffect(() => {
      setNumberInput(value);
    }, [value, setNumberInput]);

    return (
      <div className={bem('container')}>
        <div className={wrapperClasses}>
          {label && (
            <label
              className={classnames(bem('label'), { [bem('label--active')]: isActive })}
              htmlFor={name}
            >
              {label}
              {!!isRequired && <span className="workbench-form__required">*</span>}
              {questionHelp && <InputHelp text={questionHelp} />}
            </label>
          )}
          <PhoneInput
            containerClass={bem()}
            disabled={isFixed || isDisabled}
            disableDropdown={disableDropdown}
            inputProps={{
              autoComplete,
              'data-lpignore': isDataHidden ? 'true' : 'false',
              name,
              id: name,
              required: isRequired,
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onBlur(e);
              }
            }}
            isValid={handleIsValid}
            onFocus={e => {
              setIsActive(true);
              if (!numberInput) {
                setNumberInput('+1');
                onChange('+1');
              } else if (numberInput && !numberInput.includes('+')) {
                setNumberInput('+1 ' + numberInput);
                onChange('+1 ' + numberInput);
              }
              onFocus(e);
            }}
            onBlur={(e, country) => {
              let updatedEvent = e;
              if (!e.target.value) {
                setIsActive(false);
              }
              if (e.target.value === '+1') {
                updatedEvent.target.value = '';
                setNumberInput('');
                setIsActive(false);
                return onChange('', true);
              }
              onBlur(updatedEvent, country);
            }}
            onChange={(inputVal, country, e, formattedValue) => {
              setNumberInput(formattedValue);
              onChange(formattedValue);
            }}
            ref={ref}
            type="tel"
            value={numberInput}
            placeholder={inputPlaceholder}
          />
          {(!numberInput || numberInput[0] !== '+') && (
            <div className={bem('icon')}>
              <FontAwesomeIcon icon="phone" />
            </div>
          )}
        </div>
        <InputSublabel text={sublabel} />
        {error && <InputFeedback error={error} />}
      </div>
    );
  },
);

InputPhoneNumber.propTypes = {
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sublabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

InputPhoneNumber.defaultProps = {
  autoComplete: 'on',
  customClass: '',
  disableDropdown: true,
  isDisabled: false,
  error: null,
  name: 'input',
  type: 'text',
  icon: false,
  isRequired: false,
  label: '',
  placeholder: '',
  sublabel: null,
  value: '',
  onFocus: () => {},
  onBlur: () => {},
  onChange: val => {},
};

export default InputPhoneNumber;
