import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation, useParams } from 'react-router';

import paperOSLogo from '../../assets/images/paperOS_wordmark_brown_sm.png';
import paperOSLogoWhite from '../../assets/images/paperOS_wordmark_white_md.png';
import paperOSIcon from '../../assets/images/paperOS_icon.png';

import { DashboardNav, WorkbenchNav } from '../../components/Sidebar';
import TeamsNav from '../../components/Sidebar/TeamsNav';
// import RoomNav from '../../components/Sidebar/RoomNav';
import {
  getAuthAccount,
  IsLPPortalShowing,
  IsPartner,
} from '../../redux/modules/User/selectors';

import './Sidebar.scss';
import handlePath from '../../utils/handlePath';
import { setNotice } from '../../redux/modules/UI/actions';
import { getRoomWithUpdatedFeatures } from '../../redux/modules/Data/selectors';
import UseWindowSize from '../../utils/UseWindowSize';
import { MyModal, Tooltip } from '../../components/common';
import ModuleDict from '../../utils/ModuleDict';
import { Link } from 'react-router-dom';
import { deleteAccountProject } from '../../redux/modules/Project/operations';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const Sidebar = ({
  isAssessment,
  isDataRoom,
  isDashboard,
  isEditing,
  isPortalView,
  isWorkbench,
  isTeamView,
  transactionLabel,
  workbenchParams,
}) => {
  const dispatch = useDispatch();
  const { width } = UseWindowSize();
  const { push, replace } = useHistory();
  const location = useLocation();
  const { search } = location;
  const params = useParams();
  const brandMeta = useSelector(BrandMeta);
  const { logo_url, assets } = brandMeta;
  const { hide_paperos, logo_white } = assets || {};
  const { companyId, room_access_code } = params;
  const { moduleId = 'home', projectId, transactionId, step } = workbenchParams || {};

  const isPartner = useSelector(IsPartner);
  const dataRoom = useSelector(state =>
    getRoomWithUpdatedFeatures(state, room_access_code),
  );
  const isLPPortalShowing = useSelector(IsLPPortalShowing);
  const currentAccount = useSelector(getAuthAccount);
  const { is_admin_user, room: { account_id } = {} } = dataRoom;
  const { isAuthenticated, accountId } = currentAccount;

  const isWhite = (isWorkbench && !isPortalView) || isTeamView || isDataRoom;

  const isAuthorized = isAuthenticated && accountId === account_id && is_admin_user;
  const isToggleOverlayShowing = (isWhite && !isDataRoom) || (isDataRoom && isAuthorized);

  const [isCollapsed, setIsCollapsed] = useState(isPortalView || width < 1560);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    if (!isWhite) {
      setIsOverlayVisible(false);
    }
  }, [isWhite]);

  const handleDiscardTransaction = () => {
    dispatch(deleteAccountProject(projectId)).then(
      e => replace(handlePath(ModuleDict[moduleId].path, companyId)),
      error => push(handlePath(ModuleDict[moduleId].path, companyId)),
    );
  };

  return (
    <>
      {!isCollapsed && (
        <div onClick={e => setIsCollapsed(true)} className="sidebar__container-overlay" />
      )}
      <div
        className={classnames('sidebar__container', {
          hide: isCollapsed,
          white: isWhite,
          isDataRoom,
          isTeamView,
          isWorkbench: isWorkbench && !isPortalView,
          isToggleOverlayShowing,
        })}
      >
        {isPortalView && step === 'portal' && (
          <div
            className="sidebar__exit"
            onClick={e => push(handlePath(ModuleDict[moduleId].path, companyId))}
          >
            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
            {ModuleDict[moduleId].label}
          </div>
        )}
        {isPortalView && step === 'task-view' && !isAssessment && (
          <div
            className="sidebar__exit"
            onClick={e =>
              push(
                handlePath(
                  `/workbench/${moduleId}/${projectId}/${transactionId}/portal`,
                  companyId,
                ),
              )
            }
          >
            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
            Back to Portal
          </div>
        )}
        {isPortalView && step === 'task-view' && isAssessment && (
          <>
            <Link
              className="sidebar__exit sidebar__exit--inner sidebar__exit--portal"
              to={handlePath(ModuleDict[moduleId].path, companyId)}
            >
              <FontAwesomeIcon icon="chevron-left" />
              Save & Exit
            </Link>
            <button
              className="sidebar__exit sidebar__exit--portalWarning"
              onClick={handleDiscardTransaction}
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              Discard Workflow & Exit
            </button>
          </>
        )}
        {isToggleOverlayShowing && (
          <div
            className={`sidebar__toggleOverlay${
              companyId + '' === '0' ? ' isDisabled' : ''
            }`}
            onClick={() => {
              if (companyId + '' !== '0') {
                setIsOverlayVisible(true);
              } else {
                dispatch(setNotice('Please first select or create a company.'));
              }
            }}
          >
            <FontAwesomeIcon icon="bars" />
          </div>
        )}
        {(!isWhite || isDataRoom) && (
          <div
            className={classnames('sidebar__logo', { isDisabled: isDataRoom })}
            onClick={e => {
              if (isDataRoom) {
                return;
              } else if (companyId + '' !== '0') {
                push(handlePath({ pathname: '/home', search }, companyId));
              } else {
                dispatch(setNotice('First select or create a company.'));
              }
            }}
          >
            {!isCollapsed && (
              <>
                <img
                  className={classnames('sidebar__logoImg', {
                    'sidebar__logoImg--white': !logo_white,
                  })}
                  src={paperOSLogoWhite || logo_white || logo_url || paperOSLogoWhite}
                  alt=""
                />
              </>
            )}
            {isCollapsed && <img src={paperOSIcon} alt="" />}
          </div>
        )}
        {!hide_paperos && (
          <div
            className={classnames('sidebar__logo sidebar__logo--bottom', {
              isDisabled: isDataRoom,
            })}
            onClick={e => {
              if (isDataRoom) {
                return;
              } else if (companyId + '' !== '0') {
                push(handlePath({ pathname: '/home', search }, companyId));
              } else {
                dispatch(setNotice('First select or create a company.'));
              }
            }}
          >
            <div className="sidebar__paperOS">
              <h4>Powered By</h4>
              <img src={isWhite ? paperOSLogo : paperOSLogoWhite} alt="paperOS logo" />
            </div>
          </div>
        )}
        {((isWorkbench && !isPortalView) || isDashboard || width <= 1200) && (
          <div
            className={classnames('sidebar__arrow', { isDashboard, isWorkbench })}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <FontAwesomeIcon
              icon={[
                'fal',
                (!isCollapsed && 'arrow-to-left') || 'arrow-from-left',
                // (isWhite && 'arrow-from-left') ||
                // 'bars',
              ]}
            />
            {isCollapsed && isWorkbench && <Tooltip text="View Project Tasks Nav" />}
          </div>
        )}
        {(isTeamView || isDataRoom) && (
          <TeamsNav
            isCollapsed={isCollapsed}
            isDataRoom={isDataRoom}
            isPartner={isPartner}
            isLPPortalShowing={isLPPortalShowing}
          />
        )}
        {(isDashboard || isPortalView) && <DashboardNav isCollapsed={isCollapsed} />}
        {isWorkbench && !isPortalView && (
          <WorkbenchNav isCollapsed={isCollapsed} transactionLabel={transactionLabel} />
        )}
        {(!isWorkbench || isPortalView) && width > 1200 && (
          <div
            className={classnames('sidebar__toggle', { isCollapsed, isDashboard })}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <div className="sidebar__toggleIcons">
              <FontAwesomeIcon className="left-icon" icon="long-arrow-right" />
              <FontAwesomeIcon className="right-icon" icon="long-arrow-left" />
            </div>
            <h4>{isCollapsed ? 'Expand Menu' : 'Collapse Menu'}</h4>
          </div>
        )}
        {isWhite && (
          <MyModal
            className="sidebar__container is-overlay"
            isOpen={isOverlayVisible}
            onRequestClose={() => setIsOverlayVisible(false)}
            overlayClassName="sidebar__overlay"
          >
            <div
              className="sidebar__logo"
              onClick={e => {
                if (companyId + '' !== '0') {
                  push(handlePath({ pathname: '/home', search }, companyId));
                } else {
                  dispatch(setNotice('First select or create a company.'));
                }
              }}
            >
              <img
                src={logo_white || paperOSLogoWhite}
                className="sidebar__logoImg"
                alt=""
              />
            </div>
            <div
              className="sidebar__arrow is-overlay"
              onClick={() => setIsOverlayVisible(!isOverlayVisible)}
            >
              <FontAwesomeIcon icon={['fal', 'times']} />
            </div>
            <DashboardNav account_id={account_id} />
          </MyModal>
        )}
      </div>
    </>
  );
};

Sidebar.defaultProps = {
  isWorkbench: false,
};

export default Sidebar;
