import {
  CLEAR_NOTICE,
  CLEAR_NOTICES,
  CLOSE_TERMS_MODAL,
  CLOSE_USER_DROPDOWN,
  OPEN_TERMS_MODAL,
  OPEN_USER_DROPDOWN,
  SET_NOTICE,
  SET_SEARCH,
  CLEAR_SEARCH,
  SET_SIDEBAR_COLLAPSED,
  SET_BRAND_META,
} from './types';

export const openTermsModal = () => ({
  type: OPEN_TERMS_MODAL,
});

export const closeTermsModal = () => ({
  type: CLOSE_TERMS_MODAL,
});

export const openUserDropdown = () => ({
  type: OPEN_USER_DROPDOWN,
});

export const closeUserDropdown = () => ({
  type: CLOSE_USER_DROPDOWN,
});

export const setNotice = payload => ({
  type: SET_NOTICE,
  payload,
});

export const clearNotice = payload => ({
  type: CLEAR_NOTICE,
  payload,
});
export const clearNotices = () => ({
  type: CLEAR_NOTICES,
});

export const setSearch = input => ({
  type: SET_SEARCH,
  input,
});

export const clearSearch = input => ({
  type: CLEAR_SEARCH,
  input,
});

export const setSidebarCollapsed = collapsed => ({
  type: SET_SIDEBAR_COLLAPSED,
  collapsed,
});

export const setBrandMeta = payload => ({
  type: SET_BRAND_META,
  payload,
});
