import React, { Component } from 'react';

import get from 'lodash-es/get';

import { InputCheckbox } from '../../inputs';

class Checkbox extends Component {
  render() {
    const {
      field, //: { name, value, onChange, onBlur },
      form: { errors, status, touched },
      ...props // everything that we should pass goes through here classnames etc
    } = this.props;
    const { name, value } = field;
    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    const error = apiError || (isTouched && get(errors, name, null));
    return (
      <InputCheckbox
        onClick={e => {
          field.onChange(e);
        }}
        error={error}
        checked={value}
        {...field}
        {...props}
      />
    );
  }
}

export default Checkbox;
