import React, { Component } from 'react';
import PropTypes from 'prop-types';

import get from 'lodash-es/get';

import TransformDict from '../../../utils/TransformDict';
import { InputSelect, InputStyledTextField } from '../../inputs';

import './TextField.scss';

const SalaryTypesOptions = [
  { label: 'Per Year', value: 'Per Year' },
  { label: 'Per Month', value: 'Per Month' },
  { label: 'Per Hour', value: 'Per Hour' },
];

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salaryType: SalaryTypesOptions[0],
    };
  }

  componentDidMount() {
    const {
      field: { value },
    } = this.props;
    let updatedValue = value?.value || value;
    if (updatedValue) {
      const selectFieldVal = updatedValue.substring(updatedValue.indexOf(' ') + 1);
      this.setState({
        salaryType: { label: selectFieldVal, value: selectFieldVal },
      });
    }
  }

  render() {
    const {
      field: { name, onBlur, value },
      form: { errors, setFieldValue, setFieldTouched, status, touched },
      handleFormBlur,
      isLabel,
      myType,
      question,
      setLabel,
      seperator = '/',
      isRequired,
      ...props // everything that we should pass goes through here classnames etc
    } = this.props;

    const apiError = get(status, name, false);
    const isTouched = get(touched, name, false);
    const error = apiError || (isTouched && get(errors, name, null));
    let updatedValue = value?.value || value;

    if (myType === 'salary') {
      const { salaryType } = this.state;
      let textFieldVal = '';
      let selectFieldVal = salaryType.label;
      if (updatedValue) {
        textFieldVal = updatedValue.substring(0, updatedValue.indexOf(' '));
      }
      return (
        <div className="text-field__salary">
          <InputStyledTextField
            error={error}
            value={textFieldVal}
            isRequired={isRequired}
            name={name}
            onChange={({ nativeEvent, target }) => {
              const updatedVal = TransformDict.currency(
                target.value,
                nativeEvent.inputType,
              );
              if (updatedVal) {
                setFieldValue(name, updatedVal + ' ' + selectFieldVal);
              } else {
                setFieldValue(name, '');
              }
            }}
            onBlur={e => {
              const { nativeEvent, target } = e;
              const updatedVal = TransformDict.currency(
                target.value,
                nativeEvent.inputType,
              );

              handleFormBlur({
                target: {
                  value: `${updatedVal} ${selectFieldVal}`,
                },
              });
              onBlur(e);
            }}
            {...props}
          />
          <InputSelect
            isClearable={false}
            isFixed={props.isFixed}
            isDisabled={props.isFixed || props.isDisabled}
            isSearchable={false}
            isRequired={isRequired}
            name={name}
            label="Salary Type:"
            onBlur={e => setFieldTouched(name, true)}
            onChange={e => {
              this.setState({ salaryType: e });
              if (textFieldVal) {
                setFieldValue(name, textFieldVal + ' ' + e.value);
                handleFormBlur({
                  target: {
                    value: `${textFieldVal} ${e.value}`,
                  },
                });
              }
            }}
            defaultValue={SalaryTypesOptions[0]}
            value={salaryType}
            options={SalaryTypesOptions}
          />
        </div>
      );
    }

    return (
      <InputStyledTextField
        error={error}
        isRequired={isRequired}
        myType={myType}
        name={name}
        onBlur={e => {
          const { target } = e;
          if (isLabel) {
            setLabel(target.value);
          }
          onBlur(e);
          handleFormBlur(e);
        }}
        onChange={e =>
          setFieldValue(
            name,
            TransformDict[myType](e.target.value, e.nativeEvent.inputType, seperator),
          )
        }
        value={updatedValue}
        {...props}
      />
    );
  }
}

TextField.propTypes = {
  myType: PropTypes.oneOf([
    'currency',
    'date',
    'float',
    'float_currency',
    'integer',
    'month',
    'numerical',
    'percent',
    'phone',
    'salary',
    'social_security',
    'string',
    'zip',
  ]),
};

TextField.defaultProps = {
  myType: 'string',
  handleFormBlur: () => {},
};

export default TextField;
