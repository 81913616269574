import { applyMiddleware, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import payload, { myInterceptor } from '../middleware/PayloadHandler';
import createRootReducer from '../reducers';

// import { persistState } from '../utils/localStorage';
export const history = createBrowserHistory();

// import { persistState } from '../utils/localStorage';

const configureStore = preloadedState => {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    applyMiddleware(
      thunk,
      myInterceptor,
      apiMiddleware,
      payload,
      routerMiddleware(history),
    ),
  );

  // store.subscribe(() => {
  //   store.subscribe(() => {
  //     persistState(store.getState());
  //   });
  // });

  return store;
};

export default configureStore;
