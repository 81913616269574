// There are three possible states for our login
// process and we need actions for each of them
export const GET_REQUEST = 'savvi/profile/GET_REQUEST';
export const GET_SUCCESS = 'savvi/profile/GET_SUCCESS';
export const GET_FAILED = 'savvi/profile/GET_FAILED';

export const UPDATE = 'savvi/profile/UPDATE';

export const SAVE_REQUEST = 'savvi/profile/SAVE_REQUEST';
export const SAVE_FAILED = 'savvi/profile/SAVE_FAILED';
export const SAVE_SUCCESS = 'savvi/profile/SAVE_SUCCESS';

export const PASSWORD_REQUEST = 'savvi/profile/PASSWORD_REQUEST';
export const PASSWORD_SUCCESS = 'savvi/profile/PASSWORD_SUCCESS';
export const PASSWORD_FAILED = 'savvi/profile/PASSWORD_FAILED';

// Get Feature Changes
export const GET_FEATURE_CHANGES_REQUEST = 'savvi/profile/GET_FEATURE_CHANGES_REQUEST';
export const GET_FEATURE_CHANGES_SUCCESS = 'savvi/profile/GET_FEATURE_CHANGES_SUCCESS';
export const GET_FEATURE_CHANGES_FAILED = 'savvi/profile/GET_FEATURE_CHANGES_FAILED';

export const SET_FEATURE_CHANGES_DATE_REQUEST =
  'savvi/profile/SET_FEATURE_CHANGES_DATE_REQUEST';
export const SET_FEATURE_CHANGES_DATE_SUCCESS =
  'savvi/profile/SET_FEATURE_CHANGES_DATE_SUCCESS';
export const SET_FEATURE_CHANGES_DATE_FAILED =
  'savvi/profile/SET_FEATURE_CHANGES_DATE_FAILED';
export const SET_FEATURE_CHANGES = 'savvi/profile/SET_FEATURE_CHANGES';

// Update Email Actions
export const GET_EMAILS_REQUEST = 'savvi/profile/GET_EMAILS_REQUEST';
export const GET_EMAILS_SUCCESS = 'savvi/profile/GET_EMAILS_SUCCESS';
export const GET_EMAILS_FAILED = 'savvi/profile/GET_EMAILS_FAILED';

export const SEND_EMAIL_CODE_REQUEST = 'savvi/profile/SEND_EMAIL_CODE_REQUEST';
export const SEND_EMAIL_CODE_SUCCESS = 'savvi/profile/SEND_EMAIL_CODE_SUCCESS';
export const SEND_EMAIL_CODE_FAILED = 'savvi/profile/SEND_EMAIL_CODE_FAILED';

export const DELETE_EMAIL_VERIFICATION_REQUEST =
  'savvi/profile/DELETE_EMAIL_VERIFICATION_REQUEST';
export const DELETE_EMAIL_VERIFICATION_SUCCESS =
  'savvi/profile/DELETE_EMAIL_VERIFICATION_SUCCESS';
export const DELETE_EMAIL_VERIFICATION_FAILED =
  'savvi/profile/DELETE_EMAIL_VERIFICATION_FAILED';

export const VERIFY_UPDATED_EMAIL_REQUEST = 'savvi/profile/VERIFY_UPDATED_EMAIL_REQUEST';
export const VERIFY_UPDATED_EMAIL_SUCCESS = 'savvi/profile/VERIFY_UPDATED_EMAIL_SUCCESS';
export const VERIFY_UPDATED_EMAIL_FAILED = 'savvi/profile/VERIFY_UPDATED_EMAIL_FAILED';
export const SET_EMAILS = 'savvi/profile/SET_EMAILS';

// GET COUNSELS ACTIONS
export const GET_COUNSELS_REQUEST = 'savvi/profile/GET_COUNSELS_REQUEST';
export const GET_COUNSELS_SUCCESS = 'savvi/profile/GET_COUNSELS_SUCCESS';
export const GET_COUNSELS_FAILED = 'savvi/profile/GET_COUNSELS_FAILED';
export const SET_COUNSELS = 'savvi/profile/SET_COUNSELS';

// GET SAVVI PARTNERS ACTIONS
export const GET_SAVVI_PARTNERS_REQUEST = 'savvi/profile/GET_SAVVI_PARTNERS_REQUEST';
export const GET_SAVVI_PARTNERS_SUCCESS = 'savvi/profile/GET_SAVVI_PARTNERS_SUCCESS';
export const GET_SAVVI_PARTNERS_FAILED = 'savvi/profile/GET_SAVVI_PARTNERS_FAILED';
export const SET_SAVVI_PARTNERS = 'savvi/profile/SET_SAVVI_PARTNERS';

// SAVVI PARTNER GROUP ACTIONS
export const GET_SAVVI_PARTNER_GROUP_REQUEST =
  'savvi/profile/GET_SAVVI_PARTNER_GROUP_REQUEST';
export const GET_SAVVI_PARTNER_GROUP_SUCCESS =
  'savvi/profile/GET_SAVVI_PARTNER_GROUP_SUCCESS';
export const GET_SAVVI_PARTNER_GROUP_FAILED =
  'savvi/profile/GET_SAVVI_PARTNER_GROUP_FAILED';
export const SET_SAVVI_PARTNER_GROUP = 'savvi/profile/SET_SAVVI_PARTNER_GROUP';

// GET TEAMS
export const GET_TEAMS_REQUEST = 'savvi/profile/GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'savvi/profile/GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILED = 'savvi/profile/GET_TEAMS_FAILED';
export const REMOVE_TEAM_ACCOUNT_REQUEST = 'savvi/profile/REMOVE_TEAM_ACCOUNT_REQUEST';
export const REMOVE_TEAM_ACCOUNT_SUCCESS = 'savvi/profile/REMOVE_TEAM_ACCOUNT_SUCCESS';
export const REMOVE_TEAM_ACCOUNT_FAILED = 'savvi/profile/REMOVE_TEAM_ACCOUNT_FAILED';
export const SET_TEAMS = 'savvi/profile/SET_TEAMS';

export const ADD_SAVVI_PARTNER_GROUP_MEMBER_REQUEST =
  'savvi/profile/ADD_SAVVI_PARTNER_GROUP_MEMBER_REQUEST';
export const ADD_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS =
  'savvi/profile/ADD_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS';
export const ADD_SAVVI_PARTNER_GROUP_MEMBER_FAILED =
  'savvi/profile/ADD_SAVVI_PARTNER_GROUP_MEMBER_FAILED';
export const ADD_SAVVI_PARTNER_GROUP_MEMBER =
  'savvi/profile/ADD_SAVVI_PARTNER_GROUP_MEMBER';

export const EDIT_SAVVI_PARTNER_GROUP_MEMBER_REQUEST =
  'savvi/profile/EDIT_SAVVI_PARTNER_GROUP_MEMBER_REQUEST';
export const EDIT_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS =
  'savvi/profile/EDIT_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS';
export const EDIT_SAVVI_PARTNER_GROUP_MEMBER_FAILED =
  'savvi/profile/EDIT_SAVVI_PARTNER_GROUP_MEMBER_FAILED';
export const UPDATE_SAVVI_PARTNER_GROUP_MEMBER =
  'savvi/profile/UPDATE_SAVVI_PARTNER_GROUP_MEMBER';

export const REMOVE_SAVVI_PARTNER_GROUP_MEMBER_REQUEST =
  'savvi/profile/REMOVE_SAVVI_PARTNER_GROUP_MEMBER_REQUEST';
export const REMOVE_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS =
  'savvi/profile/REMOVE_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS';
export const REMOVE_SAVVI_PARTNER_GROUP_MEMBER_FAILED =
  'savvi/profile/REMOVE_SAVVI_PARTNER_GROUP_MEMBER_FAILED';

export const REMOVE_SAVVI_PARTNER_GROUP_MEMBER =
  'savvi/profile/REMOVE_SAVVI_PARTNER_GROUP_MEMBER';

// GET CALENDLY ACTIONS
export const GET_CALENDLY_REQUEST = 'savvi/profile/GET_CALENDLY_REQUEST';
export const GET_CALENDLY_SUCCESS = 'savvi/profile/GET_CALENDLY_SUCCESS';
export const GET_CALENDLY_FAILED = 'savvi/profile/GET_CALENDLY_FAILED';
