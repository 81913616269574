import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, omit } from 'lodash-es';

import fl360Logo from '../../assets/images/fund_launch_logo_wide.png';
import fl360LogoWhite from '../../assets/images/fund_launch_logo_wide_white.png';
import paperOSLogo from '../../assets/images/paperOS_capital_at_scale_brown_md.png';
import paperOSIcon from '../../assets/images/paperOS_icon.png';
import googleIcon from '../../assets/images/google_icon.svg';
import googleIconWhite from '../../assets/images/google_icon_white.png';
import Button from '../../components/common/Button';
import { InputPassword, InputStyledTextField } from '../../components/inputs';
import SavviLoading from '../../components/SavviLoading';
import { clearErrors, setUserFromToken } from '../../redux/modules/User/actions';
import {
  loginUser,
  requestVerificationEmail,
  verifyEmail,
  createUser,
} from '../../redux/modules/User/operations';
import {
  AuthErrors,
  IsInitLoading,
  isFetchingUser,
  // IsAuthenticated,
} from '../../redux/modules/User/selectors';
import { validateEmail, validatePassword } from '../../utils/FeatureTypes';

import './Login.scss';
import { decodeToken } from '../../redux/modules/User/utils';
import { useHistory, useLocation } from 'react-router';
import { setNotice } from '../../redux/modules/UI/actions';
import { BrandMeta } from '../../redux/modules/UI/selectors';
import { TooltipAction } from '../../components/common/Tooltip/Tooltip';

const Login = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const isInitLoading = useSelector(IsInitLoading);
  // const isAuthenticated = useSelector(IsAuthenticated);

  const {
    // key,
    // pathname,
    hash,
    search,
    state: { redirectPathname, passedEmail } = {},
  } = location;

  let query = new URLSearchParams(search);
  // const passedIdToken = query.get('id_token');
  const fl360sso = query.get('fl360_sso') || localStorage.getItem('fl360_sso');

  const authErrors = useSelector(AuthErrors);
  const isFetching = useSelector(isFetchingUser);
  const brandMeta = useSelector(BrandMeta);
  const { hostname = '', support_email, display_name, assets = {} } = brandMeta || {};
  const { logo_with_paperos } = assets || {};

  const [isActionsLoading, setIsActionsLoading] = useState(false);
  const [isShowingOtherMethods, setIsShowingOtherMethods] = useState(!fl360sso);
  const [isFormHidden, setIsFormHidden] = useState(fl360sso);
  const [email, setEmail] = useState(passedEmail || '');
  const [isManualLogin, setIsManualLogin] = useState(passedEmail || '');
  const [prevAuthErrors, setPrevAuthErrors] = useState(authErrors);
  const [errors, setErrors] = useState(authErrors);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPasswordAllowed, setIsPasswordAllowed] = useState(true);
  const [isPasswordPlainText, setPasswordPlainText] = useState(false);
  const [password, setPassword] = useState('');
  const idTokenInjector = useRef();

  // const isWhiteLabel = window.location.href.includes('paperos');

  const emailRef = useCallback(
    node => {
      if (node !== null && !isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  const passwordRef = useCallback(
    node => {
      if (node !== null && isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  // useEffect(() => {
  //   let ghlAttempts = Number(sessionStorage.getItem('ghl_attempts'));
  //   if (
  //     !isInitLoading &&
  //     !isAuthenticated &&
  //     !passedIdToken &&
  //     hostname.includes('app.fundlaunch360.c.paperos') &&
  //     ghlAttempts < 1 &&
  //     !!fl360sso
  //   ) {
  //     sessionStorage.setItem('ghl_attempts', 1);
  //     localStorage.setItem('fl360_sso', 1);
  //     let redirectLoc = redirectPathname;
  //     if (search) {
  //       redirectLoc = '/c/0/company-select' + search;
  //     }
  //     if (!!redirectLoc) {
  //       localStorage.setItem('redirectLoc', redirectLoc);
  //     }
  //     if (hash && redirectLoc) {
  //       let redirectHash = hash;
  //       if (redirectLoc.includes('%2F')) {
  //         redirectHash = hash.replace('#', '%23');
  //       }
  //       localStorage.setItem('redirectHash', redirectHash);
  //     }
  //     window.history.pushState({ key }, '', pathname + search);
  //     window.location.href = '/api/authn/oauth2/fl360/auth';
  //   }
  // }, [
  //   fl360sso,
  //   hash,
  //   hostname,
  //   isAuthenticated,
  //   isInitLoading,
  //   key,
  //   passedIdToken,
  //   pathname,
  //   redirectPathname,
  //   search,
  // ]);

  useEffect(() => {
    if (fl360sso) {
      setIsFormHidden(true);
      setIsShowingOtherMethods(false);
    }
  }, [fl360sso]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (passedEmail) {
      dispatch(verifyEmail(passedEmail)).then(
        payload => {
          setEmailVerified(true);
          if (!payload.password_allowed) {
            setIsPasswordAllowed(false);
          }
          setErrors({});
        },
        error => {
          if (error.status === 404) {
            push({
              pathname: '/register',
              search,
              state: { email: passedEmail, redirectPathname },
            });
          }
        },
      );
    }
  }, [dispatch, passedEmail, push, redirectPathname, search]);

  useEffect(() => {
    if (!isEqual(authErrors, prevAuthErrors)) {
      setErrors({ ...errors, ...authErrors });
      setPrevAuthErrors(authErrors);
    }
  }, [authErrors, errors, prevAuthErrors]);

  const getCreds = () => ({
    email,
    password,
  });

  const injectGoogleAuth = () => {
    const decodedToken = decodeToken(idTokenInjector.current.value);
    dispatch(setUserFromToken(decodedToken));
  };

  const handleSendEmailVerification = (isCreateUserAllowed, updatedErrors) => {
    let finalErrs = updatedErrors || errors;
    setLoading(true);
    if (isEmailVerified) {
      return dispatch(
        requestVerificationEmail({
          identifier: { type: 'email', value: email },
          template: 'magic-link',
          state: { email, redirectLocation },
        }),
      ).then(
        p =>
          push({
            pathname: '/verify-code/magic-link',
            state: {
              email,
              redirectLocation: redirectLocation || {
                pathname: '/c/0/company-select',
                state: { isInitialLoad: true },
              },
            },
          }),
        error => setLoading(false),
      );
    } else if (finalErrs.email) {
      const doc = document.getElementById('email');

      if (doc.value && doc.value !== email) {
        setEmail(doc.value);
        const updatedEmailErr = validateEmail(doc.value);

        setErrors({ ...errors, email: updatedEmailErr });
        return handleSendEmailVerification(isCreateUserAllowed, {
          ...errors,
          email: updatedEmailErr,
        });
      } else {
        dispatch(setNotice({ type: 'error', message: finalErrs.email }));
        setLoading(false);
      }
    } else {
      return dispatch(verifyEmail(email)).then(
        payload => {
          return dispatch(
            requestVerificationEmail({
              identifier: { type: 'email', value: email },
              template: 'magic-link',
              state: { email, redirectLocation },
            }),
          ).then(e =>
            push({
              pathname: '/verify-code/magic-link',
              state: {
                email,
                redirectLocation: redirectLocation || {
                  pathname: '/c/0/company-select',
                  state: { isInitialLoad: true },
                },
              },
            }),
          );
        },
        error => {
          if (error.status === 404) {
            return dispatch(
              createUser({ email: email, password: '', create: true }),
            ).then(
              p => {
                return dispatch(
                  requestVerificationEmail({
                    identifier: { type: 'email', value: email },
                    template: 'magic-link',
                    state: { email, redirectLocation },
                  }),
                ).then(
                  p =>
                    push({
                      pathname: '/verify-code/magic-link',
                      state: {
                        email,
                        redirectLocation: redirectLocation || {
                          pathname: '/c/0/company-select',
                          state: { isInitialLoad: true },
                        },
                      },
                    }),
                  error => {
                    setLoading(false);
                  },
                );
              },
              error => {
                setLoading(false);
              },
            );
          }
          setLoading(false);
        },
      );
    }
  };

  const handleSubmit = (ev, updatedErrors, isVerifyForced) => {
    ev.preventDefault();
    let finalErrs = updatedErrors || errors;
    let redirectLocation = redirectPathname;
    if (search) {
      redirectLocation = '/c/0/company-select' + search;
    }
    if (fl360sso && !finalErrs.email && !isVerifyForced && !isManualLogin) {
      setLoading(true);
      handleSendEmailVerification(true, updatedErrors);
    }
    if ((!isEmailVerified || isVerifyForced) && !finalErrs.email) {
      ev.stopPropagation();
      setLoading(true);
      dispatch(verifyEmail(email)).then(
        payload => {
          setIsManualLogin(true);
          if (!payload.password_allowed) {
            setIsPasswordAllowed(false);
          }
          setEmailVerified(true);
          setErrors({});
        },
        error => {
          if (error.status === 404) {
            push({ pathname: '/register', search, state: { email, redirectPathname } });
          }
        },
      );
      return setTimeout(() => setLoading(false), 500);
    } else if (isEmailVerified) {
      if (!isPasswordAllowed) {
        return dispatch(
          requestVerificationEmail({
            identifier: { type: 'email', value: email },
            template: 'magic-link',
            state: { email, redirectLocation },
          }),
        ).then(e =>
          push({
            pathname: '/verify-code/magic-link',
            state: {
              email,
              redirectLocation: redirectLocation || {
                pathname: '/c/0/company-select',
                state: { isInitialLoad: true },
              },
            },
          }),
        );
      } else if (!finalErrs.email && password && !finalErrs.password) {
        dispatch(clearErrors());
        setErrors({});
        return dispatch(loginUser(getCreds())).then(
          e => {
            setIsSubmitted(true);
          },
          error => {
            setIsSubmitted(true);
          },
        );
      }
    } else if (!isEmailVerified && finalErrs.email) {
      const doc = document.getElementById('email');
      if (doc.value && doc.value !== email) {
        setEmail(doc.value);
        setErrors({ ...errors, email: validateEmail(doc.value) });
        return handleSubmit(ev, { ...errors, email: '' }, isVerifyForced);
      } else {
        dispatch(setNotice({ type: 'error', message: finalErrs.email }));
      }
    }
  };

  const validateLoginPassword = e => {
    const { name, value } = e.target;
    const password = validatePassword(
      value,
      6,
      'Password must be at least 6 characters long',
    );
    setErrors(!password ? omit(errors, name) : { ...errors, password });
  };

  const validateLoginEmail = e => {
    const { name, value } = e.target;
    const email = validateEmail(value);
    setErrors(!email ? omit(errors, name) : { ...errors, email });
  };

  const primaryActionLabel =
    (fl360sso && !isManualLogin && 'Send Email Link') ||
    (isEmailVerified && !isPasswordAllowed && 'Login By Email Link') ||
    (isEmailVerified && 'Login') ||
    'Next';
  const loginDescription =
    (isPasswordAllowed && 'Please enter your password below.') ||
    `You have not yet set a password for your ${display_name || ''} account.`;

  const wordmark = logo_with_paperos || paperOSLogo || '';
  const logoClasses = `
    login__logo ${
      (wordmark.includes('savvi') && 'login__logo--savvi') ||
      (wordmark.includes('fund_launch') && 'login__logo--fundLaunch') ||
      ''
    }`;

  const isButtonDisabled = isInitLoading || isFetching || isLoading;
  let redirectLocation = redirectPathname;
  if (search) {
    redirectLocation = '/c/0/company-select' + search;
  }

  const isFL360ActionsShowing =
    hostname.includes('app.fundlaunch360.c.paperos') ||
    hostname === 'staging.savvi.legal' ||
    !!fl360sso;

  const isGoogleAuthShowing =
    window.location.href.includes('savvi.legal') ||
    window.location.href.includes('app.paperos.com') ||
    window.location.href.includes('beta.paperos.com') ||
    window.location.href.includes('app.paperos.com');

  const nonFormPrimaryAction =
    (!isShowingOtherMethods && !!fl360sso && 'fl360') || 'google';
  const isSecondaryActionsShowing =
    (isGoogleAuthShowing && !fl360sso) ||
    (isShowingOtherMethods && isFL360ActionsShowing);
  const isGoogleActionShowing =
    (!fl360sso && (isGoogleAuthShowing || isFL360ActionsShowing)) ||
    (!!fl360sso && (nonFormPrimaryAction !== 'google' || !isFormHidden));
  // const isGoogleActionShowing =
  //   (!fl360sso && isGoogleAuthShowing) ||
  //   (!!fl360sso && (nonFormPrimaryAction !== 'google' || !isFormHidden));
  const isFL360ActionShowing = isFL360ActionsShowing && nonFormPrimaryAction !== 'fl360';
  const isPaperOSActionShowing = isFL360ActionsShowing && isFormHidden;

  const welcomeLabel =
    (fl360sso &&
      isFormHidden &&
      'Before continuing on to PaperOS, please choose a method of verification') ||
    'Please enter your email below.';

  return (
    <div className="login__container">
      <div className="login__logoWrapper">
        <img className={logoClasses} src={wordmark} alt="wordmark" />
      </div>
      <div className="login__formWrapper">
        {isInitLoading && (
          <div className="login__loading-overlay">
            <SavviLoading size="md" />
          </div>
        )}
        {!isInitLoading && (
          <>
            <h2 className="login__heading">
              Welcome
              {isEmailVerified && (
                <>
                  {','}
                  <div
                    className="login__emailLink"
                    onClick={() => {
                      setErrors({});
                      setEmailVerified(false);
                      setIsPasswordAllowed(true);
                      if (fl360sso) {
                        setIsFormHidden(true);
                        setIsManualLogin(false);
                      }
                    }}
                  >
                    <b>{email}</b>
                    <span className="login__link">
                      <FontAwesomeIcon icon={['fal', 'edit']} />
                    </span>
                  </div>
                  <small className="login__sub-heading">
                    {!isPasswordAllowed && <FontAwesomeIcon icon="exclamation-circle" />}
                    {loginDescription}
                  </small>
                </>
              )}
              {!isEmailVerified && (
                <>
                  {'!'}
                  <small className="login__sub-heading">{welcomeLabel}</small>
                </>
              )}
              {!!fl360sso && (
                <span>
                  <FontAwesomeIcon icon="exclamation-circle" />
                  Make sure to verify with the same email as your Fund Launch 360 account
                </span>
              )}
            </h2>
            <form
              className={`login__form${isFormHidden ? ' login__form--hidden' : ''}${
                isEmailVerified ? ' login__form--hide-email' : ''
              }`}
            >
              <InputStyledTextField
                autoComplete="username"
                error={errors.email}
                inputClass="-js-login__input-email"
                isDataHidden={isEmailVerified}
                label={'Email'}
                name={'email'}
                onBlur={validateLoginEmail}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                ref={emailRef}
                type="email"
                value={email}
              />
              <InputPassword
                autoComplete="current-password"
                error={errors.password}
                hasToggle={true}
                inputClass="-js-login__input-password"
                isDataHidden={!isEmailVerified || !isPasswordAllowed}
                isDisabled={!isPasswordAllowed}
                isPlainText={isPasswordPlainText}
                label={'Password'}
                name={'password'}
                onBlur={ev => {
                  if (!(ev.relatedTarget?.className || '').includes('send-email-link')) {
                    validateLoginPassword(ev);
                  }
                }}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                onToggle={() => setPasswordPlainText(!isPasswordPlainText)}
                ref={passwordRef}
                value={password}
              />
              {isSubmitted && errors.form && (
                <div className="login__error">{errors.form}</div>
              )}
              <div className="login__action">
                <Button
                  className="-js-login__btn-main"
                  isDisabled={isButtonDisabled}
                  isFetching={!isInitLoading && isFetching}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {primaryActionLabel}
                </Button>
              </div>
              {!!fl360sso && !isManualLogin && (
                <div
                  className={`login__linksWrapper ${
                    passedEmail ? 'login__linksWrapper--center' : ''
                  }`}
                >
                  <h4>Want to use a password?</h4>
                  <Button
                    buttonType="link"
                    isDisabled={isButtonDisabled}
                    className="-js-login__btn-send-email-link"
                    onClick={ev => handleSubmit(ev, null, true)}
                    size="sm"
                  >
                    Use PaperOS Password
                  </Button>
                </div>
              )}
              {(!fl360sso || isEmailVerified || !!isManualLogin) && (
                <div
                  className={`login__linksWrapper ${
                    passedEmail ? 'login__linksWrapper--center' : ''
                  }`}
                >
                  {!passedEmail && (
                    <h4>
                      {!isPasswordAllowed
                        ? 'Want to use a password?'
                        : 'Forgot password?'}
                    </h4>
                  )}
                  {!isPasswordAllowed && (
                    <Button
                      buttonType="link"
                      isDisabled={isButtonDisabled}
                      className="-js-login__btn-send-email-link"
                      onClick={ev => {
                        let passedEmail = email;
                        const doc = document.getElementById('email');

                        if (!isEmailVerified) {
                          if (doc.value && doc.value !== email) {
                            setEmail(doc.value);
                            passedEmail = email;
                          }
                        }
                        dispatch(
                          requestVerificationEmail({
                            identifier: {
                              type: 'email',
                              value: passedEmail,
                            },
                            template: 'reset-password',
                            state: { email: passedEmail, redirectLocation },
                          }),
                        ).then(e => {
                          dispatch(setNotice('Set new password email sent.'));
                        });
                      }}
                      size="sm"
                    >
                      Send New Password Link
                    </Button>
                  )}
                  {isPasswordAllowed && (
                    <>
                      <Button
                        buttonType="link"
                        isDisabled={isButtonDisabled}
                        className="-js-login__btn-send-email-link"
                        onClick={() => {
                          handleSendEmailVerification(true);

                          // if (!isEmailVerified) {
                          //   if (doc.value) {
                          //     setEmail(doc.value);
                          //     passedEmail = email;
                          //   }
                          // }

                          // dispatch(
                          //   requestVerificationEmail({
                          //     identifier: { type: 'email', value: email },
                          //     template: 'magic-link',
                          //     state: {
                          //       email,
                          //       redirectLocation,
                          //     },
                          //   }),
                          // ).then(e =>
                          //   push({
                          //     pathname: '/verify-code/magic-link',
                          //     state: {
                          //       email,
                          //       redirectLocation,
                          //     },
                          //   }),
                          // );
                        }}
                      >
                        Login By Email Link
                      </Button>
                      {' | '}
                      <Button
                        buttonType="link"
                        isDisabled={isButtonDisabled}
                        className="-js-login__btn-forgot-password"
                        to={{
                          pathname: '/forgot-password',
                          state: { email, redirectLocation },
                        }}
                        size="sm"
                      >
                        Reset Password
                      </Button>
                    </>
                  )}
                </div>
              )}
            </form>
            {isActionsLoading && <SavviLoading size="md" />}
            {isFormHidden && !isActionsLoading && (
              <div className="login__action">
                {nonFormPrimaryAction === 'google' && (
                  <Button
                    className="-js-login__btn-gauth"
                    buttonType="primary"
                    isDisabled={isButtonDisabled}
                    // isFetching={!isInitLoading && isFetching}
                    href={'/api/authn/oidc/accounts.google.com/auth'}
                    onClick={e => {
                      if (!!redirectLocation) {
                        localStorage.setItem('redirectLoc', redirectLocation);
                      }
                      if (hash && redirectLocation) {
                        let redirectHash = hash;
                        if (redirectLocation.includes('%2F')) {
                          redirectHash = hash.replace('#', '%23');
                        }
                        localStorage.setItem('redirectHash', redirectHash);
                      }
                      return e;
                    }}
                  >
                    <img
                      src={isFormHidden ? googleIconWhite : googleIcon}
                      alt="google-logo"
                    />
                    {`${fl360sso ? 'Continue' : 'Login'} with Google`}
                  </Button>
                )}
                {nonFormPrimaryAction === 'fl360' && (
                  <>
                    <Button
                      className="-js-login__btn-fl360 login__action--fl360"
                      isDisabled={isButtonDisabled}
                      // isFetching={!isInitLoading && isFetching}
                      href="/api/authn/oauth2/fl360/auth"
                      onClick={e => {
                        if (!!redirectLocation) {
                          localStorage.setItem('redirectLoc', redirectLocation);
                        }
                        if (hash && redirectLocation) {
                          let redirectHash = hash;
                          if (redirectLocation.includes('%2F')) {
                            redirectHash = hash.replace('#', '%23');
                          }
                          localStorage.setItem('redirectHash', redirectHash);
                        }
                        return e;
                      }}
                    >
                      <img src={fl360LogoWhite} alt="fund-launch-360--logo" />
                      Continue with Fund Launch 360
                      <TooltipAction text="When first using this you may still need to input your CRM login credentials even if you're already logged in there." />
                    </Button>
                    <div
                      className={`login__linksWrapper ${
                        passedEmail ? 'login__linksWrapper--center' : ''
                      }`}
                    >
                      <h4>Having issues verifying?</h4>
                      <Button
                        buttonType="link"
                        size="sm"
                        isDisabled={isButtonDisabled}
                        className="-js-login__btn-send-email-link"
                        onClick={ev => {
                          // localStorage.removeItem('fl360_sso');
                          // query.delete('fl360_sso');
                          // push({
                          //   pathname: '/login',
                          //   search: query.toString(),
                          //   state: { email, redirectPathname, passedEmail },
                          // });
                          setIsActionsLoading(true);
                          setTimeout(() => {
                            setIsShowingOtherMethods(true);
                            setIsActionsLoading(false);
                          }, 500);
                        }}
                      >
                        Try alternate methods
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {isSecondaryActionsShowing && (
        <div className="login__action">
          {isGoogleActionShowing && (
            <Button
              className="-js-login__btn-gauth"
              buttonType="secondary"
              isDisabled={isButtonDisabled}
              // isFetching={!isInitLoading && isFetching}
              href={'/api/authn/oidc/accounts.google.com/auth'}
              onClick={e => {
                if (!!redirectLocation) {
                  localStorage.setItem('redirectLoc', redirectLocation);
                }
                if (hash && redirectLocation) {
                  let redirectHash = hash;
                  if (redirectLocation.includes('%2F')) {
                    redirectHash = hash.replace('#', '%23');
                  }
                  localStorage.setItem('redirectHash', redirectHash);
                }
                return e;
              }}
            >
              <img src={googleIcon} alt="google-logo" />
              {`${fl360sso ? 'Continue' : 'Login'} with Google`}
            </Button>
          )}
          {isPaperOSActionShowing && !!fl360sso && (
            <Button
              className="-js-login__btn-gauth"
              buttonType="secondary"
              isDisabled={isButtonDisabled}
              isFetching={!isInitLoading && isFetching}
              onClick={e => {
                setIsFormHidden(false);
              }}
            >
              <img src={paperOSIcon} alt="paperOS-logo" />
              Continue with PaperOS
            </Button>
          )}
          {isFL360ActionShowing && (
            <Button
              className="-js-login__btn-fl360 login__action--fl360"
              buttonType="secondary"
              isDisabled={isButtonDisabled}
              href="/api/authn/oauth2/fl360/auth"
              onClick={e => {
                if (!!redirectLocation) {
                  localStorage.setItem('redirectLoc', redirectLocation);
                }
                if (hash && redirectLocation) {
                  let redirectHash = hash;
                  if (redirectLocation.includes('%2F')) {
                    redirectHash = hash.replace('#', '%23');
                  }
                  localStorage.setItem('redirectHash', redirectHash);
                }
                return e;
              }}
            >
              <img src={fl360Logo} alt="fund-launch-360--logo" />
              Continue with Fund Launch 360
              <TooltipAction text="When first using this you may still need to input your CRM login credentials even if you're already logged in there." />
            </Button>
          )}
        </div>
      )}
      {window.location.href.match(/localhost/) && (
        <div>
          <input ref={idTokenInjector} id="googleTokenInjector" />
          <button onClick={injectGoogleAuth} className="google-token-injector">
            Inject Google Auth Token
          </button>
        </div>
      )}
      <div className="login__text">
        <p>
          By continuing, you're confirming that you've read our
          <Button
            buttonType="link"
            isDisabled={isButtonDisabled}
            to={{
              pathname: '/terms',
              state: { background: location },
            }}
            size="sm"
          >
            Terms &amp; Conditions
          </Button>
        </p>
        <p>
          For help with any issues please contact{' '}
          <Button
            buttonType="link"
            isDisabled={isButtonDisabled}
            size="sm"
            href={`mailto:${support_email || 'support@savvi.legal'}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            support
          </Button>
        </p>
      </div>
    </div>
  );
};

export default Login;
