import { replace } from 'connected-react-router';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {
  connectedReduxRedirect,
  // connectedRouterRedirect,
} from 'redux-auth-wrapper/history4/redirect';

import Loading from '../components/LoadingComponent';
import { decodeToken } from '../redux/modules/User/utils';
import { setCompany } from '../redux/modules/Company/operations';
import { isEmpty } from 'lodash-es';

const locationHelper = locationHelperBuilder({});

const userIsAuthenticated = connectedReduxRedirect({
  redirectPath: (state, ownProps) => {
    const {
      location,
      match: {
        params: { companyId },
      },
    } = ownProps;
    const { pathname, search } = location;

    let redirectQueryParam = locationHelper.getRedirectQueryParam(ownProps);

    if (!state.Auth.isInitLoading && !state.Auth.user.isAuthenticated) {
      return '/login';
    }
    if (state.Auth.isInitLoading || !state.Auth.user.isAuthenticated) {
      return location;
    }
    if (!pathname.includes('company-select')) {
      if (!redirectQueryParam && pathname.includes('/c/0')) {
        return `/c/0/company-select?redirect=${encodeURIComponent(
          pathname + (search || ''),
        )}`;
      } else if (redirectQueryParam && redirectQueryParam.includes('/c/0')) {
        return {
          ...location,
          search: location.search.replace('%2Fc%2F0', `%2Fc%2F${companyId}`),
        };
      }
    }
    return location;
  },
  redirectAction: newLoc => (dispatch, getState) => {
    if (
      newLoc &&
      (newLoc.search.includes('company-select') || newLoc.search.includes('home'))
    ) {
      return dispatch(replace('/login'));
    }
    return dispatch(replace(newLoc));
  },
  authenticatedSelector: (state, ownProps) => {
    const {
      location: { pathname },
    } = ownProps;
    const redirectQueryParam = locationHelper.getRedirectQueryParam(ownProps);

    if (
      !pathname.includes('company-select') &&
      !pathname.includes('/teams') &&
      !pathname.includes('/user-settings') &&
      !pathname.includes('/dashboard') &&
      !pathname.includes('/lp-portal') &&
      !pathname.includes('company-select/new') &&
      !pathname.includes('verify') &&
      (pathname.includes('/c/0/') ||
        (redirectQueryParam && redirectQueryParam.includes('/c/0/')))
    ) {
      return false;
    }
    return state.Auth.user.isAuthenticated && !state.Auth.isInitLoading;
  },
  authenticatingSelector: state =>
    state.Auth.isInitLoading || state.Company.isCompanyTokenRefreshing,
  AuthenticatingComponent: Loading,
});

const userIsNotAuthenticated = connectedReduxRedirect({
  redirectPath: (state, ownProps) => {
    let updatedOwnProps = ownProps;
    const storedLocation = JSON.parse(localStorage.storedLocation || '{}');

    if (!state.Auth.user.isAuthenticated) {
      return ownProps.location;
    }
    if (!isEmpty(storedLocation)) {
      updatedOwnProps = {
        ...ownProps,
        location: {
          ...storedLocation,
          pathname: ownProps.location.pathname,
        },
      };
      localStorage.removeItem('storedLocation');
    }

    const redirectQueryParam = locationHelper.getRedirectQueryParam(updatedOwnProps);

    const {
      location: { hash, search, state: { redirectPathname } = {} },
      match: {
        params: { companyId },
      },
    } = updatedOwnProps;

    const decodedToken = decodeToken('stored');
    const { accountId } = decodedToken;
    const passedId = companyId || accountId || 0;

    if (redirectPathname) {
      return redirectPathname + (hash || '');
    }

    const redirectLoc = localStorage.getItem('redirectLoc');
    const redirectHash = localStorage.getItem('redirectHash');

    if (redirectLoc) {
      localStorage.removeItem('redirectLoc');
      localStorage.removeItem('redirectHash');
      return redirectLoc + (redirectHash || '');
    }

    if (redirectQueryParam) {
      if (redirectQueryParam.includes('/c/0/') && passedId && passedId !== '0') {
        return redirectQueryParam.replace('/c/0', `/c/${passedId}`);
      } else if (
        redirectQueryParam.includes('/c/0/') &&
        !redirectQueryParam.includes('company-select') &&
        !redirectQueryParam.includes(`/c/${accountId}`)
      ) {
        return `/c/0/company-select${search}${hash}`;
      }
    }
    return redirectQueryParam || `/c/${passedId}/company-select`;
  },
  allowRedirectBack: false,
  redirectAction: newLoc => (dispatch, getState) => {
    const state = getState();

    if (
      newLoc &&
      (newLoc.pathname.includes('data-room') ||
        newLoc.pathname.includes('reporting-room'))
    ) {
      return dispatch(
        replace({ pathname: newLoc.pathname, state: { reinitialize: true } }),
      );
    }
    if (newLoc && newLoc.pathname.includes('/c/')) {
      const redirectCompanyId = newLoc.pathname.split('/')[2];
      if (
        redirectCompanyId !== '0' &&
        redirectCompanyId !== state.Auth.account.accountId
      ) {
        return dispatch(
          setCompany(redirectCompanyId, newLoc, false, 'replace', true),
        ).then(
          e => {},
          err =>
            dispatch(
              replace({
                pathname: '/c/0/company-select',
                state: { isInitialLoad: true },
              }),
            ),
        );
      }
    }

    if (
      newLoc.pathname &&
      !newLoc.pathname.includes('/c/') &&
      !newLoc.pathname.includes('remote-login')
    ) {
      return dispatch(replace({ ...newLoc, pathname: `/c/0${newLoc.pathname}` }));
    }
    if (newLoc && !newLoc.search) {
      return dispatch(
        replace({ ...newLoc, state: { isPlansShowing: true, isInitialLoad: true } }),
      );
    }
    return dispatch(replace(newLoc));
  },
  authenticatedSelector: (state, ownProps) => !state.Auth.user.isAuthenticated,
});

export { userIsAuthenticated, userIsNotAuthenticated };
