export const OPEN_TERMS_MODAL = 'savvi/UI/OPEN_TERMS_MODAL';
export const CLOSE_TERMS_MODAL = 'savvi/UI/CLOSE_TERMS_MODAL';

export const OPEN_USER_DROPDOWN = 'savvi/UI/OPEN_USER_DROPDOWN';
export const CLOSE_USER_DROPDOWN = 'savvi/UI/CLOSE_USER_DROPDOWN';

export const SET_NOTICE = 'savvi/UI/SET_NOTICE';
export const CLEAR_NOTICE = 'savvi/UI/CLEAR_NOTICE';
export const CLEAR_NOTICES = 'savvi/UI/CLEAR_NOTICES';

export const UPDATE_TASK_REQUEST = 'savvi/formation/UPDATE_TASK_REQUEST';

export const SET_SEARCH = 'savvi/UI/SET_SEARCH';
export const CLEAR_SEARCH = 'savvi/UI/CLEAR_SEARCH';
export const SET_SIDEBAR_COLLAPSED = 'savvi/UI/SET_SIDEBAR_COLLAPSED';

export const FETCH_BRAND_META_REQUEST = 'savvi/UI/FETCH_BRAND_META_REQUEST';
export const FETCH_BRAND_META_SUCCESS = 'savvi/UI/FETCH_BRAND_META_SUCCESS';
export const FETCH_BRAND_META_FAILED = 'savvi/UI/FETCH_BRAND_META_FAILED';
export const SET_BRAND_META = 'savvi/UI/SET_BRAND_META';
