export const GET_ACCOUNT_TODOS_REQUEST = 'savvi/todos/GET_ACCOUNT_TODOS_REQUEST';
export const GET_ACCOUNT_TODOS_SUCCESS = 'savvi/todos/GET_ACCOUNT_TODOS_SUCCESS';
export const GET_ACCOUNT_TODOS_FAILED = 'savvi/todos/GET_ACCOUNT_TODOS_FAILED';

export const GET_USER_TODOS_REQUEST = 'savvi/todos/GET_USER_TODOS_REQUEST';
export const GET_USER_TODOS_SUCCESS = 'savvi/todos/GET_USER_TODOS_SUCCESS';
export const GET_USER_TODOS_FAILED = 'savvi/todos/GET_USER_TODOS_FAILED';

export const UPDATE_ACCOUNT_TODO_REQUEST = 'savvi/todos/UPDATE_ACCOUNT_TODO_REQUEST';
export const UPDATE_ACCOUNT_TODO_SUCCESS = 'savvi/todos/UPDATE_ACCOUNT_TODO_SUCCESS';
export const UPDATE_ACCOUNT_TODO_FAILED = 'savvi/todos/UPDATE_ACCOUNT_TODO_FAILED';

export const UPDATE_USER_TODO_REQUEST = 'savvi/todos/UPDATE_USER_TODO_REQUEST';
export const UPDATE_USER_TODO_SUCCESS = 'savvi/todos/UPDATE_USER_TODO_SUCCESS';
export const UPDATE_USER_TODO_FAILED = 'savvi/todos/UPDATE_USER_TODO_FAILED';

export const ASSIGN_ACCOUNT_TASK_TODO_REQUEST =
  'savvi/todos/ASSIGN_ACCOUNT_TASK_TODO_REQUEST';
export const ASSIGN_ACCOUNT_TASK_TODO_SUCCESS =
  'savvi/todos/ASSIGN_ACCOUNT_TASK_TODO_SUCCESS';
export const ASSIGN_ACCOUNT_TASK_TODO_FAILED =
  'savvi/todos/ASSIGN_ACCOUNT_TASK_TODO_FAILED';

export const ASSIGN_USER_TASK_TODO_REQUEST = 'savvi/todos/ASSIGN_USER_TASK_TODO_REQUEST';
export const ASSIGN_USER_TASK_TODO_SUCCESS = 'savvi/todos/ASSIGN_USER_TASK_TODO_SUCCESS';
export const ASSIGN_USER_TASK_TODO_FAILED = 'savvi/todos/ASSIGN_USER_TASK_TODO_FAILED';

export const DELETE_ACCOUNT_TODO_REQUEST = 'savvi/todos/DELETE_ACCOUNT_TODO_REQUEST';
export const DELETE_ACCOUNT_TODO_SUCCESS = 'savvi/todos/DELETE_ACCOUNT_TODO_SUCCESS';
export const DELETE_ACCOUNT_TODO_FAILED = 'savvi/todos/DELETE_ACCOUNT_TODO_FAILED';

export const DELETE_USER_TODO_REQUEST = 'savvi/todos/DELETE_USER_TODO_REQUEST';
export const DELETE_USER_TODO_SUCCESS = 'savvi/todos/DELETE_USER_TODO_SUCCESS';
export const DELETE_USER_TODO_FAILED = 'savvi/todos/DELETE_USER_TODO_FAILED';

export const GET_USER_ASSIGNEE_OPTIONS_REQUEST =
  'savvi/todos/GET_USER_ASSIGNEE_OPTIONS_REQUEST';
export const GET_USER_ASSIGNEE_OPTIONS_SUCCESS =
  'savvi/todos/GET_USER_ASSIGNEE_OPTIONS_SUCCESS';
export const GET_USER_ASSIGNEE_OPTIONS_FAILED =
  'savvi/todos/GET_USER_ASSIGNEE_OPTIONS_FAILED';

export const GET_ACCOUNT_ASSIGNEE_OPTIONS_REQUEST =
  'savvi/todos/GET_ACCOUNT_ASSIGNEE_OPTIONS_REQUEST';
export const GET_ACCOUNT_ASSIGNEE_OPTIONS_SUCCESS =
  'savvi/todos/GET_ACCOUNT_ASSIGNEE_OPTIONS_SUCCESS';
export const GET_ACCOUNT_ASSIGNEE_OPTIONS_FAILED =
  'savvi/todos/GET_ACCOUNT_ASSIGNEE_OPTIONS_FAILED';

export const SET_ACCOUNT_TODOS = 'savvi/todos/SET_ACCOUNT_TODOS';
export const SET_ASSIGNEE_OPTIONS = 'savvi/todos/SET_ASSIGNEE_OPTIONS';
export const SET_TEAM_TODOS = 'savvi/todos/SET_TEAM_TODOS';
