import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import ResetPasswordForm from '../../components/forms/ResetPasswordForm';

const ResetPassword = ({ code, history }) => (
  <Fragment>
    <ResetPasswordForm code={code} push={history.push} />
  </Fragment>
);

ResetPassword.defaultProps = {
  code: false,
};

const mapStateToProps = ({ Auth, UI }, ownProps) => {
  const { code = false } = ownProps.match.params;
  return { code };
};

export default connect(mapStateToProps)(ResetPassword);
