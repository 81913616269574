import React from 'react';

import classnames from 'classnames';
import { Field } from 'formik';
import { get, isEmpty, isObject } from 'lodash-es';

import { options } from '../../../data/StateData';
import {
  validateAddress,
  validateSelect,
  validateString,
  validateZip,
} from '../../../utils/FeatureTypes';
import { InputFeedback, InputHelp } from '../../inputs';
import { Select, TextField } from '../';
import CountryData from '../../../data/CountryData';
import './AddressGroup.scss';

const bem = elementName => `address-group${elementName ? '__' + elementName : ''}`;

const AddressGroup = ({
  field,
  form,
  handleFormBlur,
  label,
  questionHelp,
  isDisabled,
  isFixed,
  isRequired,
  isUniversal,
}) => {
  const { name: fieldName, value: fieldValue = {} } = field;
  const { errors, touched, setFieldError, setFieldValue } = form;
  const isTouched = get(touched, `${fieldName}.address`, false);
  const error = isTouched && get(errors, `${fieldName}.address`, null);

  const isAddressStarted = () => {
    const { line_one, line_two, city, state, zip } = fieldValue;
    return !!line_one || !!line_two || !!city || !!state || !!zip;
  };

  const handleAddressBlur = (name, givenVal) => {
    let updatedFieldVal = fieldValue;
    if (isEmpty(updatedFieldVal)) {
      updatedFieldVal = {
        line_one: '',
        line_two: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States of America',
      };
    }
    updatedFieldVal[name] = givenVal;

    let {
      line_one = '',
      line_two = '',
      city = '',
      state = '',
      zip = '',
      country = 'United States of America',
    } = updatedFieldVal;

    let value = `${line_one} \n${line_two} \n${city}, ${
      isObject(state) ? state.value : state
    } ${zip}`;
    if (isUniversal) {
      value += ` \n${isObject(country) ? country.value : country}`;
    }

    if (isUniversal && !fieldValue.country) {
      setFieldValue(`${fieldName}.country`, 'United States of America');
    }

    const myError = validateAddress(updatedFieldVal, isUniversal, true);
    if (isEmpty(myError)) {
      handleFormBlur({
        target: {
          value,
        },
      });
    } else if (isRequired || line_one || line_two || state || zip) {
      Object.entries(myError).forEach(([key, value]) => {
        if (get(touched, `${fieldName}${key}`, false)) {
          setFieldError(`${fieldName}.${key}`, value);
        }
      });
    }
  };
  const countryVal = fieldValue.country?.value || fieldValue.country;

  return (
    <div className={classnames(bem('container'), { [bem('container--error')]: !!error })}>
      {label && (
        <label className={bem('label')} htmlFor={'address'}>
          {label}
          {isRequired && <span className={bem('labelSpan')}>*</span>}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
      )}
      <InputFeedback error={error} />
      {isUniversal && (
        <Field
          autoComplete="nope"
          component={Select}
          defaultValue={CountryData[0]}
          isDisabled={isFixed || isDisabled}
          isFixed={isFixed}
          handleFormBlur={e => handleAddressBlur('country', e.target.value)}
          name={`${fieldName}.country`}
          options={CountryData}
          label="Country"
          validate={(isAddressStarted || isRequired) && validateSelect}
        />
      )}
      <Field
        autoComplete="nope"
        component={TextField}
        isDisabled={isFixed || isDisabled}
        isFixed={isFixed}
        handleFormBlur={e => handleAddressBlur('line_one', e.target.value)}
        name={`${fieldName}.line_one`}
        label="Street address, P.O. box"
        validate={(isAddressStarted() || isRequired) && validateString}
      />
      <Field
        autoComplete="nope"
        component={TextField}
        isDisabled={isFixed || isDisabled}
        isFixed={isFixed}
        handleFormBlur={e => handleAddressBlur('line_two', e.target.value)}
        name={`${fieldName}.line_two`}
        label="Apartment, suite, unit, building, floor, etc."
      />
      <div className={bem('row')}>
        <div className={bem('city')}>
          <Field
            autoComplete="nope"
            component={TextField}
            isDisabled={isFixed || isDisabled}
            isFixed={isFixed}
            handleFormBlur={e => handleAddressBlur('city', e.target.value)}
            name={`${fieldName}.city`}
            label="City"
            validate={(isAddressStarted() || isRequired) && validateString}
          />
        </div>
        <div className={bem('state')}>
          {(countryVal === 'United States of America' || !isUniversal) && (
            <Field
              component={Select}
              placeholder="Type to search/create..."
              isDisabled={isFixed || isDisabled}
              isFixed={isFixed}
              handleFormBlur={e => handleAddressBlur('state', e.target.value)}
              isCreatable={!!isUniversal}
              name={`${fieldName}.state`}
              options={options}
              label={isUniversal ? 'State/Province/Region' : 'State'}
              questionHelp="You can type out your Province or Region even if it's not in the list."
              validate={(isAddressStarted() || isRequired) && validateSelect}
            />
          )}
          {isUniversal && countryVal !== 'United States of America' && (
            <Field
              component={TextField}
              isDisabled={isFixed || isDisabled}
              isFixed={isFixed}
              handleFormBlur={e => handleAddressBlur('state', e.target.value)}
              name={`${fieldName}.state`}
              label={'State/Province/Region'}
              validate={(isAddressStarted() || isRequired) && validateString}
            />
          )}
        </div>
        <div className={bem('zip')}>
          <Field
            autoComplete="nope"
            component={TextField}
            isDisabled={isFixed || isDisabled}
            isFixed={isFixed}
            handleFormBlur={e => handleAddressBlur('zip', e.target.value)}
            myType="zip"
            name={`${fieldName}.zip`}
            label={isUniversal ? 'Postal Code' : 'Zip'}
            validate={
              countryVal === 'United States of America' &&
              (isAddressStarted() || isRequired) &&
              validateZip
            }
          />
        </div>
      </div>
    </div>
  );
};

AddressGroup.defaultProps = {
  handleFormBlur: () => {},
};

export default AddressGroup;
