import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './DocRecipientsModal.scss';
import { Button, MyModal } from '../common';
import {
  addDocumentRecipient,
  removeDocumentRecipient,
} from '../../redux/modules/Document/operations';
import { InputStyledTextField } from '../inputs';
import { isFetchingWithHooks } from '../../redux/modules/Document/selectors';
import { validateEmail } from '../../utils/FeatureTypes';
import { setNotice } from '../../redux/modules/UI/actions';
// import { useParams } from 'react-router-dom';

const DocRecipientsModal = ({
  documentLabel,
  document,
  accessCode,
  isOpen,
  handleClose,
}) => {
  const { email_recipients = [], recipients = [] } = document;
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [role, setRole] = useState('Signer');
  const [error, setError] = useState([]);
  const isFetching = useSelector(isFetchingWithHooks);

  const onClose = () => {
    setName('');
    setEmail('');
    setRole('Signer');
    setError('');
    handleClose();
  };

  const addRecipient = (actionType, body, recipientId) => {
    setError('');
    if (!name || !email || !role) {
      setError('Please fill out all fields.');
      return;
    }
    dispatch(
      addDocumentRecipient({
        email,
        document_id: document.id,
        metadata: {
          name,
          role,
        },
      }),
    ).then(
      p => {
        dispatch(setNotice(p?.message || `${name} connected to document`));
        onClose();
      },
      err => setError(''),
    );
  };

  const handleEmailValidation = ev => {
    setEmailErr(validateEmail(ev.target.value));
  };

  const removeRecipient = (name, recipientId) => {
    const message = `Are you sure you want to remove ${name} as a recipient? They will no longer be able to view this document in their User Dashboard.`;

    if (window.confirm(message)) {
      dispatch(removeDocumentRecipient(recipientId)).then(p => onClose());
    }
  };

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={'Savvi Terms of Service'}
      className="DocRecipientsModal"
      overlayClassName="DocRecipientsModal__overlay"
    >
      <div className="DocRecipientsModal__header">
        <h1>View Document Recipients</h1>
        <p>
          Any recipient connected to <em>{document.filename}</em> can view this document
          in their User Dashboard.
        </p>
        <FontAwesomeIcon
          className="DocRecipientsModal__exit"
          onClick={onClose}
          icon="times"
        />
      </div>
      <div className="DocRecipientsModal__body">
        <div className="DocRecipientsModal__subheader">
          <h3>Recipients</h3>
        </div>
        <div className="DocRecipientsModal__recipients">
          {email_recipients.length === 0 && recipients.length === 0 && (
            <div>
              <p>No Recipients Found.</p>
            </div>
          )}
          {recipients.map(recipient => (
            <div
              key={`recipient-${recipient.id}`}
              className="DocRecipientsModal__recipientCard"
            >
              <div className="DocRecipientsModal__recipientCardCol">
                <h4>{recipient.email}</h4>
                <p>{recipient.first_name + ' ' + recipient.last_name}</p>
              </div>
              <Button
                buttonType="icon"
                size="xs"
                isDisabled
                disabledTooltip="Cannot remove original recipients of a generated document."
              >
                <FontAwesomeIcon icon={['fal', 'trash-alt']} />
              </Button>
            </div>
          ))}
          {email_recipients.map(recipient => {
            const metadata = JSON.parse(recipient.metadata);
            return (
              <div
                key={`email-recipient-${recipient.id}`}
                className="DocRecipientsModal__recipientCard"
              >
                <div className="DocRecipientsModal__recipientCardCol">
                  <h4>{recipient.email}</h4>
                  <p>{metadata.name}</p>
                </div>
                <Button
                  buttonType="icon"
                  isWarning
                  onClick={() => removeRecipient(metadata.name, recipient.id)}
                  size="sm"
                >
                  <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                </Button>
              </div>
            );
          })}
        </div>
        <div className="DocRecipientsModal__addRecipient">
          <h3>New Recipient</h3>
          <InputStyledTextField
            autoComplete="name"
            label={'Name'}
            name={'name'}
            onChange={e => {
              setName(e.target.value);
            }}
            value={name}
          />
          <InputStyledTextField
            autoComplete="email"
            error={emailErr}
            label={'Email'}
            name={'email'}
            onChange={e => {
              setEmail(e.target.value);
            }}
            onBlur={handleEmailValidation}
            type="email"
            value={email}
          />
          <InputStyledTextField
            autoComplete="role"
            label={'Role'}
            name={'role'}
            onChange={e => {
              setRole(e.target.value);
            }}
            type="role"
            value={role}
          />
          {!!error && <h4 className="DocRecipientsModal__error">{error}</h4>}
        </div>
        <div className="DocRecipientsModal__actions">
          <Button buttonType="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            isFetching={isFetching}
            isDisabled={isFetching || !!emailErr}
            onClick={addRecipient}
          >
            <FontAwesomeIcon icon={['fal', 'user-plus']} />
            Add Recipient
          </Button>
        </div>
      </div>
    </MyModal>
  );
};

export default DocRecipientsModal;
