import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import paperOSLogo from '../../assets/images/paperOS_capital_at_scale_brown_md.png';
import paperOSLogoWhite from '../../assets/images/paperOS_wordmark_white_md.png';
import handlePath from '../../utils/handlePath';
import OrgButton from '../OrgButton';
import { DashboardNav } from '../Sidebar';
import TeamsNav from '../Sidebar/TeamsNav';

import '../../views/Sidebar/Sidebar.scss';
import './BasicTopbar.scss';
import { BrandMeta } from '../../redux/modules/UI/selectors';
import { useSelector } from 'react-redux';
import { MyModal } from '../common';

const BasicTopbar = ({ accountId, isAuthenticated, isDataRoom, limitedUserEmail }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const brandMeta = useSelector(BrandMeta);
  const { logo_url, assets: { logo_white } = {} } = brandMeta;

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const isOverlayToggleVisible = isDataRoom && isAuthenticated;

  return (
    <div className="basic-topbar">
      <ul className="basic-topbar__nav">
        {isOverlayToggleVisible && (
          <div
            className="sidebar__toggleOverlay"
            onClick={() => setIsOverlayVisible(true)}
          >
            <FontAwesomeIcon icon="bars" />
          </div>
        )}
        {!isDataRoom && !isAuthenticated && (
          <li
            className="basic-topbar__nav-list-item basic-topbar__logo"
            onClick={() => push('/login')}
          >
            <img src={logo_url || paperOSLogo} alt="topbar logo" />
          </li>
        )}
        {isDataRoom && (
          <ul className="basic-topbar__secondary-nav">
            <li className="basic-topbar__nav-list-item--secondary user-menu">
              <OrgButton
                isDataRoom={isDataRoom}
                darkText
                limitedUserEmail={limitedUserEmail}
              />
            </li>
          </ul>
        )}
      </ul>
      {isOverlayToggleVisible && (
        <MyModal
          className="sidebar__container is-overlay"
          isOpen={isOverlayVisible}
          onRequestClose={() => setIsOverlayVisible(false)}
          overlayClassName="sidebar__overlay"
        >
          <div
            className="sidebar__logo"
            onClick={e => {
              push(handlePath({ pathname: '/home', search }, accountId));
            }}
          >
            <img src={logo_white || paperOSLogoWhite} alt="" />
          </div>
          <div
            className="sidebar__arrow is-overlay"
            onClick={() => setIsOverlayVisible(!isOverlayVisible)}
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </div>
          {accountId !== 0 && <DashboardNav account_id={accountId} />}
          {accountId === 0 && <TeamsNav />}
        </MyModal>
      )}
    </div>
  );
};

BasicTopbar.defaultProps = {
  isAuthenticated: false,
};

export default BasicTopbar;
