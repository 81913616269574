import { RSAA } from 'redux-api-middleware';

import { requestHeaders } from '../../utils/api';
import { setNotice } from '../UI/actions';
import { setEmailVerification } from '../User/utils';
import {
  setCounsels,
  setEmails,
  setFeatureChanges,
  setSavviPartners,
  setTeams,
  updateSettings,
} from './actions';
import * as types from './types';

const getSettings = () => {
  const { GET_REQUEST, GET_SUCCESS, GET_FAILED } = types;
  return async (dispatch, getState) => {
    const actionResponse = await Promise.resolve(
      dispatch({
        [RSAA]: {
          endpoint: '/api/user/settings/profile',
          method: 'GET',
          headers: requestHeaders(true, 'user'),
          types: [GET_REQUEST, GET_SUCCESS, GET_FAILED],
        },
      }),
    );
    if (!actionResponse.error) {
      return dispatch(updateSettings(actionResponse.payload));
    } else {
      throw await new Error(actionResponse.payload?.message);
    }
  };
};

const saveSettings = data => {
  const { SAVE_REQUEST, SAVE_FAILED, SAVE_SUCCESS } = types;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/profile',
        method: 'PUT',
        headers: requestHeaders(true, 'user'),
        types: [SAVE_REQUEST, SAVE_SUCCESS, SAVE_FAILED],
        body: JSON.stringify(data),
      },
    });
    if (!actionResponse.error) {
      dispatch(updateSettings(actionResponse.payload));
      dispatch(setNotice('profile updated'));
    }
    dispatch(setNotice({ type: 'warning', message: actionResponse.payload?.message }));
    throw await new Error(actionResponse.payload?.message);
  };
};

const changePassword = passwords => {
  const { PASSWORD_REQUEST, PASSWORD_FAILED, PASSWORD_SUCCESS } = types;
  return async (dispatch, getState) => {
    const body = JSON.stringify(passwords);
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/password',
        method: 'PUT',
        headers: requestHeaders(true, 'user'),
        types: [PASSWORD_REQUEST, PASSWORD_SUCCESS, PASSWORD_FAILED],
        body,
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Your password has been successfully changed.'));
      return await actionResponse.payload;
    } else {
      throw await actionResponse.payload;
    }
  };
};

const getFeatureChanges = () => {
  const {
    GET_FEATURE_CHANGES_REQUEST: REQUEST,
    GET_FEATURE_CHANGES_FAILED: FAILED,
    GET_FEATURE_CHANGES_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/profile/feature_change',
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setFeatureChanges(actionResponse.payload.feature_changes));
    }
  };
};

const setFeatureChangesDate = () => {
  const {
    SET_FEATURE_CHANGES_DATE_REQUEST: REQUEST,
    SET_FEATURE_CHANGES_DATE_FAILED: FAILED,
    SET_FEATURE_CHANGES_DATE_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/profile/feature_change',
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(getSettings());
      dispatch(getFeatureChanges());
      return await actionResponse;
    }
  };
};

//Update Email Ops

const fetchEmailsToVerify = () => {
  const {
    GET_EMAILS_REQUEST: REQUEST,
    GET_EMAILS_FAILED: FAILED,
    GET_EMAILS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/profile/email',
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setEmails(actionResponse.payload));
    }
  };
};

const sendUpdatedEmailCode = email => {
  const {
    SEND_EMAIL_CODE_REQUEST: REQUEST,
    SEND_EMAIL_CODE_FAILED: FAILED,
    SEND_EMAIL_CODE_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/profile/email',
        method: 'PATCH',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ email }),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Updated Email Verification Code Sent'));
      return await setEmailVerification('email-change-code', actionResponse.payload)[
        'email-change-code'
      ];
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw new Error(actionResponse.payload.message);
    }
  };
};

const deleteVerificationRequest = email => {
  const {
    DELETE_EMAIL_VERIFICATION_REQUEST: REQUEST,
    DELETE_EMAIL_VERIFICATION_FAILED: FAILED,
    DELETE_EMAIL_VERIFICATION_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/user/settings/profile/email',
        method: 'DELETE',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ email }),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Email Verification Deleted'));
      dispatch(fetchEmailsToVerify());
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

const verifyUpdatedEmail = (email, code) => {
  const {
    VERIFY_UPDATED_EMAIL_REQUEST: REQUEST,
    VERIFY_UPDATED_EMAIL_FAILED: FAILED,
    VERIFY_UPDATED_EMAIL_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/settings/profile/email/${code}`,
        method: 'PATCH',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ email }),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Email Updated'));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

const getCounsels = () => {
  const {
    GET_COUNSELS_REQUEST: REQUEST,
    GET_COUNSELS_FAILED: FAILED,
    GET_COUNSELS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/counsel',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(setCounsels(actionResponse.payload));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

const getSavviPartners = () => {
  const {
    GET_SAVVI_PARTNERS_REQUEST: REQUEST,
    GET_SAVVI_PARTNERS_FAILED: FAILED,
    GET_SAVVI_PARTNERS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/partner',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(setSavviPartners(actionResponse.payload));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

const addSavviPartnerGroupMember = (id, body) => {
  const {
    ADD_SAVVI_PARTNER_GROUP_MEMBER_REQUEST: REQUEST,
    ADD_SAVVI_PARTNER_GROUP_MEMBER_FAILED: FAILED,
    ADD_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/partner/add_member/${id}`,
        method: 'POST',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice({ message: actionResponse.payload.message }));
      return await dispatch(getTeams());
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      return await actionResponse;
    }
  };
};

const editSavviPartnerGroupMember = (partner_id, user_id, role_id) => {
  const {
    EDIT_SAVVI_PARTNER_GROUP_MEMBER_REQUEST: REQUEST,
    EDIT_SAVVI_PARTNER_GROUP_MEMBER_FAILED: FAILED,
    EDIT_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/partner/${partner_id}/${user_id}`,
        method: 'PUT',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ role_id }),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice({ message: actionResponse.payload.message }));
      return await dispatch(getTeams());
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

const removeSavviPartnerGroupMember = (groupId, user_id) => {
  const {
    REMOVE_SAVVI_PARTNER_GROUP_MEMBER_REQUEST: REQUEST,
    REMOVE_SAVVI_PARTNER_GROUP_MEMBER_FAILED: FAILED,
    REMOVE_SAVVI_PARTNER_GROUP_MEMBER_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/partner/${groupId}/${user_id}`,
        method: 'DELETE',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice({ type: 'warning', message: 'Member removed from partner group.' }),
      );
      return await dispatch(getTeams());
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

export const getTeams = (queryStr = '?members=1&packages=1&accounts=1') => {
  const {
    GET_TEAMS_REQUEST: REQUEST,
    GET_TEAMS_FAILED: FAILED,
    GET_TEAMS_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/team${queryStr}`,
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(setTeams(actionResponse.payload));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

export const removeTeamAccount = (partnerId, groupId) => {
  const {
    REMOVE_TEAM_ACCOUNT_REQUEST: REQUEST,
    REMOVE_TEAM_ACCOUNT_FAILED: FAILED,
    REMOVE_TEAM_ACCOUNT_SUCCESS: SUCCESS,
  } = types;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/partner/remove_account/${partnerId}/${groupId}`,
        method: 'DELETE',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice({ type: 'warning', message: actionResponse.payload.message }));
      return await dispatch(getTeams());
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
    }
  };
};

const getCalendlyMeta = () => {
  const {
    GET_CALENDLY_REQUEST: REQUEST,
    GET_CALENDLY_FAILED: FAILED,
    GET_CALENDLY_SUCCESS: SUCCESS,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/calendly',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return actionResponse.payload;
    }
  };
};

export {
  getCalendlyMeta,
  getCounsels,
  getFeatureChanges,
  addSavviPartnerGroupMember,
  editSavviPartnerGroupMember,
  removeSavviPartnerGroupMember,
  getSavviPartners,
  getSettings,
  saveSettings,
  setFeatureChangesDate,
  changePassword,
  //Update Email Ops
  fetchEmailsToVerify,
  sendUpdatedEmailCode,
  deleteVerificationRequest,
  verifyUpdatedEmail,
};
