import { get } from 'lodash-es';
import { createSelector, defaultMemoize } from 'reselect';

import WorkflowLearnMoreLinks from '../../../utils/WorkflowLearnMoreLinks';

export const IsFetching = defaultMemoize(state => get(state, 'Todos.isFetching', false));

export const AssigneeOptions = defaultMemoize((state, accountId, projectTemplateId) =>
  get(state, `Todos.assigneeOptions[${accountId}|${projectTemplateId}`, []),
);

export const FormattedAssigneeOptions = createSelector(AssigneeOptions, options =>
  options.map(e => ({
    ...e,
    label: e.name,
    value:
      (e.partner_id && e.user_id && `${e.user_id} | ${e.partner_id}`) ||
      e.partner_id ||
      e.user_id,
  })),
);

export const Todos = defaultMemoize((state, isTeam) =>
  isTeam ? get(state, 'Todos.teamTodos', []) : get(state, 'Todos.accountTodos', []),
);

export const RecommendedTodos = defaultMemoize((state, isTeam) =>
  isTeam
    ? get(state, 'Todos.teamReminders', [])
    : get(state, 'Todos.accountReminders', []),
);

export const TodosForHome = createSelector(Todos, todos =>
  todos.reduce((dict, e) => {
    let isLearnMoreShowing = false;
    let learnMoreUrl = '';
    if (e.type_id === 1) {
      e.type = 'Recommended Workflow';
    }
    for (const values of Object.entries(WorkflowLearnMoreLinks)) {
      if (values[0] + '' === e.project_template_id + '') {
        isLearnMoreShowing = true;
        learnMoreUrl = values[1];
      }
    }
    e = {
      ...e,
      isLearnMoreShowing,
      learnMoreUrl,
    };
    dict.push(e);
    return dict;
  }, []),
);

export const RecommendedTodosForHome = createSelector(RecommendedTodos, todos =>
  todos.reduce((dict, e) => {
    if (e.type_id === 1) {
      let isLearnMoreShowing = false;
      let learnMoreUrl = '';
      for (const values of Object.entries(WorkflowLearnMoreLinks)) {
        if (values[0] + '' === e.id + '') {
          isLearnMoreShowing = true;
          learnMoreUrl = values[1];
        }
      }
      e = {
        ...e,
        project: {
          ...e.project,
          isLearnMoreShowing,
          learnMoreUrl,
        },
      };
      dict.push(e);
    }
    return dict;
  }, []),
);
