import dayjs from 'dayjs/esm';
import PandaDocStatusDict from './PandaDocStatusDict';

const documentUpdatedAtSort = (a, b) => {
  // TODO check formatting of timestamps.
  let aDate =
    a.completed_timestamp ||
    a.upload_timestamp ||
    a.panda_data_timestamp ||
    a.sent_timestamp ||
    a.draft_timestamp ||
    (a.flat_features || [])[0]?.updated_at ||
    '';
  let bDate =
    b.completed_timestamp ||
    b.upload_timestamp ||
    b.panda_data_timestamp ||
    b.sent_timestamp ||
    b.draft_timestamp ||
    (b.flat_features || [])[0]?.updated_at ||
    '';

  if (aDate && !bDate) {
    return -1;
  } else if (!aDate && bDate) {
    return 1;
  } else if (!aDate && !bDate) {
    return 0;
  }
  return dayjs(bDate).diff(aDate);
};

const documentCreatedAtSort = (a, b) => {
  let aDate =
    a.sent_timestamp ||
    a.completed_timestamp ||
    a.upload_timestamp ||
    a.draft_timestamp ||
    a.panda_data_timestamp ||
    (a.flat_features || [])[0]?.updated_at ||
    '';
  let bDate =
    b.sent_timestamp ||
    b.completed_timestamp ||
    b.upload_timestamp ||
    b.draft_timestamp ||
    b.panda_data_timestamp ||
    (b.flat_features || [])[0]?.updated_at ||
    '';

  if (aDate && !bDate) {
    return -1;
  } else if (!aDate && bDate) {
    return 1;
  } else if (!aDate && !bDate) {
    return 0;
  }
  return dayjs(bDate).diff(aDate);
};

export const sortStrings = (a, b) => a.localeCompare(b);

export const documentsSortOptions = {
  name: {
    label: 'Name',
    slug: 'name',
    iconType: 'a-z',
    sortFunc: (a, b) => {
      const aLabel =
        a.label ||
        a.template_variant?.template_type?.customer_label ||
        a.template_variant?.customer_label ||
        (a.flat_features || [])[0]?.feature_type?.question_label ||
        a.template_variant?.preview?.label ||
        a.filename ||
        a.template_variant?.filename ||
        '--';
      const bLabel =
        b.label ||
        b.template_variant?.template_type?.customer_label ||
        b.template_variant?.customer_label ||
        (b.flat_features || [])[0]?.feature_type?.question_label ||
        b.template_variant?.preview?.label ||
        b.filename ||
        b.template_variant?.filename ||
        '--';
      return sortStrings(aLabel, bLabel);
    },
  },
  filename: {
    label: 'Filename',
    slug: 'filename',
    iconType: 'a-z',
    sortFunc: (a, b) => {
      const aLabel = a.filename || a.template_variant?.filename || '--';
      const bLabel = b.filename || b.template_variant?.filename || '--';
      return sortStrings(aLabel, bLabel);
    },
  },
  status: {
    label: 'Status',
    slug: 'status',
    iconType: 'a-z',
    sortFunc: (a, b) => {
      const aStatus =
        (a.revision_count > 0 && 'Awaiting Approval') ||
        (a.box_file_id && !a.status && 'Uploaded') ||
        PandaDocStatusDict(a.status);
      const bStatus =
        (b.revision_count > 0 && 'Awaiting Approval') ||
        (b.box_file_id && !b.status && 'Uploaded') ||
        PandaDocStatusDict(b.status);
      return sortStrings(aStatus, bStatus);
    },
  },
  date: {
    label: 'Date Created',
    slug: 'date',
    iconType: 'wide-short',
    sortFunc: documentCreatedAtSort,
  },
  none: {
    label: 'Default (last updated)',
    slug: 'none',
    sortFunc: documentUpdatedAtSort,
  },
};

export const userDocumentsSortOptions = {
  name: {
    label: 'Name',
    slug: 'name',
    iconType: 'a-z',
    sortFunc: (a, b) => {
      const aLabel =
        a.label ||
        a.template_variant?.template_type?.customer_label ||
        a.template_variant?.customer_label ||
        (a.flat_features || [])[0]?.feature_type?.question_label ||
        a.template_variant?.preview?.label ||
        a.filename ||
        a.template_variant?.filename ||
        '--';
      const bLabel =
        b.label ||
        b.template_variant?.template_type?.customer_label ||
        b.template_variant?.customer_label ||
        (b.flat_features || [])[0]?.feature_type?.question_label ||
        b.template_variant?.preview?.label ||
        b.filename ||
        b.template_variant?.filename ||
        '--';
      return sortStrings(aLabel, bLabel);
    },
  },
  filename: {
    label: 'Filename',
    slug: 'filename',
    iconType: 'a-z',
    sortFunc: (a, b) => {
      const aLabel = a.filename || a.template_variant?.filename || '--';
      const bLabel = b.filename || b.template_variant?.filename || '--';
      return sortStrings(aLabel, bLabel);
    },
  },
  company_name: {
    label: 'Workspace Name',
    slug: 'company_name',
    iconType: 'a-z',
    sortFunc: (a, b) => sortStrings(a.account.name, b.account.name),
  },
  status: {
    label: 'Status',
    slug: 'status',
    iconType: 'a-z',
    sortFunc: (a, b) => {
      const aStatus =
        (a.revision_count > 0 && 'Awaiting Approval') ||
        (a.box_file_id && !a.status && 'Uploaded') ||
        PandaDocStatusDict(a.status);
      const bStatus =
        (b.revision_count > 0 && 'Awaiting Approval') ||
        (b.box_file_id && !b.status && 'Uploaded') ||
        PandaDocStatusDict(b.status);
      return sortStrings(aStatus, bStatus);
    },
  },
  date: {
    label: 'Date Created',
    slug: 'date',
    iconType: 'wide-short',
    sortFunc: documentCreatedAtSort,
  },
  none: {
    label: 'Default (last updated)',
    slug: 'none',
    sortFunc: documentUpdatedAtSort,
  },
};
