import { combineReducers } from 'redux';

import deepFreeze from 'deep-freeze';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const resourcesByTypeIdReducer = () =>
  createReducer(deepFreeze({}))({
    [Types[`SET_RESOURCES_BY_TYPE_ID`]]: (state, { payload, resourceTypeId }) => ({
      ...state,
      [resourceTypeId]: [...payload],
    }),
    [Types[`SET_RESOURCE_BY_ID`]]: (state, { payload, resourceTypeId }) => {
      const resourceTypeObj = state[resourceTypeId] || [];
      let updatedArr = [...resourceTypeObj];
      const resourceIndex = updatedArr.findIndex(e => e.id === payload.id);
      if (resourceIndex !== -1) {
        updatedArr[resourceIndex] = {
          ...updatedArr[resourceIndex],
          ...payload,
        };
      } else {
        updatedArr.push(payload);
      }
      return {
        ...state,
        [resourceTypeId]: updatedArr,
      };
    },
  });

const featureReducer = () =>
  createReducer(deepFreeze({}))({
    [Types['SET_FEATURE']]: (
      state,
      { payload: { feature }, individualName, featureName },
    ) => {
      return {
        ...state,
        by_name: {
          ...state.by_name,
          [individualName]: {
            ...state.by_name[individualName],
            features: {
              ...state.by_name[individualName].features,
              [featureName]: [feature],
            },
          },
        },
      };
    },
    [Types['SET_FEATURES']]: (state, { payload, slug }) => ({
      ...state,
      [slug]: payload,
    }),
    [Types['PATCH_FEATURE']]: (
      state,
      { payload: { feature }, individualName, featureName },
    ) => {
      return {
        ...state,
        by_name: {
          ...state.by_name,
          [individualName]: {
            ...state.by_name[individualName],
            features: {
              ...state.by_name[individualName].features,
              [featureName]: [
                {
                  ...state.by_name[individualName].features[featureName][0],
                  feature_id: feature.id,
                  feature_type_id: feature.feature_type_id,
                  value: feature.value,
                },
              ],
            },
          },
        },
      };
    },
    [Types['REMOVE_INDIVIDUAL']]: (state, { resourceId }) => {
      const updatedByName = Object.fromEntries(
        Object.entries(state.by_name).filter(
          ([key, value]) => value.resource_id !== resourceId,
        ),
      );
      return {
        ...state,
        by_name: {
          ...updatedByName,
        },
      };
    },
  });

const accountInfoReducer = () =>
  createReducer(deepFreeze({}))({
    [Types['SET_ACCOUNT_INFO']]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  });

/**
 * Given the Formation state slice and a redux action this returns the updated Formation state slice.
 *
 * @param {object} state :: Formation state slice.
 * @param {object} action :: redux action.
 * @return {object} updated Formation state slice.
 */
export default combineReducers({
  resources: resourcesByTypeIdReducer(),
  features: featureReducer(),
  accountInfo: accountInfoReducer(),
  isFetching: fetchingReducer('formation'),
  apiErrors: apiErrorsReducer('formation'),
});
