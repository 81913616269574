import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { InputFeedback, InputHelp, InputSublabel } from '../';

const InputCheckbox = props => {
  const {
    error,
    name,
    value,
    checked,
    isDisabled,
    type,
    display,
    label,
    labelStyle,
    questionHelp,
    sublabel,
    onClick,
    flipLabel = false,
  } = props;

  const input = (
    <input
      type={type}
      disabled={isDisabled}
      value={value}
      className="styled"
      checked={checked}
      name={name}
      onChange={onClick}
    />
  );

  const isStacked = display === 'stacked';

  const labelClasses = classnames('control__label', {
    [`${type}-inline`]: !isStacked,
    disabled: !isStacked && isDisabled,
  });
  const choiceClasses = classnames('choice', { disabled: isDisabled });
  const checkedClasses = classnames({ checked: checked });
  const wrapperClasses = classnames(type, { disabled: isDisabled });

  return (
    <div
      className={classnames('workbench-form__group checkbox__group', {
        'workbench-form__group--has-error': !!error,
      })}
    >
      <StackWrapper classes={wrapperClasses} isStacked={isStacked}>
        {!flipLabel && (
          <label className={labelClasses}>
            <div className={choiceClasses}>
              <span className={checkedClasses}>{input}</span>
            </div>
            <div className={labelStyle}>
              {label}
              {questionHelp && <InputHelp text={questionHelp} />}
            </div>
          </label>
        )}
        {flipLabel && (
          <label className={labelClasses}>
            <div className={labelStyle}>
              {label}
              {questionHelp && <InputHelp text={questionHelp} />}
            </div>
            <div className={choiceClasses}>
              <span className={checkedClasses}>{input}</span>
            </div>
          </label>
        )}
      </StackWrapper>
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
    </div>
  );
};

const StackWrapper = ({ children, isStacked, classes }) =>
  isStacked ? (
    <div className={classes}>{children}</div>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

InputCheckbox.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  type: PropTypes.oneOf(['radio', 'checkbox']),
  display: PropTypes.oneOf(['stacked', 'inline']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InputCheckbox.defaultProps = {
  display: 'stacked',
  value: false,
  isDisabled: false,
  checked: false,
  name: '',
  type: 'checkbox',
  sublabel: null,
  error: null,
  onClick: () => {},
};

export default InputCheckbox;
