import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setCompany } from '../../redux/modules/Company/operations';
import { getAccountId } from '../../redux/modules/Formation/selectors';
import { Button, Dropdown } from '../common';
import PandaDocStatusDict from '../../utils/PandaDocStatusDict';

import './DocumentsSection.scss';
import { resendLinkEmail } from '../../redux/modules/Transaction/operations';
import { setNotice } from '../../redux/modules/UI/actions';
import { getAuthUser } from '../../redux/modules/User/selectors';
import handlePath from '../../utils/handlePath';
import {
  createRoomHistory,
  deleteRoomDocument,
  swapRoomDocuments,
  updateRoomDocument,
} from '../../redux/modules/Data/operations';
import { requestHeaders } from '../../redux/utils/api';
import { FilePreviewer } from '../FilePreviewer/FilePreviewer';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import dayjs from 'dayjs/esm';
import get from 'lodash-es/get';
import SortDropdown from '../SortDropdown';
import {
  documentsSortOptions,
  userDocumentsSortOptions,
} from '../../utils/DocumentsSortOptions';
import { InputStyledTextField } from '../inputs';
import DocRecipientsModal from '../DocRecipientsModal';
import { isEmpty } from 'lodash';
import { getAllDocuments } from '../../redux/modules/Document/operations';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const DocumentsSection = ({
  accessCode,
  accountId,
  documentsRef,
  hideToken,
  initialDocsShowing = 5,
  isAllDocuments,
  isAdmin,
  isDataRoom,
  isEditing,
  isHeightUnset,
  isPdfHiding,
  isSectionOpenInit,
  isSimpleTable,
  limitedUserEmail,
  isMyDashboard,
  isUserTable,
  isWarning,
  moduleId,
  sectionLabel,
  documents,
  roomId,
}) => {
  const dispatch = useDispatch();
  const brandMeta = useSelector(BrandMeta);
  const currentAccountId = useSelector(getAccountId);
  const boxToken = useSelector(BoxToken);
  const currentUser = useSelector(getAuthUser);
  const [docAmountShowing, setDocAmountShowing] = useState(
    isSectionOpenInit ? 0 : initialDocsShowing,
  );
  const [docRecipientsModalDocument, setDocRecipientsModalDocument] = useState({});
  const [isSortReversed, setIsSortReversed] = useState(false);
  // const [isSectionOpen, setIsOpen] = useState(isSectionOpenInit);
  const [sortOption, setSortOption] = useState(documentsSortOptions.none);
  const { slug, sortFunc } = sortOption;

  const myDocuments = useMemo(() => {
    if (isDataRoom && slug === 'none') {
      return documents;
    }
    if (isSortReversed) {
      return documents.sort(sortFunc).reverse();
    }
    return documents.sort(sortFunc);
  }, [documents, isDataRoom, isSortReversed, sortFunc, slug]);

  const handleSortRooms = selectedOption => {
    let updatedIsSortReversed = false;
    if (selectedOption.slug === slug) {
      updatedIsSortReversed = !isSortReversed;
    }
    setIsSortReversed(updatedIsSortReversed);
    setSortOption(selectedOption);
  };

  const handleOpenDocRecipientsModal = (ev, myDocument) => {
    ev.preventDefault();
    setDocRecipientsModalDocument(myDocument);
  };

  let docAdjustmentAmount = 25;
  if (myDocuments.length / 5 > 25) {
    docAdjustmentAmount = myDocuments.length / 5;
  }
  if (myDocuments.length / 5 > 200) {
    docAdjustmentAmount = 200;
  }

  const showLessDocuments = () => {
    let amountToShow = docAmountShowing - docAdjustmentAmount;
    if (amountToShow > myDocuments.length) {
      amountToShow = myDocuments.length - docAdjustmentAmount;
    }
    if (amountToShow < 5) {
      amountToShow = 5;
    }
    setDocAmountShowing(amountToShow);
  };
  const showMoreDocuments = () =>
    setDocAmountShowing(docAmountShowing + docAdjustmentAmount);

  return (
    <div
      className={classnames('documentsSection', {
        'documentsSection--room': !!accessCode,
      })}
      ref={documentsRef}
    >
      <div className="documentsSection__header">
        <h2 className="documentsSection__headerLabel">
          {!hideToken && <FontAwesomeIcon icon={['fal', 'copy']} />}
          {sectionLabel}
          <span>{` (${myDocuments.length > 0 ? myDocuments.length : 'None'})`}</span>
        </h2>
        {!!accessCode && <div className="documentsSection__headerDivider" />}
        <SortDropdown
          isDisabled={myDocuments.length < 2}
          isSortReversed={isSortReversed}
          onSelect={handleSortRooms}
          sortOption={sortOption}
          sortOptions={isMyDashboard ? userDocumentsSortOptions : documentsSortOptions}
        />
      </div>
      {myDocuments.length > 0 && (
        <div
          className={classnames('documentsSection__list', {
            myTasks: isMyDashboard,
            unsetHeight: isHeightUnset,
            simpleTable: isSimpleTable,
          })}
        >
          {myDocuments.map(
            (myDocument, index) =>
              (!docAmountShowing || index < docAmountShowing) && (
                <DocumentRow
                  key={`actionItems-${myDocument.id}-${index}`}
                  accessCode={accessCode}
                  accountId={accountId}
                  boxToken={boxToken}
                  currentAccountId={currentAccountId}
                  currentUser={currentUser}
                  dispatch={dispatch}
                  index={index}
                  isAdmin={isAdmin}
                  isDataRoom={isDataRoom}
                  isSimpleTable={isSimpleTable}
                  isUserTable={isUserTable}
                  isEditing={isEditing}
                  isMyDashboard={isMyDashboard}
                  isPdfHiding={isPdfHiding}
                  limitedUserEmail={limitedUserEmail}
                  moduleId={moduleId}
                  myDocument={myDocument}
                  myDocuments={myDocuments}
                  roomId={roomId}
                  openDocRecipientsModal={handleOpenDocRecipientsModal}
                  webBaseUrl={brandMeta.web_base_url}
                />
              ),
          )}
          <div className="documentsSection__listToggleActions">
            {docAmountShowing > 5 && (
              <Button
                buttonType="link"
                className="documentsSection__listToggle"
                onClick={showLessDocuments}
                size="sm"
              >
                show less documents
                <FontAwesomeIcon icon="caret-up" />
              </Button>
            )}
            {myDocuments.length > 4 && docAmountShowing < myDocuments.length && (
              <Button
                buttonType="link"
                className="documentsSection__listToggle"
                onClick={showMoreDocuments}
                size="sm"
              >
                show more documents
                <FontAwesomeIcon icon="caret-down" />
              </Button>
            )}
          </div>
        </div>
      )}
      <DocRecipientsModal
        isOpen={!isEmpty(docRecipientsModalDocument)}
        document={docRecipientsModalDocument}
        handleClose={() => {
          setDocRecipientsModalDocument({});
          if (isAllDocuments) {
            dispatch(getAllDocuments());
          }
        }}
      />
    </div>
  );
};

const DocumentRow = ({
  accessCode,
  accountId,
  boxToken,
  currentAccountId,
  currentUser,
  myDocument,
  dispatch,
  index,
  isAdmin,
  isSimpleTable,
  isUserTable,
  isDataRoom,
  isEditing,
  isMyDashboard,
  isPdfHiding,
  limitedUserEmail,
  moduleId,
  myDocuments,
  roomId,
  openDocRecipientsModal,
  webBaseUrl,
}) => {
  const { push } = useHistory();
  const { companyId } = useParams();
  const { search } = useLocation();
  const {
    account = {},
    account_id,
    // account_transaction = {},
    account_transaction_id,
    account_project_id,
    box_file_id,
    completed_timestamp,
    draft_timestamp,
    filename,
    file_token: { accessToken } = {},
    flat_features = [],
    label: roomLabel,
    panda_document_id,
    panda_data_timestamp,
    revision_count,
    recipients = [],
    room_document_id,
    sent_timestamp,
    status,
    template_variant,
    upload_timestamp,
  } = myDocument;
  const {
    customer_label: variant_label,
    filename: variant_filename,
    preview: templatePreview,
    template_type,
  } = template_variant || {};

  const { label, box_file_id: previewBoxFileId, preview } = templatePreview || {};
  const { customer_label } = template_type || {};

  /** Can remove filter when duplicate recipients removed on backend. */

  let dateLabel =
    sent_timestamp ||
    completed_timestamp ||
    upload_timestamp ||
    draft_timestamp ||
    panda_data_timestamp ||
    get(flat_features, '[0].updated_at', '');

  if (dateLabel) {
    dateLabel = dayjs(dateLabel).format('MM/DD/YYYY');
  } else {
    dateLabel = '--';
  }
  let taskPath = {
    pathname: `/workbench/${moduleId}/${account_project_id}/${account_transaction_id}/initialize`,
    search,
    state: { isClicked: true },
  };
  let path = `/cabinet/-1/${box_file_id}`;
  const isRedlining = revision_count > 0;
  let stateText =
    (isRedlining && 'Awaiting Approval') ||
    (box_file_id && !status && 'Uploaded') ||
    PandaDocStatusDict(status, isDataRoom);

  const isTaskConnected = !!account_transaction_id;
  const isSendingToTask =
    isTaskConnected && stateText !== 'Uploaded' && stateText !== 'Completed';

  const customerLabel =
    roomLabel ||
    customer_label ||
    variant_label ||
    flat_features[0]?.feature_type?.question_label ||
    label;
  const fileLabel = filename || variant_filename || 'Unknown name';

  const handleResendLinkEmail = (e, docId, email, passedAccountId) => {
    e.preventDefault();
    const message = `Are you sure you want to resend signature link to ${email}?`;
    if (window.confirm(message)) {
      dispatch(resendLinkEmail(docId, email, passedAccountId));
    }
  };
  const handleDealRoomRecipientLink = (e, roomCode) => {
    e.preventDefault();
    navigator.clipboard.writeText(`${webBaseUrl}/data-room/${roomCode}`);
    dispatch(setNotice('Room Link copied'));
  };
  const handleDocumentRecipientLink = (e, signatureLink) => {
    e.preventDefault();
    navigator.clipboard.writeText(signatureLink);
    dispatch(setNotice('Link copied'));
  };
  const handlePrimaryGotoClick = e => {
    if (e.defaultPrevented || isSimpleTable || (isAdmin && !!accessCode)) {
      return;
    }
    if (isMyDashboard) {
      dispatch(
        setCompany(
          account?.id,
          isSendingToTask ? taskPath : path,
          account?.id === currentAccountId,
        ),
      );
    } else {
      push(handlePath(isSendingToTask ? taskPath : path, companyId || account_id));
    }
  };
  const handleSecondaryGotoClick = e => {
    e.preventDefault();
    push(handlePath(!isSendingToTask ? taskPath : path, companyId || account_id));
  };
  const handleCabinetGotoClick = e => {
    e.preventDefault();
    dispatch(
      setCompany(
        account?.id,
        { pathname: !isSendingToTask ? taskPath : path, search },
        account?.id === currentAccountId,
      ),
    );
  };
  const handleDownloadFileClick = e => {
    e.preventDefault();
    var client = new XMLHttpRequest();
    client.open('GET', updatedUrl);
    client.withCredentials = true;
    client.responseType = 'blob';
    Object.entries(requestHeaders(true)).forEach(([key, value]) => {
      client.setRequestHeader(key, value);
    });
    client.onreadystatechange = () => {
      switch (client.readyState) {
        case 4:
          dispatch(setNotice('Starting Download.'));
          dispatch(
            createRoomHistory(
              accessCode,
              {
                action: 'download_document',
                box_file_id: box_file_id,
                type: 'download',
                label: `Downloaded ${customerLabel || fileLabel}`,
                sublabel: !!customerLabel ? fileLabel : '',
              },
              roomId,
            ),
          );
          const url = window.URL.createObjectURL(client.response);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
        default:
      }
    };
    client.send();
  };
  const handleSortUpClick = e => {
    e.preventDefault();
    dispatch(
      swapRoomDocuments(
        room_document_id,
        myDocuments[index - 1].room_document_id,
        roomId,
        accountId,
        accessCode,
      ),
    );
  };
  const handleSortDownClick = e => {
    e.preventDefault();
    dispatch(
      swapRoomDocuments(
        room_document_id,
        myDocuments[index + 1].room_document_id,
        roomId,
        accountId,
        accessCode,
      ),
    );
  };
  const handleRemoveDocument = e => {
    e.preventDefault();
    const message = `Are you sure you want to remove this document from the Data Room?`;
    if (window.confirm(message)) {
      dispatch(deleteRoomDocument(room_document_id, roomId, accountId, accessCode));
    }
  };

  const isSigned =
    recipients.findIndex(
      v =>
        v.signed &&
        [
          (currentUser?.email || '').toLowerCase(),
          isAdmin ? '' : (limitedUserEmail || '').toLowerCase(),
        ].includes(v.email.toLowerCase()),
    ) !== -1;
  const isRecipient =
    recipients.findIndex(v =>
      [
        (currentUser?.email || '').toLowerCase(),
        isAdmin ? '' : (limitedUserEmail || '').toLowerCase(),
      ].includes(v.email.toLowerCase()),
    ) !== -1;
  let filteredRecipients = [];
  recipients.forEach(el => {
    const isSignee = [
      (currentUser?.email || '').toLowerCase(),
      isAdmin ? '' : (limitedUserEmail || '').toLowerCase(),
    ].includes(el.email.toLowerCase());

    if (isAdmin) {
      if (isDataRoom && isSimpleTable && isRecipient && !isSigned && !isSignee) {
        return null;
      }
      if (isSimpleTable && !el.signature_link) {
        return null;
      }
      filteredRecipients.push(el);
    } else if (isSignee && !isSigned) {
      filteredRecipients.push(el);
    }
  });
  const isRecipientsShowing = stateText !== 'Completed' && filteredRecipients.length > 0;
  let isSimpleRow = isSimpleTable;

  if (isSimpleTable && isAdmin && filteredRecipients.length > 1) {
    isSimpleRow = false;
  }

  const updatedUrl = `${webBaseUrl}/api/${
    accessCode
      ? `guest/rooms/${accessCode}/file/${box_file_id}`
      : `account/box/file/${box_file_id}`
  }`;

  const isDocumentTemplate = !box_file_id && !!previewBoxFileId;
  const customLabel = isDocumentTemplate ? 'Preview Template' : `${stateText} Document`;
  const isDocumentUpload = !isRecipientsShowing && !!box_file_id;
  const isActionsShowing = isTaskConnected && !isEditing;
  const isSecondaryGotoShowing = !isMyDashboard && box_file_id;
  const secondaryGotoLabel = `View in ${!isSendingToTask ? 'Task View' : 'File Cabinet'}`;
  const isCabinetGotoShowing = isMyDashboard && box_file_id;
  const isDownloadShowing = !isEditing && !isTaskConnected && !!box_file_id;
  const boxFileId = box_file_id || previewBoxFileId;

  if (isSigned && stateText === 'Awaiting Signature') {
    stateText = 'Awaiting Other Signatures';
  }
  if (isSigned && stateText === 'Viewed' && isSigned && isSimpleRow) {
    stateText = 'Awaiting Other Signatures';
  }

  const [isEditingDocLabel, setIsEditingDocLabel] = useState(false);
  const [isEditDocSubmitting, setIsEditDocSubmitting] = useState(false);
  const [docLabelInput, setDocLabelInput] = useState(customerLabel);

  const handleDocLabelUpdate = e => {
    setIsEditDocSubmitting(true);
    dispatch(
      updateRoomDocument(
        { label: docLabelInput },
        room_document_id,
        roomId,
        accountId,
        accessCode,
      ),
    ).then(
      e => {
        setIsEditDocSubmitting(false);
        setIsEditingDocLabel(false);
      },
      error => setIsEditDocSubmitting(false),
    );
  };

  return (
    <div
      className={classnames('documentsSection__listRow', {
        firstRow: index === 0,
        'documentsSection__listRow--simpleTable': isSimpleRow,
        'documentsSection__listRow--roomAdmin': isAdmin && !!accessCode,
        'documentsSection__listRow--upload': isDocumentUpload,
      })}
      onClick={handlePrimaryGotoClick}
    >
      <div className="documentsSection__listItem first">
        <FilePreviewer
          accessCode={accessCode}
          boxFileId={boxFileId}
          boxToken={accessToken || boxToken}
          customLabel={customLabel}
          fileLabel={fileLabel || customerLabel}
          documentId={myDocument.id}
          height={80}
          isDocumentTemplate={isDocumentTemplate}
          isDataRoom={isDataRoom}
          isUserTable={isUserTable && !!boxFileId}
          isLinkOnHover
          isThumbnailShowing
          isPlaceholderShowing={!!isPdfHiding}
          labelPosition="hidden"
          roomId={roomId}
          previewLink={preview}
          width={80}
        />
      </div>
      <div
        className={classnames(
          'documentsSection__listItem documentsSection__listItem--label',
          {
            wide: !isRecipientsShowing,
            showPreview: myDocument.box_file_id,
            noLabel: !customerLabel && !isEditing,
          },
        )}
      >
        {(customerLabel || isEditing) && (
          <div className="documentsSection__docLabel">
            {!isEditingDocLabel && (
              <h2>{customerLabel || '*No Current Label. Add label by clicking >'}</h2>
            )}
            {isEditingDocLabel && (
              <InputStyledTextField
                className="documentsSection__docLabelInput"
                value={docLabelInput}
                isDisabled={isEditDocSubmitting}
                onChange={e => setDocLabelInput(e.target.value)}
                onBlur={e => handleDocLabelUpdate(e)}
              />
            )}
            {isEditing && !isEditingDocLabel && (
              <div className="documentsSection__docEditActions">
                <Button
                  buttonType="icon"
                  size="sm"
                  tooltip="Edit Document Label"
                  onClick={e => {
                    e.preventDefault();
                    setIsEditingDocLabel(!isEditingDocLabel);
                  }}
                >
                  <FontAwesomeIcon icon={['fal', 'edit']} />
                </Button>
                {!!roomLabel && (
                  <Button
                    buttonType="icon"
                    tooltip="Revert Label to default"
                    size="sm"
                    onClick={e => {
                      e.preventDefault();
                      dispatch(
                        updateRoomDocument(
                          { label: '' },
                          room_document_id,
                          roomId,
                          accountId,
                          accessCode,
                        ),
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'undo']} />
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
        <p>{fileLabel}</p>
      </div>
      {isMyDashboard && (
        <div className="documentsSection__listItem documentsSection__listItem--company">
          <h4>parent workspace</h4>
          <p>{account?.name || 'Not Found'}</p>
        </div>
      )}
      {isRecipientsShowing && (
        <div className="documentsSection__recipients">
          {!isSimpleRow && <h4>Recipients</h4>}
          {filteredRecipients.map(recipient => (
            <RecipientRow
              key={`document-recipient-${recipient.id}`}
              accountId={accountId}
              currentUser={currentUser}
              isAdmin={isAdmin}
              isSimpleTable={isSimpleRow}
              isDataRoom={isDataRoom}
              onDealRoomRecipientLink={handleDealRoomRecipientLink}
              onDocumentRecipientLink={handleDocumentRecipientLink}
              onResendLinkEmail={handleResendLinkEmail}
              panda_document_id={panda_document_id}
              recipient={recipient}
              status={status}
            />
          ))}
        </div>
      )}
      {!isRecipientsShowing && (isSimpleRow || !!isDocumentUpload) && (
        <div className="documentsSection__simpleItem">
          {!!box_file_id && (!isDataRoom || !isDocumentTemplate) && (
            <FilePreviewer
              accessCode={accessCode}
              boxFileId={boxFileId}
              boxToken={accessToken || boxToken}
              customLabel={customLabel}
              documentId={myDocument.id}
              fileLabel={fileLabel || customerLabel}
              height={80}
              isDocumentTemplate={isDocumentTemplate}
              isDataRoom={isDataRoom}
              isUserTable={isUserTable}
              isLinkOnHover
              isPlaceholderShowing={!!isPdfHiding}
              labelActionType="secondary"
              labelText={`View ${isDocumentTemplate ? 'Template' : 'Document'}`}
              previewLink={preview}
              roomId={roomId}
              width={80}
            />
          )}
          {/* {!!box_file_id && (!isDataRoom || !isDocumentTemplate) && (
            <Button
              onClick={e => {
                dispatch(getUserDocumentBoxToken(myDocument.id));
                // create way for user to view & dl document
              }}
              className="documentsSection__simpleAction"
              isFlat
              size="sm"
            >
              View {isDocumentTemplate ? 'Template' : 'Document'}
            </Button>
          )} */}
        </div>
      )}
      <div className="documentsSection__listItem documentsSection__listItem--last">
        <h4>{dateLabel}</h4>
        <h4>{stateText}</h4>
        {isActionsShowing && (
          <div className="documentsSection__listActions">
            {isSecondaryGotoShowing && (
              <Button
                buttonType="icon"
                size="sm"
                onClick={handleSecondaryGotoClick}
                tooltip={secondaryGotoLabel}
              >
                <FontAwesomeIcon icon={['fal', 'file-export']} />
              </Button>
            )}
            {isCabinetGotoShowing && (
              <Button
                buttonType="icon"
                size="sm"
                onClick={handleCabinetGotoClick}
                tooltip="View in File Cabinet"
              >
                <FontAwesomeIcon icon={['fal', 'file-export']} />
              </Button>
            )}
            {box_file_id && (
              <Button
                buttonType="icon"
                onClick={handleDownloadFileClick}
                size="sm"
                tooltip="Download file"
              >
                <FontAwesomeIcon icon={['fal', 'file-download']} />
              </Button>
            )}
            {isAdmin && (
              <Button
                buttonType="icon"
                onClick={ev => openDocRecipientsModal(ev, myDocument)}
                size="sm"
                tooltip="Edit Recipients"
              >
                <FontAwesomeIcon icon={['fal', 'user-plus']} />
              </Button>
            )}
          </div>
        )}
        {isDownloadShowing && (
          <div className="documentsSection__listActions documentsSection__listActions--open">
            <Button
              buttonType="icon"
              onClick={handleDownloadFileClick}
              size="sm"
              tooltip="Download file"
            >
              <FontAwesomeIcon icon={['fal', 'file-download']} />
            </Button>
            {isAdmin && (
              <Button
                buttonType="icon"
                onClick={ev => openDocRecipientsModal(ev, myDocument)}
                size="sm"
                tooltip="Edit Recipients"
              >
                <FontAwesomeIcon icon={['fal', 'user-plus']} />
              </Button>
            )}
          </div>
        )}
        {isEditing && (
          <div className="documentsSection__listActions documentsSection__listActions--open">
            <Button
              buttonType="icon"
              size="sm"
              isDisabled={index === 0}
              onClick={handleSortUpClick}
              tooltip="Move up"
            >
              <FontAwesomeIcon icon="arrow-up" />
            </Button>
            <Button
              buttonType="icon"
              size="sm"
              isDisabled={index + 1 === myDocuments.length}
              onClick={handleSortDownClick}
              tooltip="Move down"
            >
              <FontAwesomeIcon icon="arrow-down" />
            </Button>
            <Button
              buttonType="icon"
              isWarning
              size="sm"
              onClick={handleRemoveDocument}
              tooltip="Remove Document"
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
            <Button
              buttonType="icon"
              onClick={ev => openDocRecipientsModal(ev, myDocument)}
              size="sm"
              tooltip="Edit Recipients"
            >
              <FontAwesomeIcon icon={['fal', 'user-plus']} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const RecipientRow = ({
  accountId,
  currentUser,
  isSimpleTable,
  isDataRoom,
  onDealRoomRecipientLink,
  onDocumentRecipientLink,
  onResendLinkEmail,
  panda_document_id,
  recipient,
  status,
}) => {
  const {
    email,
    email_sent,
    first_name,
    last_name,
    room_access_code,
    signature_link,
    signed,
    signer,
  } = recipient;

  const [isActionsShowing, setIsActionsShowing] = useState(false);

  const handleShowActionsClick = e => {
    e.preventDefault();
    setIsActionsShowing(!isActionsShowing);
  };

  const recipientEmail = email.toLowerCase();
  const userEmail = (currentUser.email || '').toLowerCase();

  const recipientLabel =
    (!first_name && !last_name && email) || `${first_name || ''} ${last_name || ''}`;
  const isSigned = !isSimpleTable && !!signed && !!signer;
  const isSignedOrNotSigner = !isSimpleTable && (!signed || !signer);
  const recipientStatusLabel =
    (!email_sent && 'Email not sent') ||
    (status === 'document.draft' && 'Not yet sent') ||
    (!signer && 'Reviewer') ||
    'Not signed';
  const isActionsDropdownShowing = status !== 'document.draft' && isActionsShowing;
  const copyLinkLabel = `Copy ${
    (userEmail === recipientEmail && 'Your') || (!!signed && !signer && 'Reviewer') || ''
  } Document Link`;

  return (
    <>
      <div
        className={classnames('documentsSection__recipient', {
          'documentsSection__recipient--simple': isSimpleTable,
        })}
      >
        {isSimpleTable && !!signature_link && (
          <Button
            className="documentsSection__recipientAction"
            size="sm"
            href={signature_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            View / Sign
          </Button>
        )}
        {!isSimpleTable && (
          <>
            <p className="documentsSection__recipientLabel">{recipientLabel}</p>
            {isSigned && (
              <div className="documentsSection__recipientStatus">
                <p>
                  <FontAwesomeIcon icon={['fal', 'check']} />
                  Signed
                </p>
              </div>
            )}
            {isSignedOrNotSigner && (
              <div className="documentsSection__recipientStatus">
                <p onClick={handleShowActionsClick}>
                  <FontAwesomeIcon
                    className={signer ? 'documentsSection__recipientWarning' : ''}
                    icon={['fal', signer ? 'times' : 'check']}
                  />
                  <span>{recipientStatusLabel}</span>
                  <FontAwesomeIcon
                    className="documentsSection__recipientChevron"
                    icon={['fal', 'chevron-down']}
                  />
                </p>
                {isActionsDropdownShowing && (
                  <Dropdown
                    isOpen={isActionsShowing}
                    onClose={e => setIsActionsShowing(false)}
                  >
                    <h4>{email}</h4>
                    <Button
                      buttonType="secondary"
                      onClick={e =>
                        onResendLinkEmail(e, panda_document_id, email, accountId)
                      }
                      size="sm"
                    >
                      {email_sent === 0 ? 'Send email' : 'Resend email'}
                    </Button>
                    {!!room_access_code && !isDataRoom && (
                      <>
                        <Button
                          buttonType="secondary"
                          onClick={e => onDealRoomRecipientLink(e, room_access_code)}
                          size="sm"
                        >
                          Copy Deal Room Link
                        </Button>
                        {userEmail === recipientEmail && (
                          <Button
                            buttonType="secondary"
                            to={`/data-room/${room_access_code}`}
                            target="_blank"
                            onClick={e => e.stopPropagation()}
                            rel="noopener noreferrer"
                            size="sm"
                          >
                            View Deal Room
                          </Button>
                        )}
                      </>
                    )}
                    {!!signature_link && (
                      <>
                        <Button
                          buttonType="secondary"
                          onClick={e => onDocumentRecipientLink(e, signature_link)}
                          size="sm"
                        >
                          {copyLinkLabel}
                        </Button>
                        {userEmail === recipientEmail && (
                          <Button
                            buttonType="secondary"
                            href={signature_link}
                            target="_blank"
                            onClick={e => e.stopPropagation()}
                            rel="noopener noreferrer"
                            size="sm"
                          >
                            View Document
                          </Button>
                        )}
                      </>
                    )}
                  </Dropdown>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {isSimpleTable && (
        <div className="documentsSection__listActions documentsSection__listActions--open documentsSection__listActions--float">
          <Button
            buttonType="icon"
            onClick={e => onDocumentRecipientLink(e, signature_link)}
            size="sm"
            tooltip="Copy Link"
          >
            <FontAwesomeIcon icon={['fal', 'copy']} />
          </Button>
        </div>
      )}
    </>
  );
};

DocumentsSection.propTypes = {
  accessCode: PropTypes.string,
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  documents: PropTypes.array,
  hideToken: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isDataRoom: PropTypes.bool,
  isHeightUnset: PropTypes.bool,
  isMyDashboard: PropTypes.bool,
  isSectionOpenInit: PropTypes.bool,
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sectionLabel: PropTypes.string,
};
DocumentsSection.defaultProps = {
  moduleId: -1,
  documents: [],
};

export default DocumentsSection;
