import React from 'react';

import classnames from 'classnames';

import { InputFeedback, InputHelp, InputSublabel } from '..';

import './MultiToggle.scss';

const MultiToggle = props => {
  const {
    error,
    name,
    mode,
    isDisabled,
    label,
    questionHelp,
    sublabel,
    onClick,
    options = [],
  } = props;

  return (
    <div
      className={classnames('multiToggle', {
        'multiToggle--disabled': isDisabled,
        'multiToggle--error': !!error,
      })}
    >
      {!!label && (
        <label className="multiToggle__label" htmlFor={name}>
          {label}
          {questionHelp && <InputHelp text={questionHelp} />}
        </label>
      )}
      <div className="multiToggle__options">
        {options.map(option => (
          <ToggleOption
            key={`toggle-option-${name}-${option.mode || option.value}`}
            onClick={onClick}
            option={option}
            mode={mode}
          />
        ))}
      </div>
      <InputSublabel>{sublabel}</InputSublabel>
      <InputFeedback error={error} />
    </div>
  );
};

const ToggleOption = ({ onClick, option, mode }) => {
  const { disabled, label, mode: optionMode, value } = option;
  return (
    <div
      className={classnames('multiToggle__option', {
        'multiToggle__option--checked': optionMode === mode,
        'multiToggle__option--disabled': disabled || !value,
      })}
      onClick={() => onClick(option, mode)}
    >
      {label}
    </div>
  );
};

export default MultiToggle;
