export default {
  '-1': {
    icon: ['fal', 'home'],
    id: '-1',
    path: '/home',
    label: 'Home',
  },
  0: {
    icon: ['fal', 'home'],
    id: '0',
    path: '/home',
    label: 'Home',
  },
  home: {
    icon: ['fal', 'home'],
    id: 'home',
    path: '/home',
    label: 'Home',
  },
  1: {
    icon: ['fal', 'books'],
    id: '1',
    path: '/workflows',
    label: 'Workflow Library',
  },
  2: {
    icon: ['fal', 'books'],
    id: '2',
    isModule: true,
    order: 1,
    path: {
      pathname: '/workflows',
      hash: '#entity',
    },
    label: 'Entity',
  },
  3: {
    icon: ['fal', 'books'],
    id: '3',
    isModule: true,
    order: 2,
    path: {
      pathname: '/workflows',
      hash: '#governance',
    },
    label: 'Governance',
  },
  4: {
    icon: ['fal', 'books'],
    id: '4',
    isModule: true,
    order: 4,
    path: {
      pathname: '/workflows',
      hash: '#team',
    },
    label: 'Team',
  },
  9: {
    icon: ['fal', 'books'],
    id: '9',
    isModule: true,
    order: 4,
    path: {
      pathname: '/workflows',
      hash: '#equity',
    },
    label: 'Equity',
  },
  6: {
    icon: ['fal', 'books'],
    id: '6',
    isModule: true,
    order: 5,
    path: {
      pathname: '/workflows',
      hash: '#financing',
    },
    label: 'Financing',
  },
  5: {
    icon: ['fal', 'books'],
    id: '5',
    isModule: true,
    order: 6,
    path: {
      pathname: '/workflows',
      hash: '#customers-partners',
    },
    label: 'Customers | Partners',
  },
  7: {
    icon: ['fal', 'file-signature'],
    id: '7',
    isModule: true,
    order: 7,
    path: {
      pathname: '/workflows',
      hash: '#other',
    },
    label: 'Other',
  },
  10: {
    icon: ['fal', 'users'],
    id: '10',
    path: '/savvi-partners',
    label: 'Workspace Partners',
  },
  tasks: {
    icon: ['fal', 'ballot-check'],
    id: 'tasks',
    path: '/tasks',
    label: 'Tasks',
  },
  rooms: {
    icon: ['fal', 'layer-group'],
    id: 'rooms',
    path: '/rooms',
    label: 'Rooms',
  },
  submitted_tasks: {
    icon: ['fal', 'history'],
    id: 'submitted_tasks',
    path: '/submitted-tasks',
    label: 'Submitted Tasks',
  },
  documents: {
    icon: ['fal', 'copy'],
    id: 'documents',
    path: '/documents',
    label: 'Documents',
  },
  workflows: {
    icon: ['fal', 'books'],
    id: 'workflows',
    path: '/workflows',
    label: 'Workflow Library',
  },
  account: {
    icon: ['fal', 'cog'],
    id: 'account',
    path: '/settings',
    label: 'Account',
  },
};
