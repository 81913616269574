export const FETCH_FEATURES_FOR_ROOM_REQUEST =
  'savvi/data/FETCH_FEATURES_FOR_ROOM_REQUEST';
export const FETCH_FEATURES_FOR_ROOM_SUCCESS =
  'savvi/data/FETCH_FEATURES_FOR_ROOM_SUCCESS';
export const FETCH_FEATURES_FOR_ROOM_FAILED = 'savvi/data/FETCH_FEATURES_FOR_ROOM_FAILED';
export const SET_FEATURES_FOR_ROOM = 'savvi/data/SET_FEATURES_FOR_ROOM';

export const FETCH_ACCOUNT_ROOMS_REQUEST = 'savvi/data/FETCH_ACCOUNT_ROOMS_REQUEST';
export const FETCH_ACCOUNT_ROOMS_SUCCESS = 'savvi/data/FETCH_ACCOUNT_ROOMS_SUCCESS';
export const FETCH_ACCOUNT_ROOMS_FAILED = 'savvi/data/FETCH_ACCOUNT_ROOMS_FAILED';

export const FETCH_USER_ROOMS_REQUEST = 'savvi/data/FETCH_USER_ROOMS_REQUEST';
export const FETCH_USER_ROOMS_SUCCESS = 'savvi/data/FETCH_USER_ROOMS_SUCCESS';
export const FETCH_USER_ROOMS_FAILED = 'savvi/data/FETCH_USER_ROOMS_FAILED';
export const SET_USER_ROOMS = 'savvi/data/SET_USER_ROOMS';

export const FETCH_INSPECT_ACCESS_CODE_REQUEST =
  'savvi/data/FETCH_INSPECT_ACCESS_CODE_REQUEST';
export const FETCH_INSPECT_ACCESS_CODE_SUCCESS =
  'savvi/data/FETCH_INSPECT_ACCESS_CODE_SUCCESS';
export const FETCH_INSPECT_ACCESS_CODE_FAILED =
  'savvi/data/FETCH_INSPECT_ACCESS_CODE_FAILED';

export const FETCH_ROOM_REQUEST = 'savvi/data/FETCH_ROOM_REQUEST';
export const FETCH_ROOM_SUCCESS = 'savvi/data/FETCH_ROOM_SUCCESS';
export const FETCH_ROOM_FAILED = 'savvi/data/FETCH_ROOM_FAILED';

export const FETCH_ROOM_BY_ID_REQUEST = 'savvi/data/FETCH_ROOM_BY_ID_REQUEST';
export const FETCH_ROOM_BY_ID_SUCCESS = 'savvi/data/FETCH_ROOM_BY_ID_SUCCESS';
export const FETCH_ROOM_BY_ID_FAILED = 'savvi/data/FETCH_ROOM_BY_ID_FAILED';
export const FETCH_ROOM_BY_USER_REQUEST = 'savvi/data/FETCH_ROOM_BY_USER_REQUEST';
export const FETCH_ROOM_BY_USER_SUCCESS = 'savvi/data/FETCH_ROOM_BY_USER_SUCCESS';
export const FETCH_ROOM_BY_USER_FAILED = 'savvi/data/FETCH_ROOM_BY_USER_FAILED';
export const SET_ROOM = 'savvi/data/SET_ROOM';

export const PUT_ROOM_REQUEST = 'savvi/data/PUT_ROOM_REQUEST';
export const PUT_ROOM_SUCCESS = 'savvi/data/PUT_ROOM_SUCCESS';
export const PUT_ROOM_FAILED = 'savvi/data/PUT_ROOM_FAILED';

export const FETCH_ROOM_LINKS_REQUEST = 'savvi/data/FETCH_ROOM_LINKS_REQUEST';
export const FETCH_ROOM_LINKS_SUCCESS = 'savvi/data/FETCH_ROOM_LINKS_SUCCESS';
export const FETCH_ROOM_LINKS_FAILED = 'savvi/data/FETCH_ROOM_LINKS_FAILED';
export const SET_ROOM_LINKS = 'savvi/data/SET_ROOM_LINKS';

export const POST_ROOM_LINK_REQUEST = 'savvi/data/POST_ROOM_LINK_REQUEST';
export const POST_ROOM_LINK_SUCCESS = 'savvi/data/POST_ROOM_LINK_SUCCESS';
export const POST_ROOM_LINK_FAILED = 'savvi/data/POST_ROOM_LINK_FAILED';

export const PUT_ROOM_LINK_REQUEST = 'savvi/data/PUT_ROOM_LINK_REQUEST';
export const PUT_ROOM_LINK_SUCCESS = 'savvi/data/PUT_ROOM_LINK_SUCCESS';
export const PUT_ROOM_LINK_FAILED = 'savvi/data/PUT_ROOM_LINK_FAILED';

export const DELETE_ROOM_LINK_REQUEST = 'savvi/data/DELETE_ROOM_LINK_REQUEST';
export const DELETE_ROOM_LINK_SUCCESS = 'savvi/data/DELETE_ROOM_LINK_SUCCESS';
export const DELETE_ROOM_LINK_FAILED = 'savvi/data/DELETE_ROOM_LINK_FAILED';

export const FETCH_ROOM_DOCUMENTS_REQUEST = 'savvi/data/FETCH_ROOM_DOCUMENTS_REQUEST';
export const FETCH_ROOM_DOCUMENTS_SUCCESS = 'savvi/data/FETCH_ROOM_DOCUMENTS_SUCCESS';
export const FETCH_ROOM_DOCUMENTS_FAILED = 'savvi/data/FETCH_ROOM_DOCUMENTS_FAILED';
export const SET_ROOM_DOCUMENTS = 'savvi/data/SET_ROOM_DOCUMENTS';

export const POST_ROOM_DOCUMENT_REQUEST = 'savvi/data/POST_ROOM_DOCUMENT_REQUEST';
export const POST_ROOM_DOCUMENT_SUCCESS = 'savvi/data/POST_ROOM_DOCUMENT_SUCCESS';
export const POST_ROOM_DOCUMENT_FAILED = 'savvi/data/POST_ROOM_DOCUMENT_FAILED';

export const PUT_ROOM_DOCUMENT_REQUEST = 'savvi/data/PUT_ROOM_DOCUMENT_REQUEST';
export const PUT_ROOM_DOCUMENT_SUCCESS = 'savvi/data/PUT_ROOM_DOCUMENT_SUCCESS';
export const PUT_ROOM_DOCUMENT_FAILED = 'savvi/data/PUT_ROOM_DOCUMENT_FAILED';

export const DELETE_ROOM_DOCUMENT_REQUEST = 'savvi/data/DELETE_ROOM_DOCUMENT_REQUEST';
export const DELETE_ROOM_DOCUMENT_SUCCESS = 'savvi/data/DELETE_ROOM_DOCUMENT_SUCCESS';
export const DELETE_ROOM_DOCUMENT_FAILED = 'savvi/data/DELETE_ROOM_DOCUMENT_FAILED';

export const POST_ROOM_REPORT_REQUEST = 'savvi/data/POST_ROOM_REPORT_REQUEST';
export const POST_ROOM_REPORT_SUCCESS = 'savvi/data/POST_ROOM_REPORT_SUCCESS';
export const POST_ROOM_REPORT_FAILED = 'savvi/data/POST_ROOM_REPORT_FAILED';

export const DELETE_ROOM_REPORT_REQUEST = 'savvi/data/DELETE_ROOM_REPORT_REQUEST';
export const DELETE_ROOM_REPORT_SUCCESS = 'savvi/data/DELETE_ROOM_REPORT_SUCCESS';
export const DELETE_ROOM_REPORT_FAILED = 'savvi/data/DELETE_ROOM_REPORT_FAILED';

export const POST_ROOM_FEATURE_REQUEST = 'savvi/data/POST_ROOM_FEATURE_REQUEST';
export const POST_ROOM_FEATURE_SUCCESS = 'savvi/data/POST_ROOM_FEATURE_SUCCESS';
export const POST_ROOM_FEATURE_FAILED = 'savvi/data/POST_ROOM_FEATURE_FAILED';

export const PUT_ROOM_FEATURE_REQUEST = 'savvi/data/PUT_ROOM_FEATURE_REQUEST';
export const PUT_ROOM_FEATURE_SUCCESS = 'savvi/data/PUT_ROOM_FEATURE_SUCCESS';
export const PUT_ROOM_FEATURE_FAILED = 'savvi/data/PUT_ROOM_FEATURE_FAILED';

export const DELETE_ROOM_FEATURE_REQUEST = 'savvi/data/DELETE_ROOM_FEATURE_REQUEST';
export const DELETE_ROOM_FEATURE_SUCCESS = 'savvi/data/DELETE_ROOM_FEATURE_SUCCESS';
export const DELETE_ROOM_FEATURE_FAILED = 'savvi/data/DELETE_ROOM_FEATURE_FAILED';

export const POST_ROOM_USER_REQUEST = 'savvi/data/POST_ROOM_USER_REQUEST';
export const POST_ROOM_USER_SUCCESS = 'savvi/data/POST_ROOM_USER_SUCCESS';
export const POST_ROOM_USER_FAILED = 'savvi/data/POST_ROOM_USER_FAILED';

export const PUT_ROOM_USER_REQUEST = 'savvi/data/PUT_ROOM_USER_REQUEST';
export const PUT_ROOM_USER_SUCCESS = 'savvi/data/PUT_ROOM_USER_SUCCESS';
export const PUT_ROOM_USER_FAILED = 'savvi/data/PUT_ROOM_USER_FAILED';

export const DELETE_ROOM_USER_REQUEST = 'savvi/data/DELETE_ROOM_USER_REQUEST';
export const DELETE_ROOM_USER_SUCCESS = 'savvi/data/DELETE_ROOM_USER_SUCCESS';
export const DELETE_ROOM_USER_FAILED = 'savvi/data/DELETE_ROOM_USER_FAILED';

export const SWAP_ROOM_LINKS_REQUEST = 'savvi/data/SWAP_ROOM_LINKS_REQUEST';
export const SWAP_ROOM_LINKS_SUCCESS = 'savvi/data/SWAP_ROOM_LINKS_SUCCESS';
export const SWAP_ROOM_LINKS_FAILED = 'savvi/data/SWAP_ROOM_LINKS_FAILED';

export const SWAP_ROOM_DOCUMENTS_REQUEST = 'savvi/data/SWAP_ROOM_DOCUMENTS_REQUEST';
export const SWAP_ROOM_DOCUMENTS_SUCCESS = 'savvi/data/SWAP_ROOM_DOCUMENTS_SUCCESS';
export const SWAP_ROOM_DOCUMENTS_FAILED = 'savvi/data/SWAP_ROOM_DOCUMENTS_FAILED';

export const SWAP_ROOM_REPORTS_REQUEST = 'savvi/data/SWAP_ROOM_REPORTS_REQUEST';
export const SWAP_ROOM_REPORTS_SUCCESS = 'savvi/data/SWAP_ROOM_REPORTS_SUCCESS';
export const SWAP_ROOM_REPORTS_FAILED = 'savvi/data/SWAP_ROOM_REPORTS_FAILED';

export const FETCH_ROOM_TOKEN_REQUEST = 'savvi/data/FETCH_ROOM_TOKEN_REQUEST';
export const FETCH_ROOM_TOKEN_SUCCESS = 'savvi/data/FETCH_ROOM_TOKEN_SUCCESS';
export const FETCH_ROOM_TOKEN_FAILED = 'savvi/data/FETCH_ROOM_TOKEN_FAILED';
export const SET_ROOM_TOKEN = 'savvi/data/SET_ROOM_TOKEN';

export const UPDATE_LINK_REQUEST = 'savvi/data/UPDATE_LINK_REQUEST';
export const UPDATE_LINK_SUCCESS = 'savvi/data/UPDATE_LINK_SUCCESS';
export const UPDATE_LINK_FAILED = 'savvi/data/UPDATE_LINK_FAILED';

export const FETCH_LINK_BY_ID_REQUEST = 'savvi/data/FETCH_LINK_BY_ID_REQUEST';
export const FETCH_LINK_BY_ID_SUCCESS = 'savvi/data/FETCH_LINK_BY_ID_SUCCESS';
export const FETCH_LINK_BY_ID_FAILED = 'savvi/data/FETCH_LINK_BY_ID_FAILED';

export const GET_LINK_REQUEST = 'savvi/data/GET_LINK_REQUEST';
export const GET_LINK_SUCCESS = 'savvi/data/GET_LINK_SUCCESS';
export const GET_LINK_FAILED = 'savvi/data/GET_LINK_FAILED';

export const POST_LINK_REQUEST = 'savvi/data/POST_LINK_REQUEST';
export const POST_LINK_SUCCESS = 'savvi/data/POST_LINK_SUCCESS';
export const POST_LINK_FAILED = 'savvi/data/POST_LINK_FAILED';

export const SET_LINK = 'savvi/data/SET_LINK';

export const FETCH_ROOM_CODES_REQUEST = 'savvi/data/FETCH_ROOM_CODES_REQUEST';
export const FETCH_ROOM_CODES_SUCCESS = 'savvi/data/FETCH_ROOM_CODES_SUCCESS';
export const FETCH_ROOM_CODES_FAILED = 'savvi/data/FETCH_ROOM_CODES_FAILED';

export const POST_ROOM_CODE_REQUEST = 'savvi/data/POST_ROOM_CODE_REQUEST';
export const POST_ROOM_CODE_SUCCESS = 'savvi/data/POST_ROOM_CODE_SUCCESS';
export const POST_ROOM_CODE_FAILED = 'savvi/data/POST_ROOM_CODE_FAILED';

export const DELETE_ROOM_CODE_REQUEST = 'savvi/data/DELETE_ROOM_CODE_REQUEST';
export const DELETE_ROOM_CODE_SUCCESS = 'savvi/data/DELETE_ROOM_CODE_SUCCESS';
export const DELETE_ROOM_CODE_FAILED = 'savvi/data/DELETE_ROOM_CODE_FAILED';
export const SET_ROOM_CODES = 'savvi/data/SET_ROOM_CODES';

export const FETCH_ROOM_ACCESS_LOG_REQUEST = 'savvi/data/FETCH_ROOM_ACCESS_LOG_REQUEST';
export const FETCH_ROOM_ACCESS_LOG_SUCCESS = 'savvi/data/FETCH_ROOM_ACCESS_LOG_SUCCESS';
export const FETCH_ROOM_ACCESS_LOG_FAILED = 'savvi/data/FETCH_ROOM_ACCESS_LOG_FAILED';
export const SET_ROOM_ACCESS_LOG = 'savvi/data/SET_ROOM_ACCESS_LOG';

export const FETCH_ROOM_HISTORY_REQUEST = 'savvi/data/FETCH_ROOM_HISTORY_REQUEST';
export const FETCH_ROOM_HISTORY_SUCCESS = 'savvi/data/FETCH_ROOM_HISTORY_SUCCESS';
export const FETCH_ROOM_HISTORY_FAILED = 'savvi/data/FETCH_ROOM_HISTORY_FAILED';
export const SET_ROOM_HISTORY = 'savvi/data/SET_ROOM_HISTORY';
export const POST_ROOM_HISTORY_REQUEST = 'savvi/data/POST_ROOM_HISTORY_REQUEST';
export const POST_ROOM_HISTORY_SUCCESS = 'savvi/data/POST_ROOM_HISTORY_SUCCESS';
export const POST_ROOM_HISTORY_FAILED = 'savvi/data/POST_ROOM_HISTORY_FAILED';
export const DELETE_ROOM_HISTORY_REQUEST = 'savvi/data/DELETE_ROOM_HISTORY_REQUEST';
export const DELETE_ROOM_HISTORY_SUCCESS = 'savvi/data/DELETE_ROOM_HISTORY_SUCCESS';
export const DELETE_ROOM_HISTORY_FAILED = 'savvi/data/DELETE_ROOM_HISTORY_FAILED';

export const FETCH_ROOM_PARTICIPANTS_REQUEST =
  'savvi/data/FETCH_ROOM_PARTICIPANTS_REQUEST';
export const FETCH_ROOM_PARTICIPANTS_SUCCESS =
  'savvi/data/FETCH_ROOM_PARTICIPANTS_SUCCESS';
export const FETCH_ROOM_PARTICIPANTS_FAILED = 'savvi/data/FETCH_ROOM_PARTICIPANTS_FAILED';
export const SET_ROOM_PARTICIPANTS = 'savvi/data/SET_ROOM_PARTICIPANTS';
