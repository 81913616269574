import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { onLogout } from '../../redux/modules/User/operations';
import SavviLoading from '../SavviLoading';

import './LoadingComponent.scss';

const Loading = ({ history: { push = () => {} } = {} }) => {
  const dispatch = useDispatch();
  // Handle the loading state
  const [isPastDelay, setPastDelay] = useState(false);

  useEffect(() => {
    let timer1 = setTimeout(() => {
      setPastDelay(true);
    }, 7000);
    if (isPastDelay === true && isEmpty(localStorage.id_tokens)) {
      dispatch(onLogout(push));
    }
    return () => clearTimeout(timer1);
  }, [dispatch, isPastDelay, push, setPastDelay]);

  if (isPastDelay) {
    return (
      <div className="loadingComponent loadingComponent--error">
        <p>Sorry, there was a problem loading the page.</p>
        <a className="loadingComponent__reload" href={window.location.href}>
          Reload page and try again.
        </a>
        <p>or try logging out then back in.</p>
        <a
          className="loadingComponent__logout"
          href="#logout"
          onClick={e => {
            e.preventDefault();
            dispatch(onLogout(push));
          }}
        >
          Logout
        </a>
        <p>If issue persists, contact us at</p>
        <a className="loadingComponent__reload" href="mailto:support@savvi.legal">
          support@savvi.legal
        </a>
      </div>
    );
  }
  return (
    <div className="loadingComponent">
      <span className="loadingComponent__loading">Loading...</span>
      {/* <FontAwesomeIcon icon="spinner" spin /> */}
      <SavviLoading />
    </div>
  );
};
export default Loading;
