import { decodeToken, getStoredToken } from '../modules/User/utils';

export const requestHeaders = (auth = false, passedToken = '', shouldCheckExp) => {
  const token = getStoredToken(passedToken === 'user' ? '0' : passedToken);

  let Authorization = `Bearer ${token}`;
  let hasAuth = !!auth && !!token;
  let brandMeta = JSON.parse(localStorage.getItem('brandMeta') || '{}');
  if (
    !brandMeta.web_base_url ||
    brandMeta.web_base_url === 'https://app.example.com' ||
    window.location.href.includes('localhost')
  ) {
    brandMeta.web_base_url = process.env.REACT_APP_BASEURL;
  }

  if (shouldCheckExp && hasAuth) {
    const decodedToken = decodeToken(token);
    const secondsSinceEpoch = Math.ceil(Date.now() / 1000);
    hasAuth = decodedToken.exp > secondsSinceEpoch; // Check if exp is fresh
  }
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Request-Headers': '*',
    'Access-Control-Allow-Methods': 'GET',
    'Access-Control-Allow-Origin': brandMeta.web_base_url,
  };

  return hasAuth ? { ...headers, Authorization } : headers;
};
