import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '..';

import './Dropdown.scss';

const Dropdown = ({
  children,
  className = '',
  onClose,
  left,
  upward,
  isDefaultPrevented,
}) => {
  useEffect(() => {
    window.addEventListener('click', onClose);
    return () => window.removeEventListener('click', onClose);
  }, [onClose]);
  return (
    <>
      <div
        className={classNames('dropdown', className, {
          'dropdown--left': left,
          'dropdown--upward': upward,
        })}
        onClick={e => {
          if (isDefaultPrevented) {
            onClose(e);
          }
        }}
      >
        {children}
      </div>
    </>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  isDefaultPrevented: PropTypes.bool,
};

export const DropdownAction = ({
  children,
  className,
  isDefaultPrevented,
  left,
  upward,
  Toggle,
  size,
}) => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const handleClose = () => {
      setIsShowing(false);
    };
    if (isShowing) {
      window.addEventListener('click', handleClose);
    }
    return () => window.removeEventListener('click', handleClose);
  }, [isShowing, setIsShowing]);

  return (
    <div className={`dropdown__wrapper ${className}`}>
      <Toggle
        onClick={e => {
          if (isDefaultPrevented) {
            e.preventDefault();
          }
          setIsShowing(!isShowing);
        }}
      />
      {isShowing && (
        <div
          className={classNames('dropdown', {
            'dropdown--left': left,
            'dropdown--upward': upward,
          })}
          onClick={e => {
            if (isDefaultPrevented) {
              e.preventDefault();
            }
            setIsShowing(false);
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

DropdownAction.defaultProps = {
  isDefaultPrevented: true,
  Toggle: toggleProps => (
    <Button buttonType="icon" {...toggleProps}>
      <FontAwesomeIcon icon="ellipsis-v" />
    </Button>
  ),
};

export default Dropdown;
