import React, { useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './DataLedgerModal.scss';
import { generateBem } from '../../utils/generateBem';
import { Button, MyModal } from '../common';
import { Field, Form, Formik } from 'formik';
import { StyledCheckbox } from '../formik';
import { InputStyledTextField } from '../inputs';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyNameWithHooks } from '../../redux/modules/Formation/selectors';
import { setNotice } from '../../redux/modules/UI/actions';
import InputStyledCheckbox from '../common/InputStyledCheckbox/InputStyledCheckbox';

const bem = generateBem('dataLedgerModal');

const DataLedgerModal = ({
  account,
  handleClose,
  isOpen,
  selectedPortfolio,
  json,
  resourcesList,
  question_label,
}) => {
  const dispatch = useDispatch();
  const { feature_types = [], resource_label } = json || {};
  const { name } = account || {};
  const companyName = useSelector(CompanyNameWithHooks);
  let shouldShowCompanyName = false;
  if (
    selectedPortfolio &&
    resourcesList.findIndex(resource => !!resource.companyName) !== -1
  ) {
    shouldShowCompanyName = true;
  }

  const defaultFilename = `${selectedPortfolio || name || companyName} ${
    question_label || resource_label
  } Ledger ${new Intl.DateTimeFormat().format(new Date())}`;
  const [filename, setFilename] = useState(defaultFilename);

  let initVals = useMemo(() => {
    let featureTypes = {};
    let resources = {};
    if (shouldShowCompanyName) {
      featureTypes['Company Name'] = '1';
    }
    feature_types.forEach(item => {
      featureTypes[item.feature_name] = '1';
    });
    resourcesList.forEach(item => {
      resources[item.resourceName] = '1';
    });
    return { featureTypes, resources };
  }, [feature_types, resourcesList, shouldShowCompanyName]);

  const exportToCsv = (filename, rows) => {
    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    dispatch(setNotice('Starting Download.'));

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleSubmit = formikValues => {
    let csvFile = [[]];
    Object.entries(formikValues.featureTypes).forEach(([key, val]) => {
      if (val === '1') {
        csvFile[0].push(key);
      }
    });
    resourcesList.forEach((resource, resourceIndex) => {
      const isResourceSelected =
        Object.entries(formikValues.resources).findIndex(
          ([key, val]) => key === resource.resourceName && val === '1',
        ) !== -1;
      if (isResourceSelected) {
        let csvRow = [];
        if (selectedPortfolio && resource.companyName) {
          csvRow.push(resource.companyName);
        }
        resource.resourceItems.forEach(item => {
          const csvHeader = item.feature_name;
          const isHeaderSelected = csvFile[0].findIndex(i => i === csvHeader) !== -1;
          if (isHeaderSelected) {
            if (item.feature_type.feature_type === 'document' && item.label !== '--') {
              csvRow.push('File Uploaded');
            } else {
              csvRow.push(item.label);
            }
          }
        });
        csvFile.push(csvRow);
      }
    });
    exportToCsv((filename || defaultFilename) + '.csv', csvFile);
    handleClose();
  };

  return (
    <MyModal
      className={bem('')}
      overlayClassName={bem('overlay')}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <div className={bem('top')}>
        <h1>Download {question_label || resource_label} Report as CSV</h1>
        <FontAwesomeIcon className={bem('exit')} onClick={handleClose} icon="times" />
      </div>
      <div className={bem('body')}>
        <InputStyledTextField
          name="ledger-filename"
          value={filename}
          onChange={ev => setFilename(ev.target.value)}
          label="CSV Filename"
        />
        <Formik initialValues={initVals} onSubmit={handleSubmit}>
          {formikProps => (
            <LedgerForm
              formikProps={formikProps}
              shouldShowCompanyName={shouldShowCompanyName}
              resourcesList={resourcesList}
              feature_types={feature_types}
              handleClose={handleClose}
            />
          )}
        </Formik>
      </div>
    </MyModal>
  );
};

function getCheckboxMeta(vals) {
  let isAllChecked = true;
  let isAllUnchecked = true;
  vals.forEach(val => {
    if (val === '1') {
      isAllUnchecked = false;
    } else {
      isAllChecked = false;
    }
  });
  if (isAllChecked) {
    return 'checked';
  } else if (isAllUnchecked) {
    return 'unchecked';
  } else {
    return 'indeterminate';
  }
}

const LedgerForm = ({
  formikProps,
  resourcesList,
  feature_types,
  handleClose,
  shouldShowCompanyName,
}) => {
  const { values, setValues } = formikProps;
  // handleSubmit, handleChange, handleBlur, values, errors
  const [isResourcesShowing, setIsResourcesShowing] = useState(false);

  const valuesMeta = useMemo(() => {
    return {
      featureTypes: getCheckboxMeta(Object.values(values.featureTypes)),
      resources: getCheckboxMeta(Object.values(values.resources)),
    };
  }, [values]);
  let totalSelected = Object.values(formikProps.values.resources).filter(
    item => item === '1',
  ).length;

  let toggleLabel = 'Hide resources';
  if (!isResourcesShowing) {
    toggleLabel = `Edit resources to download (${totalSelected} of ${resourcesList.length} selected)`;
  }

  const handleFeatureTypesCheckboxes = () => {
    let featureTypes = {};
    if (
      valuesMeta.featureTypes === 'checked' ||
      valuesMeta.featureTypes === 'indeterminate'
    ) {
      Object.keys(values.featureTypes).forEach(key => {
        featureTypes[key] = '0';
      });
    } else {
      Object.keys(values.featureTypes).forEach(key => {
        featureTypes[key] = '1';
      });
    }
    setValues({ featureTypes, resources: values.resources });
  };

  const handleResourcesCheckboxes = () => {
    let resources = {};
    if (valuesMeta.resources === 'checked' || valuesMeta.resources === 'indeterminate') {
      Object.keys(values.resources).forEach(key => {
        resources[key] = '0';
      });
    } else {
      Object.keys(values.resources).forEach(key => {
        resources[key] = '1';
      });
    }
    setValues({ featureTypes: values.featureTypes, resources });
  };

  return (
    <Form>
      <InputStyledCheckbox
        checked={valuesMeta.featureTypes === 'checked'}
        indeterminate={valuesMeta.featureTypes === 'indeterminate'}
        name="data-ledger-feature-types-select"
        onClick={handleFeatureTypesCheckboxes}
        parentStyle={bem('checkboxHeader')}
        label="Select which inputs to download:"
        value={valuesMeta.featureTypes === 'checked'}
      />
      {shouldShowCompanyName && (
        <Field
          key={`ledger-feature-company-name`}
          name="featureTypes.Company Name"
          component={StyledCheckbox}
          label="Company Name"
        />
      )}
      {feature_types.map((item, index) => (
        <Field
          key={`ledger-feature-${index}-${item.feature_name}`}
          name={`featureTypes.${item.feature_name}`}
          component={StyledCheckbox}
          label={item.feature_name}
        />
      ))}
      <Button
        buttonType="link"
        size="sm"
        className={bem('formLink')}
        onClick={() => setIsResourcesShowing(!isResourcesShowing)}
      >
        {toggleLabel}
      </Button>
      {isResourcesShowing && (
        <>
          <InputStyledCheckbox
            checked={valuesMeta.resources === 'checked'}
            indeterminate={valuesMeta.resources === 'indeterminate'}
            name="data-ledger-resources-all"
            onClick={handleResourcesCheckboxes}
            parentStyle={bem('checkboxHeader')}
            label="Select which resources to download:"
            value={valuesMeta.resources === 'checked'}
          />
          {/* <h2 className={bem('formHeader')}>Select which resources to download:</h2> */}
          {resourcesList.map((item, index) => (
            <Field
              key={`ledger-resource-${index}-${item.resourceName}`}
              name={`resources.${item.resourceName}`}
              component={StyledCheckbox}
              label={item.resourceName}
            />
          ))}
        </>
      )}
      <div className={bem('actions')}>
        <Button buttonType="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit">Download CSV</Button>
      </div>
    </Form>
  );
};

export default DataLedgerModal;
