import React, { Component } from 'react';
import { connect } from 'react-redux';

import { clearErrors } from '../../../redux/modules/User/actions';
import { resetPassword } from '../../../redux/modules/User/operations';
import { resetSchema, validateField, validateForm } from '../../../utils/Validations';
import { Button } from '../../common';
import { InputPassword } from '../../inputs';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      password: '',
      isPwPlainText: false,
    };
  }

  UNSAFE_componentWillReceiveProps({ errors }) {
    this.setState({ errors });
  }

  validateForm = () => {
    this.setState({ errors: {} });
    const errors = validateForm(resetSchema, {
      password: this.state.password,
    });
    this.setState({ errors: errors || {} });
    return !errors;
  };

  validateField = e => {
    const error = validateField(e.target, resetSchema, this.state.errors);
    this.setState({ errors: error || {} });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.validateForm()) {
      const { password } = this.state;
      const { clearErrors, code, onSubmit } = this.props;
      clearErrors();
      onSubmit({ code, password });
    }
  };

  render() {
    const { errors, password, isPwPlainText } = this.state;
    const { isFetching } = this.props;
    return (
      <div className="page__forgot-password" style={{ paddingTop: 40 }}>
        <div className="inner">
          <form className="form form--forgot-password">
            <h2 className="form__heading">
              Reset your Password
              <small className="form__sub-heading">Enter a new password below.</small>
            </h2>
            <InputPassword
              error={errors.password}
              inputClassName="-js-reset-password__input-password"
              label={'Enter New Password'}
              name={'password'}
              value={password}
              icon="lock"
              onChange={this.handleChange}
              onBlur={this.validateField}
              hasToggle={true}
              onToggle={() => this.setState({ isPwPlainText: !isPwPlainText })}
              isPlainText={isPwPlainText}
            />
            {errors.form && <div className="alert form__error">{errors.form}</div>}
            <div className="login__action">
              <Button
                className="-js-reset-password__btn-main"
                isFetching={isFetching}
                isDisabled={isFetching}
                onClick={this.handleSubmit}
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.defaultProps = {
  errors: {},
  code: '',
};

const mapStateToProps = ({ Auth, UI }, ownProps) => {
  const { errors, isFetching } = Auth;
  return {
    isFetching,
    errors,
    code: ownProps.code,
  };
};

const mapDispatchToProps = (dispatch, { push }) => {
  return {
    onSubmit: data => dispatch(resetPassword(data, push)),
    clearErrors: () => dispatch(clearErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
