import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { getRoomWithUpdatedFeatures } from '../../../redux/modules/Data/selectors';
import { getAuthAccount, IsAuthenticated } from '../../../redux/modules/User/selectors';
import { getStoredToken } from '../../../redux/modules/User/utils';
import handlePath from '../../../utils/handlePath';
import { ReactComponent as BuildingsSVG } from '../../../assets/images/buildings.svg';

import { SidebarNavLink } from '../../Sidebar';

const TeamsNav = ({ isCollapsed, isDataRoom, isPartner, isLPPortalShowing }) => {
  const { goBack, push, replace } = useHistory();
  const { pathname, search, state = {} } = useLocation();
  const { companyId, room_access_code } = useParams();
  const isUserAuthenticated = useSelector(IsAuthenticated);
  const currentAccount = useSelector(getAuthAccount);
  const dataRoom = useSelector(state =>
    getRoomWithUpdatedFeatures(state, room_access_code),
  );

  let { account_id, is_admin_user, room = {} } = dataRoom;
  account_id = account_id || room.account_id;
  // TODO: Check if any accounts match the room account_id instead of just current account
  if (account_id !== parseInt(currentAccount.accountId)) {
    is_admin_user = false;
  }

  const { data_access_code, documents = [], account_project_id, type } = room || {};
  const { isToggled, roomCode } = state || {};
  const passedState = {
    isToggled,
    roomCode: roomCode || room_access_code || data_access_code,
  };

  const isUnauthLPPortalShowing =
    !is_admin_user &&
    documents.findIndex(
      doc => doc.box_file_id && doc.filename.includes('Subscription Agreement'),
    ) !== -1 &&
    (type === 'deal' || type === 'report');

  return (
    <>
      <div className="sidebar__nav">
        <SidebarNavLink
          SVG={BuildingsSVG}
          isReplace
          isCollapsed={isCollapsed}
          link={{
            pathname: '/company-select',
            search,
            state: passedState,
          }}
          text={'Workspaces'}
        />
        {isPartner && (
          <SidebarNavLink
            icon={['fal', 'users-class']}
            isReplace
            isCollapsed={isCollapsed}
            link={{ pathname: '/teams', search, state: passedState }}
            text={'Teams'}
          />
        )}
        <SidebarNavLink
          icon={['fal', 'house-user']}
          isActive={(match, location) => {
            return location.pathname.includes('dashboard');
          }}
          isCollapsed={isCollapsed}
          isReplace
          link={{ pathname: '/dashboard', search, state: passedState }}
          text={'Dashboard'}
        />
        {(isLPPortalShowing || isUnauthLPPortalShowing) && (
          <SidebarNavLink
            icon={['fal', 'chart-pie']}
            isActive={(match, location) => {
              return location.pathname.includes('lp-portal');
            }}
            isCollapsed={isCollapsed}
            isReplace
            link={{ pathname: '/lp-portal', search, state: passedState }}
            text={'LP Portal'}
          />
        )}
        <SidebarNavLink
          icon={['fal', 'cog']}
          isActive={(match, location) => {
            return location.pathname.includes('user-settings');
          }}
          isCollapsed={isCollapsed}
          isReplace
          link={{ pathname: '/user-settings', search, state: passedState }}
          text={'User Profile'}
        />
      </div>
      {isUserAuthenticated &&
        account_project_id &&
        (!!is_admin_user || account_id === currentAccount.accountId) && (
          <div
            className="workbench-nav__exit"
            onClick={e =>
              push(
                handlePath(
                  `/workbench/0/${account_project_id}/-1/initialize`,
                  account_id || currentAccount.accountId,
                ),
              )
            }
          >
            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
            View Project
          </div>
        )}
      {!!roomCode && !isDataRoom && (
        <div
          className="workbench-nav__exit"
          onClick={() => {
            replace(`/data-room/${roomCode}/overview`);
          }}
        >
          <FontAwesomeIcon icon={['fal', 'chevron-left']} />
          Back
        </div>
      )}
      {!isDataRoom && companyId !== '0' && !pathname.includes('dashboard') && !roomCode && (
        <div
          className="workbench-nav__exit"
          onClick={() => {
            let updatedCompanyId = companyId;
            if (!companyId || companyId === '-1') {
              updatedCompanyId = getStoredToken().accountId;
            }
            if (search) {
              push(handlePath({ pathname: '/home', search }, updatedCompanyId));
            } else if (isToggled) {
              goBack();
            } else {
              push(handlePath('/home', updatedCompanyId));
            }
          }}
        >
          {isToggled && <FontAwesomeIcon icon={['fal', 'chevron-left']} />}
          {isToggled ? 'Back' : 'To Home'}
          {!isToggled && <FontAwesomeIcon icon={['fal', 'chevron-right']} />}
        </div>
      )}
    </>
  );
};

export default TeamsNav;
