// import { loadState } from './redux/utils/localStorage';
import './utils/fontawesome';

// import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';

import Root from './containers/Root';
import configureStore, { history } from './redux/store/configureStore';
import { unregister } from './registerServiceWorker';

import './assets/styles/base.scss';
/** TODO: Adjust components to not use WorkbenchForm.scss so we don't have do this */
import './views/WorkbenchForm/WorkbenchForm.scss';

unregister();

// const persistedState = loadState();
const store = configureStore();

render(<Root store={store} history={history} />, document.getElementById('root'));
