import { combineReducers } from 'redux';

import { createReducer, fetchingReducer } from '../../utils';
import * as Types from './types';

const accountTodosReducer = createReducer([])({
  [Types['SET_ACCOUNT_TODOS']]: (state, { payload }) => [...payload],
});

const assigneeOptionsReducer = createReducer([])({
  [Types['SET_ASSIGNEE_OPTIONS']]: (
    state,
    { payload, accountId, projectTemplateId },
  ) => ({
    ...state,
    [`${accountId}|${projectTemplateId}`]: [...payload],
  }),
});

const teamTodosReducer = createReducer([])({
  [Types['SET_TEAM_TODOS']]: (state, { payload }) => [...payload],
});

const isFetching = fetchingReducer('todos');

export default combineReducers({
  isFetching,
  accountTodos: accountTodosReducer,
  assigneeOptions: assigneeOptionsReducer,
  teamTodos: teamTodosReducer,
});
