import toUpper from 'lodash-es/toUpper';

import * as Types from './types';

/**
 * General action that handles updating a specific data model.
 * Can be used to get Module, Task, Subtask, or Deliverable
 *
 * @param {('module'|'task'|'subtask'|'deliverable')} modelName
 *  :: used to match a Type to the action. (single)
 * @return {object} Action object with 'type' property.
 */
export function setModelByKey(payload, key) {
  return {
    type: Types[`SET_${toUpper(key)}`],
    payload,
  };
}

/**
 * General action that handles setting multiple of a specific data model.
 * Can be used to set Module, Task, Subtask, or Deliverable
 *
 * @param {('module'|'task'|'subtask'|'deliverable')} modelName
 *  :: used to match a Type to the action. (single)
 * @return {object} Action object with 'type' property.
 */
export function setModelsByKey(payload, key) {
  return {
    type: Types[`SET_${toUpper(key)}S`],
    payload,
  };
}

export function setModelsBySlug(payload, key) {
  return {
    type: Types[`SET_${toUpper(key)}S`],
    payload,
  };
}

/**
 * General action that handles updating a specific data model.
 * Can be used to get Module, Task, Subtask, or Deliverable
 *
 * @param {('module'|'task'|'subtask'|'deliverable')} modelName
 *  :: used to match a Type to the action. (single)
 * @return {object} Action object with 'type' property.
 */
export function updateModelByKey(payload, key) {
  return {
    type: Types[`UPDATE_${toUpper(key)}`],
    payload,
  };
}

export function setResourcesByTypeId(payload, resourceTypeId) {
  return {
    type: Types[`SET_RESOURCES_BY_TYPE_ID`],
    payload,
    resourceTypeId,
  };
}

export function setResourceById(payload, resourceTypeId) {
  return {
    type: Types[`SET_RESOURCE_BY_ID`],
    payload,
    resourceTypeId,
  };
}

export function clearErrors(payload, key) {
  return {
    type: Types['CLEAR_ERRORS'],
  };
}

export function setFeatures(payload, slug) {
  return {
    type: Types['SET_FEATURES'],
    payload,
    slug,
  };
}

export function patchFeature(payload, individualName, featureName) {
  return {
    type: Types['PATCH_FEATURE'],
    payload,
    featureName,
    individualName,
  };
}

export function setFeature(payload, individualName, featureName) {
  return {
    type: Types['SET_FEATURE'],
    payload,
    individualName,
    featureName,
  };
}

export function setAccountInfo(payload) {
  return {
    type: Types['SET_ACCOUNT_INFO'],
    payload,
  };
}

export function removeIndividual(resourceId) {
  return {
    type: Types['REMOVE_INDIVIDUAL'],
    resourceId,
  };
}
