import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

export default (myModule = '') =>
  (state = {}, { type, payload }) => {
    // if (!isModule(myModule, type)) {
    //   return state;
    // }
    if (isCleared(type)) {
      return {};
    }

    if (isFailed(type)) {
      const message = get(payload, 'message', payload);
      return {
        ...state,
        message: isEmpty(message) ? 'An error has occurred' : message,
      };
    } else {
      return state;
    }
  };

// const isModule = (myModule, type) => !!myModule && type.indexOf(myModule) !== -1;
const isFailed = type => type.endsWith('FAILED') || type.endsWith('SET_ERROR');
const isCleared = type => type.endsWith('CLEAR_ERRORS');
