import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { apiMiddleware } from 'redux-api-middleware';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { createBrowserHistory } from 'history';

import payload, { myInterceptor } from '../middleware/PayloadHandler';
import createRootReducer from '../reducers';

// import { persistState } from '../utils/localStorage';
export const history = createBrowserHistory();

const logger = createLogger({
  collapsed: true,
  duration: true,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const configureStore = preloadedState => {
  const store = createStore(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(
        thunk,
        myInterceptor,
        apiMiddleware,
        payload,
        routerMiddleware(history),
        logger,
      ),
    ),
    // composeEnhancers(applyMiddleware(thunk, apiMiddleware, payload, logger)),
  );

  // store.subscribe(() => {
  //   persistState(store.getState());
  // });

  return store;
};

export default configureStore;
