import {
  SET_BOX_TOKEN,
  SET_TEMP_TOKEN_FETCH_INITIALIZED,
  SET_USER_DOCUMENT_BOX_TOKEN,
} from './types';

export const setBoxToken = token => {
  return {
    type: SET_BOX_TOKEN,
    payload: { token },
  };
};

export const setUserDocumentBoxToken = (payload, documentId) => {
  return {
    type: SET_USER_DOCUMENT_BOX_TOKEN,
    payload,
    documentId,
  };
};

export const setTempTokenFetchInitialized = () => {
  return {
    type: SET_TEMP_TOKEN_FETCH_INITIALIZED,
    isInitialized: true,
  };
};
